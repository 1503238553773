import axios from "axios";
import store from '../store';

export function retornarDadosSindico() {
    return axios.get('/sindico', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function putSindico(sindico) {
    return axios.put('/sindico', sindico, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function resetarSenhaSmsSindico(dados) {
    return axios.post('/resetar-senha-sms', dados);
}

export function resetarSenhaEmailSindico(dados) {
    return axios.post('/resetar-senha-email', dados);
}
