var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-bloco"},[_c('div',{staticClass:"pc-titleBloco"},[_c('h3',[_vm._v("Contratos")]),(_vm.listagemContratos)?_c('div',{staticClass:"pull-right pc-title-legenda"},[_vm._v(" Informações encontradas: "+_vm._s(_vm.listagemContratos.total)+" ")]):_vm._e()]),_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),(_vm.listagemContratos)?_c('div',{staticClass:"pc-tabela"},[_vm._m(0),_vm._l((_vm.listagemContratos.contratos),function(contrato){return _c('div',{key:contrato.idcontrato,staticClass:"pc-tabela--row"},[_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('div',{staticClass:"pc-tabela--td-contentIcon"},[_vm._m(1,true),_c('p',{attrs:{"data-title":"Titulo"}},[_vm._v(" "+_vm._s(contrato.titulo)+" ")])])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Empreendimento"}},[_vm._v(" "+_vm._s(contrato.unidade.empreendimento.nome)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Bloco"}},[_vm._v(" "+_vm._s(contrato.unidade.bloco.nome)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Unidade"}},[_vm._v(" "+_vm._s(contrato.unidade.nome)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Tipo de Contrato"}},[_vm._v(" "+_vm._s(contrato.tipo)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1 justify-content-center"},[(contrato.arquivo)?_c('a',{staticClass:"pc-btnGhost pc-btnGhost-small pc-btnGhost-primary",attrs:{"href":contrato.arquivo.url,"target":"_blank"},on:{"click":function($event){return _vm.trakingMixpanel(
								'Meus Contratos',
								'Baixar contrato',
								true,
								true
							)}}},[_vm._v(" Baixar Contrato ")]):_vm._e()])])}),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"pb-0 pt-3"},[_c('vue-paginate-al',{attrs:{"totalPage":Math.ceil(_vm.listagemContratos.total / _vm.paginacao.limit),"current-page":Math.round(_vm.paginacao.offset / _vm.paginacao.limit) + 1,"myData":'listagemContratos.contratos',"withNextPrev":false,"customActiveBGColor":_vm.configuracoes.cor.primaria},on:{"btnClick":_vm.montarListagem}})],1)])],2):_c('div',[_vm._v("Nenhuma informação encontrada.")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--row pc-tabela--head"},[_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Titulo")])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Empreendimento")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Bloco")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Unidade")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Tipo de Contrato")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--td-contentIcon-icon pc-tabela--td-contentIcon-pdf"},[_c('span',[_vm._v("PDF")])])
}]

export { render, staticRenderFns }