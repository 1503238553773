<template>
    <div class="pc-bloco mb-20" v-if="oportunidades.length">
        <div class="pc-titleBloco m-b-10">
            <h3>Oportunidades</h3>
        </div>
        <VueSlickCarousel class="pc-slide-oportunidades" v-bind="settings">
            <a
                class="--slide"
                v-for="(oportunidade, index) in oportunidades"
                v-if="!oportunidade.idmarketplace"
                :key="index"
                :href="oportunidade.link ? oportunidade.link.url : null"
                :target="
                    oportunidade.link && oportunidade.link.abertura === 'mesma_pagina'
                    ? '_self'
                    : '_blank'
                "
            >
                <div class="--content">
                    <img
                        v-if="oportunidade.imagem"
                        :src="oportunidade.imagem.url
                            .replace('[[LARGURA]]', 'x')
                            .replace('[[ALTURA]]', 'x')"
                        alt=""
                    >
                </div>
            </a>
            <router-link
                class="--slide"
                v-for="(oportunidade, index) in oportunidades"
                v-if="oportunidade.idmarketplace"
                :key="index"
                :to="{ name: 'portal-beneficios', params: {idmarketplace: oportunidade.idmarketplace} }"
            >
                <div class="--content">
                    <img
                        :src="oportunidade.url"
                        alt=""
                    >
                    <div class="--info">
                        <h3>{{ oportunidade.nome_loja }}</h3>
                        <p>{{ oportunidade.descricao_card }}</p>
                    </div>
                </div>
            </router-link>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { getBanner } from "@/services/BannerService";
import { getPortalBeneficios } from "@/services/PortalBeneficios";

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
        settings: {
            dots: true,
            arrows: false,
            focusOnSelect: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 20,
            responsive: [
                {
                breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        },
        oportunidades: [],
    };
  },
    beforeMount() {
        getBanner("home_sidebar")
            .then((response) => {
                let dispositivoMobile = window.screen.width < 768;
                let banners = [];
                for (let elemento of response.data.data) {
                    if (
                        (dispositivoMobile &&
                            elemento.imagem.dispositivo_exibicao == "desktop") ||
                        (!dispositivoMobile &&
                            elemento.imagem.dispositivo_exibicao == "mobile")
                    ) {
                        continue;
                    }
                    this.oportunidades.push(elemento);
                }
            })
            .catch()
        ;
        getPortalBeneficios(0, 15)
            .then((response) => {
                for (let elemento of response.data.marketplaces) {
                    this.oportunidades.push(elemento);
                }
            })
            .catch()
        ;
    },
};
</script>
<style>
    .pc-slide-oportunidades{
        .--slide{
            padding: 0 10px;
            .--content{
                border: 1px solid #F6F7F8;
                border-radius: 4px;
                padding: 10px;
                transition: all 0.35s;
                
                img{
                    width: 100%;
                    height: 220px;
                }
    
                .--info{
                    margin-top: 10px;
                    h3{
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0 0 5px 0;
                        color: #333;
                        font-family: CircularStd-Medium;
                    }
                    p{
                        color: #D2222A;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        margin: 0;
                        font-family: CircularStd-Medium;
                    }
                }
            }

            &:hover{
                .--content{
                    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.0797913);
                }
            }
        }

        .slick-list {
            padding-bottom: 10px;
        }

        .slick-dots{
            display: flex !important;
            justify-content: center;
            margin-top: 15px;
            gap: 10px;

            li{
                button{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #ddd;
                    border: none;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    color: transparent;
                    font-size: 0;
                    transition: all 0.35s;

                    &:hover{
                        background-color: #D2222A;
                    }
                }

                &.slick-active{
                    button{
                        background-color: #D2222A;
                    }
                }
            }
        }
    }
</style>
