<style>
.pc-tabela.pc-tabela-cor .pc-tabela--row:before {
    z-index: initial;
}

.pc-tabela.pc-tabela-cor .pc-tabela--row .pc-tabela--td {
    z-index: initial;
}
</style>
<template>
    <div class="pc-bloco">
        <div class="pc-titleBloco">
            <div>
                <h3>Demonstrativo de IR</h3>
                <p>
                    As informações a serem apresentadas abaixo são
                    empreendimentos adquiridos pelo cliente e servem para
                    obter-se uma filtragem nos dados que serão exibidos no
                    relatório de demonstrativo de valores pagos.
                </p>
            </div>
        </div>
        <div class="pc-selectAno col-lg-4 col-12">
            <SelectInput
                titulo="Ano Base"
                :options="anosImposto"
                :clearable="false"
                :valor.sync="anoEscolhido"
                @update:valor="mudarAnoBase(anoEscolhido)"
            />
        </div>
        <div class="pull-right pc-title-legenda" v-if="listagemImpostoRenda">
            Informações encontradas: {{ listagemImpostoRenda.total }}
        </div>
        <LoadingComponent :isLoading="isLoading"/>
        <div class="pc-tabela" v-if="listagemImpostoRenda">
            <div class="pc-tabela--row pc-tabela--head">
                <div class="pc-tabela--td pc-f3">
                    <p>Empreendimento</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p>Bloco</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p>Unidade</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p>Ano Base</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p>Descrição</p>
                </div>
                <div class="pc-tabela--td pc-f1 pc-column-option"></div>
            </div>
            <div
                v-for="impostoRenda in listagemImpostoRenda.demonstrativos"
                class="pc-tabela--row"
            >
                <div class="pc-tabela--td pc-f3">
                    <p data-title="Empreendimento">
                        {{ unidade.empreendimento.nome }}
                    </p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p data-title="Bloco">{{ unidade.bloco.nome }}</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p data-title="Unidade">{{ unidade.nome }}</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p data-title="Ano Base">{{ anoEscolhido }}</p>
                </div>
                <div class="pc-tabela--td pc-f1">
                    <p data-title="Descrição">{{ impostoRenda.descricao }}</p>
                </div>
                <div class="pc-tabela--td pc-f1 pc-column-option">
                    <p data-title="Download">
                        <a
                            class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
                            :href="impostoRenda.url_demonstrativo"
                            target="_blank"
                        >Baixar</a>
                    </p>
                </div>
            </div>
            <PaginateInput
                    :total="listagemImpostoRenda.total"
                    :limit="paginacao.limit"
                    :offset="paginacao.offset"
                    @paginacao="montarListagem"
            />
        </div>
        <div v-else>Nenhuma informação encontrada.</div>
    </div>
</template>

<script>
import { getIrCV } from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import SelectInput from "@/components/SelectInput";
import {mapGetters, mapState} from "vuex";
import {objetoTraking} from "../../../plugins/Utils";
import Vue from "vue";

export default {
    emits: ["error", "exibirAviso"],

    components: {
        LoadingComponent,
        PaginateInput,
        SelectInput,
    },

    computed: {
        ...mapGetters({
            acessoComo: "getAcessoComo",
        }),
        ...mapState({
            unidade: (state) => state.unidades.unidade,
        }),
    },

    data() {
        return {
            listagemImpostoRenda: null,
            anoEscolhido: null,
            anosImposto: null,
            paginacao: {
                offset: 0,
                limit: 30,
            },
            isLoading: false,
        };
    },

    beforeMount() {
        const anoAtual = new Date().getFullYear();
        this.anosImposto = [anoAtual - 1, anoAtual - 2, anoAtual - 3];
        this.anoEscolhido = this.anosImposto[0];

        this.montarListagem(1, this.anoEscolhido);
    },

    methods: {
        montarListagem(pagina, ano) {
            this.isLoading = true;
            getIrCV(
                (pagina - 1) * this.paginacao.limit,
                this.paginacao.limit,
                this.unidade.idunidade,
                ano
            )
                .then((response) => {
                    this.listagemImpostoRenda = response.data;
                })
                .catch((error) => {
                    if (error.response.status != 404) {
                        this.$emit("error", error.response.data);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        mudarAnoBase(ano) {
            this.montarListagem(1, ano)
        },
        trakingMixpanel(acao, subacao, intencao, ateracao) {
            this.$mixpanel.track(
                Vue.prototype.$eventoMixPanel,
                objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
            );
        },
    },
};
</script>

<style scoped>
.pull-right {
    text-align: right;
}
</style>
