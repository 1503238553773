<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<h3>Pesquisas</h3>
			<div class="pull-right pc-title-legenda" v-if="listagemPesquisas">
				Informações encontradas: {{ listagemPesquisas.total }}
			</div>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-tabela" v-if="listagemPesquisas">
			<div class="pc-tabela--row pc-tabela--head">
				<div class="pc-tabela--td pc-f2">
					<p>Título da pesquisa</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Data de início</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Data de finalização</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Status de resposta</p>
				</div>
				<div class="pc-tabela--td pc-f1">
                    Opções
                </div>
			</div>
			<div
				v-for="pesquisa in listagemPesquisas.pesquisas"
				v-bind:key="pesquisa.idpesquisa_pessoa"
				class="pc-tabela--row"
			>
				<div class="pc-tabela--td pc-f2">
					<p data-title="Título da pesquisa">
						{{ pesquisa.pesquisa }}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Data de início">
						{{
							pesquisa.data_inicio
								| dateParse("YYYY-MM-DD")
								| dateFormat("DD/MM/YYYY")
						}}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Data de finalização">
						{{
							pesquisa.data_fim
								| dateParse("YYYY-MM-DD")
								| dateFormat("DD/MM/YYYY")
						}}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p
						v-if="pesquisa.resposta.respondido"
						class="pc-labelStatus"
						style="background: green"
					>
						Respondido
					</p>
					<p
						v-else-if="pesquisa.responder_url"
						class="pc-labelStatus"
						style="background: red"
					>
						Não respondido
					</p>
					<p v-else class="pc-labelStatus" style="background: black">
						Expirado
					</p>
				</div>
				<div class="pc-tabela--td pc-f1 justify-content-center">
					<div>
						<a
							v-on:click="
								trakingMixpanel(
									'Minhas Pesquisas',
									'Responder Pesquisa',
									true,
									true
								)
							"
							v-if="pesquisa.responder_url"
							size="sm" 
							class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
							:class="acessoComo ? 'disabled' : null"
							:id="pesquisa.idpesquisa_pessoa"
							@click="loadClientInfo(pesquisa)"
							>
							Responder Pesquisa
						</a>
					</div>
					<!-- <a
						v-on:click="
							trakingMixpanel(
								'Minhas Pesquisas',
								'Responder Pesquisa',
								true,
								true
							)
						"
						v-if="pesquisa.responder_url"
						class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
						:class="acessoComo ? 'disabled' : null"
						:href="acessoComo ? null : pesquisa.responder_url"
						target="_blank"
					>
						Responder Pesquisa
					</a> -->
				</div>
			</div>
			<PaginateInput
				v-if="listagemPesquisas.total"
				:total="listagemPesquisas.total"
				:limit="paginacao.limit"
				:offset="paginacao.offset"
				@paginacao="montarListagem"
			/>
		</div>
		<div v-else>Nenhuma informação encontrada.</div>
		<b-modal :id="'modal-pesquisa'" size="xl" @hidden="clearClientInfo">
			<template slot="modal-title">{{this.nomePesquisa}}</template>
			<iframe :src="this.urlModal" title="description"></iframe>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PaginateInput from "@/components/PaginateInput";
import LoadingComponent from "@/components/LoadingComponent";
import { getPesquisas } from "@/services/PesquisaService";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	emits: ["error"],

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
		}),
		
	},

	components: {
		LoadingComponent,
		PaginateInput,
	},

	data() {
		return {
			listagemPesquisas: null,
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
			urlModal: "",
			nomePesquisa: "",
		};
	},

	beforeMount() {
		this.montarListagem(1);
	},

	methods: {
		loadClientInfo(item) {
			this.urlModal = item.responder_url
			this.nomePesquisa = item.pesquisa
			this.$bvModal.show("modal-pesquisa")
		},
		clearClientInfo() {
			this.urlModal = "";
			this.nomePesquisa = ""
			this.montarListagem(1);
		},
		montarListagem(pagina) {
			this.isLoading = true;
			getPesquisas(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit,
			)
				.then((response) => {
					this.listagemPesquisas = response.data;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Minhas Pesquisas",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>
