import axios from "axios";
import store from '../store';

export function getBoletosSegundaVia(offset, limit, idUnidade) {
    return axios.get(`/financeiro/boletos/${idUnidade}/segunda-via`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getBoletosSegundaViaCV(offset, limit, idUnidade) {
    return axios.get(`/financeiro-cv/boletos/${idUnidade}/segunda-via`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getExtratoCV(offset, limit, idUnidade) {
    return axios.get(`/financeiro-cv/extrato/${idUnidade}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getIrCV(offset, limit, idUnidade, ano) {
    return axios.get(`/financeiro-cv/informe-rendimentos/${idUnidade}/${ano}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getEmpreendimentosIr(offset, limit) {
    return axios.get(`/financeiro/unidades/imposto-de-renda`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function gerarBoleto(codigoTitulo, codigoParcela) {
    return axios.get(`/financeiro/boleto/gerar/${codigoTitulo}/${codigoParcela}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function gerarRelatorioImpostoDeRenda(idEmpreendimento, ano, idUnidade) {
    return axios.put(`/financeiro/enviar-imposto-de-renda/${idEmpreendimento}/${ano}/${idUnidade}`, null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarImpostoDeRendaLink(idEmpreendimento, ano) {
    return axios.get(`/financeiro/retornar-imposto-de-renda/${idEmpreendimento}/${ano}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getExtrato(offset, limit) {
    return axios.get(`/financeiro/extrato`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getSaldoDevedorLink(offset, limit) {
    return axios.get(`/financeiro/saldo-devedor`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function enviarExtrato() {
    return axios.put(`/financeiro/extrato/enviar`, null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
export function baixarExtrato() {
    return axios.get(`/financeiro/extrato/gerar`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
export function getParcelasAntecipacao(offset, limit, idUnidade) {
    return axios.get(`/financeiro/antecipacao-parcelas/${idUnidade}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}
export function postCalcularAntecipacao(dados) {
    return axios.post(`/financeiro/calcular-antecipacao`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function postEfetuarAntecipacao(dados) {
    return axios.post(`/financeiro/efetuar-antecipacao`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getParcelasConfirmadas(idUnidade) {
    return axios.get(`/financeiro/parcelas-confirmadas/${idUnidade}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function postEfetuarReparcelamento(dados) {
    return axios.post(`/financeiro/reparcelamento`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}