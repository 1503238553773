<template>
  <div class="pc-login-form--wrap">
    <TituloLogin />
    <div class="pc-btns-Login">
      <router-link :to="{ name: 'login' }"> Acesse sua conta </router-link>
      <router-link :class="'active'" :to="{ name: 'cadastre-se' }">
        Cadastre-se
      </router-link>
      <router-link :to="{ name: 'loginSindico' }" v-if="configuracoes.tela_login.exibir_acesso_sindico">
        Acesse como síndico
      </router-link>
    </div>
    <Aviso
      id="aviso"
      :exibir="alerta.exibir"
      :mensagem="alerta.mensagem"
      :tipo="alerta.tipo"
    />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form v-if="exibirForm" @submit.prevent="handleSubmit(cadastrar)">
        <div id="cadastre-se" class="form-group">
          <LoadingComponent :isLoading="isLoading" />
          <div class="form-group">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                id="nome"
                type="text"
                class="form-control"
                placeholder="Nome"
                name="nome"
                required="required"
                v-model="formulario.nome"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
            </ValidationProvider>
          </div>
            <SelectDocumentoInternacional />
          <label class="form-group--label">
            Somente será aceito um cadastro por CPF/CNPJ.
          </label>
          <div class="form-group">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                id="documento"
                type="text"
                inputmode="numeric"
                v-mask="documentoInternacional ? null : mask"
                name="documento"
                class="form-control"
                v-model="formulario.documento"
                :placeholder="documentoInternacional ? 'Identificador do documento' : 'CPF ou CNPJ'"
                @keypress="documentoInternacional ? null : onlyNumber"
                @keyup="() => formatarMascaraCPFCNPJ(!documentoInternacional)"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
            </ValidationProvider>
          </div>
          <div v-if="dataNascObrigatoria" class="form-group">
              <input
                v-mask="'##/##/####'"
                id="data_nasc"
                type="text"
                name="data_nasc"
                class="form-control input-datebr"
                v-model="formulario.data_nasc"
                placeholder="Data de Nascimento"
              />
          </div>
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="form-group pc-login-password">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <vue-tel-input
                    class="form-control input-tel"
                    v-model="formulario.telefone"
                    v-bind="bindProps"
                    id="telefone"
                    name="telefone"
                    required="required"
                  >
                  </vue-tel-input>
                  <label class="error">
                    <span>{{ errors[0] }}</span>
                  </label>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="form-group pc-login-password">
                <vue-tel-input
                  class="form-control input-tel"
                  v-model="formulario.celular"
                  v-bind="bindProps"
                  id="celular"
                  name="celular"
                  required="required"
                >
                </vue-tel-input>
              </div>
            </div>
          </div>
          <div class="form-group">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                type="email"
                v-model="formulario.email"
                name="email"
                placeholder="E-mail"
                id="email"
                class="form-control input-email"
                required="required"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
            </ValidationProvider>
          </div>
          <div class="form-group pc-login-password">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                :type="exibirSenha ? 'text' : 'password'"
                name="senha"
                v-model="formulario.senha"
                placeholder="Insira sua senha"
                id="senha"
                :class="[
                  'form-control',
                  senha_regex && formulario.senha
                    ? {
                        'is-invalid': !senha_regex.test(formulario.senha),
                      }
                    : '',
                ]"
                required="required"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
              <a
                href="#"
                @click.prevent="showPassword()"
                class="pc-login-showPassword"
              >
                <div v-if="exibirSenha">
                  <IconEye></IconEye>
                </div>
                <div v-else>
                  <IconEyeSlash></IconEyeSlash>
                </div>
              </a>
            </ValidationProvider>
            <label
              v-if="
                configuracoes.especifico_cv.senha_portal_cliente &&
                configuracoes.especifico_cv.senha_portal_cliente.mensagem
              "
              class="form-group--label"
            >
              {{ configuracoes.especifico_cv.senha_portal_cliente.mensagem }}
            </label>
          </div>
          <div class="form-group pc-login-password">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                :type="exibirSenhaConfirma ? 'text' : 'password'"
                name="confirma"
                placeholder="Confirme sua senha"
                id="confirma"
                v-model="formulario.confirma"
                :class="[
                  'form-control',
                  formulario.confirma
                    ? {
                        'is-invalid': formulario.senha != formulario.confirma,
                      }
                    : '',
                ]"
                required="required"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
              <a
                href="#"
                @click.prevent="showPasswordConfirma()"
                class="pc-login-showPassword"
              >
                <div v-if="exibirSenhaConfirma">
                  <IconEye></IconEye>
                </div>
                <div v-else>
                  <IconEyeSlash></IconEyeSlash>
                </div>
              </a>
            </ValidationProvider>
          </div>
          <ReCaptcha ref="recaptcha" @verified="enviarFormulario" />
          <button
            type="submit"
            name="button"
            class="pc-btnBox pc-btnBox--fullW"
            v-bind:disabled="botaoDisable"
          >
            <span>{{ tituloBotao }}</span>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Aviso from "@/components/Aviso";
import ReCaptcha from "@/components/ReCaptcha";
import SelectInput from "@/components/SelectInput";
import {
  onlyNumber,
  validarEmail,
  validarDocumento,
  validarData,
} from "@/plugins/Utils";
import { cadastrarPessoa } from "@/services/PessoasService";
import LoadingComponent from "@/components/LoadingComponent";
import IconEye from "@/components/icons/IconEye";
import IconEyeSlash from "@/components/icons/IconEyeSlash";
import { mapGetters } from "vuex";
import TituloLogin from "@/pages/inicio/components/TituloLogin";
import { removerMascaraTelefone } from "../../plugins/Utils";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import SelectDocumentoInternacional from "./components/SelectDocumentoInternacional/SelectDocumentoInternacional";
import store from "../../store";
Vue.use(VueTelInput);

export default {
  name: "Cadastro",

  components: {
      SelectDocumentoInternacional,
    TituloLogin,
    IconEyeSlash,
    IconEye,
    Aviso,
    ReCaptcha,
    SelectInput,
    LoadingComponent,
    VueTelInput,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
        documentoInternacional: "getDocumentoInternacional",
    }),
  },

  data() {
    return {
      mask: '###.###.###-##',
      isLoading: false,
      exibirSenha: false,
      exibirSenhaConfirma: false,
      exibirForm: true,
      botaoDisable: false,
      senha_regex: null,
      dataNascObrigatoria: true,
      alerta: {
        exibir: false,
        mensagem: null,
        tipo: null,
      },
      formulario: {
        nome: null,
        documento_tipo: null,
        documento: null,
        data_nasc: null,
        telefone: null,
        celular: null,
        email: null,
        senha: null,
        confirma: null,
        recaptcha_token: null,
        pessoa_estrangeira: false,
      },
      tituloBotao: "Cadastrar",

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },
    };
  },

  beforeMount() {
    if (
      this.configuracoes.especifico_cv.senha_portal_cliente &&
      this.configuracoes.especifico_cv.senha_portal_cliente.regex
    ) {
      this.senha_regex = new RegExp(
        this.configuracoes.especifico_cv.senha_portal_cliente.regex
      );
    }
  },

  methods: {
    onlyNumber,

    showPassword() {
      this.exibirSenha = !this.exibirSenha;
    },

    showPasswordConfirma() {
      this.exibirSenhaConfirma = !this.exibirSenhaConfirma;
    },

    definirTipoDocumento() {
      this.formatarMascaraCPFCNPJ();
      const documentoSemMascara = this.formulario.documento.replace(
        /\.|\-/g,
        ""
      );
      if (documentoSemMascara.length <= 11) {
        this.formulario.documento_tipo = "cpf";
        this.dataNascObrigatoria = true;
      } else {
        this.formulario.documento_tipo = "cnpj";
        this.dataNascObrigatoria = false;
      }
    },

    cadastrar() {
      if (this.checarFormulario()) {
        this.botaoDisable = true;
        this.tituloBotao = "Cadastrando...";
        this.isLoading = true;
        this.$refs.recaptcha.execute();
      }
    },

    enviarFormulario(recaptcha) {
      this.definirTipoDocumento()
      this.formulario.recaptcha_token = recaptcha;
      this.formulario.telefone = removerMascaraTelefone(
        this.formulario.telefone
      );
      this.formulario.celular = removerMascaraTelefone(this.formulario.celular);
      this.formulario.slug = store.state.configuracoes.slug;
      this.formulario.pessoa_estrangeira = this.documentoInternacional;
      this.formulario.documento_tipo = this.documentoInternacional ? 'cpf' : this.formulario.documento_tipo;
      cadastrarPessoa(this.formulario)
        .then((response) => {
          this.alerta.exibir = true;
          this.alerta.mensagem = "Cadastro de cliente realizado com sucesso!";
          this.alerta.tipo = "sucesso";
          this.exibirForm = false;
        })
        .catch((error) => {
          this.alerta.exibir = true;
          this.alerta.mensagem = error.response.data.error ?? error.response.data.message;
          this.alerta.tipo = "erro";
          this.exibirForm = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.botaoDisable = false;
          this.tituloBotao = "Cadastrar";
        });
    },

    checarFormulario() {
      if (!this.documentoInternacional && !validarDocumento(this.formulario.documento)) {
        alert("Utilize um documento válido.");
        return false;
      }
      if (!validarEmail(this.formulario.email)) {
        alert("Utilize um e-mail válido.");
        return false;
      }
      if (this.senha_regex && !this.senha_regex.test(this.formulario.senha)) {
        alert(this.configuracoes.especifico_cv.senha_portal_cliente.mensagem);
        return false;
      }

      if (this.formulario.senha !== this.formulario.confirma) {
        alert("Senhas não conferem!");
        return false;
      }

      if (this.formulario.data_nasc && !validarData(this.formulario.data_nasc)) {
        alert("Utilize uma data válida!");
        return false;
      }

      return true;
    },

    formatarMascaraCPFCNPJ() {
      const numericValue = this.formulario.documento.replace(/\D/g, '');
      let newMask = '';

      if (numericValue.length <= 11) {
        newMask = '###.###.###-##?#';
      } else {
        newMask = '##.###.###/####-##';
      }

      this.mask = newMask;
    },
  },
};
</script>
<style>
.input-tel {
  display: flex !important;
}
.vti__selection span {
  width: 30px;
}
</style>