<template>
    <LoadingComponent :isLoading="true" />
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import { mapActions } from "vuex";

export default {
    props: {
        idpessoa: { type: String, required: true },
        sessionId: { type: String, required: true },
        slug: { type: String, required: true },
    },

    components: {
        LoadingComponent,
    },

    beforeMount() {
        this.resetarSessao();
        this.resetarRedirecionar();
    },

    mounted() {
        this.autenticarAcessarComo({
            idpessoa: Number(this.idpessoa),
            session_id: this.sessionId,
            slug: this.slug,
        })
            .then((response) => {
                this.$store.commit('SET_SLUG', this.slug);
                const mensagem = `Olá ${response.data.usuario.nome},<br>Você está acessando como o cliente`;
                this.$router.push({ name: "redirecionando", params: { mensagem: mensagem } });
            })
            .catch((error) => {
                this.$router.push({
                    name: "login",
                    params: {
                        error: error.response.data.error,
                    },
                });
            });
    },

    methods: {
        ...mapActions([
            "resetarSessao",
            "resetarRedirecionar",
            "autenticarAcessarComo",
        ]),
    },
};
</script>
