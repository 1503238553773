<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina titulo="Meus Dados" subtitulo="Dados do meu usuário" />
        <div class="vld-parent">
            <Aviso
                :exibir="alerta.exibir"
                :mensagem="alerta.mensagem"
                :tipo="alerta.tipo"
            />

            <div class="row">
                <div class="col-12 col-xl-4">
                    <PerfilSindico @exibirAviso="exibirAviso" :key="chaveRefresh" />
                </div>

                <div class="col-12 col-xl-8">
                    <FormularioSindico
                        :key="chaveRefresh"
                        @exibirAviso="exibirAviso"
                        @refresh="chaveRefresh += 1"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Aviso from "@/components/Aviso";
import TituloPagina from "@/components/TituloPagina";
import FormularioSindico from "./components/FormularioSindico";
import PerfilSindico from "./components/PerfilSindico";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        TituloPagina,
        BreadCrumbs,
        Aviso,
        FormularioSindico,
        PerfilSindico,
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Dados', 'Meus Dados', 'Formulário', true, false)
        );
    },

    data() {
        return {
            alerta: {
                exibir: false,
                mensagem: "",
                tipo: "sucesso",
            },
            caminho: [
                {
                    texto: "Meus Dados",
                    ativo: true,
                    rota: null,
                },
            ],
            chaveRefresh: 0,
        };
    },

    methods: {
        exibirAviso(alerta) {
            this.alerta = alerta;
        },
    },
};
</script>
