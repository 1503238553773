<template>
    <div v-if="mostrarModal" class="pc-modalPopostaAtiva">
        <div class="pc-modalPopostaAtiva--title">
            <p>Olá {{ cliente.nome != "" ? cliente.nome : sindico.nome }},</p>
            <h1>Escolha o que deseja fazer</h1>
        </div>
        <div class="pc-btns-nao-cliente">
            <router-link v-if="verificarRotaExiste('atendimentos')"
                class="pc-btnBox pc-btnBox-secundary"
                :to="{ name: 'atendimentos' }"
                >Atendimentos</router-link
            >
            <router-link v-if="verificarRotaExiste('assistencias')"
                class="pc-btnBox pc-btnBox-secundary"
                :to="{ name: 'assistencias' }"
                >Assistências Técnicas</router-link
            >
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            cliente: (state) => state.login.cliente,
            unidades: (state) => state.unidades.unidades,
            configuracoes: (state) => state.configuracoes.configuracoes,
            sindico: (state) => state.login.sindico,
        }),
    },

    data() {
        return {
            rotarPermitidas: [
                "atendimentos",
                "contratos",
                "meus-dados",
                "meus-dados-sindico",
                "novoAtendimento",
                "atendimento",
                "assistencias",
                "assistencia",
                "novaAssistencia",
            ],
            mostrarModal: false,
            rotaAtual: this.$route.name,
        };
    },

    beforeMount() {
        this.atualizarModal();
    },

    watch: {
        $route(newValue) {
            this.rotaAtual = newValue.name;
            this.atualizarModal();
        },

        unidades(newValue) {
            this.unidades = newValue;
            this.atualizarModal();
        },
    },

    methods: {
        atualizarModal() {
            this.adicionarRotasPluginsExcecao();
            this.mostrarModal =
                !this.unidades &&
                !this.rotarPermitidas.includes(this.rotaAtual) &&
                !this.sindico;

            if (this.mostrarModal) {
                document.body.classList.add(`noContent`);
            } else {
                document.body.classList.remove(`noContent`);
            }
        },
        adicionarRotasPluginsExcecao() {
            let plugins = this.configuracoes.plugins;
            let rotas = this.rotarPermitidas;
            if (plugins.length > 0) {
                let pluginNome = plugins.map(function (plugin) {
                    let caminho = plugin.nome.toLowerCase().replace(" ", "-");
                    return 'plugin-' + caminho;
                });
                rotas.push(pluginNome[0])
            }
            this.rotarPermitidas = rotas;
        }
    },
};
</script>
