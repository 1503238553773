import axios from "axios";
import store from '../store';

export function getDocumentosEmpreendimento(offset, limit, idEmpreendimento) {
    return axios.get(`/empreendimentos/${idEmpreendimento}/documentos`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
        }
    });
}

export function getDocumentosEmpreendimentos(offset, limit) {
    return axios.get(`/empreendimentos/documentos`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
        }
    });
}

export function getDocumentosUnidade(offset, limit, idUnidade) {
    return axios.get(`/unidades/${idUnidade}/documentos`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
        }
    });
}

export function getDocumentosSindico(offset, limit) {
    return axios.get(`/documentos-sindico`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
        }
    });
}

export function cadastrarDocumento(dados) {
    return axios.post(`/empreendimentos/documentos-sindico`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function uploadArquivos(dados) {
    return axios.post('/empreendimentos/upload-arquivos', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}