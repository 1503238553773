<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <div>
        <h3>Dados do Síndico</h3>
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(salvarDados)" class="center-block">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="telefone">Nome</label>
              <input
                v-model="sindicoFormData.nome"
                type="text"
                class="form-control"
                placeholder="Nome"
                id="nome"
                name="nome"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="celular">Celular</label>
              <vue-tel-input
                class="form-control input-tel"
                v-model="sindicoFormData.celular"
                v-bind="bindProps"
                placeholder="Celular"
                id="celular"
                name="celular"
              >
              </vue-tel-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="email"><b>* Email</b></label>
                <input
                  v-model="sindicoFormData.email"
                  type="text"
                  class="form-control"
                  placeholder="Email"
                  id="email"
                  name="email"
                  required="true"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="senha"><b>* Senha</b></label>
                <input
                  v-model="sindicoFormData.senha"
                  type="password"
                  class="form-control"
                  placeholder="Senha Atual"
                  id="senha"
                  name="senha"
                  required="true"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="nova_senha">Nova Senha</label>
              <input
                v-model="sindicoFormData.nova_senha"
                type="password"
                :class="[
                  'form-control',
                  senha_regex && sindicoFormData.nova_senha
                    ? {
                        'is-invalid': !senha_regex.test(
                          sindicoFormData.nova_senha
                        ),
                      }
                    : '',
                ]"
                placeholder="Nova Senha"
                id="nova_senha"
                name="nova_senha"
              />
              <p v-if="configuracoes.especifico_cv.senha_portal_cliente">
                {{ configuracoes.especifico_cv.senha_portal_cliente.mensagem }}
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label for="confirma">Confirmar nova senha</label>
              <input
                v-model="sindicoFormData.confirma"
                type="password"
                :class="[
                  'form-control',
                  sindicoFormData.confirma
                    ? {
                        'is-invalid':
                          sindicoFormData.nova_senha !=
                          sindicoFormData.confirma,
                      }
                    : '',
                ]"
                placeholder="Confirmar nova senha"
                id="confirma"
                name="confirma"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 btnEnviar">
            <button
              name="btSend"
              type="submit"
              value="Alterar"
              class="btn-form pc-btnBox pc-btnBox-secondary"
            >
              Alterar
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { removerMascaraString } from "@/plugins/Utils";
import { mapGetters, mapActions } from "vuex";
import { putSindico } from "@/services/SindicosService";
import LoadingComponent from "@/components/LoadingComponent";
import { objetoTraking } from "../../../plugins/Utils";
import { removerMascaraTelefone } from "../../../plugins/Utils";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

export default {
  computed: {
    ...mapGetters({
      sindico: "getSindico",
      configuracoes: "getConfiguracoes",
    }),

    ...mapActions(["getMeSindico"]),
  },

  components: {
    LoadingComponent,
    VueTelInput,
  },

  data() {
    return {
      isLoading: false,
      senha_regex: null,
      sindicoFormData: {
        nome: "",
        celular: "",
        email: "",
        senha: "",
        nova_senha: "",
        confirma: "",
      },

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },
    };
  },

  beforeMount() {
    if (this.configuracoes.especifico_cv.senha_portal_cliente) {
      this.senha_regex = new RegExp(
        this.configuracoes.especifico_cv.senha_portal_cliente.regex
      );
    }
    this.sindicoFormData.nome = this.sindico.nome;
    this.sindicoFormData.telefone = this.sindico.telefone;
    this.sindicoFormData.celular = removerMascaraString(this.sindico.celular);
    this.sindicoFormData.email = this.sindico.email;
    this.isLoading = true;
  },

  mounted() {
    this.isLoading = false;
  },

  methods: {
    salvarDados() {
      this.atualizarSindico();
    },

    atualizarSindico() {
      this.isLoading = true;
      this.sindicoFormData.telefone = removerMascaraTelefone(
        this.sindicoFormData.telefone
      );
      this.sindicoFormData.celular = removerMascaraTelefone(
        this.sindicoFormData.celular
      );
      this.trakingMixpanel("Alterar dados do cliente", "Alterar", true, true);
      putSindico(this.sindicoFormData)
        .then((response) => {
          let msg = "Seus dados foram atualizados com sucesso!";

          this.$emit("exibirAviso", {
            exibir: true,
            mensagem: msg,
            tipo: "sucesso",
          });
          this.getMeSindico();
          this.$emit("refresh");
        })
        .catch((error) => {
          this.avisoErro(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    avisoErro(error) {
      let mensagem = "Erro interno, por favor tentar mais tarde.";
      if (error.response.status != 422) {
        mensagem = error.response.data.error;
      }
      this.$emit("exibirAviso", {
        exibir: true,
        tipo: "erro",
        mensagem: mensagem,
      });
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Dados", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
