<template>
	<div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-titleBloco">
			<div>
				<h3>Endereço</h3>
			</div>
			<a
				href="javascript:void(0)"
				class="btn-form pc-btnBox pc-btnBox-small pc-btnBox-secondary"
				v-on:click="atualizaFormularioEndereco()"
			>
				Voltar
			</a>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>CEP</label>
					<input
						type="text"
						class="form-control input-tel"
						placeholder="CEP"
						v-model="endereco.cep"
						v-mask="'#####-###'"
						@keyup="enderecoPorCep"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Logradouro"
						label="nome"
						:clearable="false"
						:options="logradouros"
						:reduce="(option) => option.idlogradouro"
						:valor.sync="endereco.logradouro.idlogradouro"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<ValidationProvider
						rules="required"
						v-slot="{ errors }">
						<label><b>* Endereço</b></label>
						<input
							type="text"
							class="form-control input-alpha"
							maxlength="100"
							v-model="endereco.nome"
							placeholder="Endereço"
						/>
						<label class="error"><span>{{ errors[0] }}</span></label>
					</ValidationProvider>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Bairro</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="100"
						v-model="endereco.bairro"
						placeholder="Bairro"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Número</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="10"
						v-model="endereco.numero"
						placeholder="Número"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Complemento</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="100"
						v-model="endereco.complemento"
						placeholder="Complemento"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Estado"
						label="nome"
						regras="required"
						:clearable="false"
						:options="estados"
						:reduce="(option) => option.idestado"
						:valor.sync="endereco.estado.idestado"
						@update:valor="atualizarCidades(null)"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Cidade"
						label="nome"
						regras="required"
						:clearable="false"
						:options="cidades"
						:reduce="(option) => option.idcidade"
						:valor.sync="endereco.cidade.idcidade"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import TextInput from "@/components/TextInput";
import {
	getLogradouros,
	getEstados,
	getCidades,
	getEnderecoPorCep,
} from "@/services/EnderecoService";
import { removerMascaraString } from "@/plugins/Utils";
import LoadingComponent from "@/components/LoadingComponent";
import { mapGetters, mapActions } from "vuex";
import { putPessoaEndereco } from "@/services/PessoasService";
const cloneDeep = require("clone-deep");

export default {
	props: ["formularioEndereco"],
	components: {
		SelectInput,
		LoadingComponent,
		TextInput,
	},

	data() {
		return {
			logradouros: [],
			estados: [],
			cidades: [],
			endereco: null,
			isLoading: false,
		};
	},

	computed: {
		...mapGetters({
			cliente: "getCliente",
		}),

		...mapActions(["getMe"]),
	},

	beforeMount() {
		this.endereco = cloneDeep(this.cliente.endereco);

		getLogradouros()
			.then((response) => {
				this.logradouros = response.data;
			})
			.catch((error) => {});

		getEstados()
			.then((response) => {
				this.estados = response.data;
			})
			.catch((error) => {});

		if (this.endereco.estado) {
			this.atualizarCidades(this.endereco.cidade.idcidade);
		}
	},

	methods: {
		enderecoPorCep() {
			if (this.endereco.cep.length == 9) {
				this.isLoading = true;
				getEnderecoPorCep(removerMascaraString(this.endereco.cep))
					.then((response) => {
						this.endereco.logradouro.idLogradouro = Number(
							response.data.idlogradouro
						);
						this.endereco.nome = response.data.endereco;
						this.endereco.bairro = response.data.bairro;
						this.endereco.estado.idEstado = Number(
							response.data.idestado
						);
						this.endereco.numero = "";
						this.endereco.complemento = "";
						this.atualizarCidades(Number(response.data.idcidade));
					})
					.catch((error) => {})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},

		atualizarCidades(idCidade) {
			getCidades(this.endereco.estado.idestado)
				.then((response) => {
					this.cidades = response.data;
					this.endereco.cidade.idcidade = idCidade;
				})
				.catch((error) => {});
		},

		retornarDadosFormulario() {
			return {
				cep: removerMascaraString(this.endereco.cep),
				endereco: this.endereco.nome,
				bairro: this.endereco.bairro,
				numero: this.endereco.numero.toString(),
				complemento: this.endereco.complemento,
				idlogradouro: this.endereco.logradouro.idlogradouro,
				idestado: this.endereco.estado.idestado,
				idcidade: this.endereco.cidade.idcidade,
			};
		},

		verificarForm() {
			if (
				this.cliente.endereco.cep !=
					removerMascaraString(this.endereco.cep) ||
				this.cliente.endereco.logradouro.idlogradouro !=
					this.endereco.logradouro.idlogradouro ||
				this.cliente.endereco.nome != this.endereco.nome ||
				this.cliente.endereco.bairro != this.endereco.bairro ||
				this.cliente.endereco.numero != this.endereco.numero ||
				this.cliente.endereco.complemento !=
					this.endereco.complemento ||
				this.cliente.endereco.estado.idestado !=
					this.endereco.estado.idestado ||
				this.cliente.endereco.cidade.idcidade !=
					this.endereco.cidade.idcidade
			) {
				return this.retornarDadosFormulario();
			} else {
				return false;
			}
		},

		atualizarDados() {
			this.isLoading = true;
			putPessoaEndereco(this.retornarDadosFormulario())
				.then((response) => {
					this.$emit("sucesso", true);
					this.getMe();
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		atualizaFormularioEndereco() {
			this.$parent.$parent.formularioEndereco = false;
		},
	},
};
</script>
