import EsqueceuSenhaEmail from "@/pages/inicio/EsqueceuSenhaEmail";
import EsqueceuSenhaSms from "@/pages/inicio/EsqueceuSenhaSms";
import Cadastro from "@/pages/inicio/Cadastro";
import store from '@/store';

const especifico = store.state.configuracoes.configuracoes.especifico_cv;
let rotas = [];

if (especifico.cadastro_painel_cliente) {
    rotas.push(
        {
            path: '/cliente/cadastre-se',
            component: Cadastro,
            name: 'cadastre-se',
            meta: {
                title: 'Portal do Cliente - Cadastro'
            },
        }
    );
}

if (especifico.esqueci_senha_painel_cliente) {
    rotas.push({
        path: "/cliente/:slug?/esqueceu-senha",
        component: EsqueceuSenhaSms,
        name: "esqueceu-senha-sms",
        meta: {
            title: "Esqueci a senha - SMS",
        },
    });

    if (especifico.modo_esqueci_senha_painel_cliente !== 'sms') {
        rotas.push(
            {
                path: '/cliente/:slug?/esqueceu-senha-email',
                name: 'esqueceu-senha-email',
                component: EsqueceuSenhaEmail,
                meta: {
                    title: 'Esqueci a senha - Email',
                },
            },
        )
    }
}

export default {
    rotas
}
