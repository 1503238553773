<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <h3>Documentos</h3>
      <div class="pull-right pc-title-legenda" v-if="listagemDocumentos">
        Informações encontradas: {{ listagemDocumentos.total }}
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-tabela" v-if="listagemDocumentos">
      <div class="pc-tabela--row pc-tabela--head">
        <div class="pc-tabela--td pc-f2">
          <p>Titulo</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Tipo do Arquivo</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Data de Criação</p>
        </div>
        <div class="pc-tabela--td pc-f1"></div>
      </div>
      <div
        v-for="documento in listagemDocumentos.documentos"
        v-bind:key="documento.idContrato"
        class="pc-tabela--row"
      >
        <div class="pc-tabela--td pc-f2">
          <p data-title="Titulo">
            {{ documento.titulo }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Tipo do Arquivo">
            {{ documento.arquivo.nome }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Data de Criação">
            {{
              documento.data_criacao
                | dateParse("YYYY-MM-DD HH:mm")
                | dateFormat("DD/MM/YY")
            }}
          </p>
        </div>

        <div class="pc-tabela--td pc-f1 justify-content-center">
          <a
            @click="
              trakingMixpanel(
                'Documentos do Síndico',
                'Baixar Documento',
                true,
                true
              )
            "
            v-if="documento.arquivo"
            class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
            target="_blank"
            :href="documento.arquivo.url"
          >
            Baixar Documento
          </a>
        </div>
      </div>
      <PaginateInput
        :total="listagemDocumentos.total"
        :limit="paginacao.limit"
        :offset="paginacao.offset"
        @paginacao="montarListagem"
      />
    </div>
    <div v-else>Nenhuma informação encontrada.</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDocumentosSindico } from "@/services/DocumentosService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { objetoTraking, quebraDeLinhaParaHtml } from "../../../plugins/Utils";
import Vue from "vue";

export default {
  computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico",
    }),
  },

  props: {
    idEmpreendimento: { type: Number, required: false },
  },

  emits: ["error"],

  components: {
    LoadingComponent,
    PaginateInput,
  },

  data() {
    return {
      listagemDocumentos: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: false,
    };
  },

  beforeMount() {
    if (this.idEmpreendimento || this.acessoSindico) {
      this.montarListagem(1);
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      getDocumentosSindico(
        (pagina - 1) * this.paginacao.limit,
        this.paginacao.limit
      )
        .then((response) => {
          response.data.documentos = response.data.documentos.filter(function(
            linha
          ) {
            if (linha.arquivo.nome.length >= 25) {
              linha.arquivo.nome =
                linha.arquivo.nome.substring(0, 14) +
                "..." +
                linha.arquivo.nome.substring(
                  linha.arquivo.nome.length - 6,
                  linha.arquivo.nome.length
                );
            }
            return linha;
          });
          this.listagemDocumentos = response.data;
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.$emit("error", error.response.data);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Documentos", acao, subacao, intencao, ateracao)
      );
    },
    quebraDeLinhaParaHtml,
  },
};
</script>
