<template>
    <div>
        <div class="pc-titleBloco">
            <div>
                <h3>Histórico de Mensagens</h3>
            </div>
        </div>
        <div class="historico-mensagem">
            <div class="linhadotempo-mensagens">
                <div
                    v-for="(mensagem, index) in mensagens"
                    :key="index"
                    class="box-mensagem mensagem"
                >
                    <div class="header-mensagem">
                        <div class="perfil">
                            <div class="info-perfil">
                                <div class="linhadotempo-quando">
                                    <small>{{
                                        mensagem.data_cadastro
                                            | dateParse("YYYY-MM-DD HH:mm:ss")
                                            | dateFormat("DD/MM/YYYY HH:mm:ss")
                                    }}</small>
                                </div>
                                <strong class="linhadotempo-quem"
                                    >{{
                                        mensagem.nome
                                            ? mensagem.nome + " (Gestor)"
                                            : null
                                    }}
                                    {{ mensagem.cliente }}
                                    </strong
                                >
                            </div>
                        </div>
                    </div>
                    <div class="info-mensagem">
                        <span
                            class="cv-descricao-mensagem"
                            v-html="quebraDeLinhaParaHtml(mensagem.mensagem)"
                        ></span>

                        <div class="pc-files" v-if="mensagem.arquivos">
                            <span>Arquivo(s):</span>
                            <div
                                v-for="(arquivo, index) in mensagem.arquivos"
                                :key="index"
                                class="pictures"
                            >
                                <a
                                    :href="arquivo.url"
                                    :title="arquivo.nome"
                                    target="_blank"
                                >
                                    <IconFile />
                                    <span
                                        >{{ arquivo.nome }} ({{
                                            formatBytes(arquivo.tamanho)
                                        }})</span
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatBytes, quebraDeLinhaParaHtml } from "@/plugins/Utils";

export default {
    props: ["mensagens"],

    methods: { formatBytes, quebraDeLinhaParaHtml },
};
</script>
