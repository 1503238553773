import axios from "axios";
import store from '../store';
import { URL_API } from "@/bootstrap/index";

export function getLogradouros() {
    return axios.get('/endereco/logradouros', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getEstados() {
    return axios.get('/endereco/estados', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getCidades(idEstado) {
    return axios.get(`/endereco/estados/${idEstado}/cidades`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getEnderecoPorCep(cep) {
    return axios.post(`${URL_API}/api/get/cep`, `cep=${cep}`);
}
