import axios from "axios";
import store from '../store';

export function retornarEmpreendimentos() {
    return axios.get('/empreendimentos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarEmpreendimentosPessoa(verificarFlags = null) {
    return axios.get('/empreendimentos/pessoa', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            verificarFlags: verificarFlags
        }
    });
}

export function retornarFotosObra(idEmpreendimento, idunidade, ano, mes) {
    return axios.get(`/empreendimentos/${idEmpreendimento}/galerias-obra/${idunidade}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            ano: ano,
            mes: mes
        }
    });
}

export function retornarAndamentoObra(idEmpreendimento, idunidade) {
    return axios.get(`/empreendimentos/${idEmpreendimento}/andamento-obra/${idunidade}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarUnidadesDocumentoSindico(idEmpreendimento) {
    return axios.get(`/empreendimentos/${idEmpreendimento}/unidades`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}