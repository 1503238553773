const getters = {
    carregamentoFinalizado: state => {
        return state.concluido.pessoa && state.concluido.unidades 
            && state.concluido.termos && state.concluido.permissoes;
    },

    retornarSessao: state => {
        return {
            "sessaoExpirada": state.sessaoExpirada,
            "rotaRedirecionar": state.rotaRedirecionar,
        };
    },
};
export default getters
