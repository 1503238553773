<template>
  <div class="pc-login-slide d-none d-lg-block" v-if="banners && banners.length > 0">
    <div class="pc-login-fts">
      <VueSlickCarousel
        v-if="banners && banners.length > 0"
        v-bind="settingsSlick"
        style="height: 100%"
      >
        <a
          v-for="(banner, index) in banners"
          :key="index"
          :href="banner.link ? banner.link.url : null"
          class="pc-banner item"
          :target="
            banner.link && banner.link.abertura == 'mesma_pagina'
              ? '_self'
              : '_blank'
          "
        >
          <img
            v-if="banner.imagem"
            :src="
              banner.imagem.url
                .replace('[[LARGURA]]', 'x')
                .replace('[[ALTURA]]', 'x')
            "
            alt=""
          />
          <p
            v-else
            :style="
              `line-height: 1.5; background-color: ${banner.texto.cor.fundo};color: ${banner.texto.cor.texto};`
            "
          >
            {{ banner.texto.texto }}
          </p>
        </a>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { getBanner } from "@/services/BannerService";

export default {
  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      banners: null,
      settingsSlick: {
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        cssEase: "linear",
      },
    };
  },

  beforeMount() {
    getBanner("login")
      .then((response) => {
        let dispositivoMobile = window.screen.width < 768;
        let banners = [];
        for (let elemento of response.data.data) {
          if (elemento.imagem) {
            if (
              (dispositivoMobile && elemento.imagem.dispositivo_exibicao == "desktop")
              || (!dispositivoMobile && elemento.imagem.dispositivo_exibicao == "mobile")
            ) {
              continue;
            }
          }
          banners.push(elemento);
        }
        this.banners = banners;
      })
      .catch();
  }
};
</script>
