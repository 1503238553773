<template>
    <div>
        <div class="pc-bloco mb-20">
            <div class="pc-titleBloco">
                <h3>Fotos da Obra</h3>
                <div class="dropdown pc-dropdownArrow">
                    <a
                        href="javascript:void(0)"
                        class="pc-btnText"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span class="labelAno">{{labelAno}}</span>
                        <IconSetaDown />
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right dropdownAno"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <a
                            class="dropdown-item"
                            v-on:click.stop="filtrarAno(ano)"
                            v-for="ano in filtroAno"
                            >{{ ano }}</a
                        >
                    </div>
                </div>
            </div>
            <div id="fotosObraBox" class="noStatusChart" v-if="exibirNoContentGaleria">
                <LoadingComponent :isLoading="isLoading" />
                <img src="@/assets/img/preview-obra.jpg" alt="" />
                <span class="posicionado"><font size="3"><b>Nenhum Resultado Encontrado!</b></font></span>
            </div>
            <div class="pc-timelineObra" v-else>
                <div class="pc-timelineObra--wrap">
                    <div
                        class="pc-timelineObra--dia"
                        v-for="(galeria, indexGaleria) in galerias"
                        v-if="indexGaleria < 2"
                    >
                        <p>
                            {{ galeria.nome
                            }}<span>{{
                                galeria.data_base
                                    | dateParse("YYYY-MM")
                                    | dateFormat("MM/YYYY")
                            }}</span>
                        </p>
                        <div class="pc-timelineObra--fotos">
                            <a
                                class="swipebox"
                                data-rel="obra"
                                v-for="(midia, index) in galeria.midias"
                                v-if="index < maxFotosGaleria"
                                v-on:click.stop="
                                    showMultiple(galeria, midia, index)
                                "
                            >
                                <img
                                    v-if="midia.tipo == 'youtube'"
                                    :src="midia.thumbnails"
                                    :alt="midia.titulo"
                                />
                                <img
                                    v-else
                                    :src="midia.src"
                                    :alt="midia.titulo"
                                />
                            </a>
                            <router-link
                                :to="{ name: 'andamento-obra' }"
                                class="pc-timelineObra--fotosAll"
                                v-if="galeria.midias[maxFotosGaleria]"
                            >
                                <div class="pc-timelineObra--fotosAll-info">
                                    <p>
                                        Mais Mídias
                                        <span
                                            >+{{
                                                galeria.midias.length -
                                                maxFotosGaleria
                                            }}</span
                                        >
                                    </p>
                                </div>
                                <img
                                    v-if="
                                        galeria.midias[maxFotosGaleria].tipo ==
                                        'youtube'
                                    "
                                    :src="
                                        galeria.midias[maxFotosGaleria]
                                            .thumbnails
                                    "
                                    alt=""
                                />
                                <img
                                    v-else
                                    :src="galeria.midias[maxFotosGaleria].src"
                                    alt=""
                                />
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <router-link
                        :to="{ name: 'andamento-obra' }"
                        class="pc-btnBox pc-btnBox-small"
                    >
                        Ver mais fotos
                    </router-link>
                </div>
            </div>
            <silent-box ref="silentbox" :gallery="imgs">
                <template v-slot:silentbox-item="{ silentboxItem }">
                    <p></p>
                </template>
            </silent-box>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import { retornarFotosObra } from "./../../../services/EmpreendimentoService";
import VueSilentbox from "vue-silentbox";

export default {
    computed: {
        ...mapState({
            unidade: (state) => state.unidades.unidade,
        }),
    },

    data() {
        return {
            maxFotosGaleria: 3,
            exibirNoContentGaleria: true,
            isLoading: true,
            galerias: null,
            galeriasData: null,
            filtroAno: [],
            imgs: [],
            visible: false,
            index: 0,
            labelAno: '',
            passouPeloScroll: false,
        };
    },

    created () {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
    },

    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    },

    mounted() {
        if (this.unidade && this.isInViewport(document.querySelector('#fotosObraBox'))) {
            this.retornarFotos(this.unidade.empreendimento.idempreendimento, this.unidade.idunidade);
        }
    },

    watch: {
        unidade(newValue) {
            if (newValue && this.isInViewport(document.querySelector('#fotosObraBox'))) {
                this.retornarFotos(newValue.empreendimento.idempreendimento, this.unidade.idunidade);
            }
            return newValue;
        },
    },

    components: {
        LoadingComponent,
        VueSilentbox,
    },

    methods: {
        filtrarAno(ano) {
            this.galerias = [];
            let galerias = [];
            this.galeriasData.map(function (galeria) {
                let anoBase = galeria.data_base.split("-");
                if (ano == anoBase[0]) {
                    galerias.push(galeria);
                }
            });
            this.galerias = galerias;
            this.labelAno = ano;

            const collapseBox = document.querySelector('.dropdownAno');
            collapseBox.classList.remove('show');
        },

        handleScroll(event) {
            if (!this.passouPeloScroll && this.isInViewport(document.querySelector('#fotosObraBox'))) {
                this.passouPeloScroll = true;
                this.retornarFotos(this.unidade.empreendimento.idempreendimento, this.unidade.idunidade);
            }
        },

        retornarFotos(idEmpreendimento, idUnidade) {
            this.isLoading = true;
            retornarFotosObra(idEmpreendimento, idUnidade)
                .then((response) => {
                    this.carregarGaleria(response.data);
                    this.criarFiltroAnos(response.data);
                    this.exibirNoContentGaleria = false;
                })
                .catch(() => {
                    this.filtroAno = [];
                    this.exibirNoContentGaleria = true;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        isInViewport(el) {
            const rect = el.getBoundingClientRect();
            return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
        },


        criarFiltroAnos(galerias) {
            let anos = [];
            galerias.map(function (galeria) {
                anos.push(galeria.data_base.split("-")[0]);
            });
            this.filtroAno = [...new Set(anos)];
        },

        carregarGaleria(dados) {
            let galerias = [];
            let midias = [];
            dados.map(function (galeria) {
                midias = [];
                galeria.midias.map(function (midia) {
                    var url;
                    if (midia.tipo == "youtube") {
                        url = midia.url;
                    } else {
                        url = midia.url
                            .replace("[[LARGURA]]", "x")
                            .replace("[[ALTURA]]", "x");
                    }
                    midias.push({
                        src: url,
                        titulo: galeria.nome + " - " + midia.titulo,
                        data: galeria.data_base,
                        tipo: midia.tipo,
                        thumbnails: midia.thumbnail,
                        thumbnailWidth: "170px",
                        thumbnailHeight: "120px",
                    });
                });
                galerias.push({
                    nome: galeria.nome,
                    data_base: galeria.data_base,
                    midias: midias,
                });
            });
            this.galerias = [...new Set(galerias)];
            this.galeriasData = [...new Set(galerias)];
            
            this.labelAno = this.galeriasData[0].data_base.split("-")[0]

        },

        showMultiple(galeria, midia, index) {
            this.imgs = galeria.midias;
            this.$refs.silentbox.openOverlay(midia, index);
        },
    },
};
</script>
<style>
  .posicionado {
	right: 0%;
    bottom: 40%;
    left: 30%;
	position: absolute;
}
</style>
