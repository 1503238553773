<template>
    <div>
        <div class="sidebar-caixa" v-if="sidebarCaixa">
            <div class="--titulo-geral">
                <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6226 24.4999H17.0553L10.9844 13.0723H21.5516L27.6226 24.4999Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3334 0.5H22.7662L11.8574 11.9464H22.4247L33.3334 0.5Z" fill="#EF7D1B"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.71094 0.5H16.2782L22.3491 11.9464H11.7819L5.71094 0.5Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24.4999H10.5862L21.476 13.0723H10.9087L0 24.4999Z" fill="#EF7D1B"/>
                </svg>
                Sua proposta online
                <svg @click="handleClickSidebarCaixaOverflow" class="btn-close-sidebar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/></svg>
            </div>

            <div class="passos-wrap">
                
                <div class="passo" id="passo2" v-if="step == 1">
                    <p class="m-b-20"><b>Benificiário</b></p>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li>
                                <span>02</span>
                                Atendimento
                            </li>
                            <li>
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 14%;"></div>
                        </div>
                    </div>
                    <h4>Dados pessoais</h4>
                    <div class="form-group">
                        <label for="inputtext">CPF</label>
                        <input type='text' name="inputtext" class="form-control input-cpf" id='inputtext' placeholder="000.000.000-00" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Data de nascimento do beneficiáro</label>
                        <input type='date'
                                name="data_inicio"
                                class="form-control"
                                id='form_data_inicio'
                                placeholder="00/00/0000"
                                onfocus="this.showPicker()"
                                value=""
                        />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Naturalidade</label>
                        <input type='text' name="inputtext" class="form-control" id='inputtext' placeholder="Ex. São Paulo" />
                    </div>
                    <div class="form-group">
                        <label for="espacos">Gênero</label>
                        <select name="diasemana" id="diasemana" class="cv-select-placeholder">
                            <option hidden value="">Selecione</option>
                            <option value="Domingo">Masculino</option>
                            <option value="Domingo">Feminino</option>
                            <option value="Domingo">Prefiro não informarz</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Celular</label>
                        <input type='text' name="inputtext" class="form-control input-tel" id='inputtext' placeholder="Ex: 11 99876-2552" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Renda mensal bruta</label>
                        <input type='text' name="inputtext" class="form-control input-real" id='inputtext' placeholder="Ex: R$ 20.000,00" />
                    </div>
                    <div class="form-group">
                        <label for="espacos">Declara imposto de renda?</label>
                        <select name="diasemana" id="diasemana" class="cv-select-placeholder">
                            <option hidden value="">Selecione</option>
                            <option value="Domingo">Sim</option>
                            <option value="Domingo">Não</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="espacos">Estado civil</label>
                        <select name="diasemana" id="diasemana" class="cv-select-placeholder">
                            <option hidden value="">Selecione</option>
                            <option value="Domingo">Solteiro</option>
                            <option value="Domingo">Casado</option>
                        </select>
                    </div>
                    <div class="switch-group">
                        <p>Possui mais de um comprador na proposta e/ou dependentes?</p>
                        <div class="form-group">
                            <input class="cv-switch-toggle" id="toggle-1" type="checkbox"/>
                            <label class="cv-switch-label" for="toggle-1"> 
                                <div class="cv-switch"></div>
                            </label>
                        </div>
                    </div>
                    <div class="switch-group">
                        <p>Deseja incluir o FGTS na proposta?</p>
                        <div class="form-group">
                            <input class="cv-switch-toggle" id="toggle-2" type="checkbox"/>
                            <label class="cv-switch-label" for="toggle-2"> 
                                <div class="cv-switch"></div>
                            </label>
                        </div>
                    </div>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Continuar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click="handleClickSidebarCaixaOverflow">
                            Cancelar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo3" v-if="step == 2">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Benificiário</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li>
                                <span>02</span>
                                Atendimento
                            </li>
                            <li>
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 14%;"></div>
                        </div>
                    </div>
                    <h4>Dados de endereço</h4>
                    <div class="form-group">
                        <label for="inputtext">CEP</label>
                        <input type='text' name="inputtext" class="form-control input-cep" id='inputtext' placeholder="EX: 00.000-00" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Endereço (Avenida, rua, travessa...)</label>
                        <input type='text' name="inputtext" class="form-control" id='inputtext' placeholder="Ex: Rua Ribeirópolis" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Bairro</label>
                        <input type='text' name="inputtext" class="form-control" id='inputtext' placeholder="Ex: Centro" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Número</label>
                        <input type='text' name="inputtext" class="form-control" id='inputtext' placeholder="Ex: 12" />
                    </div>
                    <div class="form-group">
                        <label for="inputtext">Complemento</label>
                        <input type='text' name="inputtext" class="form-control" id='inputtext' placeholder="Ex: Cond. Luar da Duna" />
                    </div>
                    <div class="form-group">
                        <label for="espacos">Estado</label>
                        <select name="diasemana" id="diasemana" class="cv-select-placeholder">
                            <option hidden value="">Selecione</option>
                            <option value="Domingo">Brasília</option>
                            <option value="Domingo">São Paulo</option>
                            <option value="Domingo">Sergipe</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="espacos">Cidade</label>
                        <select name="diasemana" id="diasemana" class="cv-select-placeholder">
                            <option hidden value="">Selecione</option>
                            <option value="Domingo">Brasília</option>
                            <option value="Domingo">São Paulo</option>
                            <option value="Domingo">Sergipe</option>
                        </select>
                    </div>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Próximo passo
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo4" v-if="step == 3">
                    <a href="javascript:void(0)" class="btn-prev-passo btn-back btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Atendimento</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li>
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 40%;"></div>
                        </div>
                    </div>
                    <h4 class="m-b-20 m-t-20">CCA selecionado</h4>
                    <div class="cv-blocoBorder -branco -full p-10">
                        <div class="dado">
                            <span>Nome do CCA</span>
                            <p>Exito imobiliária ltda</p>
                        </div>
                        <div class="dado">
                            <span>Código identificador</span>
                            <p>00000</p>
                        </div>
                        <div class="dado">
                            <span>Telefone</span>
                            <p>(00)99999-0000</p>
                        </div>
                        <div class="dado">
                            <span>Endereço</span>
                            <p>Rua Luiz da hora Santos, 34 - Grageru, Aracaju/se</p>
                        </div>
                    </div>
                    <h4 class="m-t-20">Agência de contratação</h4>
                    <p class="m-b-20">Selecione uma agência</p>
                    <label for="agencia" class="cv-blocoBg -full -branco p-10 card-radius">
                        <input type="radio" name="agencia" id="agencia">
                        <div class="dados -preto">
                            <div class="d-flex g-20">
                                <div class="dado">
                                    <span>Id</span>
                                    <p>0000</p>
                                </div>
                                <div class="dado">
                                    <span>Nome da agência</span>
                                    <p>Shopping Jardins, se</p>
                                </div>
                            </div>
                            <div class="dado">
                                <span>Endereço</span>
                                <p>Avenida Ministro Geraldo Barreto Sobral - Jardins, Aracaju/se</p>
                            </div>
                        </div>
                    </label>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Próximo passo
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo5" v-if="step == 4">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Resumo da proposta</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li class="active">
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 64%;"></div>
                        </div>
                    </div>
                    <h4 class="m-b-20 m-t-20">Para prosseguir, responda algumas perguntas sobre o beneficiário:</h4>
                    <div class="switch-group">
                        <p>Possui imóvel na cidade do imóvel pretendido?</p>
                        <div class="form-group">
                            <input class="cv-switch-toggle" id="toggle-3" type="checkbox"/>
                            <label class="cv-switch-label" for="toggle-3">
                                <div class="cv-switch"></div>
                            </label>
                        </div>
                    </div>
                    <div class="switch-group">
                        <p>Possui 3 anos de contribuição de FGTS somodo todos os períodos trabalhados?</p>
                        <div class="form-group">
                            <input class="cv-switch-toggle" id="toggle-4" type="checkbox"/>
                            <label class="cv-switch-label" for="toggle-4">
                                <div class="cv-switch"></div>
                            </label>
                        </div>
                    </div>
                    <div class="cv-blocoBorder -branco -full p-10 m-t-20 m-b-20">
                        <h4 class="m-b-20">Você tem ou gostaria de ter relacionamento com a CAIXA?</h4>
                        <div class="switch-group">
                            <p>Informe se você é ou deseja ser cliente CAIXA, mediante contratação de Conta integrada ou Conta com opção de pagamento de prestação com débito automático em conta.</p>
                            <div class="form-group">
                                <input class="cv-switch-toggle" id="toggle-5" type="checkbox"/>
                                <label class="cv-switch-label" for="toggle-5">
                                    <div class="cv-switch"></div>
                                </label>
                            </div>
                        </div>
                        <h4 class="m-b-20 m-t-20">Algum dos participantes é Servidor Público?</h4>
                        <div class="switch-group">
                            <p>Informe se você ou outro comprador participante do contrato trabalha no Setor Público, seja na esfera Federal, Estadual, Municipal ou sistema Petrobrás.</p>
                            <div class="form-group">
                                <input class="cv-switch-toggle" id="toggle-6" type="checkbox"/>
                                <label class="cv-switch-label" for="toggle-6">
                                    <div class="cv-switch"></div>
                                </label>
                            </div>
                        </div>
                        <h4 class="m-b-20 m-t-20">Você tem ou gostaria de ter conta salário na CAIXA?</h4>
                        <div class="switch-group">
                            <p>Informe se você deseja ter Crédito Salário na CAIXA ou, se você tem ou deseja adquirir produto de previdência na CAIXA.</p>
                            <div class="form-group">
                                <input class="cv-switch-toggle" id="toggle-7" type="checkbox"/>
                                <label class="cv-switch-label" for="toggle-7">
                                    <div class="cv-switch"></div>
                                </label>
                            </div>
                        </div>
                        <div class="card-alerta">
                            <div class="--icone">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"/></svg>
                            </div>
                            <div class="--texto">
                                <span>Relacionamento CAIXA</span>
                                <p>Quanto maior o relacionamento com a CAIXA, mais barato o valor do financiamento. </p>
                            </div>
                        </div>
                    </div>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Continuar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo6" v-if="step == 5">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Resumo da proposta</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li class="active">
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 64%;"></div>
                        </div>
                    </div>
                    <h4 class="m-t-20">Modalidades de simulação</h4>
                    <p class="m-b-20">Com base nas suas informações apresentamos as melhores modalidades de simulação para a sua proposta. </p>
                    <div class="scroll-wrap">
                        <label for="el1" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="modalidade" id="el1">
                            <div class="dados">
                                <h5>SBPE (Crédito Imobiliário Poupança CAIXA): Relacionamento</h5>
                                <p class="-preto">Condições e vantagens especiais para você que é ou quer ser cliente com Conta na CAIXA e opção por inclusão da prestação do Financiamento Habitacional para débito em conta.</p>
                                <b-collapse id="collapse-1">
                                    <div class="collapse-card">
                                        <div class="linha-separador"></div>
                                        <ul class="lista-dados-valores">
                                            <li>
                                                <p>Valor do imóvel</p>
                                                <p><b>R$ 260.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Valor de entrada</p>
                                                <p><b>R$ 26.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Valor financiada</p>
                                                <p><b>R$ 234.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Prestação máxima</p>
                                                <p><b>Não informado</b></p>
                                            </li>
                                            <li>
                                                <p>Prazo</p>
                                                <p><b>420 meses</b></p>
                                            </li>
                                        </ul>
                                        <div class="linha-separador"></div>
                                        <ul class="lista-dados-valores">
                                            <li>
                                                <p>Amortização</p>
                                                <p><b>Sistema de Amortização Constante</b></p>
                                            </li>
                                            <li>
                                                <p>Indexador</p>
                                                <p><b>TR</b></p>
                                            </li>
                                            <li>
                                                <p>Juros nominais</p>
                                                <p><b>9,46% a.a</b></p>
                                            </li>
                                            <li>
                                                <p>Juros efetivos</p>
                                                <p><b>9,89% a.a</b></p>
                                            </li>
                                        </ul>
                                    </div>
                                </b-collapse>
                                <a href="javascript:void(0)" class="btn-collapse" v-b-toggle.collapse-1>
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                        <label for="el2" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="modalidade" id="el2">
                            <div class="dados">
                                <h5>SBPE (Crédito Imobiliário Poupança CAIXA): Relacionamento</h5>
                                <p class="-preto">Condições e vantagens especiais para você que é ou quer ser cliente com Conta na CAIXA e opção por inclusão da prestação do Financiamento Habitacional para débito em conta.</p>
                                <b-collapse id="collapse-2">
                                    <div class="collapse-card">
                                        <div class="linha-separador"></div>
                                        <ul class="lista-dados-valores">
                                            <li>
                                                <p>Valor do imóvel</p>
                                                <p><b>R$ 260.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Valor de entrada</p>
                                                <p><b>R$ 26.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Valor financiada</p>
                                                <p><b>R$ 234.000,00</b></p>
                                            </li>
                                            <li>
                                                <p>Prestação máxima</p>
                                                <p><b>Não informado</b></p>
                                            </li>
                                            <li>
                                                <p>Prazo</p>
                                                <p><b>420 meses</b></p>
                                            </li>
                                        </ul>
                                        <div class="linha-separador"></div>
                                        <ul class="lista-dados-valores">
                                            <li>
                                                <p>Amortização</p>
                                                <p><b>Sistema de Amortização Constante</b></p>
                                            </li>
                                            <li>
                                                <p>Indexador</p>
                                                <p><b>TR</b></p>
                                            </li>
                                            <li>
                                                <p>Juros nominais</p>
                                                <p><b>9,46% a.a</b></p>
                                            </li>
                                            <li>
                                                <p>Juros efetivos</p>
                                                <p><b>9,89% a.a</b></p>
                                            </li>
                                        </ul>
                                    </div>
                                </b-collapse>
                                <a href="javascript:void(0)" class="btn-collapse" v-b-toggle.collapse-2>
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                    </div>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Continuar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo7" v-if="step == 6">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Resumo da proposta</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li class="active">
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 64%;"></div>
                        </div>
                    </div>
                    <h4 class="m-b-20 m-t-20">Seguradoras</h4>
                    <p class="m-b-20">A contratação de um seguro habitacional é obrigatório. Selecione a seguradora e verifique as condições de financiamento.</p>
                    <div class="scroll-wrap">
                        <label for="seguradoras1" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="seguradoras" id="seguradoras1">
                            <div class="dados">
                                <h4>CAIXA RESIDENCIAL HABITACIONAL</h4>
                                <div class="linha-separador"></div>
                                <ul class="lista-dados-valores">
                                    <li>
                                        <p>Primeira parcela</p>
                                        <p>R$ 2.468,04</p>
                                    </li>
                                    <li>
                                        <p>Última parcela</p>
                                        <p>R$ 586,55</p>
                                    </li>
                                    <li>
                                        <p>Seguro MIP</p>
                                        <p>A calcular</p>
                                    </li>
                                    <li>
                                        <p>Seguro DFI</p>
                                        <p>A calcular</p>
                                    </li>
                                </ul>
                                <div id="card11" class="collapse collapse-card">
                                    <ul class="lista-dados-valores">
                                        <li>
                                            <p>Valor do imóvel</p>
                                            <p><b>R$ 260.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor de entrada</p>
                                            <p><b>R$ 26.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor financiada</p>
                                            <p><b>R$ 234.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Prestação máxima</p>
                                            <p><b>Não informado</b></p>
                                        </li>
                                        <li>
                                            <p>Prazo</p>
                                            <p><b>420 meses</b></p>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javascript:void(0)" data-toggle="collapse" data-target="#card11" class="btn-collapse m-t-10">
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                        <label for="seguradoras2" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="seguradoras" id="seguradoras2">
                            <div class="dados">
                                <h4>CAIXA RESIDENCIAL HABITACIONAL Epaecial</h4>
                                <div class="linha-separador"></div>
                                <ul class="lista-dados-valores">
                                    <li>
                                        <p>Primeira parcela</p>
                                        <p>R$ 2.468,04</p>
                                    </li>
                                    <li>
                                        <p>Última parcela</p>
                                        <p>R$ 586,55</p>
                                    </li>
                                    <li>
                                        <p>Seguro MIP</p>
                                        <p>A calcular</p>
                                    </li>
                                    <li>
                                        <p>Seguro DFI</p>
                                        <p>A calcular</p>
                                    </li>
                                </ul>
                                <div id="card12" class="collapse collapse-card">
                                    <ul class="lista-dados-valores">
                                        <li>
                                            <p>Valor do imóvel</p>
                                            <p><b>R$ 260.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor de entrada</p>
                                            <p><b>R$ 26.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor financiada</p>
                                            <p><b>R$ 234.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Prestação máxima</p>
                                            <p><b>Não informado</b></p>
                                        </li>
                                        <li>
                                            <p>Prazo</p>
                                            <p><b>420 meses</b></p>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javascript:void(0)" data-toggle="collapse" data-target="#card12" class="btn-collapse m-t-10">
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                        <label for="seguradoras3" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="seguradoras" id="seguradoras3">
                            <div class="dados">
                                <h4>Tokio marine</h4>
                                <div class="linha-separador"></div>
                                <ul class="lista-dados-valores">
                                    <li>
                                        <p>Primeira parcela</p>
                                        <p>R$ 2.468,04</p>
                                    </li>
                                    <li>
                                        <p>Última parcela</p>
                                        <p>R$ 586,55</p>
                                    </li>
                                    <li>
                                        <p>Seguro MIP</p>
                                        <p>A calcular</p>
                                    </li>
                                    <li>
                                        <p>Seguro DFI</p>
                                        <p>A calcular</p>
                                    </li>
                                </ul>
                                <div id="card13" class="collapse collapse-card">
                                    <ul class="lista-dados-valores">
                                        <li>
                                            <p>Valor do imóvel</p>
                                            <p><b>R$ 260.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor de entrada</p>
                                            <p><b>R$ 26.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor financiada</p>
                                            <p><b>R$ 234.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Prestação máxima</p>
                                            <p><b>Não informado</b></p>
                                        </li>
                                        <li>
                                            <p>Prazo</p>
                                            <p><b>420 meses</b></p>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javascript:void(0)" data-toggle="collapse" data-target="#card13" class="btn-collapse m-t-10">
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                        <label for="seguradoras4" class="cv-blocoBg -full -branco p-10 card-radius">
                            <input type="radio" name="seguradoras" id="seguradoras4">
                            <div class="dados">
                                <h4>Too Seguros</h4>
                                <div class="linha-separador"></div>
                                <ul class="lista-dados-valores">
                                    <li>
                                        <p>Primeira parcela</p>
                                        <p>R$ 2.468,04</p>
                                    </li>
                                    <li>
                                        <p>Última parcela</p>
                                        <p>R$ 586,55</p>
                                    </li>
                                    <li>
                                        <p>Seguro MIP</p>
                                        <p>A calcular</p>
                                    </li>
                                    <li>
                                        <p>Seguro DFI</p>
                                        <p>A calcular</p>
                                    </li>
                                </ul>
                                <div id="card14" class="collapse">
                                    <ul class="lista-dados-valores">
                                        <li>
                                            <p>Valor do imóvel</p>
                                            <p><b>R$ 260.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor de entrada</p>
                                            <p><b>R$ 26.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Valor financiada</p>
                                            <p><b>R$ 234.000,00</b></p>
                                        </li>
                                        <li>
                                            <p>Prestação máxima</p>
                                            <p><b>Não informado</b></p>
                                        </li>
                                        <li>
                                            <p>Prazo</p>
                                            <p><b>420 meses</b></p>
                                        </li>
                                    </ul>
                                </div>
                                <a href="javascript:void(0)" data-toggle="collapse" data-target="#card14" class="btn-collapse m-t-10">
                                    Ver detalhes
                                </a>
                            </div>
                        </label>
                    </div>
                    <div class="rodape-passos">
                        <button class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Continuar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo8" v-if="step == 7">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Resumo da proposta</b>
                    </a>
                    <div class="card-unidade m-b-20 m-t-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li class="active">
                                <span>03</span>
                                Resumo
                            </li>
                            <li>
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 64%;"></div>
                        </div>
                    </div>
                    <h4 class="m-b-20 m-t-20">Número da proposta</h4>
                    <a href="javascript:void(0)" id="btn1Copy" class="card-copy" @click="copyText('907557', 'btn1Copy')" >
                        <span>907557</span>
                        <div class="--icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z"/></svg>
                        </div>
                    </a>
                    <h4 class="m-b-20 m-t-20">Financiamento selecionado</h4>
                    <a href="javascript:void(0)" id="btn2Copy" class="card-copy" @click="copyText('SBPE (Crédito Imobiliário Poupança CAIXA): Relacionamento', 'btn2Copy')" >
                        <p>SBPE (Crédito Imobiliário Poupança CAIXA): Relacionamento</p>
                        <div class="--icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z"/></svg>
                        </div>
                    </a>
                    <h4 class="m-b-20 m-t-20">Valores e taxas</h4>
                    <div class="cv-blocoBg -full -branco p-10">
                        <ul class="lista-dados-valores">
                            <li>
                                <p>Valor do imóvel</p>
                                <p>R$ 260.000,00</p>
                            </li>
                            <li>
                                <p>Valor de entrada</p>
                                <p>R$ 26.000,00</p>
                            </li>
                            <li>
                                <p>Valor financiada</p>
                                <p>R$ 234.000,00</p>
                            </li>
                            <li>
                                <p>Prestação máxima</p>
                                <p>Não informado</p>
                            </li>
                            <li>
                                <p>Prazo</p>
                                <p>420 meses</p>
                            </li>
                        </ul>
                        <div class="linha-separador"></div>
                        <ul class="lista-dados-valores">
                            <li>
                                <p>Amortização</p>
                                <p>Sistema de Amortização Constante</p>
                            </li>
                            <li>
                                <p>Indexador</p>
                                <p>TR</p>
                            </li>
                            <li>
                                <p>Juros nominais</p>
                                <p>9,46% a.a</p>
                            </li>
                            <li>
                                <p>Juros efetivos</p>
                                <p>9,89% a.a</p>
                            </li>
                        </ul>
                    </div>
                    <h4 class="m-b-20 m-t-20">Seguro selecionado</h4>
                    <div class="cv-blocoBg -full -branco p-10 m-b-20">
                        <h4 class="destaque">
                            CAIXA RESIDENCIAL HABITACIONAL
                            <img src="https://dev.cvcrm.com.br/assets/img/caixa-seguradora.png" alt="">
                        </h4>
                        <div class="linha-separador"></div>
                        <ul class="lista-dados-valores">
                            <li>
                                <p>Primeira parcela</p>
                                <p>R$ 2.468,04</p>
                            </li>
                            <li>
                                <p>Última parcela</p>
                                <p>R$ 586,55</p>
                            </li>
                            <li>
                                <p>Seguro MIP</p>
                                <p>R$ 22,41</p>
                            </li>
                            <li>
                                <p>Seguro DFI</p>
                                <p>R$ 17,16</p>
                            </li>
                        </ul>
                    </div>
                    <div class="rodape-passos">
                        <button type="button" class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Próximo passo
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button type="button" class="cv-btn-ghost -branco -medium -icon">
                            Baixar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 270.1-95-95c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 369c9.4 9.4 24.6 9.4 33.9 0L409 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95L280 24zM128.8 304L64 304c-35.3 0-64 28.7-64 64l0 80c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-80c0-35.3-28.7-64-64-64l-64.8 0-48 48L448 352c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16l112.8 0-48-48zM432 408a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo9" v-if="step == 8">
                    <a @click.prevent="prevStep" href="javascript:void(0)" class="btn-prev-passo btn-back">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                        <b>Documentos</b>
                    </a>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <div class="card-unidade m-b-20 m-t-20" style="">
                        <div class="--ft">
                            <img id="img-unidade" src="https://picsum.photos/300/300" alt="Imagem da unidade">
                        </div>
                        <div class="--info">
                            <div class="--header">
                                <div class="--text">
                                    <div class="content">
                                        <h5>Unidade 0000</h5>
                                        <p>Morada Blue Pen</p>
                                    </div>
                                    <div class="cv-status-label" style="background-color: #53AE57; color: white;">Imóvel novo</div>
                                </div>
                            </div>
                            <div class="--footer">
                                <p>Valor da unidade: R$400.000,00</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-passos">
                        <ul>
                            <li class="active">
                                <span>01</span>
                                Beneficiário
                            </li>
                            <li class="active">
                                <span>02</span>
                                Atendimento
                            </li>
                            <li class="active">
                                <span>03</span>
                                Resumo
                            </li>
                            <li class="active">
                                <span>04</span>
                                Documentos
                            </li>
                        </ul>
                        <div class="grafico">
                            <div class="barra" style="width: 90%;"></div>
                        </div>
                    </div>
                    <h4 class="m-b-20 m-t-20">Enviar  documentos</h4>
                    <p class="m-b-20">Documentos encontrados no CV vinculados a este repasse.</p>
                    <h4 class="m-b-20 m-t-20">Checklist documentos</h4>
                    <p class="m-b-20">Foi identificado a ausência de um dos documentos necessários para prosseguir com a proposta. Verifique na lista e adicione para continuar com o envio.</p>
                    <a href="javascript:void(0)" class="card-link m-b-20">
                        <p>Documento de identificação com foto</p>
                        <div class="status">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                        </div>
                        <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                        </div>
                    </a>
                    <a href="javascript:void(0)" class="card-link m-b-20">
                        <p>Comprovante de estado civil</p>
                        <div class="status">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                        </div>
                        <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                        </div>
                    </a>
                    <a href="javascript:void(0)" @click class="card-link m-b-20">
                        <p>Comprovante de rendimentos</p>
                        <div class="status alerta">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"/></svg>
                        </div>
                        <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                        </div>
                    </a>
                    <a href="javascript:void(0)" class="card-link m-b-20">
                        <p>Comprovante de endereço</p>
                        <div class="status">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                        </div>
                        <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                        </div>
                    </a>
                    <a href="javascript:void(0)" class="card-link m-b-20">
                        <p>Declaração IRPF</p>
                        <div class="status">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                        </div>
                        <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
                        </div>
                    </a>
                    <div class="card-alerta m-b-20">
                        <div class="--icone">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"/></svg>
                        </div>
                        <div class="--texto">
                            <span>Documentos adicionais</span>
                            <p>Documentos adicionais podem ser solicitados pela CAIXA a qualquer momento.</p>
                        </div>
                    </div>
                    <div class="rodape-passos">
                        <button type="button" class="cv-btn-block -laranja -medium -icon btn-prox-passo btn-proposta" @click.prevent="nextStep">
                            Enviar Proposta
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/></svg>
                        </button>
                        <button class="cv-btn-ghost -branco -medium" @click.prevent="prevStep">
                            Voltar
                        </button>
                    </div>
                </div>
                
                <div class="passo" id="passo10" v-if="step == 9">
                    <div class="loader">
                        <span class="cv-loader"></span>
                        <p>Processando dados...</p>
                    </div>
                </div>
                
                <div class="passo" id="passo11" v-if="step == 10">
                    <div class="resultado-envio">
                        <div class="icone">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                        </div>
                        <h4>Proposta enviada com sucesso!</h4>
                        <p>Agora você pode acompanhar o andamento da proposta clicando no botão abaixo.</p>
                        <button type="button" class="cv-btn-block -laranja -medium -icon btn-prox-passo" @click.prevent="nextStep">
                            Acompanhar proposta
                        </button>
                    </div>
                </div>

                <div class="passo" id="passo12" v-if="step == 11">
                    <p class="m-b-20">Proposta em andamento</p>
                    <p class="m-b-20">Clique abaixo e acompanhe o andamento da proposta.</p>
                    <div class="cv-blocoBorder -branco -full p10 card-resultado">
                        <div class="dado">
                            <p>Número da proposta</p>
                            <span>907557</span>
                        </div>
                        <a href="javascript:void(0)" class="dado btn-prox-passo btn-animacao-right" @click.prevent="nextStep">
                            <div class="cv-status-label" style="background: #53AE57; color: white">Enviada para a CAIXA</div>
                            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M505 273c9.4-9.4 9.4-24.6 0-33.9L369 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l95 95L24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l406.1 0-95 95c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273z"/></svg></span>
                        </a>
                    </div>
                </div>
                
                <div class="passo" id="passo13" v-if="step == 12">
                    <div class="deletar-proposta">
                        <a href="javascript:void(0)" class="btn-prev-passo btn-back" @click.prevent="prevStep">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M7 239c-9.4 9.4-9.4 24.6 0 33.9L143 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-95-95L488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24L81.9 232l95-95c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239z"/></svg>
                            <b>Acompanhamento da proposta</b>
                        </a>
                        <a href="javascript:void(0)" class="btn-deletar" onclick="abreAlerta(event, 'Você realmente deseja deletar essa proposta?', 'deletarProposta', null , 'Você está deletando a proposta <b>907557</b>', 'Deletar Proposta', 'Deletar Proposta'); window.parent.scrollTo({top: 200, behavior: 'smooth'});">
                            <i class="fa-regular fa-trash"></i>
                        </a>
                    </div>
                    <div class="card-numero">
                        <p>Número da proposta:</p>
                        <span>907557</span>
                    </div>
                    <p class="m-t-20 m-b-20">
                        Acompanhe no checklist abaixo o andamento da análise.
                    </p>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p1">
                            <p>Proposta</p>
                            <div class="status">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p1" class="collapse">
                            <p>Etapa concluída</p>
                        </div>
                    </div>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p2">
                            <p>Avaliação de crédito</p>
                            <div class="status alerta">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm64 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm128-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p2" class="collapse">
                            <p>Etapa em análise</p>
                        </div>
                    </div>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p3">
                            <p>Avaliação do imóvel</p>
                            <div class="status alerta">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm64 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm128-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p3" class="collapse">
                            <p>Etapa em análise</p>
                        </div>
                    </div>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p4">
                            <p>FGTS</p>
                            <div class="status">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p4" class="collapse">
                            <p>Etapa concluída</p>
                        </div>
                    </div>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p5">
                            <p>Emissão de contrato</p>
                            <div class="status alerta">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p5" class="collapse">
                            <p>Etapa pendente</p>
                        </div>
                    </div>
                    <div class="cv-blocoBg -full -branco p-0 card-collapse">
                        <a href="javascript:void(0)" class="card-link static collapsed" data-toggle="collapse" data-target="#p6">
                            <p>Contrato assinado</p>
                            <div class="status alerta">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm64 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm128-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                            </div>
                            <div class="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                            </div>
                        </a>
                        <div id="p6" class="collapse">
                            <p>Etapa em análise</p>
                        </div>
                    </div>
                    
                </div>

            </div>

        </div>

        <div class="sidebar-caixa-overflow" v-if="sidebarCaixa" @click="handleClickSidebarCaixaOverflow"></div>
    </div>
    
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "SidebarCaixa",

    components: {
        vSelect,
    },

    data() {
      return {
        step: 1,
        totalSteps: 12,
        isCopied: false
      };
    },

    props: {
        sidebarCaixa: {
            type: Boolean,
            default: false
        }
    },
   
    methods: {
        nextStep() {
            this.step++;
            if(this.step == 9){
                setTimeout(() => {
                    this.step++;
                }, 3000);
            }
        },

        prevStep() {
            this.step--;
        },

        handleClickSidebarCaixaOverflow() {
            this.$emit('hide-sidebar');
        },
        copyText(text, btnId) {
            navigator.clipboard.writeText(text).then(() => {
                const button = document.getElementById(btnId);
                if (button) {
                button.classList.add('cvCopiedText-btn');
                setTimeout(() => {
                    button.classList.remove('cvCopiedText-btn');
                }, 5000);
                }
            }).catch(() => {
                alert('Falha ao copiar o texto.');
            });
        }
    },
};
</script>
