<template>
  <div  v-if="address">
    <h5>Localização</h5>
    <p id="address">{{getSafe(() => address)}}</p>
    <div id="map"></div>
  </div>
</template>

<script>
  import getSafe from '@/plugins/OptionalChain';

  export default {

    props: {
      address: { type: String, required: true }
    },

    async beforeMount() {
      window.mapApiInitialized = this.initMap;
      let script = document.createElement('script')
      script.async = true 
      script.setAttribute('src',  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDIOPp-9Dp6pyrqtkv9B7ucxkAKbG4laEc&libraries=places&callback=mapApiInitialized")
      let meta = document.createElement('meta')
      meta.setAttribute('http-equiv',  "Content-Security-Policy")
      meta.setAttribute('content',  
                        "script-src 'self' 'unsafe-inline' 'unsafe-eval' https://*.googleapis.com https://*.gstatic.com *.google.com https://*.ggpht.com *.googleusercontent.com *.construtordevendas.com.br *.cvcrm.com.br ; "+
                        "img-src 'self' https://*.googleapis.com https://*.gstatic.com *.google.com  *.googleusercontent.com data:; " +
                        "frame-src *.google.com; "+
                        "connect-src 'self' https://*.googleapis.com *.google.com https://*.gstatic.com  data: blob:; "+
                        "font-src https://fonts.gstatic.com; "+
                        "style-src 'self' 'unsafe-inline' https://fonts.googleapis.com")
      document.head.appendChild(meta)
      document.head.appendChild(script)
    },
    
    async updated() {
      this.address;
      this.initMap();
    },

    methods: { 
      getSafe, 
      initMap(){
        let brazil = new google.maps.LatLng(-15.7500, -47.9500)
        let map = new google.maps.Map(document.getElementById("map"), {
                      center: brazil,
                      zoom: 16,
                    }); 
        let infowindow = new google.maps.InfoWindow() 
        const request = {
          query: document.getElementById("address").innerText,
          fields: ["name", "geometry"],
        };
        let service = new google.maps.places.PlacesService(map);
        
        service.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && results) {
            for (let i = 0; i < results.length; i++) {
              if (!results[i].geometry || !results[i].geometry.location) return;
              const marker = new google.maps.Marker({
                map,
                position: results[i].geometry.location,
              });
              google.maps.event.addListener(marker, "click", () => {
                infowindow.setContent(results[i].name || "");
                infowindow.open(map);
              });
            }
            map.setCenter(results[0].geometry.location);
          }
        });
      }
    }
  };
</script>

<style>
  #map{
    width: 100%;
    height: 275px;
  }
</style>
