<template>
    <div class="sidebar-filters-overlay" :class="show" @click.self="hideSidebarFilters">
        <div class="sidebar-filters" :class="show">
            <div class="sidebar-filters-header">
                <h3>Filtros</h3>
                <button class="sidebar-button-close" @click="hideSidebarFilters">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                        <path fill="#000000" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                    </svg>
                </button>
            </div>
            <div class="sidebar-filters-body">
                <form>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Filtro:"
                            placeholder="Todos"
                        />
                    </div>
                </form>
            </div>
            <div class="sidebar-filters-footer">
                <button class="pc-btnBox pc-btnBox-primary">Aplicar Filtros</button>
                <button class="pc-btnBox pc-btnBox-branco">Limpar Filtros</button>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectInput from '@/components/SelectInput.vue';

    export default {
        components: {
            SelectInput
        },
        data() {
            return {
                show: ""
            };
        },
        methods: {
            showSidebarFilters() {
                this.show = "show";
            },
            hideSidebarFilters() {
                this.show = "";
            }
        },
        mounted() {
            document.addEventListener('keydown', (e) => {
                if (this.show && e.key === 'Escape') {
                    this.hideSidebarFilters();
                }
            });
        }
    };
</script>

<style scoped>
    .sidebar-filters-overlay {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2223;
        visibility: hidden;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-filters-overlay.show {
        visibility: visible;
    }

    .sidebar-filters {
        position: fixed;
        top: 0;
        right: -300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 300px;
        height: 100%;
        background: #ffffff;
        border-radius: 4px 0 0 0;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-filters.show {
        right: 0;
    }

    .sidebar-filters-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    .sidebar-filters-header h3 {
        font-size: 16px;
        font-weight: 700;
        color: #2f2f2f;
        margin-bottom: 0;
    }

    .sidebar-filters-body {
        flex: 1;
        padding: 20px;
        border-top: 2px solid #d9d9d9;
        background-color: #f6f7f8;
        overflow: auto;
    }

    .sidebar-filters-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    }

    .sidebar-button-close {
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
    }
</style>
