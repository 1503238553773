<template>
  <div
    class="modal fade "
    id="modal-pesquisas"
    ref="modal-pesquisas"
    tabindex="-1"
    role="dialog"
    aria-labelledby="indicaramigoTitle"
    aria-hidden="true"
  >
    <div v-if="" class="modal-dialog modal-dialog-centered " role="document">
      <div class="modal-content">
            <div class="modal-body ">
              <div class="pesquisas-wrap">
                <div class="modal-pesquisas" v-for="(pesquisa, index) in pesquisas">
                  <h2>{{ pesquisa.pesquisa }}</h2>
                  <p>{{ pesquisa.descricao }}</p>
                  <div class="--btns">
                    <a :href="pesquisa.responder_url" target="_blank" class="pc-btnBox pc-btnBox-primary">Link Pesquisa</a>
                    <button
                        class="pc-btnGhost pc-btnGhost-secondary"
                        data-dismiss="modal"
                        aria-label="Close"
                    >Responder mais tarde
                    </button>
                  </div>
                </div>
              </div>
              <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                Fechar
              </button>
            </div>
      </div>
    </div>
    <a
        id="abrirModal"
        style="display: none"
        class="pc-btnGhost"
        data-toggle="modal"
        data-target="#modal-pesquisas"
    ></a>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import {getPesquisas} from "@/services/PesquisaService";

export default {
    data() {
      return {
        mostrarModal: false,
        pesquisas: [],
        carouselSettings: {
          arrows:true,
          dots: false,
          dotsClass: "slick-dots custom-dot-class",
          edgeFriction: 0.35,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      };
    },
    components: {
      VueSlickCarousel,
    },
    methods: {
        handlePesquisaBar() {
           this.$root.$emit('pesquisaStatus')
        }
    },
    beforeMount() {
      getPesquisas(0, 100)
          .then((response) => {
              for(const pesquisa of response.data.pesquisas){
                  if (!pesquisa.resposta.respondido) {
                      this.pesquisas.push(pesquisa);
                      break;
                  }
              }
              if (this.pesquisas.length > 0) {
                this.mostrarModal = true;
                document.getElementById("abrirModal").click();
              }
          })
          .catch(() => {
            this.mostrarModal = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
};
</script>
