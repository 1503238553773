<template>
    <div class="pc-leftColumn">
        <a href="javascript:void(0)" class="pc-btnMenuXsClose d-flex d-lg-none">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id=""
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                style="display: block"
                xml:space="preserve"
                class="pc-svg replaced-svg"
            >
                <g>
                    <path
                        d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717    L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859    c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287    l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285    L284.286,256.002z"
                    ></path>
                </g>
            </svg>
        </a>
        <router-link :to="{ name: 'home' }" class="pc-leftColumn--logo">
            <img
                v-if="configuracoes.logo"
                :src="configuracoes.logo.url"
                alt=""
            />
            <img
                v-else
                :src="baseUrl + '/especifico/img/logo_empresa.png'"
                alt=""
            />
        </router-link>
        <div class="pc-leftColumn--wrap">
             <ul
                v-if="configuracoes.ordem_menu.length > 0"
            >
                <li>
                    <router-link class="pc-link-menu" :to="{ name: 'home' }">
                        <IconHouse />
                        <span>Página Inicial</span>
                    </router-link>
                </li>
                <li v-for="menu in configuracoes.ordem_menu">
                    <div v-if="
                        !acessoSindico &&
                        menu.idextensao > 0
                        && (
                          !acessoSindico && menu.painel_exibicao != 'S'
                          || acessoSindico && menu.painel_exibicao != 'C'
                          )
                        && unidade
                        && (
                          unidade.financeiro && menu.iconepc === 'financeiro'
                          || menu.iconepc !== 'financeiro'
                        )
                        &&
                        (
                          unidade.perfil.sigla === 'TR'
                          || (menu.associado === 'S' && unidade.perfil.sigla === 'AR')
                        )
                        && menu.idextensao > 0">
                        <a
                            class="pc-link-menu"
                            v-if="menu.tipo === 'blank'"
                            :href="menu.link"
                            target="_blank"
                        >
                            <IconHandler :icone="menu.icone"/>
                            <span>{{ menu.nome_descricao }}</span>
                        </a>
                        <router-link
                            class="pc-link-menu"
                            v-else
                            :to="{ name: 'plugin-' + nomeUrl(menu.nome_descricao) }"
                        >
                            <IconHandler :icone="menu.icone"/>
                            <span>{{ menu.nome_descricao }}</span>
                        </router-link>
                    </div>
                    <router-link v-if="menu.rota == 'unidades' && !acessoSindico &&
                        verificarRotaExiste('unidades')" class="pc-link-menu" :to="{ name: 'unidades' }">
                        <IconPredio />
                        <span>Minhas Unidades</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'contratos' && !acessoSindico &&
                        verificarRotaExiste('contratos')" class="pc-link-menu" :to="{ name: 'contratos' }">
                        <IconContratos />
                         <span>Meus Contratos</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'financeiro' && !acessoSindico && unidade.financeiro &&
                        verificarRotaExiste('financeiro')" class="pc-link-menu" :to="{ name: 'financeiro' }">
                        <IconFinanceiro />
                        <span>Financeiro</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'atendimentos' &&
                        verificarRotaExiste('atendimentos')" class="pc-link-menu" :to="{ name: 'atendimentos' }">
                        <IconAtendimento />
                        <span>Atendimentos</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'assistencias' &&
                        verificarRotaExiste('assistencias')" class="pc-link-menu" :to="{ name: 'assistencias' }">
                        <IconFerramenta />
                        <span>Assistências Técnicas</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'agendamentos' && !acessoSindico &&
                        verificarRotaExiste('agendamentos')" class="pc-link-menu" :to="{ name: 'agendamentos' }">
                            <IconCalendar />
                            <span>Meus Agendamentos</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'documentos' &&
                        verificarRotaExiste('documentos')" class="pc-link-menu" :to="{ name: 'documentos' }">
                        <IconDocumentos />
                        <span>Documentos</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'portal-beneficios' && !acessoSindico &&
                    verificarRotaExiste('portal-beneficios')" class="pc-link-menu" :to="{ name: 'portal-beneficios' }">
                        <IconFinanceiro/>
                        <span>Portal de Benefícios</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'pesquisas' && !acessoSindico &&
                        verificarRotaExiste('pesquisas')"  class="pc-link-menu" :to="{ name: 'pesquisas' }">
                        <IconContratos />
                        <span>Minhas Pesquisas</span>
                    </router-link>
                    <router-link v-if="menu.rota == 'meus-cadastros' && !acessoSindico &&
                        verificarRotaExiste('meus-cadastros')" class="pc-link-menu" :to="{ name: 'meus-cadastros' }">
                        <IconLocatario />
                        <span>Meus Cadastros</span>
                    </router-link>
                </li>
            </ul>
            <ul v-else>
                <li>
                    <router-link class="pc-link-menu" :to="{ name: 'home' }">
                        <IconHouse />
                        <span>Página Inicial</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('unidades') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'unidades' }">
                        <IconPredio />
                        <span>Minhas Unidades</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('contratos') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'contratos' }">
                        <IconContratos />
                        <span>Meus Contratos</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('financeiro') && !acessoSindico && unidade.financeiro">
                    <router-link class="pc-link-menu" :to="{ name: 'financeiro' }">
                        <IconFinanceiro />
                        <span>Financeiro</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('atendimentos')">
                    <router-link class="pc-link-menu" :to="{ name: 'atendimentos' }">
                        <IconAtendimento />
                        <span>Atendimentos</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('assistencias')">
                    <router-link class="pc-link-menu" :to="{ name: 'assistencias' }">
                        <IconFerramenta />
                        <span>Assistências Técnicas</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('agendamentos') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'agendamentos' }">
                        <IconCalendar />
                        <span>Meus Agendamentos</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('documentos')">
                    <router-link class="pc-link-menu" :to="{ name: 'documentos' }">
                        <IconDocumentos />
                        <span>Documentos</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('portal-beneficios') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'portal-beneficios' }">
                        <IconFinanceiro/>
                        <span>Portal de Benefícios</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('pesquisas') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'pesquisas' }">
                        <IconContratos />
                        <span>Minhas Pesquisas</span>
                    </router-link>
                </li>
                <li v-if="verificarRotaExiste('meus-cadastros') && !acessoSindico">
                    <router-link class="pc-link-menu" :to="{ name: 'meus-cadastros' }">
                        <IconLocatario />
                        <span>Meus Cadastros</span>
                    </router-link>
                </li>
                <li v-for="plugin in configuracoes.plugins">
                    <a
                        class="pc-link-menu"
                        v-if="plugin.tipo === 'blank' && (!acessoSindico && plugin.painel_exibicao != 'S' ||
                        acessoSindico && plugin.painel_exibicao != 'C')"
                        :href="plugin.link"
                        target="_blank"
                    >
                        <IconHandler :icone="plugin.icone"/>
                        <span>{{ plugin.nome }}</span>
                    </a>
                    <router-link
                        class="pc-link-menu"
                        v-else-if="(!acessoSindico && plugin.painel_exibicao != 'S' ||
                        acessoSindico && plugin.painel_exibicao != 'C')"
                        :to="{ name: 'plugin-' + nomeUrl(plugin.nome) }"
                    >
                        <IconHandler :icone="plugin.icone"/>
                        <span>{{ plugin.nome }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState , mapGetters } from "vuex";
import { URL_API } from "@/bootstrap/index";
import IconFerramenta from "../../components/icons/IconFerramenta.vue";
import getSafe from "@/plugins/OptionalChain";
import IconHandler from "@/components/IconHandler";

export default {
    components: { IconFerramenta, IconHandler },
    computed: {
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes,
            unidades: (state) => state.unidades.unidades,
            unidade: (state) => state.unidades.unidade,
            perfil: (state) => state.permissao.perfisCliente,
        }),
        ...mapGetters({
            acessoSindico: "getAcessoSindico",
        }),
    },
    mounted() {
        var $linkMenu = document.querySelectorAll(".pc-link-menu");
        let overlayMenuXs = document.querySelector(".pc-overlayMenuXs");
        let leftColumn = document.querySelector(".pc-leftColumn");
        $linkMenu.forEach(item => {
            item.addEventListener("click", event => {
                leftColumn.classList.remove("show");
                overlayMenuXs.classList.remove("show");
            });
        });
    },
    data() {
        return {
            baseUrl: URL_API,
        };
    },
    methods: {
        nomeUrl(nome) {
            return nome.toLowerCase().replace(" ", "-").normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        },
        getSafe,
    },
};
</script>