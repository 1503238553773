import { getUnidade, getUnidades } from '@/services/UnidadeService'
import { getDefaultState } from "./state";

export default {
    async unidadesCliente({ commit, dispatch }, cliente) {
        await getUnidades()
            .then(response => {
                if (response.data) {
                    commit('SET_UNIDADES', response.data)

                    const idUnidade = cliente.idunidade_padrao
                        ? cliente.idunidade_padrao : response.data[0].idunidade;

                    let unidadeSelecionada = response.data.find((unidade) => {
                        return unidade.idunidade === idUnidade;
                    });

                    if (!unidadeSelecionada && response.data.length > 0) {
                        unidadeSelecionada = response.data[0];
                    }

                    if (unidadeSelecionada) {
                        commit('SET_UNIDADE', unidadeSelecionada);
                        commit('SET_EMPREENDIMENTO', unidadeSelecionada.empreendimento.idempreendimento);
                    }
                } else {
                    dispatch('resetState');
                }
            })
            .catch(error => {
                commit('SET_UNIDADES', null)
                commit('SET_UNIDADE', null)
            })
            .finally(() => {
                commit('CONCLUIDO_UNIDADES');
                dispatch('retornarPermissoes');
            });
    },

    novaUnidadePadrao({ commit }, idUnidade) {
        getUnidade(idUnidade)
            .then(response => {
                commit('SET_UNIDADE', response.data)
            })
            .catch(error => {
                commit('SET_UNIDADE', null)
            })
    },

    resetState({ state }) {
        Object.assign(state, getDefaultState());
    },

}
