var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-login-form--wrap"},[_vm._m(0),_c('Aviso',{attrs:{"id":"aviso","exibir":_vm.alerta.exibir,"mensagem":_vm.alerta.mensagem,"tipo":_vm.alerta.tipo}}),(_vm.alerta.exibir && _vm.alerta.tipo === 'erro')?_c('router-link',{staticClass:"pc-btnBox pc-btnBox--fullW",attrs:{"to":{
         name: _vm.configuracoes.tela_login.padrao_reset_senha === 'sms' ?
         (_vm.$route.params.modulo === 'sindico' ? 'esqueceu-senha-sms-sindico' : 'esqueceu-senha-sms') :
         (_vm.$route.params.modulo === 'sindico' ? 'esqueceu-senha-email-sindico' : 'esqueceu-senha-email'),
         params: {slug: this.$store.state.configuracoes.slug}
      }}},[_vm._v(" Gerar uma nova solicitação ")]):_vm._e(),(_vm.alerta.exibir && _vm.alerta.tipo === 'sucesso')?_c('router-link',{staticClass:"pc-btnBox pc-btnBox--fullW",attrs:{"to":{
         name: 'login',
         params: {slug: this.$store.state.configuracoes.slug}
      }}},[_vm._v(" Voltar para realizar login ")]):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [(_vm.exibirForm)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.alterarSenha)}}},[_c('div',{staticClass:"form-group",attrs:{"id":"cadastre-se"}},[_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"form-group pc-login-password"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [((_vm.exibirSenha ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.senha),expression:"formulario.senha"}],class:[
                'form-control',
                _vm.senha_regex && _vm.formulario.senha
                  ? {
                      'is-invalid': !_vm.senha_regex.test(_vm.formulario.senha),
                    }
                  : '',
              ],attrs:{"name":"senha","placeholder":"Insira sua senha","id":"senha","required":"required","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formulario.senha)?_vm._i(_vm.formulario.senha,null)>-1:(_vm.formulario.senha)},on:{"change":function($event){var $$a=_vm.formulario.senha,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formulario, "senha", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formulario, "senha", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formulario, "senha", $$c)}}}}):((_vm.exibirSenha ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.senha),expression:"formulario.senha"}],class:[
                'form-control',
                _vm.senha_regex && _vm.formulario.senha
                  ? {
                      'is-invalid': !_vm.senha_regex.test(_vm.formulario.senha),
                    }
                  : '',
              ],attrs:{"name":"senha","placeholder":"Insira sua senha","id":"senha","required":"required","type":"radio"},domProps:{"checked":_vm._q(_vm.formulario.senha,null)},on:{"change":function($event){return _vm.$set(_vm.formulario, "senha", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.senha),expression:"formulario.senha"}],class:[
                'form-control',
                _vm.senha_regex && _vm.formulario.senha
                  ? {
                      'is-invalid': !_vm.senha_regex.test(_vm.formulario.senha),
                    }
                  : '',
              ],attrs:{"name":"senha","placeholder":"Insira sua senha","id":"senha","required":"required","type":_vm.exibirSenha ? 'text' : 'password'},domProps:{"value":(_vm.formulario.senha)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formulario, "senha", $event.target.value)}}}),_c('label',{staticClass:"error"},[_c('span',[_vm._v(_vm._s(errors[0]))])]),_c('a',{staticClass:"pc-login-showPassword",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showPassword()}}},[(_vm.exibirSenha)?_c('div',[_c('IconEye')],1):_c('div',[_c('IconEyeSlash')],1)])]}}],null,true)}),(
              _vm.configuracoes.especifico_cv.senha_portal_cliente &&
              _vm.configuracoes.especifico_cv.senha_portal_cliente.mensagem
            )?_c('label',{staticClass:"form-group--label"},[_vm._v(" "+_vm._s(_vm.configuracoes.especifico_cv.senha_portal_cliente.mensagem)+" ")]):_vm._e()],1),_c('div',{staticClass:"form-group pc-login-password"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [((_vm.exibirSenhaConfirma ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.confirma),expression:"formulario.confirma"}],class:[
                'form-control',
                _vm.formulario.confirma
                  ? {
                      'is-invalid': _vm.formulario.senha !== _vm.formulario.confirma,
                    }
                  : '',
              ],attrs:{"name":"confirma","placeholder":"Confirme sua senha","id":"confirma","required":"required","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formulario.confirma)?_vm._i(_vm.formulario.confirma,null)>-1:(_vm.formulario.confirma)},on:{"change":function($event){var $$a=_vm.formulario.confirma,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formulario, "confirma", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formulario, "confirma", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formulario, "confirma", $$c)}}}}):((_vm.exibirSenhaConfirma ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.confirma),expression:"formulario.confirma"}],class:[
                'form-control',
                _vm.formulario.confirma
                  ? {
                      'is-invalid': _vm.formulario.senha !== _vm.formulario.confirma,
                    }
                  : '',
              ],attrs:{"name":"confirma","placeholder":"Confirme sua senha","id":"confirma","required":"required","type":"radio"},domProps:{"checked":_vm._q(_vm.formulario.confirma,null)},on:{"change":function($event){return _vm.$set(_vm.formulario, "confirma", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formulario.confirma),expression:"formulario.confirma"}],class:[
                'form-control',
                _vm.formulario.confirma
                  ? {
                      'is-invalid': _vm.formulario.senha !== _vm.formulario.confirma,
                    }
                  : '',
              ],attrs:{"name":"confirma","placeholder":"Confirme sua senha","id":"confirma","required":"required","type":_vm.exibirSenhaConfirma ? 'text' : 'password'},domProps:{"value":(_vm.formulario.confirma)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formulario, "confirma", $event.target.value)}}}),_c('label',{staticClass:"error"},[_c('span',[_vm._v(_vm._s(errors[0]))])]),_c('a',{staticClass:"pc-login-showPassword",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showPasswordConfirma()}}},[(_vm.exibirSenhaConfirma)?_c('div',[_c('IconEye')],1):_c('div',[_c('IconEyeSlash')],1)])]}}],null,true)})],1),_c('ReCaptcha',{ref:"recaptcha",on:{"verified":_vm.enviarFormulario}}),_c('button',{staticClass:"pc-btnBox pc-btnBox--fullW",attrs:{"type":"submit","name":"button","disabled":_vm.botaoDisable}},[_c('span',[_vm._v(_vm._s(_vm.tituloBotao))])])],1)]):_vm._e()]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titulo-login"},[_c('h2',[_vm._v("Seja Bem-vindo")]),_c('h1',[_vm._v("Utilize os campos abaixo para criar sua nova senha:")])])
}]

export { render, staticRenderFns }