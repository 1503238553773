import axios from "axios";
import store from '../store';

export function getReservasPendentesDocumento() {
    return axios.get('/reservas-pendentes', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
