import UnidadesDisponiveis from '@/pages/agendamentos/UnidadesDisponiveis';
import MeusAgendamentos from '@/pages/agendamentos/MeusAgendamentos'
import NovoAgendamento from '@/pages/agendamentos/NovoAgendamento';
import store from '@/store';
import getSafe from '../../plugins/OptionalChain'

const sindico = store.state.login.acesso_sindico;
const modulos = store.state.configuracoes.configuracoes.modulos;
const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
const perfil = getSafe(() => store.state.unidades.unidade.perfil.sigla);
let rotas = [];

if (
    modulos.agendamentos
    && exibir_menu.agendamentos
    && store.state.unidades.unidades
    && store.state.unidades.unidades.length > 0
    && !sindico
) {
    rotas.push(
        {
            path: '/cliente/agendamentos/novo',
            component: UnidadesDisponiveis,
            props: true,
            name: 'unidades-disponiveis',
            meta: {
                title: 'Portal do Cliente - Unidades Disponíveis'
            }
        },
        {
            path: '/cliente/agendamentos/:idUnidade?',
            component: MeusAgendamentos,
            props: true,
            name: 'agendamentos',
            meta: {
                title: 'Portal do Cliente - Meus Agendamentos'
            }
        }
    );
    if (getSafe(() => store.state.permissao.permissoes[perfil].criar_agendamentos)) {
        rotas.push(
            {
                path: '/cliente/agendamentos/novo/:idAgendamento/:idUnidade/agendar/:idVistoria?',
                component: NovoAgendamento,
                props: true,
                name: 'novo-agendamento',
                meta: {
                    title: 'Portal do Cliente - Meus Agendamentos'
                }
            }
        );
    }
}

export default {
    rotas
}
