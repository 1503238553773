import axios from "axios";
import { URL_API } from "@/bootstrap/index";

export function indicarAmigo(dados, email, token) {
    return axios.post(`${URL_API}/api/cvio/lead`, dados, {
        headers: {
            'email': email,
            'token': token,
        }
    });
}