<template>
  <div class="pc-content">
    <BreadCrumbs :caminho="caminho"/>
    <TituloPagina
        :titulo="'Atendimentos'"
        :subtitulo="'Relação de atendimentos'"
    />
    <LoadingComponent :isLoading="isLoading"/>
    <Aviso
        :exibir="aviso.exibir"
        :tipo="aviso.tipo"
        :mensagem="aviso.mensagem"
    />

    <div class="row">
      <div class="col-12 col-xl-4">
        <Informacoes
            v-if="atendimento && !acessoSindico"
            :key="chaveComponentes"
            :atendimento="atendimento"
            @error="tratarErro"
        />
      </div>
      <div :class="acessoSindico ? 'col-12 col-xl-12' : 'col-12 col-xl-8'">
        <Feed
            v-if="atendimento"
            :key="chaveComponentes"
            :atendimento="atendimento"
            @error="tratarErro"
            @refresh="dadosAtendimento"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import {getAtendimento} from "./../../services/AtendimentosService";
import Informacoes from "./components/administrar/Informacoes";
import Feed from "./components/administrar/Feed";
import Aviso from "@/components/Aviso";
import TituloPagina from "../../components/TituloPagina";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
  components: {
    TituloPagina,
    BreadCrumbs,
    LoadingComponent,
    Informacoes,
    Feed,
    Aviso,
  },
  computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico",
    }),
  },
  created() {
    this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking('Meus Atendimentos', 'Meus Atendimentos', 'Visualizar Atendimento', true, false)
    );
  },

  data() {
    return {
      isLoading: false,
      atendimento: null,
      caminho: [
        {
          texto: "Meus Atendimentos",
          ativo: false,
          rota: "atendimentos",
        },
        {
          texto: "Atendimento",
          ativo: true,
          rota: null,
        },
      ],
      aviso: {
        exibir: false,
        tipo: null,
        mensagem: null,
      },
      chaveComponentes: 0,
    };
  },

  beforeMount() {
    this.dadosAtendimento();
  },

  methods: {
    dadosAtendimento() {
      this.isLoading = true;
      getAtendimento(
          this.$route.params.idAtendimento)
          .then((response) => {
            var unidades = response.data.unidade;
            let arrayNomes = [];
            Object.entries(unidades.unidades).forEach(([index, valor]) => {
              arrayNomes.push(valor.nome);
            });
            unidades.nome = arrayNomes.join(" | ");
            this.atendimento = response.data;
          })
          .catch((error) => {
            this.tratarErro(error.response.data);
          })
          .finally(() => {
            this.chaveComponentes += 1;
            this.isLoading = false;
          });
    },

    tratarErro(erro) {
      let mensagem =
          "Ocorreu um erro inesperado, por favor tente mais tarde.";
      if (erro.code != 422) {
        mensagem = erro.error;
      }

      this.aviso = {
        exibir: true,
        tipo: "erro",
        mensagem: mensagem,
      };
    },
  },
};
</script>
