<template>
    <div class="titulo-login">
        <h2
            v-html="
                configuracoes.tela_login && configuracoes.tela_login.titulo
                    ? configuracoes.tela_login.titulo
                    : 'Seja Bem-vindo'
            "
        ></h2>
        <h1
            v-html="
                configuracoes.tela_login && configuracoes.tela_login.subtitulo
                    ? configuracoes.tela_login.subtitulo
                    : 'Fique por dentro de cada detalhe do seu imóvel'
            "
        ></h1>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "TituloLogin",
    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
    },
};
</script>
