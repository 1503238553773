<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form
            @submit.prevent="handleSubmit(enviarArquivos)"
            id="zdrop"
            class="fileuploader center-align center-block pc-form"
        >
            <div class="row" v-if="atendimento.responder_por_audio">
                <div class="col-2">
                    <GravadorDeAudio @result="onResult" />
                </div>
                <div class="col-10 mt-10">
                    <audio v-if="audio_url" :src="audio_url" controls="controls"></audio>
                </div>
                <br>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <label for="descricao"
                                ><strong>*Resposta</strong></label
                            >
                            <VueEditor
                                v-model="respostaFormData.resposta_atendimento"
                                id="descricao"
                                :error-messages="errors"
                                :editorToolbar="customToolbar"
                            />
                            <label class="error"
                                ><span>{{ errors[0] }}</span></label
                            >
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="control-group">
                      <input type="hidden" id="uniqid" name="uniqid" :value="respostaFormData.uniqid">
                        <label class="control-label" for="dropzone"
                            >Arquivo(s):</label
                        >
                        <Dropzone
                            ref="dropzone"
                            v-on:sucesso="responderAtendimento"
                            :uniqid="respostaFormData.uniqid"
                            id="dropzone"
                        />
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-12 btnEnviar">
                    <button
                        name="btSend"
                        type="submit"
                        value="Enviar"
                        class="btn-form pc-btnBox pc-btnBox-secondary"
                    >
                        Responder Atendimento
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import Dropzone from "@/components/Dropzone";
import { gerarUniqId } from "@/plugins/Utils";
import {uploadArquivos, responder} from "../../../../services/AtendimentosService";
import {objetoTraking} from "../../../../plugins/Utils";
import Vue from "vue";
import GravadorDeAudio from "@/components/GravadorDeAudio.vue";

export default {
    props: ["atendimento"],
    emits: ["error", "refresh"],

    components: {
        GravadorDeAudio,
        Dropzone,
        VueEditor,
    },
    computed: {
    ...mapGetters({
            acessoSindico: "getAcessoSindico",
        })
    },

    data() {
        return {
            respostaFormData: {
                resposta_atendimento: "",
                uniqid: null,
            },
            audio: {
                uniqid: null,
                file: [],
            },
            audio_url: null,
            scricao: null,
            customToolbar : [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["clean"] // remove formatting button
            ]
        };
    },

    beforeMount() {
        this.respostaFormData.uniqid = this.audio.uniqid = gerarUniqId();
    },

    methods: {
        onResult (data) {
            this.audio_url = window.URL.createObjectURL(data);
            this.audio.file = new File([data], `gravacao_audio_${this.audio.uniqid}.mp3`, {type: 'audio/mpeg'});
        },

        enviarArquivos() {
            this.$parent.$parent.isLoading = true;
            this.$refs.dropzone.enviarArquivos();
        },

        responderAtendimento() {
            this.trakingMixpanel('Visualizar Atendimento', 'Responder Atendimento', true, true)
            const formData = new FormData();
            formData.append('uniqid', this.audio.uniqid);
            formData.append('file[0]', this.audio.file);

            uploadArquivos(formData).finally(() => {
                responder(this.$route.params.idAtendimento, this.respostaFormData)
                    .then(async (response) => {
                        if (this.audio_url) {
                            await uploadArquivos(this.audio).catch((error) => {
                                console.log(error.response.data)
                            })
                        }
                        this.$emit("refresh");
                    })
                    .catch((error) => {
                        this.$emit("error", error.response.data);
                    })
                    .finally(() => {
                        this.$parent.$parent.isLoading = false;
                    });
            })
        },

        trakingMixpanel(acao, subacao, intencao, ateracao) {
            this.$mixpanel.track(
                Vue.prototype.$eventoMixPanel,
                objetoTraking('Meus Atendimentos', acao, subacao, intencao, ateracao)
            );
        },
    },
};
</script>

