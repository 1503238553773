<template>
    <LoadingComponent :isLoading="true" />
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        mensagem: { type: String, required: false },
    },

    components: {
        LoadingComponent,
    },

    computed: {
        ...mapGetters({
            finalizado: "carregamentoFinalizado",
            sessao: "retornarSessao",
	    acessoSindico: "getAcessoSindico",
        }),
    },

    beforeMount() {
        if (this.finalizado) {
            this.acessarSite();
        }
    },

    watch: {
        finalizado(newValue) {
            if (newValue) {
                this.acessarSite();
            }
            return newValue;
        },
    },

    methods: {
        ...mapActions(["resetarSessao", "setarMensagemLogin"]),

        acessarSite() {
            const sessao = this.sessao;
            this.setarMensagemLogin(this.mensagem ?? "Seja bem vindo" + (this.acessoSindico ? "" : "<br> ao Portal do cliente"));
            this.resetarSessao();
            window.location.href = this.$router.resolve({
                name: sessao.sessaoExpirada ? sessao.rotaRedirecionar : "home",
            }).href;
        },
    },
};
</script>
