<template>
	<div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-titleBloco">
			<div>
				<h3>Dados Pessoais</h3>
			</div>
			<a
				href="javascript:void(0)"
				class="btn-form pc-btnBox pc-btnBox-small pc-btnBox-secondary"
				v-on:click="atualizaFormularioEnderecoAtendimento()"
			>
				Voltar
			</a>
		</div>
		<div class="row">
            <div class="col-lg-6 col-12">
				<ValidationProvider rules="required" v-slot="{ errors }">
				  <label for="email"><b>Email</b></label>
				  <input
				  	v-model="dadosCliente.email"
					type="text"
					class="form-control"
					placeholder="Email"
					id="emailCliente"
					name="emailCliente"
					required="true"
				  />
				  <label class="error"
					><span>{{ errors[0] }}</span></label
				  >
				</ValidationProvider>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
				  <label for="telefone">Telefone</label>
				  <vue-tel-input id="telefoneCliente" v-model="dadosCliente.telefone" v-bind="bindProps" name="telefoneCliente">
				  </vue-tel-input>
				</div>
			</div>
		</div></br>
		<div class="pc-titleBloco">
			<div>
				<h3>Endereço</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>CEP</label>
					<input
						type="text"
						class="form-control input-tel"
						placeholder="CEP"
						v-model="endereco.cep"
						v-mask="'#####-###'"
						@keyup="enderecoPorCep"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Logradouro"
						label="nome"
						:clearable="false"
						:options="logradouros"
						:reduce="(option) => option.idlogradouro"
						:valor.sync="endereco.logradouro.idlogradouro"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<ValidationProvider
						rules="required"
						v-slot="{ errors }">
						<label><b>* Endereço</b></label>
						<input
							type="text"
							class="form-control input-alpha"
							maxlength="100"
							v-model="endereco.nome"
							placeholder="Endereço"
						/>
						<label class="error"><span>{{ errors[0] }}</span></label>
					</ValidationProvider>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Bairro</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="100"
						v-model="endereco.bairro"
						placeholder="Bairro"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Número</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="10"
						v-model="endereco.numero"
						placeholder="Número"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<label>Complemento</label>
					<input
						type="text"
						class="form-control input-alpha"
						maxlength="100"
						v-model="endereco.complemento"
						placeholder="Complemento"
					/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Estado"
						label="nome"
						regras="required"
						:clearable="false"
						:options="estados"
						:reduce="(option) => option.idestado"
						:valor.sync="endereco.estado.idestado"
						@update:valor="atualizarCidades(null)"
					/>
				</div>
			</div>
			<div class="col-lg-6 col-12">
				<div class="form-group">
					<SelectInput
						titulo="Cidade"
						label="nome"
						regras="required"
						:clearable="false"
						:options="cidades"
						:reduce="(option) => option.idcidade"
						:valor.sync="endereco.cidade.idcidade"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import TextInput from "@/components/TextInput";
import { VueTelInput } from "vue-tel-input";
import {
	getLogradouros,
	getEstados,
	getCidades,
	getEnderecoPorCep,
} from "@/services/EnderecoService";
import { removerMascaraString } from "@/plugins/Utils";
import LoadingComponent from "@/components/LoadingComponent";
import { mapGetters, mapActions } from "vuex";
import { putPessoaEndereco } from "@/services/PessoasService";
import { removerMascaraTelefone } from "../../../plugins/Utils";
const cloneDeep = require("clone-deep");

export default {
	props: ["formularioEnderecoAtendimento"],
	components: {
		SelectInput,
		LoadingComponent,
		TextInput,
		VueTelInput
	},

	data() {
		return {
			logradouros: [],
			estados: [],
			cidades: [],
			endereco: null,
			isLoading: false,

			bindProps: {
				nationalMode: false,
				separateDialCode: true,
				formatOnDisplay: true,
				initialCountry: "BR",
				preferredCountries: ["BR"],
				autoHideDialCode: true,
				enabledCountryCode: true,
				mode: 'international',
				validCharactersOnly: true,
				inputOptions: {
				showDialCode: false
				},
			},
		};
	},

	computed: {
		...mapGetters({
			cliente: "getCliente",
		}),

		...mapActions(["getMe"]),
	},

	beforeMount() {
		this.endereco = cloneDeep(this.cliente.endereco);
		this.dadosCliente = cloneDeep(this.cliente); 
		getLogradouros()
			.then((response) => {
				this.logradouros = response.data;
			})
			.catch((error) => {});

		getEstados()
			.then((response) => {
				this.estados = response.data;
			})
			.catch((error) => {});

		if (this.endereco.estado) {
			this.atualizarCidades(this.endereco.cidade.idcidade);
		}
	},

	methods: {
		enderecoPorCep() {
			if (this.endereco.cep.length == 9) {
				this.isLoading = true;
				getEnderecoPorCep(removerMascaraString(this.endereco.cep))
					.then((response) => {
						this.endereco.logradouro.idLogradouro = Number(
							response.data.idlogradouro
						);
						this.endereco.nome = response.data.endereco;
						this.endereco.bairro = response.data.bairro;
						this.endereco.estado.idEstado = Number(
							response.data.idestado
						);
						this.endereco.numero = "";
						this.endereco.complemento = "";
						this.atualizarCidades(Number(response.data.idcidade));
					})
					.catch((error) => {})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},

		atualizarCidades(idCidade) {
			getCidades(this.endereco.estado.idestado)
				.then((response) => {
					this.cidades = response.data;
					this.endereco.cidade.idcidade = idCidade;
				})
				.catch((error) => {});
		},

		retornarDadosFormulario() {
			return {
				cep: removerMascaraString(this.endereco.cep),
				endereco: this.endereco.nome,
				bairro: this.endereco.bairro,
				numero: this.endereco.numero.toString(),
				complemento: this.endereco.complemento,
				idlogradouro: this.endereco.logradouro.idlogradouro,
				idestado: this.endereco.estado.idestado,
				idcidade: this.endereco.cidade.idcidade,
			};
		},
		retornarDadosFormularioCliente() {
			return {
				nome: this.dadosCliente.nome,
				email: this.dadosCliente.email,
				telefone: removerMascaraTelefone(this.dadosCliente.telefone)
			};
		},

		verificarForm() {
			if (
				this.cliente.endereco.cep !=
					removerMascaraString(this.endereco.cep) ||
				this.cliente.endereco.logradouro.idlogradouro !=
					this.endereco.logradouro.idlogradouro ||
				this.cliente.endereco.nome != this.endereco.nome ||
				this.cliente.endereco.bairro != this.endereco.bairro ||
				this.cliente.endereco.numero != this.endereco.numero ||
				this.cliente.endereco.complemento !=
					this.endereco.complemento ||
				this.cliente.endereco.estado.idestado !=
					this.endereco.estado.idestado ||
				this.cliente.endereco.cidade.idcidade !=
					this.endereco.cidade.idcidade
			) {
				return this.retornarDadosFormulario();
			} else {
				return false;
			}
		},
		verificarFormDadosCliente() {
			if (
				this.cliente.email != this.dadosCliente.email ||
				this.cliente.telefone != this.dadosCliente.telefone
			) {
				return this.retornarDadosFormularioCliente();
			} else {
				return false;
			}
		},

		atualizarDados() {
			this.isLoading = true;
			putPessoaEndereco(this.retornarDadosFormulario())
				.then((response) => {
					this.$emit("sucesso", true);
					this.getMe();
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		atualizaFormularioEnderecoAtendimento() {
			this.$parent.$parent.formularioEnderecoAtendimento = false;
		},
	},
};
</script>
