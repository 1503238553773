<template>
    <div class="pc-bloco">
        <div class="pc-titleBloco">
            <h3>Fotos da Obra</h3>
            <div class="dropdown pc-dropdownArrow" v-if="filtroData">
                <a
                    href="javascript:void(0)"
                    class="pc-btnText"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>{{labelAno}}</span>
                    <IconSetaDown />
                </a>
                <div
                    class="dropdown-menu dropdown-menu-right dropdownAno"
                    aria-labelledby="dropdownMenuButton"
                >
                    <a
                        class="dropdown-item"
                        v-on:click.stop="retornarFotos(null)"
                        >Mostrar Todos</a
                    >
                    <a
                        class="dropdown-item"
                        v-on:click.stop="retornarFotos(data)"
                        v-for="(data, index) in filtroData"
                        :key="index"
                        >{{ data }}</a
                    >
                </div>
            </div>
        </div>
        <div class="pc-galeria" v-if="fotos" :key="componentKeyGaleria">
            <VueSlickCarousel
                class="pc-slider-for"
                ref="c1"
                lazyLoad="ondemand"
                :asNavFor="$refs.c2"
                :focusOnSelect="true"
                :dots="false"
                :arrows="true"
            >
                <template #prevArrow="arrowOption">
                    <div class="pc-prev"><IconSetaLeft /></div>
                </template>
                <template #nextArrow="arrowOption">
                    <div class="pc-next"><IconSetaRight /></div>
                </template>
                <div class="item" v-for="(foto, index) in fotos" :key="index">
                    <p>
                        {{ foto.titulo }}
                        <span>{{
                            foto.data
                                | dateParse("YYYY-MM-DD")
                                | dateFormat("MM/YYYY")
                        }}</span>
                    </p>
                    <div v-if="foto.tipo == 'youtube'">
                        <iframe :src="foto.src" :title="foto.titulo"></iframe>
                    </div>
                    <div v-else>
                        <a v-on:click.stop="showMultiple(foto, item)">
                            <img :src="foto.src" alt="" />
                        </a>
                    </div>
                </div>
            </VueSlickCarousel>
            <VueSlickCarousel
                class="pc-slider-nav"
                ref="c2"
                lazyLoad="ondemand"
                :asNavFor="$refs.c1"
                :slidesToShow="5"
                :focusOnSelect="true"
                :dots="false"
                :arrows="false"
            >
                <div class="item" v-for="(foto, index) in fotos" :key="index">
                    <div v-if="foto.tipo == 'youtube'">
                        <img :src="foto.thumbnails" alt="" />
                    </div>
                    <div v-else>
                        <img :src="foto.src" alt="" />
                    </div>
                </div>
            </VueSlickCarousel>
            <silent-box ref="silentbox" :gallery="fotos">
                <template v-slot:silentbox-item="{ silentboxItem }">
                    <p></p>
                </template>
            </silent-box>
        </div>
        <div v-else>Nenhuma informação encontrada.</div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import VueSilentbox from "vue-silentbox";
import VueSlickCarousel from "vue-slick-carousel";
import { retornarFotosObra } from "@/services/EmpreendimentoService";

export default {
    props: {
        idEmpreendimento: { type: Number, required: true },
        idUnidade: { type: Number, required: true },
    },
    computed: {
        ...mapState({
            unidade: state => state.unidades.unidade,
        }),
    },
    watch: {
        unidade() {
            this.criarFiltroData();
            this.retornarFotos();
            this.componentKeyGaleria += 1;
        },
    },
    emits: ["error"],

    components: {
        VueSlickCarousel,
        VueSilentbox,
    },

    data() {
        return {
            fotos: null,
            fotoShow: null,
            filtroData: null,
            visible: false,
            carouselSettings: {
                dots: false,
                dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            componentKeyGaleria: 0,
            labelAno: ''
        };
    },

    beforeMount() {
        this.criarFiltroData();
        this.retornarFotos();
    },

    methods: {
        retornarFotos(filtro) {
            let data = [];
            if (filtro) {
                data = filtro.split("/");
            }

            retornarFotosObra(this.idEmpreendimento, this.idUnidade, data[1], data[0])
                .then((response) => {
                    this.carregarGaleria(response.data);
                })
                .catch((error) => {
                    if (error.response.status != 404) {
                        this.$emit("error", error.response.data);
                    }
                })
                .finally(() => {});

                if(filtro == null){
                    this.labelAno = 'Selecione uma data';
                }else{
                    this.labelAno = filtro;
                }

                const collapseBox = document.querySelector('.dropdownAno');
                collapseBox.classList.remove('show');
        },

        criarFiltroData() {
            retornarFotosObra(this.idEmpreendimento,this.idUnidade)
                .then((response) => {
                    let filtroData = [];
                    response.data.map(function (galeria) {
                        const data = galeria.data_base.split("-");
                        filtroData.push(data[1] + "/" + data[0]);
                    });
                    filtroData.sort(function (a, b) {
                        const data1 = a.split("/");
                        const data2 = b.split("/");
                        if (data1[1] - data2[1] == 0) {
                            return data1[0] - data2[0];
                        } else {
                            return data1[1] - data2[1];
                        }
                    });
                    this.filtroData = [...new Set(filtroData)];
                })
                .catch((error) => {
                    if (error.response.status != 404) {
                        this.$emit("error", error.response.data);
                    }
                });
        },

        carregarGaleria(galerias) {
            let fotos = [];
            galerias.map(function (galeria) {
                galeria.midias.map(function (midia) {
                    var url;
                    if (midia.tipo == "youtube") {
                        url = midia.url;
                    } else {
                        url = midia.url
                            .replace("[[LARGURA]]", "x")
                            .replace("[[ALTURA]]", "x");
                    }
                    fotos.push({
                        src: url,
                        titulo: galeria.nome + " - " + midia.titulo,
                        data: galeria.data_base,
                        tipo: midia.tipo,
                        thumbnails: midia.thumbnail,
                        thumbnailWidth: "170px",
                        thumbnailHeight: "120px",
                    });
                });
            });
            this.fotos = [...new Set(fotos)];
        },

        showMultiple(foto, index) {
            this.$refs.silentbox.openOverlay(foto, index);
        },
    },
};
</script>