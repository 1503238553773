<template>
    <div class="pc-tabela--row">
        <div class="pc-tabela--td pc-f1">
            <p data-title="Protocolo">{{ getSafe(() => atendimento.protocolo) }}</p>
        </div>
        <div class="pc-tabela--td pc-f1">
            <p data-title="Data">{{ atendimento.cadastro | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}</p>
        </div>
        <div class="pc-tabela--td pc-f2">
            <p data-title="Assunto">{{ getSafe(() => atendimento.assunto.nome) }}</p>
        </div>
        <div class="pc-tabela--td pc-f2">
            <p data-title="Subassunto">{{ getSafe(() => atendimento.assunto.subassunto) }}</p>
        </div>
        <div class="pc-tabela--td pc-f2">
            <p data-title="Empreendimento">
                {{ getSafe(() => atendimento.unidade.empreendimento.nome) }}
            </p>
        </div>
        <div class="pc-tabela--td pc-f2">
            <p data-title="Bloco">
                {{ getSafe(() => atendimento.unidade.bloco.nome)  }}
            </p>
        </div>
        <div class="pc-tabela--td pc-f3" v-if="acessoSindico">
            <p data-title="Área Comum">{{ getSafe(() => atendimento.unidade.empreendimento.area.nome) }}</p>
        </div>
        <div class="pc-tabela--td pc-f2" v-else>
            <p data-title="Unidade(s)" v-if="atendimento.unidade.nome">
                {{ getSafe(() => atendimento.unidade.nome) }}
            </p>
            <p v-else>--</p>
        </div>
        <div class="pc-tabela--td pc-f2">
            <p data-title="Situação atual"
               class="pc-labelStatus"
               v-bind:style="{
                color: atendimento.situacao.situacao_cor_nome ?? '#fff',
                background: atendimento.situacao.situacao_cor_bg ?? '#0533ff'
            }">
                {{ atendimento.situacao.nome }}
            </p>
        </div>
        <div class=" pc-tabela--td pc-tabela--td-icons pc-f1 justify-content-center">
            <router-link
                :to="{
                    name: 'atendimento',
                    params: { idAtendimento: atendimento.idatendimento}
                }"
                class="pc-btnGhost pc-btnGhost-small pc-btnGhost-secondary"
            >Visualizar
            </router-link>
        </div>
    </div>
</template>

<script>
import getSafe from '@/plugins/OptionalChain';
import { mapGetters } from "vuex";

export default {
    name: "Listagem",
    computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico",
    }),
  },
    props: {
        atendimento: {type: Object, required: true},
    },
    methods: {getSafe},
}
</script>
