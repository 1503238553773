import axios from "axios";
import store from '../store';

export function getAssuntos(idEmpreendimento = null) {
    return axios.get('/atendimentos/assuntos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            idEmpreendimento: idEmpreendimento
        }
    });
}

export function getSubassuntos(idAssunto, idEmpreendimento = null) {
    return axios.get(`/atendimentos/assuntos/${idAssunto}/subassuntos`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            idEmpreendimento: idEmpreendimento
        }
    });
}
