import Vue from 'vue'
import VueRouter from 'vue-router'
import { isSignedIn } from './../plugins/auth';
import routes from './routes.map'
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    const logado = isSignedIn();
    if (logado == 'logado') {
        if (to.name == "redirecionando") {
            next()
        } else {
            if (store.state.termoAceite.termoAceite.termo_pendente && to.name != "termo-aceite") {
                next({ name: 'termo-aceite' })
            } else if (
                to.name == "login" ||
                (!store.state.termoAceite.termoAceite.termo_pendente && to.name == "termo-aceite")
            ) {
                next({ name: 'home' })
            } else {
                next()
            }
        }
    } else {
        if (
            to.name != "login"
            && to.name != "cadastre-se"
            && to.name != "esqueceu-senha-sms"
            && to.name != "esqueceu-senha-email"
            && to.name != "confirmar-conta"
            && to.name != "autenticando"
            && to.name != "acessarComo"
            && to.name != "not-found"
            && to.name != "loginSindico"
            && to.name != "esqueceu-senha-sms-sindico"
            && to.name != "esqueceu-senha-email-sindico"
            && to.name != "nova-senha"
            && to.name != "multiplos-portais"
        ) {
            if (logado == 'expirado') {
                store.commit('SESSAO_EXPIRADA', to.name);
                next({ name: 'login' });
            } else {
                next({ name: 'login' });
            }
        } else {
            next({
              params: {
                slug:
                  store.state.configuracoes.slug == "default"
                    ? ""
                    : store.state.configuracoes.slug,
              },
            });
        }
    }
});

export default router;
