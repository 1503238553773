<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Andamento da Obra"
            subtitulo="Painel com informações da obra dos seu empreendimento"
        />
        <div class="vld-parent">
            <div class="row">
                <div class="col-12">
                    <UnidadePadrao />
                </div>
            </div>

            <Aviso
                :exibir="aviso.exibir"
                :tipo="aviso.tipo"
                :mensagem="aviso.mensagem"
            />

            <div class="row">
                <div class="col-12 col-xl-4 pc-orderlg2 pc-orderxs2">
                    <StatusObra
                        :key="empreendimento.idempreendimento"
                        :porcentagemTotalEstagioObra="
                            empreendimento.porcentagem_total_estagio_obra
                        "
                        :idEmpreendimento="empreendimento.idempreendimento"
                        :idUnidade="unidade.idunidade"
                        @error="tratarErro"
                    />
                </div>

                <div class="col-12 col-xl-8">
                    <GaleriaObra
                        :key="empreendimento.idempreendimento"
                        :idEmpreendimento="empreendimento.idempreendimento"
                        :idUnidade="unidade.idunidade"
                        @error="tratarErro"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import UnidadePadrao from "@/components/UnidadePadrao";
import StatusObra from "./components/StatusObra";
import GaleriaObra from "./components/GaleriaObra";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import { mapState } from "vuex";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        BreadCrumbs,
        UnidadePadrao,
        StatusObra,
        GaleriaObra,
        TituloPagina,
        Aviso,
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Andamento da Obra', 'Andamento da Obra', 'Listagem', true, false)
        );
    },

    computed: {
        ...mapState({
            empreendimento: (state) => state.unidades.unidade.empreendimento,
            unidade: (state) => state.unidades.unidade,
        }),
    },

    watch: {
        empreendimento(newValue) {
            return newValue;
        },
    },

    data() {
        return {
            caminho: [
                {
                    texto: "Andamento da Obra",
                    ativo: true,
                    rota: null,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
        };
    },

    methods: {
        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
