var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-bloco"},[_c('div',{staticClass:"pc-titleBloco"},[_c('h3',[_vm._v("Unidades disponíveis")]),(_vm.unidadesDisponiveis.total)?_c('div',{staticClass:"pull-right pc-title-legenda"},[_vm._v(" Informações encontradas: "+_vm._s(_vm.unidadesDisponiveis.total)+" ")]):_vm._e()]),_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"pc-tabela"},[(_vm.unidadesDisponiveis.total)?_c('div',[_vm._m(0),_vm._l((_vm.unidadesDisponiveis.unidades),function(unidade){return _c('div',{key:unidade.tipo +
						'-' +
						unidade.idagendamento +
						'-' +
						unidade.unidade.idunidade,staticClass:"pc-tabela--row"},[_c('div',{staticClass:"pc-tabela--td pc-f3"},[_c('p',{attrs:{"data-title":"Empreendimento"}},[_vm._v(" "+_vm._s(_vm.getSafe( () => unidade.unidade.empreendimento.nome ))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Etapa"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => unidade.unidade.etapa.nome))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Bloco"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => unidade.unidade.bloco.nome))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Unidade"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => unidade.unidade.nome))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f3"},[(unidade.motivo)?_c('p',{attrs:{"data-title":"Motivo"}},[_vm._v(" "+_vm._s(unidade.motivo)+" ")]):_c('p',{attrs:{"data-title":"Motivo"}},[_vm._v("--")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[(unidade.tipo == 'vistoria')?_c('div',[_c('p',{staticClass:"pc-labelStatus",staticStyle:{"background":"#dc910c","color":"white"}},[_vm._v(" Vistoria ")])]):_c('div',[_c('p',{staticClass:"pc-labelStatus",staticStyle:{"background":"#1e90ff","color":"white"}},[_vm._v(" Visita ")])])]),_c('div',{staticClass:"pc-tabela--td pc-tabela--td-icons pc-f1 v justify-content-center"},[(unidade.vistoriadores > 0 && unidade.unidade.empreendimento.permitir_criar_agendamento)?_c('router-link',{staticClass:"pc-btnGhost pc-btnGhost-small pc-btnGhost-primary",attrs:{"to":{
							name: 'novo-agendamento',
							params: {
								idAgendamento: unidade.idagendamento,
								idUnidade: unidade.unidade.idunidade,
							},
						}}},[_vm._v(" Agendar ")]):_vm._e()],1)])}),_c('PaginateInput',{attrs:{"total":_vm.unidadesDisponiveis.total,"limit":_vm.paginacao.limit,"offset":_vm.paginacao.offset},on:{"paginacao":_vm.montarListagem}})],2):_c('div',[_vm._v(" Nenhuma unidade disponível encontrada para realizar agendamento. ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--row pc-tabela--head"},[_c('div',{staticClass:"pc-tabela--td pc-f3"},[_c('p',[_vm._v("Empreendimento")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Etapa")])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Bloco")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Unidade")])]),_c('div',{staticClass:"pc-tabela--td pc-f3"},[_c('p',[_vm._v("Motivo")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Tipo")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"})])
}]

export { render, staticRenderFns }