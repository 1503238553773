<template>
    <div>
        <div class="pc-titleBloco">
            <div>
                <h3>Histórico de Mensagens</h3>
            </div>
        </div>
        <div class="historico-mensagem">
            <div class="linhadotempo-mensagens">
                <div
                    v-for="(resposta, index) in respostas"
                    :key="index"
                    class="box-mensagem mensagem"
                >
                    <div class="header-mensagem">
                        <div class="perfil">
                            <div class="info-perfil">
                                <div class="linhadotempo-quando">
                                    <small>{{
                                        resposta.data_criacao
                                            | dateParse("YYYY-MM-DD HH:mm:ss")
                                            | dateFormat("DD/MM/YYYY HH:mm:ss")
                                    }}</small>
                                </div>
                                <strong class="linhadotempo-quem"
                                    >{{
                                        resposta.atendente
                                            ? resposta.atendente + " (Gestor)"
                                            : null
                                    }}
                                    {{ resposta.cliente }}
                                    {{
                                        resposta.corretor
                                            ? resposta.corretor + " (Corretor)"
                                            : null
                                    }}
                                    {{
                                        resposta.imobiliaria
                                            ? resposta.imobiliaria +
                                              " (Imobiliária)"
                                            : null
                                    }}</strong
                                >
                            </div>
                        </div>
                    </div>
                    <div class="info-mensagem">
                        <span
                            class="cv-descricao-mensagem"
                            v-html="quebraDeLinhaParaHtml(resposta.resposta)"
                        ></span>

                        <div v-if="resposta.arquivos.length">
                            <div v-for="(arquivo, index) in resposta.arquivos" v-if="arquivo.tipo === 'audio/mpeg'">
                                <br><audio controls><source :src="arquivo.url" type="audio/mp3"></audio><br>
                            </div>
                        </div>

                        <div class="pc-files" v-if="resposta.arquivos.length">
                            <span>Arquivo(s):</span>
                            <div
                                v-for="(arquivo, index) in resposta.arquivos"
                                :key="index"
                                class="pictures"
                            >
                                <a
                                    :href="arquivo.url"
                                    :title="arquivo.nome"
                                    target="_blank"
                                >
                                    <IconFile />
                                    <span
                                        >{{ arquivo.nome }} ({{
                                            formatBytes(arquivo.tamanho)
                                        }})</span
                                    >
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatBytes, quebraDeLinhaParaHtml } from "@/plugins/Utils";

export default {
    props: ["respostas"],

    methods: { formatBytes, quebraDeLinhaParaHtml },
};
</script>
