<template>
    <div class="pc-bloco">
        <div class="pc-perfil">
            <div class="pc-perfil-avatar">
                <div class="pc-perfil--circle">
                    <LoadingComponent :isLoading="isLoadingAvatar" />
                    <img
                        class="pc-profile-pic"
                        v-if="cliente.avatar"
                        v-bind:src="
                            cliente.avatar.url
                                .replace('[[LARGURA]]', 'x')
                                .replace('[[ALTURA]]', 'x')
                        "
                        alt=""
                    />
                    <img
                        class="pc-profile-pic"
                        v-else
                        v-bind:src="avatar"
                        alt=""
                    />
                </div>
                <div class="pc-image">
                    <span
                        v-if="!acessoComo"
                        toggle="tooltip"
                        placement="bottom"
                        title="Alterar Avatar"
                        class="pc-upload-button"
                        id="pick-avatar"
                        >+</span
                    >
                </div>
            </div>
            <p>
                {{ cliente.nome }}
                <span>{{ cliente.email }}</span>
            </p>
        </div>
        <div class="pc-unidadePerfil">
            <div class="pc-titleBloco">
                <div>
                    <h3>Dados de Contato</h3>
                </div>
            </div>
            <ul class="pc-unidadePerfil--list">
                <li class="pc-infoIcon">
                    <p><span>Telefone</span>{{ cliente.telefone }}</p>
                </li>
                <li class="pc-infoIcon">
                    <p><span>Celular</span>{{ cliente.celular }}</p>
                </li>
            </ul>
            <router-link
            class="btn-form pc-btnBox pc-btnBox-primary btn-danger"
            :to="{ name :'excluir-dados'}"
            >
            Excluir acesso
            </router-link>
        </div>
        <avatar-cropper
            trigger="#pick-avatar"
            :upload-url="url"
            :labels="labels"
            :upload-headers="headers"
            @uploaded="handleUploaded"
            @error="handlerError"
            @submit="handlerSubmit"
        ></avatar-cropper>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import { URL_API } from "@/bootstrap/index";
import store from "@/store";
import { retornarTempAvatar } from "@/plugins/Utils";
import LoadingComponent from "@/components/LoadingComponent";

export default {
    components: {
        AvatarCropper,
        LoadingComponent
    },

    computed: {
        ...mapGetters({
            cliente: "getCliente",
            acessoComo: "getAcessoComo",
        }),

        ...mapActions(["getMe"]),

        avatar() {
            return require(`@/assets/img/alfabeto_cv/${this.retornarTempAvatar(this.cliente.nome)}.png`);
        },
    },

    data() {
        return {
            url: `${URL_API}/api/v1/cliente/pessoa/avatar`,
            headers: {
                Authorization: `Bearer ${store.state.login.access_token}`,
            },
            labels: {
                submit: "Salvar",
                cancel: "Cancelar",
            },
            isLoadingAvatar: false
        };
    },

    methods: {
        ...mapActions(["getMeOnlyMe"]),

        retornarTempAvatar,

        handlerSubmit() {
            this.isLoadingAvatar = true;
        },

        handleUploaded(response, form, xhr) {
            this.getMeOnlyMe().finally(() => {
                this.isLoadingAvatar = false;
            });
        },

        handlerError(message, type, xhr) {
            if (type == "upload") {
                this.isLoadingAvatar = false;
                let mensagem = "Erro interno, por favor tentar mais tarde.";
                if (xhr.status != 422) {
                    mensagem = xhr.response.error;
                }
                this.$emit("exibirAviso", {
                    exibir: true,
                    tipo: "erro",
                    mensagem: mensagem,
                });
            }
        },
    },
};
</script>
