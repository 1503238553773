<template>
  <div class="pc-topo">
    <router-link
      :to="{ name:'home' }"
      class="pc-top--logo"
    >
      <img
        v-if="configuracoes.logo"
        :src="
          configuracoes.logo.url
            .replace('[[LARGURA]]', 'x')
            .replace('[[ALTURA]]', 'x')
        "
        alt=""
      />
      <img v-else :src="baseUrl + '/especifico/img/logo_empresa.png'" alt="" />
    </router-link>
    <div class="pc-top--content">
      <a href="javascript:void(0)" class="pc-btnMenuXs d-flex d-lg-none">
        <IconMenu2 />
      </a>
      <nav class="greedy d-none d-lg-flex">
        <ul
          class="links pc-top--menu"
          v-if="configuracoes.ordem_menu.length > 0"
        >
          <li>
            <router-link class="pc-link-menu" :to="{ name: 'home' }"
              >Página Inicial</router-link
            >
          </li>
          <li v-for="opcaoMenu in configuracoes.ordem_menu" :key="opcaoMenu.idextensao">
            <div v-if="
                !acessoSindico &&
                opcaoMenu.idextensao > 0
                && (
                  !acessoSindico && opcaoMenu.painel_exibicao != 'S'
                  || acessoSindico && opcaoMenu.painel_exibicao != 'C'
                  )
                   && (
                     unidade.financeiro && opcaoMenu.iconepc === 'financeiro'
                      || opcaoMenu.iconepc !== 'financeiro'
                   )
                  && (
                  unidade.perfil.sigla === 'TR'
                  || (opcaoMenu.associado === 'S' && unidade.perfil.sigla === 'AR')
                )">
              <a
                v-if="opcaoMenu.tipo == 'blank'"
                :href="opcaoMenu.link"
                target="_blank"
                class="pc-link-menu"
                >{{ getSafe(() => opcaoMenu.nome_descricao) }}</a
              >
              <router-link
                class="pc-link-menu"
                v-else
                :to="{ name: 'plugin-' + nomeUrl(opcaoMenu.nome_descricao) }"
                >{{ getSafe(() => opcaoMenu.nome_descricao) }}</router-link
              >
            </div>
            <div v-else-if="!opcaoMenu.idextensao">
              <router-link
                v-if="
                  verificarRotaExiste(opcaoMenu.rota)
                "
                class="pc-link-menu"
                :to="{ name: opcaoMenu.rota }"
                >{{ getSafe(() => opcaoMenu.nome_descricao) }}</router-link
              >
            </div>
          </li>
        </ul>
        <ul class="links pc-top--menu" v-else>
          <li>
            <router-link class="pc-link-menu" :to="{ name: 'home' }">
              Página Inicial
            </router-link>
          </li>
          <li v-if="verificarRotaExiste('unidades') && !acessoSindico">
            <router-link class="pc-link-menu" :to="{ name: 'unidades' }"
              >Minhas Unidades</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('portal-beneficios') && !acessoSindico">
            <router-link
              class="pc-link-menu"
              :to="{ name: 'portal-beneficios' }"
              >Portal de Benefícios</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('contratos') && !acessoSindico">
            <router-link class="pc-link-menu" :to="{ name: 'contratos' }"
              >Meus Contratos</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('financeiro') && !acessoSindico && unidade.financeiro">
            <router-link class="pc-link-menu" :to="{ name: 'financeiro' }"
              >Financeiro</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('atendimentos')">
            <router-link class="pc-link-menu" :to="{ name: 'atendimentos' }"
              >Atendimentos</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('assistencias')">
            <router-link class="pc-link-menu" :to="{ name: 'assistencias' }"
              >Assistências Técnicas</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('agendamentos') && !acessoSindico">
            <router-link class="pc-link-menu" :to="{ name: 'agendamentos' }"
              >Meus Agendamentos</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('documentos')">
            <router-link class="pc-link-menu" :to="{ name: 'documentos' }"
              >Documentos</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('pesquisas') && !acessoSindico">
            <router-link class="pc-link-menu" :to="{ name: 'pesquisas' }"
              >Minhas Pesquisas</router-link
            >
          </li>
          <li v-if="verificarRotaExiste('meus-cadastros') && !acessoSindico">
            <router-link class="pc-link-menu" :to="{ name: 'meus-cadastros' }">
              <span>Meus Cadastros</span>
            </router-link>
          </li>
          <li v-if="!acessoSindico" v-for="plugin in configuracoes.plugins">
            <a
              v-if="plugin.tipo == 'blank' && plugin.cliente != 'N' &&
              (!acessoSindico && plugin.painel_exibicao != 'S' || acessoSindico && plugin.painel_exibicao != 'C')"
              :href="plugin.link"
              target="_blank"
              class="pc-link-menu"
              >{{ plugin.nome }}</a
            >
            <router-link
              class="pc-link-menu"
              v-else-if="plugin.cliente != 'N' && (!acessoSindico && plugin.painel_exibicao != 'S' ||
              acessoSindico && plugin.painel_exibicao != 'C')"
              :to="{ name: 'plugin-' + nomeUrl(plugin.nome) }"
              >{{ plugin.nome }}</router-link
            >
          </li>
        </ul>
        <button>+ Menu</button>
        <ul class="hidden-links hidden"></ul>
      </nav>
      <div class="pc-top--controles">
        <div class="pc-top--user">
          <span
            v-if="acessoComo"
            style="margin-right: 2%"
            toggle="tooltip"
            placement="bottom"
            title="Logado através do acessar como"
          >
            <IconAlert />
          </span>
          <p class="d-none d-lg-block">
            {{ acessoSindico ? sindico.nome : cliente.nome }}
            <span>{{ acessoSindico ? sindico.email : cliente.email }}</span>
          </p>
          <div class="pc-avatar">
            <img
              v-if="cliente.avatar"
              v-bind:src="
                cliente.avatar.url
                  .replace('[[LARGURA]]', 'x')
                  .replace('[[ALTURA]]', 'x')
              "
              alt=""
            />
            <img
              v-else-if="sindico.avatar"
              v-bind:src="
                sindico.avatar.url
                  .replace('[[LARGURA]]', 'x')
                  .replace('[[ALTURA]]', 'x')
              "
              alt=""
            />
            <img v-else v-bind:src="avatar" alt="" />
          </div>
          <div class="dropdown">
            <a
              href="javascript:void(0)"
              class="pc-btnContext"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <IconMenu />
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <router-link
              :to="{ name: 'home' }"
              class="dropdown-item"
                >Página Inicial</router-link
              >
              <router-link
                :to="{ name: acessoSindico ? 'meus-dados-sindico' : 'meus-dados' }"
                class="dropdown-item"
                >Meus Dados</router-link
              >
              <router-link
                v-if="verificarRotaExiste('perguntas-frequentes')"
                :to="{ name: 'perguntas-frequentes' }"
                class="dropdown-item d-block d-lg-none"
                >Perguntas Frequentes</router-link
              >
              <a href="#" @click.prevent="sairPainel()" class="dropdown-item"
                >Sair</a
              >
            </div>
          </div>
          <div class="dropdown" v-if="verificarRotaExiste('perguntas-frequentes')">
            <a
              href="javascript:void(0)"
              class="pc-btnSair d-none d-lg-block"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <IconFaq />
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <router-link
                :to="{ name: 'perguntas-frequentes' }"
                class="dropdown-item"
                >Perguntas Frequentes</router-link
              >
            </div>
          </div>
          <a
            href="#"
            @click.prevent="sairPainel()"
            class="pc-btnSair d-none d-lg-block"
          >
            <IconSair />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { URL_API } from "@/bootstrap/index";
import { retornarTempAvatar } from "@/plugins/Utils";
import getSafe from "@/plugins/OptionalChain";

export default {
  computed: {
    ...mapState({
      cliente: (state) => state.login.cliente,
      sindico: (state) => state.login.sindico,
      configuracoes: (state) => state.configuracoes.configuracoes,
      unidades: (state) => state.unidades.unidades,
      unidade: (state) => state.unidades.unidade,
      perfil: (state) => state.permissao.perfisCliente,
    }),

    ...mapGetters({
      acessoComo: "getAcessoComo",
      acessoSindico: "getAcessoSindico",
    }),

    avatar() {
      let nome = this.cliente.nome ? this.cliente.nome : this.sindico.nome;
      return require(`@/assets/img/alfabeto_cv/${this.retornarTempAvatar(
        nome
      )}.png`);
    },
  },
  updated() {
    if (this.$route.params.reload) {
      location.reload();
    }
  },
  mounted() {
    var $nav = document.querySelector("nav.greedy");
    var $btn = document.querySelector("nav.greedy button");
    var $vlinks = document.querySelector("nav.greedy .links");
    var $hlinks = document.querySelector("nav.greedy .hidden-links");
    var $linkMenu = document.querySelectorAll(".pc-link-menu");

    var numOfItems = 0;
    var totalSpace = 0;
    var breakWidths = [];

    Array.prototype.forEach.call($vlinks.children, function (el, i) {
      totalSpace += el.offsetWidth;
      numOfItems += 1;
      breakWidths.push(totalSpace);
    });

    var availableSpace, numOfVisibleItems, requiredSpace;

    function check() {
      // Get instant state
      availableSpace = $vlinks.offsetWidth - 60;
      numOfVisibleItems = $vlinks.children.length;
      requiredSpace = breakWidths[numOfVisibleItems - 1];

      // There is not enought space
      if (requiredSpace > availableSpace) {
        $hlinks.insertBefore($vlinks.lastChild, $hlinks.childNodes[0]);
        numOfVisibleItems -= 1;
        check();
        // There is more than enough space
      } else if (availableSpace > breakWidths[numOfVisibleItems]) {
        $vlinks.appendChild($hlinks.firstChild);
        numOfVisibleItems += 1;
      }
      // Update the button accordingly
      $btn.setAttribute("count", numOfItems - numOfVisibleItems);
      if (numOfVisibleItems === numOfItems) {
        $btn.classList.add("hidden");
      } else $btn.classList.remove("hidden");
    }

    window.addEventListener("resize", function (event) {
      check();
    });

    $btn.onclick = function () {
      $hlinks.classList.toggle("hidden");
    };

    $linkMenu.forEach((item) => {
      item.addEventListener("click", (event) => {
        $hlinks.classList.add("hidden");
      });
    });

    document.addEventListener(
      "click",
      function (event) {
        if (event.target.closest(".greedy")) return;
        document
          .querySelector("nav.greedy .hidden-links")
          .classList.add("hidden");
      },
      false
    );

    document.onkeydown = function (evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        document
          .querySelector("nav.greedy .hidden-links")
          .classList.add("hidden");
      }
    };

    check();

    //menuXs
    let btnMenuXs = document.querySelector(".pc-btnMenuXs");
    let overlayMenuXs = document.querySelector(".pc-overlayMenuXs");
    let leftColumn = document.querySelector(".pc-leftColumn");
    let btnMenuXsClose = document.querySelector(".pc-btnMenuXsClose");
    let body = document.querySelector("body");

    btnMenuXs.addEventListener("click", function () {
      leftColumn.classList.add("show");
      overlayMenuXs.classList.add("show");
    });

    overlayMenuXs.addEventListener("click", function () {
      leftColumn.classList.remove("show");
      overlayMenuXs.classList.remove("show");
    });

    btnMenuXsClose.addEventListener("click", function () {
      leftColumn.classList.remove("show");
      overlayMenuXs.classList.remove("show");
    });

    leftColumn.addEventListener("mouseenter", function () {
      if (
        body.classList.contains("pc-menu-vertical") &&
        window.innerWidth > 992
      ) {
        overlayMenuXs.classList.add("show");
      }
    });

    leftColumn.addEventListener("mouseleave", function () {
      if (
        body.classList.contains("pc-menu-vertical") &&
        window.innerWidth > 992
      ) {
        overlayMenuXs.classList.remove("show");
      }
    });
  },

  data() {
    return {
      nomeCliente: "a",
      baseUrl: URL_API,
    };
  },

  methods: {
    retornarTempAvatar,

    ...mapActions(["logout"]),

    sairPainel() {
      this.logout();
      this.$router.push(
        '/cliente/' + this.$store.state.configuracoes.slug
       );
    },

    nomeUrl(nome) {
      return nome.toLowerCase().replace(" ", "-");
    },
    getSafe,
  },
};
</script>
