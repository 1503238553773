import {getAtualizacaoCadastral} from "@/services/AtualizacaoCadastralService";
import {getDefaultState} from "@/store/modules/atualizacaoCadastral/state";

export default {
    setPendencia({ commit }, pendente) {
        commit('ATUALIZACAO_CADASTRAL_PENDENTE', pendente);
    },

    async getAtualizacaoCadastral({ commit }, idConfiguracao) {
        await getAtualizacaoCadastral(idConfiguracao)
            .then(response => {
                commit('SET_ATUALIZACAO_CADASTRAL', response.data.data);
            })
            .catch(error => {
                commit('SET_ATUALIZACAO_CADASTRAL', getDefaultState());
            })
            .finally(() => {
                commit('CONCLUIDO_ATUALIZACAO_CADASTRAL');
            });
    }
}
