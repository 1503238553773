<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<div>
				<h3>Atualizar Associado</h3>
			</div>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<ValidationObserver v-slot="{ handleSubmit }">
			<form
				@submit.prevent="handleSubmit(enviarFormulario)"
				class="center-block"
			>
				<div class="row">
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<label>Nome do Associado</label>
							<input
								type="text"
								class="form-control input-cpf"
								placeholder="Nome do Associado"
								v-model="formulario.nome"
								:readonly="true"
							/>
						</div>
					</div>
					<div class="col-lg-6 col-12">
						<div class="form-group">
							<SelectInput
								titulo="Tipo de Associação"
								label="nome"
								:reduce="(option) => option.idtipo"
								:options="tiposAssociacoes"
								:clearable="false"
								:valor.sync="formulario.idtipo"
								regras="required"
							/>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<SelectInput
								titulo="Unidade de acesso do Associado"
								:reduce="(option) => option.idunidade"
								:options="unidadesEscolher"
								:clearable="true"
								:valor.sync="unidades"
								:multiple="true"
								regras="required"
							/>
						</div>
					</div>
				</div>

				<div class="row mt-10">
					<div class="col">
						<div class="btnEnviar">
							<button
								:disabled="acessoComo"
								name="btSend"
								type="submit"
								value="Enviar"
								class="btn-form pc-btnBox pc-btnBox-secondary"
							>
								Salvar Associação
							</button>
						</div>
					</div>
				</div>
			</form>
		</ValidationObserver>

		<div class="pc-excluirCadastro">
			<p>Deseja excluir esse associado?</p>
			<button
				class="pc-btnGhost pc-btnGhost-small pc-btnGhost-cinza"
				:disabled="acessoComo"
				@click="excluirAssociado"
			>
				Excluir Associado
			</button>
		</div>
	</div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import LoadingComponent from "@/components/LoadingComponent";
import {
	retornarAssociacoes,
	retornarTiposAssociacoes,
	deletarAssociacao,
	atualizarAssociacao,
} from "@/services/CadastrosService";
import { mapState, mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	props: {
		idAssociacao: { type: Number, required: true },
	},

	components: {
		SelectInput,
		LoadingComponent,
	},

	computed: {
		...mapState({
			unidadesCliente: (state) => state.unidades.unidades,
		}),
		...mapGetters({
			verificarPermissao: "verificarPermissao",
			acessoComo: "getAcessoComo",
		}),
	},

	data() {
		return {
			formulario: {
				idassociacao: null,
				idtipo: null,
				unidades: [],
			},
			unidades: [],
			unidadesEscolher: [],
			tiposAssociacoes: [],
			isLoading: false,
		};
	},

	beforeMount() {
		for (let index = 0; index < this.unidadesCliente.length; index++) {
			if (
				this.verificarPermissao(
					this.unidadesCliente[index].perfil.sigla,
					"exibir_cadastros"
				)
			) {
				this.unidadesEscolher.push({
					idunidade: this.unidadesCliente[index].idunidade,
					label: [
						this.unidadesCliente[index].empreendimento.nome,
						this.unidadesCliente[index].bloco.nome,
						this.unidadesCliente[index].nome,
					].join(" - "),
				});
			}
		}

		retornarTiposAssociacoes()
			.then((response) => {
				this.tiposAssociacoes = response.data;
			})
			.catch((error) => {});

		this.isLoading = true;
		retornarAssociacoes(this.idAssociacao)
			.then((response) => {
				this.formulario.idtipo =
					response.data.associacoes[0].tipo_associacao.idtipo;
				this.unidades = response.data.associacoes[0].unidades.map(
					function(unidade) {
						return unidade.idunidade;
					}
				);
				this.formulario.nome =
					response.data.associacoes[0].nome_associado;
			})
			.catch((error) => {
				this.exibirErro(error);
			})
			.finally(() => {
				this.isLoading = false;
			});
	},

	methods: {
		enviarFormulario() {
			if (this.acessoComo) {
				return;
			}

			this.isLoading = true;
			this.formulario.unidades = this.unidades.map(function(unidade) {
				return { idunidade: unidade };
			});

			this.trakingMixpanel("Alterar Associado", "Alterar", true, true);
			atualizarAssociacao(this.idAssociacao, this.formulario)
				.then((response) => {
					this.exibirSucesso("Associado atualizado com sucesso.");
				})
				.catch((error) => {
					this.exibirErro(error);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		excluirAssociado() {
			if (this.acessoComo) {
				return;
			}

			if (confirm("Deseja realmente remover esse associado?")) {
				this.isLoading = true;
				this.trakingMixpanel(
					"Excluir Associado",
					"Excluir",
					true,
					true
				);
				deletarAssociacao(this.idAssociacao)
					.then((response) => {
						this.exibirSucesso("Associado removido com sucesso.");
						this.$emit("removeuAssociacao");
					})
					.catch((error) => {
						this.exibirErro(error);
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},

		exibirSucesso(mensagem) {
			this.$emit("exibirAviso", {
				exibir: true,
				mensagem: mensagem,
				tipo: "sucesso",
			});
		},

		exibirErro(error) {
			let mensagem =
				"Erro interno ao tentar cadastrar a associção, por favor tente mais tarde.";
			if (error.response.status != 422) {
				mensagem = error.response.data.error;
			}
			this.$emit("exibirAviso", {
				exibir: true,
				mensagem: mensagem,
				tipo: "erro",
			});
		},

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Meus Cadastros",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>
