import decode from 'jwt-decode';
import store from '../store';

export function isSignedIn() {
    const token = store.state.login.access_token;
    if (!token) {
        return 'nao-logado';
    }
    try {
        const { exp: expiration } = decode(token);
        const isExpired = !!expiration && Date.now() > expiration * 1000;
        return isExpired ? 'expirado' : 'logado';
    } catch (_) {
        return 'nao-logado';
    }
}
