<template>
	<div class="formulario-novo-agendamento">
		<Aviso
			:exibir="alerta.exibir"
			:mensagem="alerta.mensagem"
			:tipo="alerta.tipo"
		/>
		<div class="pc-titleBloco">
			<h3>Datas e Horários disponíveis para o agendamento</h3>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<ValidationObserver v-slot="{ handleSubmit }">
			<form @submit.prevent="handleSubmit(cadastrarAgendamento)">
				<div class="row">
					<div class="col-lg-4 col-12">
						<div class="form-group">
							<ValidationProvider
								rules="required"
								v-slot="{ errors }"
							>
								<label for="data"
									><strong>* Data</strong></label
								>
								<datepicker
									input-class="form-control"
									name="data"
									id="data"
									placeholder="- Selecione uma data - "
									:disabled-dates="disabledFn"
									@selected="escolheuData"
									format="dd/MM/yyyy"
									:value="dataEscolhida"
									:language="ptBR"
								></datepicker>
								<label class="error"
									><span>{{ errors[0] }}</span></label
								>
							</ValidationProvider>
						</div>
					</div>

					<div class="col-lg-4 col-12">
						<div class="form-group">
							<SelectInput
								label="hora"
								titulo="Hora"
								:options="horariosDisponiveis"
								:reduce="(option) => option.hora"
								:clearable="false"
								:placeholder="placeHolderHora"
								:valor.sync="horaEscolhida"
								regras="required"
								@update:valor="escolheuHora"
							/>
						</div>
					</div>
					<div class="col-lg-4 col-12 btnEnviar">
						<br /><button
							:disabled="acessoComo"
							name="btSend"
							type="submit"
							value="Enviar"
							class="btn-form pc-btnBox pc-btnBox-secondary pc-btnBox-small m-t-3"
						>
							Concluir Agendamento
						</button>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import {
	getHorariosDisponiveis,
	cadastrarAgendamento,
} from "@/services/AgendamentosService";
import LoadingComponent from "@/components/LoadingComponent";
import Aviso from "@/components/Aviso";
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	name: "FormularioNovoAgendamento",
	props: ["agendamento"],
	components: {
		SelectInput,
		Datepicker,
		LoadingComponent,
		Aviso,
	},

	data() {
		let self = this;
		return {
			isLoading: false,
			diasDisponiveis: this.agendamento.dias_disponiveis_agendamento,
			horariosDisponiveis: [],
			dataEscolhida: null,
			horaEscolhida: null,
			ptBR: ptBR,
			disabledFn: {
				customPredictor: function(date) {
					return self.habilitarDatasDisponiveis(date);
				},
			},
			placeHolderHora: "- Selecione uma data -",
			alerta: {
				exibir: false,
				mensagem: null,
				tipo: null,
			},
			formulario: {
				data: null,
				hora: null,
			},
		};
	},

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
		}),
	},

	methods: {
		habilitarDatasDisponiveis(date) {
			let dataCalendarioFormatada = moment(date).format("YYYY-MM-DD");
			let datasDisponiveisAgendar = this.diasDisponiveis;
			if (datasDisponiveisAgendar[dataCalendarioFormatada]) {
				return false;
			}
			return true;
		},
		escolheuData(data) {
			let dataFormatada = moment(data).format("YYYY-MM-DD");
			this.formulario.data = moment(data).format("DD/MM/YYYY");
			this.dataEscolhida = data;
			this.setarHorarios(dataFormatada);
			this.horaEscolhida = null;
		},
		setarHorarios(data) {
			this.isLoading = true;
			getHorariosDisponiveis(
				this.agendamento.idagendamento,
				this.agendamento.unidade.idunidade,
				data
			)
				.then((response) => {
					this.horariosDisponiveis = response.data;
					this.isLoading = false;
					this.placeHolderHora = "Selecione um horário";
				})
				.catch(() => {
					this.placeHolderHora = "Nenhum horário disponível";
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		escolheuHora(hora) {
			this.formulario.hora = hora;
		},
		async cadastrarAgendamento() {
			if (this.acessoComo) {
				return;
			}

			this.isLoading = true;
			this.formulario.idagendamento = parseInt(
				this.agendamento.idagendamento
			);
			this.formulario.idempreendimento = this.agendamento.unidade.empreendimento.idempreendimento;
			this.formulario.idunidade = this.agendamento.unidade.idunidade;

            if (this.agendamento.idvistoria) {
                this.formulario.idvistoria = this.agendamento.idvistoria;
            }

			this.trakingMixpanel(
				"Novo Agendamento",
				"Cadastrar Agendamento",
				true,
				true
			);

			await cadastrarAgendamento(this.formulario)
				.then((response) => {
					this.$router.push({
						name: "agendamentos",
						params: {
							avisoConcluido: true,
							cliente: response.data,
							empreendimento: response.data.empreendimento,
							unidade: response.data.unidade,
							tipo: response.data.tipo,
							motivo: response.data.motivo,
							data: response.data.data,
							horario: response.data.horario,
							vistoriador: response.data.vistoriador,
							data_agendamento: this.formulario.data,
						},
					});
				})
				.catch((error) => {
					this.alerta = {
						exibir: true,
						mensagem:
							"Erro interno ao tentar cadastrar o agendamento.",
						tipo: "erro",
					};
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Meus Agendamentos",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>
