const getters = {
    atualizacaoCadastralPendente: state => {
        return state.atualizacaoCadastral.pendente;
    },

    retornarCamposAtualizacaoCadastral: state => {
        return state.atualizacaoCadastral.campos;
    },
};
export default getters
