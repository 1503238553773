<template>
    <ValidationProvider :rules="regras" v-slot="{ errors }">
        <div v-if="showTitle">
            <label v-if="regras.includes('required')"
                ><strong>*{{ titulo }}</strong></label
            >
            <label v-else>{{ titulo }}</label>
        </div>
        <input
            :type="tipo"
            :class="classe"
            :placeholder="placeholder"
            v-model="valorInterno"
            :v-mask="mascara"
            @blur="onChange"
        />
        <label class="error"
            ><span>{{ errors[0] }}</span></label
        >
    </ValidationProvider>
</template>

<script>
export default {
    name: "TextInput",

    props: {
        titulo: { type: String, required: true },
        tipo: { type: String, required: true },
        classe: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        valor: { default: null },
        regras: { type: String, default: "" },
        mascara: { type: String, default: "" },
        showTitle: { default: true },
    },

    data() {
        return {
            valorInterno: this.valor,
        };
    },

    methods: {
        onChange() {
            this.$emit("update:valor", this.valorInterno);
        },
    },
};
</script>