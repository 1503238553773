<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Meus Contratos"
            subtitulo="Painel com informações de seus contratos"
        />
        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />

        <div class="row">
            <div class="col-12">
                <ListagemContratos :idUnidade="idUnidade" @error="tratarErro" />
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemContratos from "./components/ListagemContratos";
import UnidadePadrao from "@/components/UnidadePadrao";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import { mapState } from "vuex";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    props: ["idUnidade"],

    components: {
        BreadCrumbs,
        ListagemContratos,
        UnidadePadrao,
        TituloPagina,
        Aviso,
    },

    computed: {
        ...mapState({
            unidadeGlobal: (state) => state.unidades.unidade,
        }),
    },

    created () {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Contratos', 'Meus Contratos', 'Listagem', true, false)
        );
    },

    data() {
        return {
            caminho: [
                {
                    texto: "Meus Contratos",
                    ativo: true,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
            chaveListagem: 0,
        };
    },
    methods: {
        atualizarListagem() {
            this.chaveListagem += 1;
        },

        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
