<template>
  <div class="pc-content">
    <BreadCrumbs :caminho="caminho" />
    <TituloPagina
      titulo="Cadastrar um novo documento"
      subtitulo="Painel com informações do seu condomínio"
    />

    <div class="row">
      <div class="col-12">
        <FormularioDocumento />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import FormularioDocumento from "./components/novoDocumento/FormularioDocumento";
import TituloPagina from "@/components/TituloPagina";

export default {
  name: "NovoDocumento",

  components: {
    BreadCrumbs,
    TituloPagina,
    FormularioDocumento,
  },

  data() {
    return {
      caminho: [
        {
          texto: "Meus Documentos",
          rota: "Documentos",
        },
        {
          texto: "Novo Documento",
          ativo: true,
        },
      ],
    };
  },
};
</script>
