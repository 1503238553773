import axios from "axios";
import store from "@/store";

export function getConfiguracoes() {
  let empreendimento = "";
  if (store.state.configuracoes.empreendimento) {
    empreendimento = `/${store.state.configuracoes.empreendimento}`;
  }
  return axios.get(
    `/configuracoes${empreendimento}`,
    {
      params: {
        slug: store.state.configuracoes.slug,
      },
    }
  );
}

export function getMultiplosPortais() {
  return axios.get(`/retornar-multiplos-portais`);
}

export function validarSlug(slug) {
  return axios.get(`/validar-slug/${slug}`);
}
