<template>
    <button @click="showSidebarFilters" class="pc-btnBox pc-btnBox-icon-left pc-btnBox-secondary">
        <IconFilters />
        <span>Filtros</span>
    </button>
</template>

<script>
    export default {
        methods: {
            showSidebarFilters() {
                this.$emit('show-sidebar-filters');
            }
        }
    };
</script>
