<template>
    <div class="pc-bloco">
        <div class="pc-perfil">
            <div class="pc-perfil-avatar">
                <div class="pc-perfil--circle">
                    <img
                        class="pc-profile-pic"
                        v-if="sindico.avatar"
                        v-bind:src="
                            sindico.avatar.url
                                .replace('[[LARGURA]]', 'x')
                                .replace('[[ALTURA]]', 'x')
                        "
                        alt=""
                    />
                    <img
                        class="pc-profile-pic"
                        v-else
                        v-bind:src="avatar"
                        alt=""
                    />
                </div>
                <div class="pc-image">
                    <span
                        v-if="!acessoComo"
                        toggle="tooltip"
                        placement="bottom"
                        title="Alterar Avatar"
                        class="pc-upload-button"
                        id="pick-avatar"
                        >+</span
                    >
                </div>
            </div>
            <p>
                {{ sindico.nome }}
                <span>{{ sindico.email }}</span>
            </p>
        </div>
        <div class="pc-unidadePerfil">
            <div class="pc-titleBloco">
                <div>
                    <h3>Dados de Contato</h3>
                </div>
            </div>
            <ul class="pc-unidadePerfil--list">
                <li class="pc-infoIcon">
                    <p><span>Celular</span>{{ sindico.celular }}</p>
                </li>
            </ul>
        </div>
        <avatar-cropper
            trigger="#pick-avatar"
            :upload-url="url"
            :labels="labels"
            :upload-headers="headers"
            @uploaded="handleUploaded"
            @error="handlerError"
        ></avatar-cropper>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AvatarCropper from "vue-avatar-cropper";
import { URL_API } from "@/bootstrap/index";
import store from "@/store";
import { retornarTempAvatar } from "@/plugins/Utils";

export default {
    components: { AvatarCropper },

    computed: {
        ...mapGetters({
            sindico: "getSindico",
            acessoComo: "getAcessoComo",
        }),

        ...mapActions(["getMeSindico"]),

        avatar() {
            return require(`@/assets/img/alfabeto_cv/${this.retornarTempAvatar(this.sindico.nome)}.png`);
        },
    },

    data() {
        return {
            url: `${URL_API}/api/v1/cliente/sindico/avatar`,
            headers: {
                Authorization: `Bearer ${store.state.login.access_token}`,
            },
            labels: {
                submit: "Salvar",
                cancel: "Cancelar",
            },
        };
    },

    methods: {
        retornarTempAvatar,
        
        handleUploaded(response, form, xhr) {
            this.getMe();
        },

        handlerError(message, type, xhr) {
            if (type == "upload") {
                let mensagem = "Erro interno, por favor tentar mais tarde.";
                if (xhr.status != 422) {
                    mensagem = xhr.response.error;
                }
                this.$emit("exibirAviso", {
                    exibir: true,
                    tipo: "erro",
                    mensagem: mensagem,
                });
            }
        },
    },
};
</script>
