const getDefaultState = () => {
    return {
        atualizacaoCadastral: {
            pendente: false,
            campos: []
        }
    }
}
const state = getDefaultState();

export { getDefaultState }
export default state;
