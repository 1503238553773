<template>
    <div class="col-12">
        <div class="pc-itemUnidade">
            <div class="pc-ft-u">
                <img
                    v-if="unidade.empreendimento.foto"
                    :src="
                        unidade.empreendimento.foto.url
                            .replace('[[LARGURA]]', 'x')
                            .replace('[[ALTURA]]', 'x')
                    "
                    alt=""
                />
                <img src="@/assets/img/ft.jpg" alt="" v-else />
            </div>
            <div class="pc-info">
                <div class="pc-info--resumo">
                    <h2>
                        <span>Empreendimento</span
                        >{{ unidade.empreendimento.nome }}
                    </h2>
                    <ul>
                        <li class="pc-infoIcon">
                            <div class="pc-infoIcon--icon">
                                <IconPredio />
                            </div>
                            <p><span>Bloco</span>{{ unidade.bloco.nome }}</p>
                        </li>
                        <li class="pc-infoIcon">
                            <div class="pc-infoIcon--icon">
                                <IconHouse />
                            </div>
                            <p><span>Unidade</span>{{ unidade.nome }}</p>
                        </li>
                        <li
                            class="pc-infoIcon"
                            v-if="configuracoes.exibir_menu.previsao_entrega && unidade.previsao_entrega"
                        >
                            <div class="pc-infoIcon--icon">
                                <IconCalendar />
                            </div>
                            <p>
                                <span>Previsão de Entrega</span>
                                {{
                                    unidade.previsao_entrega
                                        | dateParse("YYYY-MM-DD")
                                        | dateFormat("MMM/YYYY")
                                }}
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="pc-info--btns">
                    <router-link
                        v-if="verificarRotaExiste('financeiro')"
                        :to="{ name: 'financeiro' , params: modulos.sienge ? { idUnidade: unidade.idunidade } : {}}"
                        class="pc-bloco pc-btnAtalho mb-20"
                    >
                        <div class="pc-infoIcon pc-iconInfo-big">
                            <p>
                                Ver Financeiro<span
                                    >Seu histórico financeiro</span
                                >
                            </p>
                        </div>
                        <div class="pc-btnAtalho--seta">
                            <IconSetaRight />
                        </div>
                    </router-link>
                    <router-link
                        v-if="verificarRotaExiste('documentos')"
                        :to="{ name: 'documentos', params: { idUnidade: unidade.idunidade }}"
                        class="pc-bloco pc-btnAtalho mb-20"
                    >
                        <div class="pc-infoIcon pc-iconInfo-big">
                            <p>
                                Documentos<span>Consulte seus documentos</span>
                            </p>
                        </div>
                        <div class="pc-btnAtalho--seta">
                            <IconSetaRight />
                        </div>
                    </router-link>
                    <router-link
                        v-if="verificarRotaExiste('atendimentos')"
                        :to="{ name: 'atendimentos' }"
                        class="pc-bloco pc-btnAtalho mb-20"
                    >
                        <div class="pc-infoIcon pc-iconInfo-big">
                            <p>
                                Ver Atendimentos<span
                                    >Consulte os atendimentos</span
                                >
                            </p>
                        </div>
                        <div class="pc-btnAtalho--seta">
                            <IconSetaRight />
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import {mapState} from "vuex";
export default {
    computed: {
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes,
        }),
    },
    props: {
        unidade: { type: Object, required: true },
    },
  data() {
    return {
      modulos: store.state.configuracoes.configuracoes.modulos,
    }
  },
};
</script>
