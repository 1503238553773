const getDefaultState = () => {
    return {
        termoAceite: {
            termo_pendente: false,
            termo: {
                idtermo: null,
                contrato: null,
                url_termo: null,
            }
        }
    }
};

const state = getDefaultState();

export {getDefaultState}
export default state;
