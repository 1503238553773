<template>
  <div class="pc-bloco">
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-titleBloco">
      <h3>Reparcelamento</h3>
      <div class="pull-right pc-title-legenda" v-if="parcelasAntecipacao">
        Informações encontradas: {{ parcelasAntecipacao.total }}
      </div>
    </div>
    <div v-if="parcelasAntecipacao.parcelas">
      <div v-if="parcelasSelecionadas.length > 0" id="collapseResumo">
        <div class="pc-bloco box-resumo-antecipacao">
          <div class="row">
            <div class="form-group col-lg-4 col-md-6 col-12">
              <label>Quantidade de parcelas a serem geradas</label>
              <input
                type="number"
                class="form-control input-alpha"
                placeholder="- Número de parcelas -"
                name="quantidade_parcelas_gerar"
                v-model="quantidade_parcelas_gerar"
                @change="assertPositiveNumber"
              />
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
              <label>Data do primeiro vencimento</label>
              <datepicker
                input-class="form-control"
                name="primeiro_vencimento"
                id="primeiro_vencimento"
                placeholder="- Selecione uma data - "
                :disabled-dates="disabledFn"
                v-model="primeiro_vencimento"
                format="dd/MM/yyyy"
              ></datepicker>
            </div>
            <div class="form-group col-lg-4 col-md-6 col-12">
              <label>Data de efetivação do reparcelamento</label>
              <datepicker
                input-class="form-control"
                name="data_reparcelamento"
                id="data_reparcelamento"
                placeholder="- Selecione uma data - "
                :disabled-dates="disabledFn"
                v-model="data_reparcelamento"
                format="dd/MM/yyyy"
              ></datepicker>
            </div>
          </div>
          <button @click="efetuarReparcelamento" class="m-t-10 pc-btnBox pc-btnBox-small pc-btnBox-primary">Efetuar Reparcelamento</button>
        </div>
      </div>
      <div class="pc-bloco">
        <div id="alertaParcela" class="cv-notificacao --alerta m-b-15">
          <p>Boletos em atraso não são considerados para reparcelamento.</p>
        </div>
        <div class="pc-titleBloco">
          <h3>Minhas parcelas</h3>
        </div>
        <div class="pc-tabela pc-table-check">
          <div class="pc-tabela--row pc-tabela--head">
            <div class="pc-tabela--td pc-f1">
              <p class="-checkbox">
                <input type="checkbox" name="item" id="checkALl" class="cv-checkAll" @click="selectAll" :checked="todasSelecionadas"> Nº
                <span class="text-desktop"></span>
              </p>
            </div>
            <div class="pc-tabela--td ">
              <p>Vencimento</p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p>Tipo</p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p>Valor da parcela</p>
            </div>
          </div>
          <div
              v-for="parcela in parcelasAntecipacao.parcelas"
              v-bind:key="parcela.codigo_parcela_sienge"
              class="pc-tabela--row">
            <div class="pc-tabela--td">
              <p data-title="Nº" class="-checkbox">
                <input
                    type="checkbox"
                    name="parcela"
                    v-model="parcelasSelecionadas"
                    :value="parcela.codigo_parcela_sienge"
                    @change="selectOne"
                > {{parcela.codigo_parcela_sienge}}
              </p>
            </div>
            <div class="pc-tabela--td ">
              <p data-title="Vencimento">
                {{
                  parcela.data_vencimento
                  | dateParse("YYYY-MM-DD")
                  | dateFormat("DD/MM/YY")
                }}
              </p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p data-title="Tipo">{{ parcela.tipo_condicao_parcela }}</p>
            </div>
            <div class="pc-tabela--td pc-f2 m-t-0-mobile">
              <p data-title="Valor da parc.">{{ parcela.valor_corrigido}}</p>
            </div>
          </div>
        </div>
      </div>
      <PaginateInput
          :total="parcelasAntecipacao.total"
          :limit="paginacao.limit"
          :offset="paginacao.offset"
          @paginacao="montarListagem"
      />
    </div>
    <div v-else >Nenhuma informação encontrada.</div>
  </div>
</template>

<script>
import {
  postEfetuarReparcelamento,
  getParcelasAntecipacao,
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { ptBR } from "vuejs-datepicker/dist/locale";
import {feriadosNacionais} from "@/plugins/Utils";
import Datepicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  props: {
    idUnidade: { type: Number },
  },

  emits: ["error"],

  components: {
    LoadingComponent,
    PaginateInput,
    Datepicker
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
    }),
  },

  data() {
    return {
      ptBR: ptBR,
      todasSelecionadas: false,
      parcelasAntecipacao: [],
      parcelasSelecionadas: [],
      primeiro_vencimento: null,
      data_reparcelamento: null,
      quantidade_parcelas_gerar: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: true,
      erroParcelasAtraso: false,
      disabledFn: {
        customPredictor: function(date) {
          const hoje = new Date();
          const formattedDate = date.toISOString().split('T')[0];
          const dayOfWeek = date.getDay();
          if (dayOfWeek === 0 || dayOfWeek === 6 || date <= hoje) {
            return true;
          }
          return feriadosNacionais.includes(formattedDate);
        },
      },
    };
  },

  beforeMount() {
    if (this.idUnidade) {
      this.montarListagem(1);
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      getParcelasAntecipacao(
          (pagina - 1) * this.paginacao.limit,
          this.paginacao.limit,
          this.idUnidade
      )
          .then((response) => {
            this.parcelasAntecipacao = response.data;
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.$emit("error", error.response.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    selectOne() {
      const checkboxes = document.querySelectorAll("input[type='checkbox'][name='parcela']");
      this.todasSelecionadas = this.parcelasSelecionadas.length === checkboxes.length;
    },
    selectAll() {
      this.selectOne();
      const checkboxes = document.querySelectorAll("input[type='checkbox'][name='parcela']");
      this.parcelasSelecionadas = [];
      if (!this.todasSelecionadas) {
        checkboxes.forEach(checkbox => {
          this.parcelasSelecionadas.push(checkbox.value);
        });
      }
      this.selectOne();
    },
    validarCampos() {
      if (!this.quantidade_parcelas_gerar || this.quantidade_parcelas_gerar <= 0) {
        this.$emit("exibirAviso", {
          exibir: true,
          tipo: "erro",
          mensagem: "Quantidade de parcelas a serem geradas é obrigatório",
        });
        return false;
      }
      if (!this.primeiro_vencimento) {
        this.$emit("exibirAviso", {
          exibir: true,
          tipo: "erro",
          mensagem: "Data do primeiro vencimento é obrigatório",
        });
        return false;
      }
      if (!this.data_reparcelamento) {
        this.$emit("exibirAviso", {
          exibir: true,
          tipo: "erro",
          mensagem: "Data de efetivação do reparcelamento é obrigatório",
        });
        return false;
      }
      return true;
    },
    efetuarReparcelamento() {
      if (this.validarCampos()) {
        this.isLoading = true;
        let data = {
          idunidade: this.idUnidade,
          idsparcelas: '',
          primeiro_vencimento: moment(this.primeiro_vencimento).format("YYYY-MM-DD"),
          data_reparcelamento: moment(this.data_reparcelamento).format("YYYY-MM-DD"),
          quantidade_parcelas_gerar: parseInt(this.quantidade_parcelas_gerar)
        };
        this.parcelasSelecionadas.map((parcela, index) => {
          if (index === 0) {
            data.idsparcelas += parcela;
          } else {
            data.idsparcelas += `/${parcela}`;
          }
        });

        postEfetuarReparcelamento(data)
          .then(() => {
            this.$emit("exibirAviso", {
              exibir: true,
              tipo: "sucesso",
              mensagem: "Solicitação efetuada com sucesso",
            });
            this.montarListagem(1);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.$emit("exibirAviso", {
                exibir: true,
                tipo: "erro",
                mensagem: error.response.data.error});
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    assertPositiveNumber() {
      if (isNaN(parseInt(this.quantidade_parcelas_gerar)) || this.quantidade_parcelas_gerar < 1) {
        this.quantidade_parcelas_gerar = 1;
      }
    }
  },
};
</script>
