const getters = {
    getCliente: state => {
        return state.cliente;
    },

    getSindico: state => {
        return state.sindico;
    },

    getAcessoComo: state => {
        return state.acesso_como;
    },

    getAcessoSindico: state => {
        return state.acesso_sindico;
    },

    getDocumentoInternacional: state => {
        return state.documento_internacional;
    }
};
export default getters
