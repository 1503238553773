import axios from "axios";
import store from '../store';

export function getUnidade(idUnidade) {
    return axios.get('/unidades/' + idUnidade, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getUnidades(idEmpreendimento = null, verificarFlags = null) {
    return axios.get(`/unidades`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            idEmpreendimento: idEmpreendimento,
            verificarFlags: verificarFlags,
        }
    });
}
