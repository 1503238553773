<template>
    <div class="pc-modalPopostaAtiva--proposta">
        <div class="pc-ft">
            <img
                v-if="getSafe(() => precadastro.empreendimento.foto.url)"
                :src="
                   precadastro.empreendimento.foto.url
                        .replace('[[LARGURA]]', 'x')
                        .replace('[[ALTURA]]', 'x')
                "
                alt=""
            />
            <img src="@/assets/img/ft.jpg" alt="" v-else />
        </div>
        <div class="pc-info">
            <h3>{{ precadastro.empreendimento.nome }}</h3>
            <p></p>
            <ul>
                <li>
                    <p>
                        Bloco Selecionado
                        <span>--</span>
                    </p>
                </li>
                <li>
                    <p>
                        Coluna Selecionada
                        <span >--</span>
                    </p>
                </li>
                <li>
                    <p>
                        Andar Selecionado
                        <span>--</span>
                    </p>
                </li>
                <li>
                    <p>
                        Unidade Selecionada
                         <span>--</span>
                       
                    </p>
                </li>
            </ul>
            <div class="pc-alert pc-warning">
                <p>Nesse pré cadastro falta(m) {{precadastro.documentos_faltando}} documento(s) obrigatório(s).</p>
            </div>
            <div class="pc-footer">
        
                <div class="pc-btns">
                    <a
                        v-if="precadastro.hash_precadastro"
                        @click.prevent="submitProposta()"
                        href="javascript:void(0)"
                        target="_blank"
                        class="
                            pc-btnGhost pc-btnGhost-small pc-btnGhost-primary
                        "
                        :class="acessoComo ? 'disabled' : null"
                    >
                        Enviar Documentos
                    </a>
                </div>
            </div>
        </div>
        <form ref="form" method="POST" :action="!acessoComo ? `/plugins/meuprecadastro/${precadastro.hash_precadastro}/${precadastro.hash_cliente}` : null" target="_blank">
            <input type="hidden" name="token_cliente" :value="$store.state.login.access_token">
        </form>
    </div>
</template>

<script>
import getSafe from "../../../plugins/OptionalChain";
import { mapGetters } from "vuex";

export default {
    props: {
        precadastro: { type: Object, required: true },
    },

    name: "ModalInfoPreCadastros",

    computed: {
        ...mapGetters({
            acessoComo: "getAcessoComo",
        }),
    },

    created() {
    },

    methods: {
        getSafe,

        submitProposta() {
            this.$refs.form.submit();
        }
    },
};
</script>
