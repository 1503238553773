<template>
    <div class="row">
        <div
          v-if="verificarRotaExiste('atendimentos')"
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'atendimentos' }"
              :icone="IconAtendimento"
              titulo="Meus Atendimentos"
              descricao="Consulte seus Atendimentos"
            />
        </div>
        <div
          v-if="verificarRotaExiste('assistencias')"
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'assistencias' }"
              :icone="IconCalendar"
              titulo="Minhas Assistências"
              descricao="Consulte suas Assistências Técnicas"
            />
        </div>

        <div
          v-if="
            (
              unidade.perfil.sigla === 'TR'
              || (plugin.associado === 'S' && unidade.perfil.sigla === 'AR')
            )
            && unidade.financeiro
            &&
            ((typeof plugin === 'object') && (plugin !== null))
          "
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'plugin-' + nomeUrl(plugin.nome) }"
              :icone="IconFinanceiro"
              :titulo="plugin.nome"
              descricao="Consulte seu Financeiro"
            />
        </div>

        <div
          v-if="(verificarRotaExiste('financeiro') && (plugin === null))"
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'financeiro' }"
              :icone="IconFinanceiro"
              titulo="Financeiro"
              descricao="Consulte seu Financeiro"
            />
        </div>
        <div
          v-if="acessoSindico && verificarRotaExiste('assistencias')"
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'assistencias' }"
              :icone="IconFerramenta"
              titulo="Minhas Assistências"
              descricao="Consulte suas Assistências"
            />
        </div>
        <div
          v-if="verificarRotaExiste('documentos')"
          class="col-lg-3 col-md-6 col-12"
        >
            <Atalho
              :rota="{ name: 'documentos' }"
              :icone="IconDocumentos"
              titulo="Documentos"
              descricao="Consulte os documentos do seu condomínio"
            />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import Atalho from "@/pages/home/components/Atalho.vue";
import IconAtendimento from "@/components/icons/IconAtendimento.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconFinanceiro from "@/components/icons/IconFinanceiro.vue";
import IconFerramenta from "@/components/icons/IconFerramenta.vue";
import IconDocumentos from "@/components/icons/IconDocumentos.vue";

export default {
    components: {
        Atalho,
        IconAtendimento,
        IconCalendar,
        IconFinanceiro,
        IconFerramenta,
        IconDocumentos,
    },

    computed: {
        ...mapGetters({
            acessoSindico: "getAcessoSindico",
            configuracoes: "getConfiguracoes",
        }),
        ...mapState({
            unidade: (state) => state.unidades.unidade,
        }),
    },

    data() {
        return {
            plugin: null,
            IconAtendimento: IconAtendimento,
            IconCalendar: IconCalendar,
            IconFinanceiro: IconFinanceiro,
            IconFerramenta: IconFerramenta,
            IconDocumentos: IconDocumentos,
        };
    },

    mounted() {
        this.pluginFinanceiro();
    },

    methods: {
        pluginFinanceiro(){
            this.configuracoes.plugins.forEach((plugin) => {
                if (plugin.iconepc == 'financeiro') {
                    this.plugin = plugin;
                }
            });
        },

        nomeUrl(nome) {
            return nome.toLowerCase().replace(" ", "-");
        },
    },
}
</script>