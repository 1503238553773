<template>
	<div class="pc-login-wrap">
		<BannerLateralInicio />
		<div class="pc-login-form">
			<div class="pc-login-form--wrap">
				<LogoTopoInicio />
				<router-view></router-view>
			</div>
			<div class="pc-login-footer">
				<p>Feito com ❤️ por <a href="https://cvcrm.com.br" target="_blank">CV CRM</a></p>
        <div>
				  <a href="https://cvcrm.com.br/politica-de-privacidade/" class="m-r-10" target="_blank">Política de Privacidade</a>
				  <span v-if="configuracoes.exibir_menu.perguntas_frequentes" class="m-r-10 separador-links">|</span>
          <a v-if="configuracoes.exibir_menu.perguntas_frequentes" href="/cliente/perguntas-frequentes" target="_blank">Perguntas Frequentes</a>
        </div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
 .separador-links {
   color: lightgray;
   font-weight: 100;
   position: relative;
   top: 2px;
 }

 @media (max-width: 768px) {
   .pc-login-footer {
     flex-direction: column;
   }
 }
</style>

<script>
import BannerLateralInicio from "@/layouts/_partials/BannerLateralInicio";
import LogoTopoInicio from "@/layouts/_partials/LogoTopoInicio";
import { mapState } from "vuex";

export default {
	name: "MyComponent",
	components: { BannerLateralInicio, LogoTopoInicio },
  computed: {
    ...mapState({
      configuracoes: (state) => state.configuracoes.configuracoes,
    }),
  },
};
</script>
