<template>
  <div class="pc-tabela--row">
    <div class="pc-tabela--td pc-f1">
      <p data-title="Protocolo">{{ assistencia.idassistencia }}</p>
    </div>
    <div class="pc-tabela--td pc-f2">
      <p data-title="Empreendimento">
        <span v-if="assistencia.unidade != undefined">
          {{ getSafe(() => assistencia.unidade.empreendimento.nome) }}
        </span>
        <span v-else-if="assistencia.unidade_avulsa != undefined">
          {{ getSafe(() => assistencia.unidade_avulsa.empreendimento) }}
        </span>
        <span v-else-if="assistencia.area != undefined">
          {{ getSafe(() => assistencia.area.empreendimento) }}
        </span>
        <span v-else>
          {{ " -- " }}
        </span>
      </p>
    </div>
    <div class="pc-tabela--td pc-f1">
      <p data-title="Bloco">
        <span v-if="assistencia.unidade != undefined">
          {{ getSafe(() => assistencia.unidade.bloco.nome) }}
        </span>
        <span v-else>
          {{ getSafe(() => assistencia.unidade_avulsa.bloco) }}
        </span>
      </p>
    </div>
    <div class="pc-tabela--td pc-f1">
      <p data-title="Dados da unidade">
        <span v-if="assistencia.unidade != undefined">
          {{ "Unidade: " + getSafe(() => assistencia.unidade.nome) }}
        </span>
        <span v-else-if="assistencia.unidade_avulsa != undefined">
          {{ "Unidade: " + getSafe(() => assistencia.unidade_avulsa.unidade) }}
        </span>
        <span v-else-if="assistencia.localidade != undefined">
          {{ "Localidade: " + getSafe(() => assistencia.localidade.nome) }}
        </span>
        <span v-else-if="assistencia.area != undefined">
          {{ "Área: " + getSafe(() => assistencia.area.nome) }}
        </span>
        <span v-else>
          {{ " -- " }}
        </span>
      </p>
    </div>
    <div class="pc-tabela--td pc-f2">
      <p data-title="Itens">{{ getSafe(() => assistencia.dados_gerais) }}</p>
    </div>
    <div class="pc-tabela--td pc-f1">
        <p data-title="Data">
            {{assistencia.data_cadastro | dateParse("YYYY-MM-DD HH:mm:ss") |dateFormat("DD/MM/YYYY HH:mm:ss") }}
        </p>
    </div>
    <div class="pc-tabela--td pc-f1">
      <p data-title="Situação atual"
         class="pc-labelStatus"
         v-bind:style="{
                color: assistencia.situacao.situacao_cor_nome ?? '#fff',
                background: assistencia.situacao.situacao_cor_bg ?? '#0533ff'
            }">
        {{ assistencia.situacao.nome }}
      </p>
    </div>
    <div class=" pc-tabela--td pc-tabela--td-icons pc-f1 justify-content-center">
      <router-link
          :to="{
                    name: 'assistencia',
                    params: { idAssistencia: assistencia.idassistencia}
                }"
          class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
      >Ver Assistência
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import getSafe from '@/plugins/OptionalChain';

export default {
  name: "Listagem",
  props: {
    assistencia: {type: Object, required: true},
  },
  computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico"
    }),
  },
  methods: {getSafe},
}
</script>
