<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<div>
				<h3>Extrato do Cliente</h3>
			</div>
			<div>
        <a v-if="
        temExtrato &&
        !configuracoes.especifico_cv.ocultar_visualizacao_detalhada_extrato &&
        configuracoes.exibir_financeiro.botao_saldo_devedor"
           :class="acessoComo ? 'disabled' : null"
           class=" pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
           @click="baixarSaldoDevedor()"
        >
          Saldo devedor
        </a>
				<a v-if="temExtrato"
					:class="acessoComo ? 'disabled' : null"
					class="ml-2 pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
					@click="baixarExtrato()"
        >
					Baixar extrato
				</a>
				<a v-if="temExtrato"
					class="ml-2 pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
					:class="acessoComo ? 'disabled' : null"
					@click="enviarExtratoEmail()"
        >
						Enviar extrato por e-mail
					</a>
			</div>
		</div>
		<div class="pull-right mb-20 pc-title-legenda" v-if="listagemExtrato && !configuracoes.especifico_cv.ocultar_visualizacao_detalhada_extrato">
			Informações encontradas: {{ listagemExtrato.total }}
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-tabela-overflow">
			<div class="pc-tabela" v-if="listagemExtrato && !configuracoes.especifico_cv.ocultar_visualizacao_detalhada_extrato">
				<div class="pc-tabela--row pc-tabela--head">
					<div class="pc-tabela--td pc-f1"
					v-if="!configuracoes.especifico_cv.retirar_coluna_parcelas_extrato_sienge">
						<p>Número Parcela</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Tipo de Parcela</p>
					</div>
					<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_valor_original">
						<p>Valor Original</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Data de vencimento</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Data de correção</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Valor corrigido</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Data de Baixa</p>
					</div>
					<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_valor_de_baixa">
						<p>Valor de Baixa</p>
					</div>
					<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_acrescimo">
						<p>Acréscimo</p>
					</div>
					<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_desconto">
						<p>Desconto</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Recto Líquido</p>
					</div>
				</div>
				<div
					v-for="(parcela, numP) in listagemExtrato.extrato"
					:key="numP"
				>
					<div class="pc-tabela--row">
						<div class="pc-tabela--td pc-f1"
						v-if="!configuracoes.especifico_cv.retirar_coluna_parcelas_extrato_sienge">
							<p data-title="Número Parcela">
								{{ parcela.numero_parcela }}/{{
									parcela.total_parcela
								}}
							</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p data-title="Tipo de Condição">
								{{ parcela.condicao }}
							</p>
						</div>
						<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_valor_original">
							<p data-title="Valor Original">
								{{
									parcela.valor_original.toLocaleString(
										"pt-br",
										{ style: "currency", currency: "BRL" }
									)
								}}
							</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p data-title="Data de vencimento">
								{{
									parcela.data_vencimento
										| dateParse("YYYY-MM-DD")
										| dateFormat("DD/MM/YY")
								}}
							</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p
								data-title="Data de correção"
								v-if="parcela.recibos.length"
							>
								{{
                                    retornarDadosAgrupadosRecibos(parcela.recibos, 'data_calculo', false)
										| dateParse("YYYY-MM-DD")
										| dateFormat("DD/MM/YY")
								}}
							</p>
							<p data-title="Data de correção" v-else>
								--
							</p>
						</div>
						<div class="pc-tabela--td pc-f1">
							<p data-title="Valor corrigido">
								{{
									parcela.saldo_atual.toLocaleString(
										"pt-br",
										{ style: "currency", currency: "BRL" }
									)
								}}
							</p>
						</div>
						<div class="pc-tabela--td pc-f2">
							<p
								data-title="Data de Baixa"
								v-if="parcela.recibos.length"
							>
								{{
                  retornarDadosAgrupadosRecibos(parcela.recibos, 'data_recibo', false)
										| dateParse("YYYY-MM-DD")
										| dateFormat("DD/MM/YY")
								}}
							</p>
							<p data-title="Data de Baixa" v-else>
								--
							</p>
						</div>
						<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_valor_de_baixa">
							<p
								data-title="Valor de Baixa"
								v-if="parcela.recibos.length"
							>
								{{
                  retornarDadosAgrupadosRecibos(parcela.recibos, 'recibo_valor', true).toLocaleString(
										"pt-br",
										{ style: "currency", currency: "BRL" }
									)
								}}
							</p>
							<p data-title="Valor de Baixa" v-else>
								--
							</p>
						</div>
						<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_acrescimo">
							<p
								data-title="Acréscimo"
								v-if="parcela.recibos.length"
							>
								<template
									v-if="
										parcela.recibos[0].tipo_recibo ==
											'Reparcelamento' ||
											parcela.recibos[0].tipo_recibo ==
												'Distrato'
									"
								>
									**{{ parcela.recibos[0].tipo_recibo }}**
								</template>
								<template v-else>
									{{
                    retornarDadosAgrupadosRecibos(parcela.recibos, 'valor_adicional', true).toLocaleString(
											"pt-br",
											{
												style: "currency",
												currency: "BRL",
											}
										)
									}}
								</template>
							</p>
							<p data-title="Acréscimo" v-else>
								--
							</p>
						</div>
						<div class="pc-tabela--td pc-f1" v-if="configuracoes.exibir_financeiro.coluna_extrato_desconto">
							<p
								data-title="Desconto"
								v-if="parcela.recibos.length"
							>
								<template v-if="
								  parcela.recibos[0].tipo_recibo === 'Reparcelamento' ||
								  parcela.recibos[0].tipo_recibo === 'Distrato'"
								>
									--
								</template>
								<template v-else>
									{{
                    retornarDadosAgrupadosRecibos(parcela.recibos, 'valor_deconto', true).toLocaleString(
											"pt-br",
											{
												style: "currency",
												currency: "BRL",
											}
										)
									}}
								</template>
							</p>
							<p data-title="Desconto" v-else>
								--
							</p>
						</div>
						<div class="pc-tabela--td pc-f1">
							<p
								data-title="Recto Líquido"
								v-if="parcela.recibos.length"
							>
								<template v-if="
									parcela.recibos[0].tipo_recibo === 'Reparcelamento' ||
									parcela.recibos[0].tipo_recibo === 'Distrato'"
								>
									--
								</template>
								<template v-else>
									{{
                    retornarDadosAgrupadosRecibos(parcela.recibos, 'total_recibo_valor', true).toLocaleString(
											"pt-br",
											{
												style: "currency",
												currency: "BRL",
											}
										)
									}}
								</template>
							</p>
							<p data-title="Recto Líquido" v-else>
								--
							</p>
						</div>
					</div>
				</div>
				<PaginateInput
					:total="listagemExtrato.total"
					:limit="paginacao.limit"
					:offset="paginacao.offset"
					@paginacao="montarListagem"
				/>
			</div>
			<div v-else-if="!configuracoes.especifico_cv.ocultar_visualizacao_detalhada_extrato">Nenhuma informação encontrada.</div>
		</div>
	</div>
</template>

<script>
import {getExtrato, enviarExtrato, baixarExtrato, getSaldoDevedorLink} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import SelectInput from "@/components/SelectInput";
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";
import store from "../../../store";

export default {
	emits: ["error", "exibirAviso"],

	components: {
		LoadingComponent,
		PaginateInput,
		SelectInput,
	},

	data() {
		return {
			listagemExtrato: null,
			anoEscolhido: null,
			anosImposto: null,
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
			temExtrato: false,
		};
	},

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
			configuracoes: "getConfiguracoes",
		}),
		console: () => console,
	},

	beforeMount() {
		this.montarListagem(1);
	},

	methods: {
    retornarDadosAgrupadosRecibos(recibos, indice, somar) {
      if (somar) {
        let total = 0;
        for (let i = 0; i < recibos.length; i++) {
          total += recibos[i][indice];
        }
        return total;
      } else {
        let retorno = '';
        for (let i = 0; i < recibos.length; i++) {
          retorno = recibos[i][indice];
        }
        return retorno;
      }
    },

		montarListagem(pagina) {
			this.isLoading = true;
			getExtrato(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit
			)
				.then((response) => {
					this.temExtrato = true;
					this.listagemExtrato = response.data;
				})
				.catch((error) => {
					this.temExtrato = false;
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		enviarExtratoEmail() {
			if (this.acessoComo) {
				return;
			}

			this.isLoading = true;
			this.trakingMixpanel(
				"Extratos",
				"Enviar extrato por email",
				true,
				true
			);
			enviarExtrato()
				.then((response) => {
					this.$emit("exibirAviso", {
						exibir: true,
						tipo: "sucesso",
						mensagem: "Relatório do extrato enviado com sucesso.",
					});
				})
				.catch((error) => {
					this.$emit("error", error.response.data);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

    baixarSaldoDevedor() {
      this.isLoading = true;
      this.trakingMixpanel(
        "Extratos",
        "Baixar Saldo Devedor",
        true,
        true
      );
      getSaldoDevedorLink()
        .then((response) => {
          if (response.status === 200) {
            console.log(JSON.stringify({'data': response.data.link, 'origem': 'download_saldo_devedor'}))
            if (store.state.login.login_origem !== 'app') {
              window.open(response.data.link, "_blank");
            }
          } else {
            this.$emit("error", {"error": "Saldo devedor não encontrado!"});
          }
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

		baixarExtrato() {
			this.isLoading = true;
			this.trakingMixpanel(
				"Extratos",
				"Baixar extrato",
				true,
				true
			);
			baixarExtrato()
				.then((response) => {
					console.log(JSON.stringify({'data': response.data.link, 'origem': 'download_extrato'}))
					if (store.state.login.login_origem !== 'app') {
						window.open(response.data.link, "_blank");
					}
				})
			.catch((error) => {
				this.$emit("error", error.response.data);
			})
			.finally(() => {
				this.isLoading = false;
			});
    },

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
			);
		},
	},
};
</script>
<style scoped>
.pull-right {
	text-align: right;
}
</style>
