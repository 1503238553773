<template>
    <div class="form-group">
        <SelectInput
            label="label"
            titulo="Tipo do documento"
            :options="[
                {label: 'Documento Nacional', valor: false},
                {label: 'Documento Internacional', valor: true}
                ]"
            :reduce="(option) => option.valor"
            :clearable="false"
            placeholder="Tipo de documento"
            :valor.sync="documentoInternacional"
            :showTitle="false"
            @update:valor="toggleDocumentoInternacional"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SelectInput from "@/components/SelectInput.vue";

export default {
    name: "SelectDocumentoInternacional",
    components: {
        SelectInput
    },
    computed: {
        ...mapGetters({
            documentoInternacional: "getDocumentoInternacional",
        }),
    },
    methods: {
        ...mapActions(["toggleDocumentoInternacional"]),
    }
};
</script>

<style scoped>
    @import "./SelectDocumentoInternacional.scss";
</style>