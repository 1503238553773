const state = {
    permissoes: {
        TR: {
            criar_agendamentos: true,
            criar_atendimentos: true,
            criar_assistencias: false,
            exibir_contratos: true,
            exibir_documentos: true,
            exibir_financeiro: true,
            exibir_cadastros: true,
            exibir_financeiro_cv: false,
            boletos_antecipacao: false,
        },
        AR: {
            criar_agendamentos: true,
            criar_atendimentos: true,
            criar_assistencias: false,
            exibir_contratos: true,
            exibir_documentos: false,
            exibir_financeiro: true,
            exibir_cadastros: true,
            exibir_boletos_titular: false,
            boletos_antecipacao: false,
        },
        AC: {
            criar_agendamentos: true,
            criar_atendimentos: true,
            criar_assistencias: false,
            exibir_contratos: false,
            exibir_documentos: true,
            exibir_financeiro: false,
            exibir_cadastros: false,
            boletos_antecipacao: false,
        }
    },

    perfisCliente: {
        TR: false,
        AR: false,
        AC: false,
    },

    perfis: ["TR", "AR", "AC"],

    permissoesSindico: {
        criar_atendimentos: false,
        criar_assistencias: false,
    }
};

export default state;
