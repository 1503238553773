var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-bloco"},[_vm._m(0),_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"pc-grafico mb-20"},[_c('div',{staticClass:"pc-grafico--info"},[(_vm.porcentagemTotalEstagioObra)?_c('p',[_vm._v(" "+_vm._s(_vm.porcentagemTotalEstagioObra)+"% "),_c('span',[_vm._v("Status Geral")])]):_c('p',[_c('span',[_vm._v("Nenhuma informação encontrada.")])])]),_c('div',{staticClass:"pc-grafico--svgs"},[_c('svg',{staticClass:"pc-graficoPorcentagem",attrs:{"preserveAspectRatio":"none","width":"360","height":"360","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"pc-graficoPorcentagem--circle",style:({
                        strokeDashoffset:
                            (100 - _vm.porcentagemTotalEstagioObra) * 4.4 +
                            'px',
                    }),attrs:{"r":"140","cy":"0","cx":"176","stroke-width":"40","stroke":"","fill":"none"}})]),_c('svg',{staticClass:"pc-graficoMask",attrs:{"preserveAspectRatio":"none","width":"360","height":"360","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"pc-graficoMask--circle",staticStyle:{"stroke-dashoffset":"360"},attrs:{"r":"140","cy":"0","cx":"176","stroke-width":"40","stroke":"white","fill":"none"}})]),_c('svg',{staticClass:"pc-graficoBg",attrs:{"preserveAspectRatio":"none","width":"360","height":"360","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{staticClass:"pc-graficoBg--circle",staticStyle:{"stroke-dashoffset":"360"},attrs:{"r":"140","cy":"0","cx":"176","stroke-width":"40","stroke":"","fill":"none"}})])])]),_c('div',{staticClass:"pc-statusObra"},[_vm._m(1),(_vm.andamentoObra)?_c('div',{key:_vm.componentKeyStatus},_vm._l((this.andamentoObra.etapas),function(estagio){return _c('div',{staticClass:"pc-statusObra--item"},[_c('div',{staticClass:"pc-statusObra--item-labels"},[_c('p',[_vm._v(_vm._s(estagio.nome))]),_c('p',[_vm._v(_vm._s(estagio.porcentagem)+"%")])]),_c('div',{staticClass:"pc-statusObra--item-grafico"},[_c('div',{staticClass:"pc-statusObra--item-porcentagel",style:('width: ' + estagio.porcentagem + '%')}),_c('div',{staticClass:"pc-statusObra--item-bar"})])])}),0):_c('div',[_vm._v("Nenhuma informação encontrada.")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-titleBloco"},[_c('div',[_c('h3',[_vm._v("Andamento da Obra")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-titleBloco"},[_c('div',[_c('h3',[_vm._v("Status da Obra")])])])
}]

export { render, staticRenderFns }