<template>
    <router-link
        v-if="!acessoSindico"
        :to="{ name: 'home' }"
        class="pc-btnBox pc-btnBox-icon-left pc-btnBox-primary"
    >
        <IconHouse />
        <span>Página Inicial</span>
    </router-link>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
      ...mapGetters({
            acessoSindico: "getAcessoSindico",
        }),
    }
}
</script>