var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.verificarRotaExiste('atendimentos'))?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'atendimentos' },"icone":_vm.IconAtendimento,"titulo":"Meus Atendimentos","descricao":"Consulte seus Atendimentos"}})],1):_vm._e(),(_vm.verificarRotaExiste('assistencias'))?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'assistencias' },"icone":_vm.IconCalendar,"titulo":"Minhas Assistências","descricao":"Consulte suas Assistências Técnicas"}})],1):_vm._e(),(
        (
          _vm.unidade.perfil.sigla === 'TR'
          || (_vm.plugin.associado === 'S' && _vm.unidade.perfil.sigla === 'AR')
        )
        && _vm.unidade.financeiro
        &&
        ((typeof _vm.plugin === 'object') && (_vm.plugin !== null))
      )?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'plugin-' + _vm.nomeUrl(_vm.plugin.nome) },"icone":_vm.IconFinanceiro,"titulo":_vm.plugin.nome,"descricao":"Consulte seu Financeiro"}})],1):_vm._e(),((_vm.verificarRotaExiste('financeiro') && (_vm.plugin === null)))?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'financeiro' },"icone":_vm.IconFinanceiro,"titulo":"Financeiro","descricao":"Consulte seu Financeiro"}})],1):_vm._e(),(_vm.acessoSindico && _vm.verificarRotaExiste('assistencias'))?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'assistencias' },"icone":_vm.IconFerramenta,"titulo":"Minhas Assistências","descricao":"Consulte suas Assistências"}})],1):_vm._e(),(_vm.verificarRotaExiste('documentos'))?_c('div',{staticClass:"col-lg-3 col-md-6 col-12"},[_c('Atalho',{attrs:{"rota":{ name: 'documentos' },"icone":_vm.IconDocumentos,"titulo":"Documentos","descricao":"Consulte os documentos do seu condomínio"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }