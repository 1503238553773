<template>
	<div class="tc-wrap">
		<div class="tc-logo">
			<img
				class="img-fluid"
				v-if="configuracoes.logo"
				:src="configuracoes.logo.url"
				alt=""
			/>
			<img
				class="img-fluid"
				v-else
				:src="baseUrl + '/especifico/img/logo_empresa.png'"
				alt=""
			/>
		</div>
		<div class="tc-title mb-20">
			<h4>{{ cliente.nome }}</h4>
			<p class="mt-10">
				Você deve aceitar o termo abaixo para ter acesso ao sistema.
			</p>
		</div>
		<div class="tc-bloco -full mt-20">
			<div class="tc-termo">
				<div class="tc-termo--title">
					<h5 v-html="termoAceite.termo.contrato"></h5>
				</div>

				<div class="tc-termo--texto" id="printTable">
					<LoadingComponent :isLoading="isLoading" />
					<iframe
						title="plugin"
						v-if="termoAceite.termo.url_termo"
						:src="termoAceite.termo.url_termo"
						@load="isLoading = false"
						class="tc-termo--texto"
					></iframe>
				</div>
			</div>
		</div>

		<div class="form-group mt-10">
			<div class="cv-checkFlex">
				<ul>
					<li>
						<input
							type="checkbox"
							name="item1"
							class="form-control checkAceito"
							id="nome"
							@change="checkado = !checkado"
						/>
						<span
							>Eu li e concordo com o termo de -
							{{ termoAceite.termo.contrato }}</span
						>
					</li>
				</ul>
			</div>
		</div>
		<div class="text-center">
			<button
				class="pc-btnBox pc-btnBox-secondary mt-20"
				:disabled="!checkado"
				@click.prevent="aceitarTermo"
			>
				AUTORIZAR TERMO
			</button>
			<div>
				<button class="pc-btnGhost" @click.prevent="sairPainel()">
					Sair
				</button>
			</div>
		</div>
	</div>
</template>
<style scoped>
@import "../assets/css/cv-termo-aceite.min.css";

iframe {
	display: block;
	width: 100%;
	min-width: 768px;
	height: 398vh;
	border: none;
	overflow: auto;
	padding-top: 20px;
}

@media (max-width: 800px) {
	iframe {
		min-width: initial;
		height: 400px;
	}
}

.pc-btnGhost {
	margin-top: 10px;
}
</style>
<script>
import { aceitarTermo } from "@/services/TermoAceiteService";
import { mapGetters, mapActions } from "vuex";
import { URL_API } from "@/bootstrap/index";
import LoadingComponent from "@/components/LoadingComponent";

export default {
	computed: {
		...mapGetters({
			cliente: "getCliente",
			configuracoes: "getConfiguracoes",
			termoAceite: "getTermoAceite",
		}),
	},
	components: {
		LoadingComponent,
	},
	data() {
		return {
			baseUrl: URL_API,
			isLoading: true,
			checkado: false,
		};
	},

	watch: {
		termoAceite(newValue) {
			if (!newValue.termo_pendente) {
				this.$router.push({ name: "home" });
			}
		},
	},

	methods: {
		...mapActions(["getTermos", "logout"]),
		aceitarTermo() {
			this.isLoading = true;
			aceitarTermo(this.termoAceite.termo.idtermo)
				.then((response) => {
					this.getTermos();
				})
				.catch()
				.finally(() => {
					this.isLoading = false;
				});
		},

		sairPainel() {
			this.logout();
			location.reload();
		},
	},
};
</script>
