var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-bloco"},[_c('div',{staticClass:"pc-titleBloco"},[_c('h3',[_vm._v("Documentos")]),(_vm.listagemDocumentos)?_c('div',{staticClass:"pull-right pc-title-legenda"},[_vm._v(" Informações encontradas: "+_vm._s(_vm.listagemDocumentos.total)+" ")]):_vm._e()]),_c('LoadingComponent',{attrs:{"isLoading":_vm.isLoading}}),(_vm.listagemDocumentos)?_c('div',{staticClass:"pc-tabela"},[_vm._m(0),_vm._l((_vm.listagemDocumentos.documentos),function(documento){return _c('div',{key:documento.idContrato,staticClass:"pc-tabela--row"},[_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Titulo"}},[_vm._v(" "+_vm._s(documento.titulo)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Tipo do Arquivo"}},[_vm._v(" "+_vm._s(documento.arquivo.nome)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Data de Criação"}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(documento.data_criacao,"YYYY-MM-DD HH:mm"),"DD/MM/YY"))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f1 justify-content-center"},[(documento.arquivo)?_c('a',{staticClass:"pc-btnGhost pc-btnGhost-small pc-btnGhost-primary",attrs:{"target":"_blank","href":documento.arquivo.url},on:{"click":function($event){return _vm.trakingMixpanel(
              'Documentos do Síndico',
              'Baixar Documento',
              true,
              true
            )}}},[_vm._v(" Baixar Documento ")]):_vm._e()])])}),_c('PaginateInput',{attrs:{"total":_vm.listagemDocumentos.total,"limit":_vm.paginacao.limit,"offset":_vm.paginacao.offset},on:{"paginacao":_vm.montarListagem}})],2):_c('div',[_vm._v("Nenhuma informação encontrada.")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--row pc-tabela--head"},[_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',[_vm._v("Titulo")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Tipo do Arquivo")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',[_vm._v("Data de Criação")])]),_c('div',{staticClass:"pc-tabela--td pc-f1"})])
}]

export { render, staticRenderFns }