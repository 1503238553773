<template>
    <div>
        <div class="collapse dont-collapse-sm" id="collapseExample">
            <a
                class="pc-bloco pc-btnAtalho mb-20"
                :class="{ active: !idAssociacaoInterno }"
                @click="escolherAssociacao(null)"
            >
                <div class="pc-infoIcon pc-iconInfo-big">
                    <div class="pc-infoIcon--icon">
                        <IconLocatario />
                    </div>
                    <p>Novo Associado<span>Cadastra novo associado</span></p>
                </div>
                <div class="pc-btnAtalho--seta">
                    <IconSetaRight />
                </div>
            </a>

            <div v-if="listaCadastros">
                <h5 class="pc-title-atalhos">Lista de Cadastrados</h5>
                <a
                    v-for="associacao in listaCadastros.associacoes"
                    :key="associacao.idassociacao"
                    class="pc-bloco pc-btnAtalho pc-btnAtalho--small"
                    :class="{
                        active: idAssociacaoInterno == associacao.idassociacao,
                    }"
                    @click="escolherAssociacao(associacao.idassociacao)"
                >
                    <div class="pc-infoIcon pc-iconInfo-big">
                        <p>
                            {{ associacao.nome_associado }}
                            <span>{{ associacao.tipo_associacao.nome }}</span>
                        </p>
                    </div>
                    <div class="pc-btnAtalho--seta">
                        <IconSetaRight />
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { retornarAssociacoes } from "@/services/CadastrosService.js";

export default {
    props: {
        idAssociacao: { type: Number, default: null },
    },

    data() {
        return {
            listaCadastros: null,
            idAssociacaoInterno: null,
        };
    },

    watch: {
        idAssociacao(newValeu) {
            this.idAssociacaoInterno = newValeu;
            return newValeu;
        },
    },

    beforeMount() {
        this.idAssociacaoInterno = this.idAssociacao;
        retornarAssociacoes()
            .then((response) => {
                this.listaCadastros = response.data;
            })
            .catch((error) => {});
    },

    methods: {
        escolherAssociacao(novaIdAssociacao) {
            if (this.idAssociacaoInterno != novaIdAssociacao) {
                this.$emit("update:idAssociacao", novaIdAssociacao);
                this.idAssociacaoInterno = novaIdAssociacao;
            }
        },
    },
};
</script>