<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina titulo="Excluir Conta" subtitulo="Excluir conta do usuário" />
        <div class="vld-parent">
            <Aviso
                :exibir="alerta.exibir"
                :mensagem="alerta.mensagem"
                :tipo="alerta.tipo"
            />

            <div class="row" @error="avisoErro">
                <div class="col-12 col-xl-8">
                    <div class="pc-bloco">
                        <div class="pc-titleBloco">
                          <div>
                            <h3>Confirme com sua senha</h3>
                          </div>
                        </div>
                        <div class="pc-alert pc-warning closeButton" >
                            <p>O acesso a este sistema será excluído e infelizmente você não poderá mais acessar o Painel do cliente.
                                <br>
                                <br>
                                Caso queira prosseguir, por favor, insira a sua senha atual abaixo e clique no botão "Confirmar". Se você não deseja excluir o acesso, basta Ir para página inicial.
                                <br>
                                <br>
                            Para mais informações, por favor, entre em contato com a incorporadora.</p>
                        </div>
                        <LoadingComponent :isLoading="isLoading" />
                        <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(excluirDados)" class="center-block">
                            <div class="col-lg-6 col-12">
                                <div class="form-group">
                                  <ValidationProvider rules="required" v-slot="{ errors }">
                                    <label for="senha"><b>* Senha atual</b></label>
                                    <input
                                      v-model="senha"
                                      type="password"
                                      class="form-control"
                                      placeholder="Senha Atual"
                                      id="senha"
                                      name="senha"
                                      required="true"
                                    />
                                    <label class="error"
                                      ><span>{{ errors[0] }}</span></label
                                    >
                                  </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 btnEnviar">
                                  <button
                                    onclick="excluirDados()"
                                    name="btSend"
                                    type="submit"
                                    value="Alterar"
                                    class="btn-form pc-btnBox pc-btnBox-secondary"
                                  >
                                    Confirmar
                                  </button>
                                </div>
                              </div>
                        </form>
                    </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Aviso from "@/components/Aviso";
import TituloPagina from "@/components/TituloPagina";
import LoadingComponent from "@/components/LoadingComponent";
import { excluirDadosPessoa } from "@/services/PessoasService";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        TituloPagina,
        BreadCrumbs,
        Aviso,
        LoadingComponent
    },
    computed: {
    ...mapActions(["getMe"]),
    },
    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Dados', 'Meus Dados', 'Formulário', true, false)
        );
    },

    beforeMount() {
        this.isLoading = true;
    },

    mounted() {
        this.isLoading = false;
    },

    data() {
        return {
            alerta: {
                exibir: false,
                mensagem: "",
                tipo: "sucesso",
            },
            caminho: [
                {
                    texto: "Meus Dados",
                    ativo: true,
                    rota: null,
                },
            ],
            chaveRefresh: 0,
            senha: null,
            isLoading: false
        };
    },

    methods: {
        exibirAviso(alerta) {
            this.alerta = alerta;
        },

        ...mapActions(["logout"]),

        sairPainel() {
        this.logout();
        location.reload();
        },
        
        async excluirDados() {
            this.isLoading = true;
            await excluirDadosPessoa(this.senha)
                .then((response) => {

                this.exibirAviso({
                    exibir: true,
                    mensagem: "Seus dados foram removidos com sucesso",
                    tipo: "sucesso",
                });
                console.log("Usuário desativado");
                this.sairPainel();
                })
                .catch((error) => {
                    this.avisoErro(error);
                })
                .finally(() => {
                    this.isLoading = false;
            });
        },
        avisoErro(error) {
            let mensagem = "Erro interno, por favor tentar mais tarde.";
            if (error.response.status != 422) {
                mensagem = error.response.data.error;
            }
            this.exibirAviso({
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            });
        },
    }
};
</script>
