import EsqueceuSenhaEmailSindico from "@/pages/inicio/EsqueceuSenhaEmailSindico";
import EsqueceuSenhaSmsSindico from "@/pages/inicio/EsqueceuSenhaSmsSindico";
import store from '@/store';
import LoginSindico from "@/pages/inicio/LoginSindico.vue";

const especifico = store.state.configuracoes.configuracoes.especifico_cv;
const exibir_acesso_sindico = store.state.configuracoes.configuracoes.tela_login.exibir_acesso_sindico;
let rotas = [];


rotas.push(
    {
        path: "/cliente/:slug?/autenticacao/sindico",
        name: "loginSindico",
        component: LoginSindico,
        meta: {
            title: "Login Síndico",
        }
    }
);

if (especifico.esqueci_senha_painel_cliente) {
    rotas.push({
        path: "/cliente/:slug?/esqueceu-senha-sindico-sindico",
        component: EsqueceuSenhaSmsSindico,
        name: "esqueceu-senha-sms-sindico",
        meta: {
            title: "Esqueci a senha - SMS",
        },
    });

    if (especifico.modo_esqueci_senha_painel_cliente === 'email' || especifico.modo_esqueci_senha_painel_cliente === 'ambos') {
        rotas.push({
            path: "/cliente/:slug?/esqueceu-senha-email-sindico",
            name: "esqueceu-senha-email-sindico",
            component: EsqueceuSenhaEmailSindico,
            meta: {
                title: "Esqueci a senha - Email",
            },
        });
    }
}

export default {
    rotas
}
