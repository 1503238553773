<template>
    <div>
        <div class="pc-bloco mb-20" v-if="unidade">
            <div class="pc-resumoEmp">
                <div class="pc-resumoEmp--info">
                    <h2>
                        <span>Empreendimento</span
                        >{{ unidade.empreendimento.nome }}
                    </h2>
                    <ul>
                        <li class="pc-infoIcon" v-if="unidade.bloco.nome">
                            <div class="pc-infoIcon--icon">
                                <IconPredio />
                            </div>
                            <p><span>Bloco</span>{{ unidade.bloco.nome }}</p>
                        </li>
                        <li class="pc-infoIcon" v-if="unidade.nome">
                            <div class="pc-infoIcon--icon">
                                <IconHouse />
                            </div>
                            <p><span>Unidade</span>{{ unidade.nome }}</p>
                        </li>
                        <li
                            class="pc-infoIcon"
                            v-if="configuracoes.exibir_menu.previsao_entrega && unidade.previsao_entrega"
                        >
                            <div class="pc-infoIcon--icon">
                                <IconCalendar />
                            </div>
                            <p>
                                <span>Previsão de Entrega</span>
                                {{
                                    unidade.previsao_entrega
                                        | dateParse("YYYY-MM-DD")
                                        | dateFormat("MMM/YYYY")
                                }}
                            </p>
                        </li>
                    </ul>
                </div>
                <!-- <button class="btn-open-caixa" @click="handleClickSidebarCaixa">
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6226 23.9999H17.0553L10.9844 12.5723H21.5516L27.6226 23.9999Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3334 0H22.7662L11.8574 11.4464H22.4247L33.3334 0Z" fill="#EF7D1B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.71094 0H16.2782L22.3491 11.4464H11.7819L5.71094 0Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 23.9999H10.5862L21.476 12.5723H10.9087L0 23.9999Z" fill="#EF7D1B"/>
                    </svg>
                    <span>Iniciar proposta Online</span>
                </button> -->
            </div>
        </div>
        <!-- <SidebarCaixa :sidebarCaixa="mostrarSidebarBarCaixa" @hide-sidebar="hideSidebar" /> -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import SidebarCaixa from "@/components/SidebarCaixa";

export default {
    computed: {
        ...mapState({
            unidade: (state) => state.unidades.unidade,
            configuracoes: (state) => state.configuracoes.configuracoes,
        }),
    },

    components: {
        SidebarCaixa,
    },

    data() {
        return {
            mostrarSidebarBarCaixa: false
        }
    },

    methods: {
        handleClickSidebarCaixa() {
            this.scrollBody();
            this.toggleSidebarCaixa();
        },
        scrollBody() {
            const body = document.body;
            if (body.style.overflow === 'hidden') {
                body.style.overflow = 'auto';
            } else {
                body.style.overflow = 'hidden';
            }
        },
        toggleSidebarCaixa() {
            this.mostrarSidebarBarCaixa = !this.mostrarSidebarBarCaixa
        },
        hideSidebar() {
            this.mostrarSidebarBarCaixa = false;
            this.scrollBody();
        }
    },

    watch: {
        unidade(newValue) {
            return newValue;
        },
    },
};
</script>


