import MeusCadastros from "@/pages/cadastros/MeusCadastros";
import store from '@/store';

const sindico = store.state.login.acesso_sindico;
let rotas = [];

if (store.getters.mostrarMenu("exibir_cadastros") && !sindico) {
    rotas.push(
        {
            path: '/cliente/meus-cadastros',
            component: MeusCadastros,
            name: 'meus-cadastros',
            meta: {
                title: 'Portal do Cliente - Meus Cadastros'
            }
        },
    );
}

export default {
    rotas
}
