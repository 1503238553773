<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <h3>Documentos</h3>
      <div class="pull-right pc-title-legenda" v-if="listagemDocumentos">
        Informações encontradas: {{ listagemDocumentos.total }}
      </div>
    <div class="pc-selectAno" v-if="acessoSindico">
      <select @change="trocouEmpreendimento" name="" id="">
        <option value="">Selecione por Empreendimento</option>
        <option :value="empreendimento" v-for="empreendimento in empreendimentos">
          {{ empreendimento }}
        </option>
      </select>
    </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-tabela" v-if="listagemDocumentos">
      <div class="pc-tabela--row pc-tabela--head">
        <div class="pc-tabela--td pc-f2">
          <p>Título</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Tipo do Arquivo</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Data de Criação</p>
        </div>
        <div class="pc-tabela--td pc-f2" v-if="acessoSindico">
          <p>Empreendimento</p>
        </div>
        <div class="pc-tabela--td pc-f1"></div>
      </div>
      <div id="documentos">
        <div
          v-for="documento in listagemDocumentos.documentos"
          v-bind:key="documento.idContrato"
          class="pc-tabela--row linha"
        >
          <div class="pc-tabela--td pc-f2">
            <p data-title="Título">
              {{ documento.titulo }}
              <a
                v-if="acessoSindico && documento.descricao"
                toggle="tooltip"
                placement="bottom"
                :title="'Descrição:\n' + documento.descricao"
              >
                <IconQuestion />
              </a>
            </p>
          </div>
          <div class="pc-tabela--td pc-f1">
            <p data-title="Tipo do Arquivo">
              {{ documento.arquivo.nome }}
            </p>
          </div>
          <div class="pc-tabela--td pc-f1">
            <p data-title="Data de Criação">
              {{
                documento.data_criacao
                  | dateParse("YYYY-MM-DD HH:mm")
                  | dateFormat("DD/MM/YY")
              }}
            </p>
          </div>
          <div class="pc-tabela--td pc-f2" v-if="acessoSindico">
            <p data-title="Empreendimento">
              {{ documento.empreendimento }}
              <a
                v-if="acessoSindico && documento.unidades"
                toggle="tooltip"
                placement="bottom"
                :title="'Unidades:\n' + documento.unidades"
              >
                <IconQuestion />
              </a>
            </p>
          </div>
          <div class="pc-tabela--td pc-f1 justify-content-center">
            <a
              @click="
                trakingMixpanel(
                  'Documentos do Empreendimento',
                  'Baixar Documento',
                  true,
                  true
                )
              "
              v-if="documento.arquivo"
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
              target="_blank"
              :href="documento.arquivo.url"
            >
              Baixar Documento
            </a>
          </div>
        </div>
      </div>
      <PaginateInput
        :total="listagemDocumentos.total"
        :limit="paginacao.limit"
        :offset="paginacao.offset"
        @paginacao="montarListagem"
      />
    </div>
    <div v-else>Nenhuma informação encontrada.</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getDocumentosEmpreendimento,
  getDocumentosEmpreendimentos,
} from "@/services/DocumentosService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";
import {retornarEmpreendimentos} from "@/services/EmpreendimentoService";
import SelectInput from "@/components/SelectInput.vue";

export default {
  computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico",
    }),
  },

  props: {
    idEmpreendimento: { type: Number, required: false },
  },

  emits: ["error"],

  components: {
    SelectInput,
    LoadingComponent,
    PaginateInput,
  },

  data() {
    return {
      listagemDocumentos: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: false,
      empreendimentoSelected: '',
      empreendimentos: [],
    };
  },

  beforeMount() {
    if (this.idEmpreendimento || this.acessoSindico) {
      this.montarListagem(1);
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      if (!this.acessoSindico) {
        getDocumentosEmpreendimento(
          (pagina - 1) * this.paginacao.limit,
          this.paginacao.limit,
          this.idEmpreendimento
        )
          .then((response) => {
            response.data.documentos = response.data.documentos.filter(function(
              linha
            ) {
              if (linha.arquivo.nome.length >= 14) {
                linha.arquivo.nome =
                  linha.arquivo.nome.substring(0, 7) +
                  "..." +
                  linha.arquivo.nome.substring(
                    linha.arquivo.nome.length - 6,
                    linha.arquivo.nome.length
                  );
              }
              return linha;
            });
            this.listagemDocumentos = response.data;
            this.listagemDocumentos.documentos.forEach((element) => {
              if (element.empreendimento && !this.empreendimentos.includes(element.empreendimento)) {
                this.empreendimentos.push({
                  nome: element.empreendimento
                });
              }
            });
          })
          .catch((error) => {
            if (error.response.status != 404) {
              this.$emit("error", error.response.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        getDocumentosEmpreendimentos(
          (pagina - 1) * this.paginacao.limit,
          this.paginacao.limit
        )
          .then((response) => {
            response.data.documentos = response.data.documentos.filter(function(
              linha
            ) {
              if (linha.arquivo.nome.length >= 14) {
                linha.arquivo.nome =
                  linha.arquivo.nome.substring(0, 7) +
                  "..." +
                  linha.arquivo.nome.substring(
                    linha.arquivo.nome.length - 6,
                    linha.arquivo.nome.length
                  );
              }
              return linha;
            });
            this.listagemDocumentos = response.data;
            this.listagemDocumentos.documentos.forEach((element) => {
              if (element.empreendimento && !this.empreendimentos.includes(element.empreendimento)) {
                this.empreendimentos.push(
                  element.empreendimento
                );
              }
            });
          })
          .catch((error) => {
            if (error.response.status != 404) {
              this.$emit("error", error.response.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    trocouEmpreendimento(event) {
        var filter = event.target.value.toUpperCase();
        var documentos = document.getElementById("documentos").getElementsByClassName("linha");

        for (var i = 0; i < documentos.length; i++) {
          var tdValor = documentos[i].getElementsByTagName("p")[3];
          if (tdValor) {
            var valor = tdValor.textContent || tdValor.innerText;

            if (valor.toUpperCase().indexOf(filter) > -1) {
              documentos[i].style.display = "";
            } else {
              documentos[i].style.display = "none";
            }
          }
        }
    },
    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Documentos", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
<style>
  .select-empreendimentos {
    width: 200px;
  }
</style>
