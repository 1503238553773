import axios from "axios";
import store from '../store';

export function getPreCadastroPendentesDocumento() {
    return axios.get('/precadastros-pendentes', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
