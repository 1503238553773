<template>
    <div class="pc-bloco">
        <div class="pc-titleBloco">
            <div>
                <h3>Andamento da Obra</h3>
            </div>
        </div>

        <LoadingComponent :isLoading="isLoading" />

        <div class="pc-grafico mb-20">
            <div class="pc-grafico--info">
                <p v-if="porcentagemTotalEstagioObra">
                    {{ porcentagemTotalEstagioObra }}%
                    <span>Status Geral</span>
                </p>
                <p v-else>
                    <span>Nenhuma informação encontrada.</span>
                </p>
            </div>
            <div class="pc-grafico--svgs">
                <svg
                    preserveAspectRatio="none"
                    class="pc-graficoPorcentagem"
                    width="360"
                    height="360"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        class="pc-graficoPorcentagem--circle"
                        r="140"
                        cy="0"
                        cx="176"
                        stroke-width="40"
                        stroke=""
                        fill="none"
                        v-bind:style="{
                            strokeDashoffset:
                                (100 - porcentagemTotalEstagioObra) * 4.4 +
                                'px',
                        }"
                    ></circle>
                </svg>
                <svg
                    preserveAspectRatio="none"
                    class="pc-graficoMask"
                    width="360"
                    height="360"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        class="pc-graficoMask--circle"
                        r="140"
                        cy="0"
                        cx="176"
                        stroke-width="40"
                        stroke="white"
                        fill="none"
                        style="stroke-dashoffset: 360"
                    ></circle>
                </svg>
                <svg
                    preserveAspectRatio="none"
                    class="pc-graficoBg"
                    width="360"
                    height="360"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        class="pc-graficoBg--circle"
                        r="140"
                        cy="0"
                        cx="176"
                        stroke-width="40"
                        stroke=""
                        fill="none"
                        style="stroke-dashoffset: 360"
                    ></circle>
                </svg>
            </div>
        </div>
        <div class="pc-statusObra">
            <div class="pc-titleBloco">
                <div>
                    <h3>Status da Obra</h3>
                </div>
            </div>
            <div v-if="andamentoObra" :key="componentKeyStatus">
                <div
                    class="pc-statusObra--item"
                    v-for="estagio in this.andamentoObra.etapas"
                >
                    <div class="pc-statusObra--item-labels">
                        <p>{{ estagio.nome }}</p>
                        <p>{{ estagio.porcentagem }}%</p>
                    </div>
                    <div class="pc-statusObra--item-grafico">
                        <div
                            class="pc-statusObra--item-porcentagel"
                            :style="'width: ' + estagio.porcentagem + '%'"
                        ></div>
                        <div class="pc-statusObra--item-bar"></div>
                    </div>
                </div>
            </div>
            <div v-else>Nenhuma informação encontrada.</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import { retornarAndamentoObra } from "@/services/EmpreendimentoService";

export default {
    props: {
        porcentagemTotalEstagioObra: { type: Number },
        idEmpreendimento: { type: Number, required: true },
        idUnidade: { type: Number, required: true },
    },
    computed: {
        ...mapState({
            unidade: state => state.unidades.unidade,
        }),
    },
    watch: {
        unidade() {
            this.retornarDadosAndamento();
            this.componentKeyStatus += 1;
        },
        andamentoObra(newValue) {
            return newValue;
        },
    },
    emits: ["error"],

    components: {
        LoadingComponent,
    },
    data() {
        return {
            isLoading: false,
            andamentoObra: null,
            componentKeyStatus: 0,
        };
    },
    beforeMount() {
        this.isLoading = true;
        this.retornarDadosAndamento();
        if (this.porcentagemTotalEstagioObra) {
            this.isLoading = false;
        }
    },
    

    methods: {
        retornarDadosAndamento() {
            retornarAndamentoObra(this.idEmpreendimento, this.idUnidade)
                .then((response) => {
                    this.porcentagemTotalEstagioObra = response.data["porcentagem_total"];
                    delete response.data["porcentagem_total"];
                    this.andamentoObra = response.data;
                })
                .catch((error) => {
                    if (error.response.status != 404) {
                        this.$emit("error", error.response.data);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
