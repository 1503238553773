<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<h3>Contratos</h3>
			<div class="pull-right pc-title-legenda" v-if="listagemContratos">
				Informações encontradas: {{ listagemContratos.total }}
			</div>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-tabela" v-if="listagemContratos">
			<div class="pc-tabela--row pc-tabela--head">
				<div class="pc-tabela--td pc-f2">
					<p>Titulo</p>
				</div>
				<div class="pc-tabela--td pc-f2">
					<p>Empreendimento</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Bloco</p>
				</div>
                <div class="pc-tabela--td pc-f1">
					<p>Unidade</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Tipo de Contrato</p>
				</div>
				<div class="pc-tabela--td pc-f1"></div>
			</div>
			<div
				v-for="contrato in listagemContratos.contratos"
				v-bind:key="contrato.idcontrato"
				class="pc-tabela--row"
			>
				<div class="pc-tabela--td pc-f2">
					<div class="pc-tabela--td-contentIcon">
						<div
							class="pc-tabela--td-contentIcon-icon pc-tabela--td-contentIcon-pdf"
						>
							<span>PDF</span>
						</div>
						<p data-title="Titulo">
							{{ contrato.titulo }}
						</p>
					</div>
				</div>
				<div class="pc-tabela--td pc-f2">
					<p data-title="Empreendimento">
						{{ contrato.unidade.empreendimento.nome }}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Bloco">
						{{ contrato.unidade.bloco.nome }}
					</p>
				</div>
                <div class="pc-tabela--td pc-f1">
					<p data-title="Unidade">
						{{ contrato.unidade.nome }}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Tipo de Contrato">
						{{ contrato.tipo }}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1 justify-content-center">
					<a
						v-if="contrato.arquivo"
						class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
						:href="contrato.arquivo.url"
						target="_blank"
						v-on:click="
							trakingMixpanel(
								'Meus Contratos',
								'Baixar contrato',
								true,
								true
							)
						"
					>
						Baixar Contrato
					</a>
				</div>
			</div>
			<div class="text-center">
				<div class="pb-0 pt-3">
					<vue-paginate-al
						:totalPage="
							Math.ceil(listagemContratos.total / paginacao.limit)
						"
						:current-page="
							Math.round(paginacao.offset / paginacao.limit) + 1
						"
						:myData="'listagemContratos.contratos'"
						:withNextPrev="false"
						:customActiveBGColor="configuracoes.cor.primaria"
						@btnClick="montarListagem"
					>
					</vue-paginate-al>
				</div>
			</div>
		</div>
		<div v-else>Nenhuma informação encontrada.</div>
	</div>
</template>

<script>
import { getContratos } from "@/services/ContratosService";
import LoadingComponent from "@/components/LoadingComponent";
import { mapState } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	props: {
		idUnidade: { type: Number },
	},

	emits: ["error"],

	computed: {
		...mapState({
			configuracoes: (state) => state.configuracoes.configuracoes,
		}),
	},

	components: {
		LoadingComponent,
	},

	data() {
		return {
			listagemContratos: null,
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
		};
	},

	beforeMount() {
		this.montarListagem(1);
	},

	methods: {
		montarListagem(pagina) {
			this.isLoading = true;
			getContratos(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit,
				this.idUnidade
			)
				.then((response) => {
					this.listagemContratos = response.data;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Meus Contratos",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>
