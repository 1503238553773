<template>
    <div>
        <PesquisaBar v-if="pesquisabar" />
        <div class="body-wrapper " :class="{ down: pesquisabar }">
            <PaginaTopo />
            <div class="pc-overlayMenuXs"></div>
            <div class="pc-wrapper container-fluid">
                <PaginaMenu />
                <div class="pc-rightColumn">
                    <ModalSemUnidades />
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaginaTopo from "./_partials/PaginaTopo.vue";
import PaginaMenu from "./_partials/PaginaMenu.vue";
import ModalSemUnidades from "./_partials/ModalSemUnidades.vue";
import PesquisaBar from "../pages/home/components/PesquisaBar.vue";

export default {
    props: [ 'handlePesquisaBarProps' ],
    components: {
        PaginaTopo,
        PaginaMenu,
        ModalSemUnidades,
        PesquisaBar
    },
    data() {
        return {
            pesquisabar: false,
        }
    },
    mounted() {
        this.$root.$on('pesquisaStatus', () => {
            this.pesquisabar = !this.pesquisabar;
        })
    }
};
</script>