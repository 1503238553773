<template>
    <div>
        <div class="text-center pc-login-logo">
            <div v-if="configuracoes.logo">
                <img
                    :src="
                        configuracoes.logo.url
                            .replace('[[LARGURA]]', 'x')
                            .replace('[[ALTURA]]', 'x')
                    "
                    alt=""
                />
            </div>
            <div v-else>
                <img
                    :src="baseUrl + '/especifico/img/logo_empresa.png'"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { URL_API } from "@/bootstrap/index";

export default {
    name: "LogoTopoInicio",
    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
    },

    data() {
        return {
            baseUrl: URL_API,
        };
    },
};
</script>

<style>
.pc-login-logo {
  margin-bottom: 40px;
}
.pc-login-logo img {
  max-width: 200px !important;
}
</style>