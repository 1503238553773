import MinhasUnidades from '@/pages/unidades/MinhasUnidades'
import store from '@/store';

const sindico = store.state.login.acesso_sindico;
let rotas = [];

if (store.state.unidades.unidades && store.state.unidades.unidades.length > 0 && !sindico) {
    rotas.push(
        {
            path: '/cliente/minhas-unidades',
            component: MinhasUnidades,
            name: 'unidades',
            meta: {
                title: 'Portal do Cliente - Minhas Unidades'
            }
        },
    );
}

export default {
    rotas
}
