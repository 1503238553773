<template>
    <div class="titulo-esqueceu-senha">
        <h2>Esqueceu a senha?</h2>
        <h1>{{ this.titulo }}</h1>
    </div>
</template>

<script>
export default {
    name: "TituloEsqueceuSenha",
    props: {
        titulo: { type: String, required: true },
    },
}
</script>
