import axios from "axios";
import store from "../store";

export function getBanner(lugarExibicao) {
  let empreendimento = "";
  if (store.state.configuracoes.empreendimento) {
    empreendimento = `/${store.state.configuracoes.empreendimento}`;
  }

  return axios.get(
    `/banners/${lugarExibicao}${empreendimento}`,
    {
      headers: {
        Authorization: `Bearer ${store.state.login.access_token}`,
      },
      params: {
        slug: store.state.configuracoes.slug,
      },
    }
  );
}
