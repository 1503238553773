
export default {
    resetarRedirecionar({ commit }) {
        commit('PADRAO_REDIRECIONAR');
    },

    resetarSessao({ commit }) {
        commit('RESETAR_SESSAO');
    },

    homeVisualizada({ commit }) {
        commit('HOME_VISUALIZADA');
    },

    setarMensagemLogin({ commit }, mensagem) {
        commit('MENSAGEM_LOGIN', mensagem);
    },

    limparMensagemLogin({ commit }) {
        commit('MENSAGEM_LOGIN', null);
    },
}
