import axios from "axios";
import store from '../store';

export function getAtendimentos(
    offset, 
    limit, 
    idUnidade = null,
    {
        protocolo = null,
        cadastro = null,
        idempreendimento = null,
        idbloco = null,
        idunidade = null,
        idassunto = null,
        idsubassunto = null,
        idsituacao = null,
    }
    ) {
    return axios.get('/atendimentos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
            idUnidade: idUnidade,
            protocolo: encodeURIComponent(protocolo || ''),
            data_cad: cadastro,
            idempreendimento: idempreendimento,
            idbloco: idbloco,
            idassunto: idassunto,
            idsubassunto: idsubassunto,
            idunidade: idunidade,
            idsituacao: idsituacao,
        }
    });
}

export function retornarAreaComum(idEmpreendimento = null) {
    return axios.get(`/atendimento/area-comum`, {
        headers: {
            Authorization: `Bearer ${store.state.login.access_token}`,
        },
        params: {
            idEmpreendimento: idEmpreendimento,
        },
    });
}

export function getAtendimento(idAtendimento) {
    return axios.get('/atendimentos/' + idAtendimento, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function avaliar(idAtendimento, nota, comentario) {
    return axios.post(`/atendimentos/${idAtendimento}/avaliar`, {
        nota: nota,
        comentario: comentario
    }
    , {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function responder(idAtendimento, data) {
    return axios.post(`/atendimentos/${idAtendimento}/responder`, data, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function cadastrarAtendimento(dados) {
    return axios.post('/atendimentos', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function uploadArquivos(dados) {
    return axios.post('/atendimento/upload-arquivos', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`,
            'Content-Type': 'multipart/form-data'
        },
    });
}

export function getSituacoes() {
    return axios.get(`/atendimentos/situacoes`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}