<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <h3>Boletos</h3>
      <div class="pull-right pc-title-legenda" v-if="listagemBoletos">
        Informações encontradas: {{ listagemBoletos.total }}
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-tabela-overflow">
    <div class="pc-tabela" v-if="listagemBoletos">
      <div class="pc-tabela--row pc-tabela--head">
        <div class="pc-tabela--td pc-f1">
          <p>Vencimento</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Número do Título</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Tipo</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Valor</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Situação</p>
        </div>
        <div class="pc-tabela--td pc-f2"></div>
      </div>
      <div
        v-for="boleto in listagemBoletos.boletos"
        class="pc-tabela--row"
      >
        <div class="pc-tabela--td pc-f1">
          <p data-title="Vencimento">
            {{
              boleto.vencimento
                | dateParse("YYYY-MM-DD HH:II:SS")
                | dateFormat("DD/MM/YYYY")
            }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Número do Título">
            {{
              boleto.titulo
            }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Tipo">
            {{ boleto.tipo }}
          </p>
        </div>
        <div
          class="pc-tabela--td pc-f1"
        >
          <p data-title="Valor">
            {{ boleto.valor }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Situação">
            {{ boleto.situacao.nome }}
          </p>
        </div>
        <div
          class="pc-tabela--td pc-tabela--td-icons pc-f2 justify-content-center"
        >
          <div v-if="boleto.situacao.sigla != 'PG' && boleto.url_boleto">
            <a
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
              :href="boleto.url_boleto"
              target="_blank"
              >Baixar Boleto</a
            >
            <a
              @click="
                copiarLink(boleto.linha_digitavel)
              "
              toggle="tooltip"
              placement="bottom"
              title="Copiar Código"
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary pc-btnGhost-iconOnly pc-btnCopy"
            >
              <IconCopy />
            </a>
          </div>
        </div>
      </div>
      <PaginateInput
        :total="listagemBoletos.total"
        :limit="paginacao.limit"
        :offset="paginacao.offset"
        @paginacao="montarListagem"
      />
    </div>
    <div v-else>Nenhuma informação encontrada.</div>
    </div>
  </div>
</template>

<style>
@media (max-width: 768px) {
  .pc-tabela-overflow .pc-tabela {
    min-width: 100%;
  }
}
</style>

<script>
import {
  getBoletosSegundaViaCV,
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  props: {
    idUnidade: { type: Number },
  },

  emits: ["error"],

  components: {
    LoadingComponent,
    PaginateInput,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
    }),
  },

  data() {
    return {
      listagemBoletos: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: false,
    };
  },

  beforeMount() {
    if (this.idUnidade) {
      this.montarListagem(1);
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      getBoletosSegundaViaCV(
        (pagina - 1) * this.paginacao.limit,
        this.paginacao.limit,
        this.idUnidade
      )
        .then((response) => {
          this.listagemBoletos = response.data;
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.$emit("error", error.response.data);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    copiarLink(linhaDigitavel) {
      this.trakingMixpanel("Segunda via financeira", "Copiar link", true, true);
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = linhaDigitavel;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.$vToastify.success("", "Copiado!");
    },
    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
