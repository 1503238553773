<template>
    <div>
        <div v-if="verificacaoFinalizada && multiplosPortais">
            <div class="titulo-login">
                <h2>Seja Bem-vindo</h2>
                <h1>Selecione o portal que deseja acessar</h1>
            </div>
            <form>
                <div class="form-group">
                    <SelectInput
                        titulo="Portais"
                        :showTitle="false"
                        :options="portais"
                        :reduce="(option) => option.slug"
                        :clearable="false"
                        placeholder="Selecione o portal que deseja acessar"
                        label="slug"
                        @update:valor="selecionarPortal"
                    />
                </div>
            </form>
        </div>

        <LoadingComponent :isLoading="!this.verificacaoFinalizada" :isFullPage="true"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getMultiplosPortais} from "@/services/ConfiguracoesService";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SelectInput from "@/components/SelectInput.vue";
import {selectSlug} from "@/plugins/Slugs";

export default {
    name: "MultiplosPortais",

    components: {SelectInput, LoadingComponent},

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes"
        }),
    },

    data() {
        return {
            slug: this.$store.state.configuracoes.slug,
            verificacaoFinalizada: false,
            multiplosPortais: false,
            portais: [],
        };
    },

    mounted() {
        getMultiplosPortais()
            .then((response) => {
                this.multiplosPortais = true;
                this.portais = response.data;
                this.verificacaoFinalizada = true;
            })
            .catch((error) => {
                this.$vToastify.error(error.response.data.error, 'Opps');
            });
    },

    methods: {
        selectSlug,

        selecionarPortal(slug) {
            selectSlug(this, slug);
        }
    },
};
</script>
