import axios from "axios";
import store from '../store';

export function getAtualizacaoCadastral(idConfiguracao) {
    return axios.get('/atualizacao-cadastral/' + idConfiguracao, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function postAtualizacaoCadastral(formulario) {
    return axios.post('/atualizacao-cadastral', formulario, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function postEstouAtualizado() {
    return axios.post('/atualizacao-cadastral/estou-atualizado', null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
