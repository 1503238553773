<template>
    <div class="pc-content confirmar-conta">
        <LoadingComponent :isLoading="isLoading" />
        <div class="row">
            <div class="col-12">
                <div class="pc-bloco">
                    <LogoTopoInicio />
                    <div class="pc-titleBloco">
                        <h3>Confirmar Criação de Conta</h3>
                        <div class="pull-right">
                            <router-link
                                :to="{ name: 'login' }"
                                class="pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary"
                            >
                                Acessar Site</router-link
                            >
                        </div>
                    </div>
                    <div v-if="response">
                        <div v-if="sucesso">
                            Sua conta foi ativada com sucesso!
                        </div>
                        <div v-else>
                            <div v-if="response.data.code == 422">
                                Ocorreu um erro inesperado, por favor tente mais
                                tarde.
                            </div>
                            <div v-else>Erro: {{ response.data.error }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import { ativarContaPessoa } from "@/services/PessoasService";
import LogoTopoInicio from "@/layouts/_partials/LogoTopoInicio";
import { URL_API } from "@/bootstrap/index";
import { mapGetters } from "vuex";

export default {
    props: {
        hash: { type: String, required: true },
    },

    components: {
        LoadingComponent,
        LogoTopoInicio,
    },

    data() {
        return {
            baseUrl: URL_API,
            isLoading: true,
            response: null,
            sucesso: null,
        };
    },

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
    },

    beforeMount() {
        ativarContaPessoa(this.hash)
            .then((response) => {
                this.response = response;
                this.sucesso = true;
            })
            .catch((error) => {
                this.response = error.response;
                this.sucesso = false;
                if (error.response.status != 422) {
                    this.mensagem = error.response.data.error;
                } else {
                    this.mensagem = "";
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    },
};
</script>

<style scoped>
.confirmar-conta {
    margin-top: 8%;
    margin-right: 5%;
    margin-left: 5%;
}
</style>
