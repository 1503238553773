<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            :titulo="'Minhas Unidades'"
            :subtitulo="'Painel com informações da sua unidade'"
        />
        <div class="row" v-if="unidades">
            <CardUnidade
                v-for="unidade in unidades"
                v-bind:key="unidade.idunidade"
                :unidade="unidade"
            />
        </div>
        <div v-else class="row">
            <div class="pc-bloco">Nenhuma informação encontrada.</div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import CardUnidade from "./components/CardUnidade";
import TituloPagina from "../../components/TituloPagina";
import { mapState } from "vuex";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";

export default {
    created () {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Minhas Unidades', 'Minhas Unidades', 'Listagem', true, false)
        );
    },

    computed: {
        ...mapState({
            unidades: (state) => state.unidades.unidades,
        }),
    },

    components: {
        TituloPagina,
        BreadCrumbs,
        CardUnidade,
    },

    data() {
        return {
            caminho: [
                {
                    texto: "Minhas Unidades",
                    ativo: true,
                    rota: null,
                },
            ],
        };
    },
};
</script>
