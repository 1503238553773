import Vue from "vue";
import moment from "moment/moment";

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function onlyNumber($event) {
    let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
    }
}

function validarEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function validarCPF(cpf) {
    cpf = cpf.replace(/[\s.-]*/igm, '');
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false;
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) {
        return false;
    }
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) {
        return false;
    }
    return true;
}

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

function validarDocumento(documento) {
    documento = documento.replace(/\.|\-/g, '');
    if (documento.length == 11) {
        return validarCPF(documento);
    }
    return validarCNPJ(documento);
}

function validarData(data) {
    var dia = data.substring(0, 2)
    var mes = data.substring(3, 5)
    var ano = data.substring(6, 10)

    //Criando um objeto Date usando os valores ano, mes e dia.
    var novaData = new Date(ano, (mes - 1), dia);

    var mesmoDia = parseInt(dia, 10) == parseInt(novaData.getDate());
    var mesmoMes = parseInt(mes, 10) == parseInt(novaData.getMonth()) + 1;
    var mesmoAno = parseInt(ano) == parseInt(novaData.getFullYear());

    if (!((mesmoDia) && (mesmoMes) && (mesmoAno))) {
        return false;
    }
    return true;
}

function removerMascaraString(palavra) {
    return palavra.replace(/\D/g, '');
}

function quebraDeLinhaParaHtml(palavra) {
    return palavra.replace(/\\r\\n|\\n|\\r/g, "</br>")
}

function gerarUniqId() {
    const data = Date.now() / 1000;
    let d = data.toString(16).split(".").join("").substring(0, 13);
    while (d.length < 13) d += "0";
    return d;
}

function esconderTelefone(telefone) {
    if (!telefone) {
        return "";
    }

    let telefoneInverso = telefone.split("").reverse().join("");
    telefoneInverso = "****" + telefoneInverso.substring(4);
    return telefoneInverso.split("").reverse().join("");
}

function esconderEmail(email) {
    if (!email) {
        return "";
    }

    return email.substring(0, 3) + email.replace(/[a-zA-Z0-9]/g, "*");
}

function retornarTempAvatar(nomeCliente) {
    if (!nomeCliente) {
        return "_coringa";
    }

    let palavra = nomeCliente.substr(0, 1).toLowerCase();
    palavra = palavra.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
    var patt = new RegExp(/[0-9a-z]/);
    return patt.test(palavra) ? palavra : "_coringa";
}

function montarObjetoMixPanel(funcionalidade, origem = null) {
    let url = window.location.pathname.substr(1).split('/');
    let jsonUrl = '';
    for (let i = 0; i < url.length; i++) {
        jsonUrl += ",URL_" + i + ": '" + url[i] + "'";
    }
    let urlObj = eval('({' + jsonUrl.substr(1) + '})');

    return {
        ...urlObj,
        painel_url: 'cliente',
        painel: 'Cliente',
        funcionalidade_url : url[1],
        funcionalidade : funcionalidade,
        origem: origem,
        url: window.location.href
    }
}

function objetoTraking(funcionalidade, acao, subacao, intencao, alteracao, origem = null) {
    return {
        ...montarObjetoMixPanel(funcionalidade, origem),
        acao: acao,
        subacao: subacao,
        intencao: intencao,
        alteracao: alteracao,
    }
}

function mixPanelLogin(idLogin, tipoLogin, response) {
     let host = window.location.host;
     let clienteCV = host.replace('.cvcrm.com.br', '').replace('.construtordevendas.com.br', '');

     if (clienteCV.includes('localhost')) {
         clienteCV = 'localhost';
     }

     let url = window.location.pathname.substr(1).split('/');
     let jsonUrl = '';

     for (let i = 0; i < url.length; i++) {
         jsonUrl += ",URL_" + i + ": '" + url[i] + "'";
     }

     let objUrl = eval('({' + jsonUrl.substr(1) + '})');
     let mixPanelConfig = {
         painel_url: 'cliente',
         painel: 'Cliente',
     };

     let cliente_id = clienteCV + '_' + tipoLogin + '_' + idLogin;

     Vue.prototype.$mixpanel.identify(cliente_id)

     let mixPanelPeopleConfig = {
         user_id: cliente_id,
         $distinct_id: cliente_id,
         $email: response.data.email,
         $name: response.data.nome,
         data_cadastro: response.data.data_cad,
         cliente: clienteCV,
         painel_url: 'cliente',
         painel: 'Cliente',
     };

     Vue.prototype.$mixpanel.people.set(mixPanelPeopleConfig);
     Vue.prototype.$mixpanel.set_group('cliente', clienteCV);

     mixPanelConfig = { ...objUrl, ...mixPanelConfig }
     Vue.prototype.$mixpanel.track('Login', mixPanelConfig);

 }

 function removerMascaraTelefone (telefone) {
    return  String(telefone).replace(/[^0-9+^]/g,'');
 }

function getFeriadosNacionais(ano) {
    const feriados = [];

    // Ano Novo
    feriados.push(new Date(ano, 0, 1));

    // Carnaval (dois dias antes da Quarta-feira de Cinzas)
    const dataCarnaval = new Date(ano, 1, 1);
    dataCarnaval.setDate(dataCarnaval.getDate() - dataCarnaval.getDay() - 47);
    feriados.push(dataCarnaval);
    feriados.push(new Date(dataCarnaval.getFullYear(), dataCarnaval.getMonth(), dataCarnaval.getDate() + 1));

    // Sexta-feira Santa (sempre na sexta-feira anterior ao Domingo de Páscoa)
    const dataPascoa = new Date(ano, 2, 31); // Domingo de Páscoa é em março ou abril
    const diaSemanaPascoa = dataPascoa.getDay();
    const dataSextaSanta = new Date(ano, 2, 31 - diaSemanaPascoa - 2);
    feriados.push(dataSextaSanta);

    // Tiradentes
    feriados.push(new Date(ano, 3, 21));

    // Dia do Trabalhador
    feriados.push(new Date(ano, 4, 1));

    // Corpus Christi (sempre na quinta-feira 60 dias após a Páscoa)
    const dataCorpusChristi = new Date(dataPascoa.getFullYear(), dataPascoa.getMonth(), dataPascoa.getDate() + 60);
    feriados.push(dataCorpusChristi);

    // Independência do Brasil
    feriados.push(new Date(ano, 8, 7));

    // Nossa Senhora Aparecida
    feriados.push(new Date(ano, 9, 12));

    // Dia de Finados
    feriados.push(new Date(ano, 10, 2));

    // Proclamação da República
    feriados.push(new Date(ano, 10, 15));

    // Natal
    feriados.push(new Date(ano, 11, 25));

    return feriados;
}

const data = new Date();
const feriadosNacionais = getFeriadosNacionais(data.getFullYear()).map((data)=>{
    return moment(data).format('YYYY-MM-DD').toString();
});


export {
    formatBytes,
    onlyNumber,
    validarEmail,
    validarDocumento,
    validarData,
    gerarUniqId,
    quebraDeLinhaParaHtml,
    removerMascaraString,
    esconderTelefone,
    esconderEmail,
    retornarTempAvatar,
    montarObjetoMixPanel,
    objetoTraking,
    mixPanelLogin,
    removerMascaraTelefone,
    feriadosNacionais,
};
