<template>
    <div class="pc-login-form--wrap">
        <TituloLoginSindico />
        <div class="pc-btns-Login">
            <router-link  :to="{ name: 'login' }">
                Acesse sua conta
            </router-link>
            <router-link
                v-if="configuracoes.especifico_cv.cadastro_painel_cliente"
                :to="{ name: 'cadastre-se' }"
            >
                Cadastre-se
            </router-link>            
            <router-link :class="'active'" :to="{ name: 'loginSindico' }" v-if="configuracoes.tela_login.exibir_acesso_sindico">
                Acesse como síndico 
            </router-link>
        </div>
        <form @submit.prevent="realizarLoginSindico()">
            <div v-if="sessaoExpiradaLocal" class="pc-alert pc-error">
                <p>Sessão Expirada</p>
            </div>
            <div class="form-group">
                <input
                    type="text"
                    inputmode="numeric"
                    v-mask="mask"
                    @keyup="formatarMascaraCPFCNPJ"
                    name="usuario"
                    v-model="formData.usuario"
                    :class="[
                        'form-control',
                        { 'is-invalid': errors.usuario != '' },
                    ]"
                    value=""
                    placeholder="CPF ou CNPJ"
                    required
                />
            </div>
            <div class="form-group pc-login-password">
                <input
                    :type="exibirSenha ? 'text' : 'password'"
                    name=""
                    v-model="formData.senha"
                    :class="[
                        'form-control',
                        { 'is-invalid': errors.senha != '' },
                    ]"
                    value=""
                    placeholder="Senha"
                    required
                />
                <a
                    href="#"
                    @click.prevent="showPassword()"
                    class="pc-login-showPassword"
                >
                    <div v-if="exibirSenha">
                        <IconEye></IconEye>
                    </div>
                    <div v-else>
                        <IconEyeSlash></IconEyeSlash>
                    </div>
                </a>
            </div>
            <div class="d-flex justify-content-between">
                <router-link
                    class="pc-login-btnEsqueciSenha"
                    :to="{ 
                        name: configuracoes.tela_login.padrao_reset_senha === 'sms' ? 'esqueceu-senha-sms-sindico' : 'esqueceu-senha-email-sindico',
                        params: {slug: this.$store.state.configuracoes.slug}
                    }"
                >
                    Esqueceu a senha?
                </router-link>
            </div>
            <br />
            <button
                type="submit"
                name="button"
                class="pc-btnBox pc-btnBox--fullW"
                :disabled="loading"
            >
                <span v-if="loading">Autenticando...</span>
                <span v-else>Acessar Portal</span>
            </button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TituloLoginSindico from "./components/TituloLoginSindico";

export default {
    name: "LoginSindico",

    components: { TituloLoginSindico },

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
        ...mapState({
            sessaoExpirada: (state) => state.sessao.sessaoExpirada,
        }),
    },

    data() {
        return {
            mask: '###.###.###-##',
            exibirSenha: false,
            loading: false,
            sessaoExpiradaLocal: false,
            formData: {
                usuario: "",
                senha: "",
                slug: "",
            },
            errors: {
                usuario: "",
                senha: "",
            },
            slug: this.$store.state.configuracoes.slug
        };
    },

    beforeMount() {
        this.sessaoExpiradaLocal = this.sessaoExpirada;
        this.resetarSessao();
        this.resetarRedirecionar();
        if (this.$route.params.error) {
            this.$vToastify.error(this.$route.params.error, "Opps");
        }
    },

    methods: {
        ...mapActions(["loginSindico", "resetarRedirecionar", "resetarSessao"]),

        showPassword() {
            this.exibirSenha = !this.exibirSenha;
        },

        realizarLoginSindico() {
            this.reset();
            this.loading = true;
            this.loginSindico(this.formData)
                .then((response) => {
                    this.$router.push({
                        name: "redirecionando",
                    });
                })
                .catch((error) => {
                    this.$vToastify.error(error.response.data.message, "Opps");
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        reset() {
            this.errors = {
                usuario: "",
                senha: "",
            };
        },

        formatarMascaraCPFCNPJ() {
          const numericValue = this.formData.usuario.replace(/\D/g, '');
          let newMask = '';

          if (numericValue.length <= 11) {
            newMask = '###.###.###-##?#';
          } else {
            newMask = '##.###.###/####-##';
          }

          this.mask = newMask;
        },
    },
};
</script>
