import axios from "axios";
import store from '../store';

export function retornarAssociacoes(idAssociacao = null) {
    return axios.get('/associacoes', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            idAssociacao: idAssociacao
        }
    });
}

export function retornarTiposAssociacoes() {
    return axios.get('/associacoes/tipos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function cadastrarAssociacao(dados) {
    return axios.post('/associacoes', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function cadastrarAssociacaoComPessoa(dados) {
    return axios.post('/associacoes/nova-pessoa', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function deletarAssociacao(idAssociacao) {
    return axios.delete(`/associacoes/${idAssociacao}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function atualizarAssociacao(idAssociacao, dados) {
    return axios.put(`/associacoes/${idAssociacao}`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}
