export default {
    SET_PERMISSOES(state, permissoes) {
        state.permissoes = permissoes;
    },

    SET_PERMISSOES_SINDICOS(state, permissoesSindico) {
        state.permissoesSindico = permissoesSindico;
    },

    SET_PERFIS_CLIENTE(state, perfisCliente) {
        state.perfisCliente = perfisCliente;
    },
}
