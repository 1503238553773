import FramePlugin from "@/pages/plugins/FramePlugin";
import store from "@/store";

const plugins = store.state.configuracoes.configuracoes.plugins;
const login = store.state.login;
let rotas = [];

if (plugins.length > 0 ) {
    plugins.map(function (plugin) {
        if (plugin.tipo === "iframe") {
            const caminho = plugin.nome.toLowerCase().replace(" ", "-").normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            const token = plugin.hasOwnProperty('autenticacao') ? login.access_token : '';
            rotas.push(
                {
                    path: "/cliente/plugin/" + caminho,
                    component: FramePlugin,
                    name: 'plugin-' + caminho,
                    props: {
                      url: plugin.link,
                      token: token,
                      slug: store.state.configuracoes.slug,
                      idconfiguracao:
                        store.state.configuracoes.configuracoes.idconfiguracao,
                    },
                    meta: {
                        title: "Portal do Cliente - " + plugin.nome,
                    },
                }
            );
        }
    });
}

export default {
  rotas,
};
