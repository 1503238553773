<template>
    <a
        href="javascrip:voi(0)"
        class="pesquisa-btn-fixed"
        data-toggle="modal"
        data-target="#ModalPesquisasNPS"
    >
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.9167 47.8333C37.5732 47.8333 47.8333 37.5732 47.8333 24.9167C47.8333 12.2601 37.5732 2 24.9167 2C12.2601 2 2 12.2601 2 24.9167C2 37.5732 12.2601 47.8333 24.9167 47.8333Z" fill="#F4CB5B"/>
        <path d="M21.2079 23.0245C21.1167 23.0245 21.0263 23.0066 20.9421 22.9717C20.8578 22.9368 20.7812 22.8857 20.7167 22.8212C20.6522 22.7567 20.6011 22.6801 20.5662 22.5958C20.5313 22.5116 20.5134 22.4212 20.5134 22.33C20.5134 19.6519 19.111 17.3889 17.4515 17.3889C15.7913 17.3889 14.3889 19.6519 14.3889 22.33C14.3889 22.5142 14.3157 22.6908 14.1855 22.8211C14.0553 22.9513 13.8786 23.0245 13.6944 23.0245C13.5103 23.0245 13.3336 22.9513 13.2034 22.8211C13.0732 22.6908 13 22.5142 13 22.33C13 18.8395 14.9972 16 17.4515 16C19.9058 16 21.9023 18.8395 21.9023 22.33C21.9024 22.4212 21.8844 22.5116 21.8496 22.5958C21.8147 22.6801 21.7635 22.7567 21.699 22.8212C21.6345 22.8857 21.558 22.9368 21.4737 22.9717C21.3894 23.0066 21.2991 23.0245 21.2079 23.0245ZM35.484 23.0245C35.3928 23.0245 35.3025 23.0066 35.2182 22.9717C35.1339 22.9368 35.0573 22.8857 34.9928 22.8212C34.9283 22.7567 34.8772 22.6801 34.8423 22.5958C34.8074 22.5116 34.7895 22.4212 34.7896 22.33C34.7896 19.6519 33.3871 17.3889 31.7269 17.3889C30.0675 17.3889 28.665 19.6519 28.665 22.33C28.665 22.5142 28.5918 22.6908 28.4616 22.8211C28.3314 22.9513 28.1547 23.0245 27.9706 23.0245C27.7864 23.0245 27.6098 22.9513 27.4795 22.8211C27.3493 22.6908 27.2761 22.5142 27.2761 22.33C27.2761 18.8395 29.2727 16 31.7269 16C34.1812 16 36.1784 18.8395 36.1784 22.33C36.1785 22.4212 36.1606 22.5116 36.1257 22.5958C36.0908 22.6801 36.0397 22.7567 35.9752 22.8212C35.9107 22.8857 35.8341 22.9368 35.7498 22.9717C35.6655 23.0066 35.5752 23.0245 35.484 23.0245Z" fill="#474750"/>
        <path d="M38.5651 31C38.7635 30.9991 38.9599 31.0407 39.141 31.122C39.3221 31.2033 39.4836 31.3224 39.6149 31.4713C39.7462 31.6201 39.844 31.7954 39.902 31.9852C39.9599 32.175 39.9766 32.3751 39.9509 32.5719C39.8915 32.9782 39.809 33.3809 39.7035 33.7778C38.2035 39.3195 32.3702 43.5 25.4813 43.5C18.5924 43.5 12.7591 39.3195 11.2591 33.7778C11.1537 33.3809 11.0711 32.9782 11.0117 32.5719C10.986 32.3751 11.0027 32.175 11.0606 31.9852C11.1186 31.7954 11.2165 31.6201 11.3477 31.4713C11.479 31.3224 11.6406 31.2033 11.8216 31.122C12.0027 31.0407 12.1991 30.9991 12.3976 31H38.5651Z" fill="white"/>
        </svg>
        <p>Avalie sua experiência</p>
    </a>
  </template>
  
  <script>
  import Vue from "vue";
  
  
  export default {
    components: {
      
    },
  
    computed: {
      
    },
  
    data() {
      return {
        showBar: true,
      };
    },
  
    methods: {
        handlePesquisaBar() {
           this.$root.$emit('pesquisaStatus')
        }
    },
  };
  </script>