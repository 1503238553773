import { getTermo } from '@/services/TermoAceiteService'
import { getDefaultState } from "./state";

export default {
    async getTermos({ commit }, acessarComo) {
        if (acessarComo) {
            commit('CONCLUIDO_TERMOS');
            return;
        }
        await getTermo()
            .then(response => {
                commit('SET_TERMO', response.data);
            })
            .catch(error => {
                commit('SET_TERMO', getDefaultState())
            })
            .finally(() => {
                commit('CONCLUIDO_TERMOS');
            });
    },

    resetState({ state }) {
        Object.assign(state, getDefaultState());
    },
}
