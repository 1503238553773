import { retornarPermissoes, retornarPermissoesSindico } from '@/services/PermissaoService'

export default {
    retornarPermissoes({ commit, dispatch }) {
        retornarPermissoes()
            .then(response => {
                commit('SET_PERMISSOES', response.data);
                dispatch('verificarPerfisCliente');
            })
            .catch(error => { });
    },

    verificarPerfisCliente({ commit, rootState }) {
        let perfis = {
            TR: false,
            AR: false,
            AC: false,
        };
        if (rootState.unidades.unidades) {
            rootState.unidades.unidades.map(function (unidade) {
                perfis[unidade.perfil.sigla] = true;
            });
        }
        commit('SET_PERFIS_CLIENTE', perfis);
        commit('CONCLUIDO_PERMISSOES');
    },

    retornarPermissoesSindico({ commit, dispatch }) {
        retornarPermissoesSindico()
            .then(response => {
                commit('SET_PERMISSOES_SINDICOS', response.data);
            })
            .catch(error => { });
        commit('CONCLUIDO_PERMISSOES_SINDICO');
    },
}