import Financeiro from '@/pages/financeiro/Financeiro';
import store from '@/store';
import FinanceiroCV from "@/pages/financeiro/FinanceiroCV.vue";

const modulos = store.state.configuracoes.configuracoes.modulos;
const sindico = store.state.login.acesso_sindico;
const exibirFinanceiro = store.state.configuracoes.configuracoes.exibir_financeiro;
let rotas = [];

if (
    modulos.sienge
    && !sindico
    && store.getters.mostrarMenu("exibir_financeiro")
    && (exibirFinanceiro.boletos 
    || exibirFinanceiro.extrato_financeiro 
    || exibirFinanceiro.imposto_de_renda)
) {
    rotas.push(
        {
            path: '/cliente/financeiro/:idUnidade?',
            name: 'financeiro',
            component: Financeiro,
            props: true,
            meta: {
                title: 'Portal do Cliente - Financeiro'
            }
        }
    );
}

if (
    !modulos.sienge
    && !sindico
    && store.getters.mostrarMenu("exibir_financeiro_cv")
) {
    rotas.push(
        {
            path: '/cliente/financeiro/:idUnidade?',
            name: 'financeiro',
            component: FinanceiroCV,
            props: true,
            meta: {
                title: 'Portal do Cliente - Financeiro'
            }
        }
    );
}

export default {
    rotas
}
