import axios from "axios";
import store from '../store';

export function retornarDadosPessoa() {
    return axios.get("/pessoa", {
      headers: {
        Authorization: `Bearer ${store.state.login.access_token}`,
      }
    });
}

export function putPessoa(pessoa) {
    return axios.put('/pessoa', pessoa, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function putPessoaAtendimento(pessoa) {
    return axios.put('/pessoa-atendimento', pessoa, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function putPessoaEndereco(endereco) {
    return axios.put('/pessoa/atualizar-endereco', endereco, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function cadastrarPessoa(dados) {
    return axios.post('/pessoa', dados);
}

export function resetarSenhaSms(dados) {
    return axios.post('/resetar-senha-sms', dados);
}

export function resetarSenhaEmail(dados) {
    return axios.post('/resetar-senha-email', dados);
}

export function verificarPessoaDocumento(documento) {
    return axios.get(`/pessoa/${documento}`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function ativarContaPessoa(hash) {
    return axios.put(`/pessoa/ativar-conta/${hash}`);
}

export function retornarPessoaToken() {
    return axios.get(`/pessoa/token`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function setUnidadePadrao(dados, reload = true) {
    return axios.post('/pessoa/unidade-padrao', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    }).then(()=>{
        if (reload) {
            window.location.reload();
        }
    });
}

export function excluirDadosPessoa(senha) {
    return axios.put('/pessoa/excluir-dados',{
        senha : senha
    },{
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}