import MeusAtendimentos from '@/pages/atendimentos/MeusAtendimentos'
import NovoAtendimento from '@/pages/atendimentos/NovoAtendimento'
import Administrar from "@/pages/atendimentos/Administrar";
import store from '@/store';
import getSafe from '../../plugins/OptionalChain'

const sindico = store.state.login.acesso_sindico;
const modulos = store.state.configuracoes.configuracoes.modulos;
const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
const unidades = store.state.unidades.unidades;
const perfil = getSafe(() => store.state.unidades.unidade.perfil.sigla);

let valida_permissoes_unidades = false;
let rotas = [];

if (modulos.atendimentos && exibir_menu.atendimentos) {
    if (unidades != null) {
        for (const unidade of unidades) {
            if (store.state.permissao.permissoes[unidade.perfil.sigla]['criar_atendimentos']) {
                valida_permissoes_unidades = true;
                break;
            }
        }
    }
    if ((!sindico &&
        getSafe(() => store.state.permissao.permissoes[perfil].criar_atendimentos) || unidades === null) ||
        (sindico && getSafe(() => store.state.permissao.permissoesSindico.criar_atendimentos)) ||
        (unidades != null && valida_permissoes_unidades)) {
        rotas.push(
            {
                path: '/cliente/atendimentos/novo',
                component: NovoAtendimento,
                name: 'novoAtendimento',
                slug: 'atendimento',
                meta: {
                    title: 'Portal do Cliente - Novo Atendimento',
                    slugDropZone: 'atendimento'
                }
            }
        );
    }
    rotas.push(
        {
            path: '/cliente/atendimentos/:idUnidade?',
            component: MeusAtendimentos,
            props: true,
            name: 'atendimentos',
            meta: {
                title: 'Portal do Cliente - Meus Atendimentos'
            },
        },
        {
            path: '/cliente/atendimento/:idAtendimento',
            component: Administrar,
            name: 'atendimento',
            meta: {
                title: 'Portal do Cliente - Atendimento',
                slugDropZone: 'atendimento'
            }
        }
    );
}

export default {
    rotas
}
