import axios from "axios";
import store from "../store";
import { getConfiguracoes } from "@/services/ConfiguracoesService";

export function getPortalBeneficios(offset, limit, idPessoa = null) {
  return axios.get("/portal-beneficios", {
    headers: {
      Authorization: `Bearer ${store.state.login.access_token}`,
    },
    params: {
      offset: offset,
      limit: limit,
      idPessoa: idPessoa,
      idConfiguracao: store.state.configuracoes.configuracoes.idconfiguracao,
    },
  });
}