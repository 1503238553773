<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form
            @submit.prevent="handleSubmit(enviarArquivos)"
            id="zdrop"
            class="fileuploader center-align center-block pc-form"
        >
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <label for="mensagem"
                                ><strong>*Mensagem</strong></label
                            >
                            <VueEditor
                                v-model="mensagemFormData.mensagem"
                                id="mensagem"
                                :error-messages="errors"
                                :editorToolbar="customToolbar"
                            />
                            <label class="error"
                                ><span>{{ errors[0] }}</span></label
                            >
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="control-group">
                        <label class="control-label" for="dropzone"
                            >Arquivo(s):</label
                        >
                        <Dropzone
                            ref="dropzone"
                            v-on:sucesso="novamensagem"
                            :uniqid="mensagemFormData.uniqid"
                            id="dropzone"
                        />
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-12 btnEnviar">
                    <button
                        name="btSend"
                        type="submit"
                        value="Enviar"
                        class="btn-form pc-btnBox pc-btnBox-secondary"
                    >
                        Enviar mensagem
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Dropzone from "@/components/Dropzone";
import { gerarUniqId } from "@/plugins/Utils";
import {uploadArquivos, cadastrarMensagem} from "../../../../services/AssistenciasService";
import {objetoTraking} from "../../../../plugins/Utils";
import Vue from "vue";

export default {
    props: ["assistencia"],
    emits: ["error", "refresh"],

    components: {
        Dropzone,
        VueEditor,
    },

    data() {
        return {
            mensagemFormData: {
                mensagem: "",
                uniqid: null,
            },
            audio: {
                uniqid: null,
                file: [],
            },
            audio_url: null,
            scricao: null,
            customToolbar : [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ["clean"] // remove formatting button
            ]
        };
    },

    beforeMount() {
        this.mensagemFormData.uniqid = this.uniqid = gerarUniqId();
    },

    methods: {
        enviarArquivos() {
            this.$parent.$parent.isLoading = true;
            this.$refs.dropzone.enviarArquivos();
        },

        novamensagem() {
            this.trakingMixpanel('Visualizar assistencia', 'Responder assistencia', true, true)
            const formData = new FormData();

            uploadArquivos(formData).finally(() => {
                cadastrarMensagem(this.$route.params.idAssistencia, this.mensagemFormData)
                    .then(async (response) => {
                        this.$emit("refresh");
                    })
                    .catch((error) => {
                        this.$emit("error", error.response.data);
                    })
                    .finally(() => {
                        this.$parent.$parent.isLoading = false;
                    });
            })
        },

        trakingMixpanel(acao, subacao, intencao, ateracao) {
            this.$mixpanel.track(
                Vue.prototype.$eventoMixPanel,
                objetoTraking('Minhas assistencias', acao, subacao, intencao, ateracao)
            );
        },
    },
};
</script>

