<template>
  <div v-if="unidade">
    <div class="pc-bloco mb-20">
      <div class="pc-titleBloco">
        <h3>Status da Obra</h3>
      </div>
      <LoadingComponent :isLoading="isLoading" />
      <div class="noStatusChart" v-if="noStatusChart">
        <img src="@/assets/img/preview-chart.jpg" alt="">
        <span class="posicao"><font size="3"><b>Nenhum Resultado Encontrado!</b></font></span>
      </div>
      <div class="pc-grafico" v-else>
        <div class="pc-grafico--info">
          <p>{{andamentoObra}}% <span>Status Geral</span></p>
        </div>
        <div class="pc-grafico--svgs">
          <svg preserveAspectRatio="none" class="pc-graficoPorcentagem" width="360"
               height="360" xmlns="http://www.w3.org/2000/svg">
            <circle class="pc-graficoPorcentagem--circle" r="140" cy="0" cx="176"
                    stroke-width="40" stroke="" fill="none"
                    v-bind:style="{strokeDashoffset: ((100 - andamentoObra) * 4.4) + 'px'}"></circle>
          </svg>
          <svg preserveAspectRatio="none" class="pc-graficoMask" width="360" height="360"
               xmlns="http://www.w3.org/2000/svg">
            <circle class="pc-graficoMask--circle" r="140" cy="0" cx="176" stroke-width="40"
                    stroke="white" fill="none" style="stroke-dashoffset: 360;"></circle>
          </svg>
          <svg preserveAspectRatio="none" class="pc-graficoBg" width="360" height="360"
               xmlns="http://www.w3.org/2000/svg">
            <circle class="pc-graficoBg--circle" r="140" cy="0" cx="176" stroke-width="40"
                    stroke="" fill="none" style="stroke-dashoffset: 360;"></circle>
          </svg>
        </div>
      </div>
      <router-link :to="{ name: 'andamento-obra' }" class="pc-btnSelect">
        <span>Ver mais Detalhes da obra</span>
        <IconSetaRight />
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { retornarAndamentoObra } from "@/services/EmpreendimentoService";
import LoadingComponent from "@/components/LoadingComponent";

export default {
  components: {
    LoadingComponent,
  },

  computed: {
    ...mapState({
      unidade: state => state.unidades.unidade,
    }),
    noStatusChart() {
      return this.unidade.empreendimento.porcentagem_total_estagio_obra === null
    }
  },

  data() {
    return {
      isLoading: false,
      andamentoObra: null,
    };
  },

  watch: {
    unidade(newValue) {
      return newValue;
    },
    andamentoObra(newValue) {
      return newValue;
    },
  },

  beforeMount() {
    this.isLoading = true;
    this.retornarDadosAndamento();
  },

  methods: {
    retornarDadosAndamento() {
      retornarAndamentoObra(this.unidade.empreendimento.idempreendimento, this.unidade.idunidade)
          .then((response) => {
            this.andamentoObra = response.data["porcentagem_total"];
          })
          .catch((error) => {
            if (error.response.status != 404) {
              this.$emit("error", error.response.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  }
}
</script>
