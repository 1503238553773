<template>
    <div class="pc-login-form--wrap">
        <TituloEsqueceuSenha
            titulo="Insira o documento associado à sua conta"
        />
        <div class="pc-btns-Login">
            <router-link class="active" :to="{ name: 'esqueceu-senha-sms' }">
                Resetar por SMS
            </router-link>
            <router-link
                v-if="configuracoes.tela_login.reset_senha_disponivel === 'ambos'"
                :to="{ name: 'esqueceu-senha-email' }"
            >
                Resetar por E-mail
            </router-link>
        </div>
        <Aviso
            id="aviso"
            :exibir="alerta.exibir"
            :mensagem="alerta.mensagem"
            :tipo="alerta.tipo"
        />
        <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(resetarSenhaSms)">
                <div id="resetar" class="form-group">
                    <LoadingComponent :isLoading="isLoading" />
                    <div class="form-group">
                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <input
                                id="documento"
                                type="text"
                                inputmode="numeric"
                                v-mask="mask"
                                @keyup="formatarMascaraCPFCNPJ"
                                name="documento"
                                class="form-control"
                                required="required"
                                v-model="formulario.documento"
                                placeholder="CPF/CNPJ"
                                @keypress="onlyNumber"
                            />
                            <label class="error">
                                <span>{{ errors[0] }}</span>
                            </label>
                        </ValidationProvider>
                    </div>
                    <ReCaptcha ref="recaptcha" @verified="enviarFormulario" />
                    <button
                        type="submit"
                        name="button"
                        class="pc-btnBox pc-btnBox--fullW"
                        v-bind:disabled="botaoDisable"
                    >
                        <span>{{ tituloBotao }}</span>
                    </button>
                    <br /><br />
                    <div class="d-flex justify-content-between">
                        <div
                            class="form-group d-flex pc-login-checkLembrar"
                        ></div>
                        <router-link
                            class="pc-login-btnEsqueciSenha"
                            :to="{ name: 'login' }"
                        >
                            Caso tenha lembrado sua senha, clique aqui.
                        </router-link>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import Aviso from "@/components/Aviso";
import { onlyNumber, validarDocumento } from "@/plugins/Utils";
import { resetarSenhaSms } from "@/services/PessoasService";
import ReCaptcha from "@/components/ReCaptcha";
import LoadingComponent from "@/components/LoadingComponent";
import TituloEsqueceuSenha from "./components/TituloEsqueceuSenha";
import { mapGetters } from "vuex";

export default {
    name: "esqueceu-senha-sms",
    components: {
        Aviso,
        ReCaptcha,
        LoadingComponent,
        TituloEsqueceuSenha,
    },

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
    },

    data() {
        return {
            mask: '###.###.###-##',
            isLoading: false,
            botaoDisable: false,
            alerta: {
                exibir: false,
                mensagem: null,
                tipo: null,
            },
            formulario: {
                documento: null,
                recaptcha_token: null,
            },
            tituloBotao: "Resetar Senha",
        };
    },

    methods: {
        onlyNumber,

        resetarSenhaSms() {
            if (this.checarFormulario()) {
                this.$refs.recaptcha.execute();
                this.botaoDisable = true;
                this.tituloBotao = "Solicitando...";
                this.isLoading = true;
            }
        },

        enviarFormulario(recaptcha) {
            this.formulario.recaptcha_token = recaptcha;
            resetarSenhaSms(this.formulario)
                .then((response) => {
                    this.alerta.exibir = true;
                    this.alerta.mensagem = response.data.mensagem;
                    this.alerta.tipo = "sucesso";
                    this.exibirForm = false;
                })
                .catch((error) => {
                    this.alerta.exibir = true;
                    this.alerta.mensagem =
                        "Erro ao tentar resetar senha. Verifique se o cliente possui celular cadastrado e se está ativo.";
                    this.alerta.tipo = "erro";
                })
                .finally(() => {
                    this.isLoading = false;
                    this.botaoDisable = false;
                    this.tituloBotao = "Resetar Senha";
                });
        },

        checarFormulario() {
            if (!validarDocumento(this.formulario.documento)) {
                alert("Utilize um documento válido.");
                return false;
            }
            return true;
        },
        formatarMascaraCPFCNPJ() {
          const numericValue = this.formulario.documento.replace(/\D/g, '');
          let newMask = '';

          if (numericValue.length <= 11) {
            newMask = '###.###.###-##?#';
          } else {
            newMask = '##.###.###/####-##';
          }

          this.mask = newMask;
        },
    },
};
</script>
