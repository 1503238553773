<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Meus Agendamentos"
            subtitulo="Painel com informações de seus agendamentos"
            :mostrarFiltros="true"
            @showSidebarFilters="showSidebarFilters"
        >
            <router-link
                v-if="permissaoCriarAgendamento"
                :to="{ name: 'unidades-disponiveis' }"
                class="pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary"
            >Novo Agendamento</router-link>
        </TituloPagina>
        <Aviso :exibir="aviso.exibir" :tipo="aviso.tipo" :mensagem="aviso.mensagem" />
        <div class="row">
            <div class="col-12">
                <ListagemAgendamentos
                    :idUnidade="idUnidade"
                    :key="chaveListagem"
                    :queryParams.sync="queryParams"
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                    ref="listagemAgendamentos"
                />
            </div>
        </div>

        <SidebarFilters ref="sidebarFilters" 
			:search-params="queryParams"
			@show-sidebar-filters="showSidebarFilters" 
			@clear-form="clearForm"
			@search="search" />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemAgendamentos from "./components/ListagemAgendamentos";
import Aviso from "@/components/Aviso";
import HomeButton from "@/components/HomeButton";
import TituloPagina from "@/components/TituloPagina";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";
import SidebarFilters from "./components/SidebarFilters";

export default {
    props: ["idUnidade"],

    components: {
        BreadCrumbs,
        Aviso,
        ListagemAgendamentos,
        HomeButton,
        TituloPagina,
        SidebarFilters,
    },

    computed: {
        ...mapState({
            unidade: (state) => state.unidades.unidade,
			unidades: (state) => state.unidades.unidades
        }),
        ...mapGetters({
            verificarPermissao: "verificarPermissao"
        }),
    },
    data() {
        return {
            caminho: [
                {
                    texto: "Meus Agendamentos",
                    ativo: true,
                    rota: null,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
            chaveListagem: 0,
            permissaoCriarAgendamento: false,
            queryParams: {
				dataagendamento: null,
				idempreendimento: null,
				idbloco: null,
				idunidade: null,
				idtipo: null,
                idsituacao: null,
			},
        };
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Agendamentos', 'Meus Agendamentos', 'Listagem', true, false)
        );
        if (
            this.unidades == null
            || this.verificarPermissao(
                this.unidade.perfil.sigla,
                "criar_agendamentos"
            )
        ) {
            this.permissaoCriarAgendamento = true;
        }
    },

    methods: {
        exibirAviso(aviso) {
            this.aviso = aviso;
        },

        fecharAviso() {
            this.aviso = null;
        },

        atualizarListagem() {
            this.chaveListagem += 1;
        },

        showSidebarFilters() {
			this.$refs.sidebarFilters.showSidebarFilters();
		},

		search(value) {
            this.$refs.listagemAgendamentos.search(value);
		},

		clearForm() {
			this.queryParams = {
				dataagendamento: null,
				idassunto: null,
				idsubasunto: null,
				idempreendimento: null,
				idbloco: null,
				idunidade: null,
				idsituacao: null,
			};
		},

        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error ?? erro.message;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
