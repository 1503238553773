import PortalBeneficios from "@/pages/portalBeneficios/PortalBeneficios";
import store from "@/store";

const sindico = store.state.login.acesso_sindico;
const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
let rotas = [];
if (
    exibir_menu.portal_beneficios &&
    store.state.unidades.unidades &&
    store.state.unidades.unidades.length > 0 &&
    !sindico
) {
	
	rotas.push({
    path: "/cliente/portal-beneficios/:idmarketplace?",
    component: PortalBeneficios,
    name: "portal-beneficios",
    meta: {
      title: "Portal do Cliente - Portal de Benefícios",
    },
  });
}

export default {
	rotas,
};
