import axios from 'axios'
import store from '../store';

import { BASE_URL_API_DEV } from '../configs/api'

let baseUrlAPI = BASE_URL_API_DEV;
if (
    window.location.host.includes('construtordevendas.com.br')
    || window.location.host.includes('cvcrm.com.br')
) {
    baseUrlAPI = 'https://' + window.location.host;
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 403) {
        store.commit('LOGOUT');
        location.reload();
    }
    return Promise.reject(error);
});

axios.defaults.baseURL = baseUrlAPI + '/api/v1/cliente';

export const URL_API = baseUrlAPI;
