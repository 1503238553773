<template>
  <div class="pc-content">
    <BreadCrumbs :caminho="caminho" />
    <TituloPagina
      titulo="Meus Documentos"
      subtitulo="Painel com informações de seus documentos"
    >
      <router-link
        v-if="acessoSindico"
        :to="{ name: 'novoDocumento' }"
        class="pc-btnBox pc-btnBox-icon-right pc-btnBox-secondary"
      >
        Novo Documento</router-link
      >
    </TituloPagina>

    <div class="row">
      <div class="col-12">
        <UnidadePadrao />
      </div>
    </div>

    <Aviso
      :exibir="aviso.exibir"
      :tipo="aviso.tipo"
      :mensagem="aviso.mensagem"
    />

    <div class="row">
      <div class="col-12 col-lg-4">
        <MenuDocumentos :ativo.sync="menuAtivo" />
      </div>

      <div v-if="menuAtivo == 'docEmpreendimento'" class="col-12 col-lg-8">
        <ListagemDocumentosEmpreendimento
          :key="idEmpreendimento"
          :idEmpreendimento="idEmpreendimento"
          @error="tratarErro"
        />
      </div>
      <div
        v-else-if="!acessoSindico && menuAtivo == 'docUnidade'"
        class="col-12 col-lg-8"
      >
        <ListagemDocumentosUnidade
          :key="idUnidade"
          :idUnidade="idUnidade"
          @error="tratarErro"
        />
      </div>
      <div
        v-else-if="acessoSindico && menuAtivo == 'docSindico'"
        class="col-12 col-lg-8"
      >
        <ListagemDocumentosSindico @error="tratarErro" />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemDocumentosSindico from "./components/ListagemDocumentosSindico";
import ListagemDocumentosEmpreendimento from "./components/ListagemDocumentosEmpreendimento";
import ListagemDocumentosUnidade from "./components/ListagemDocumentosUnidade";
import MenuDocumentos from "./components/MenuDocumentos";
import UnidadePadrao from "@/components/UnidadePadrao";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import { setUnidadePadrao } from "@/services/PessoasService";
import { mapState, mapGetters, mapActions } from "vuex";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";

export default {
  components: {
    BreadCrumbs,
    ListagemDocumentosSindico,
    ListagemDocumentosEmpreendimento,
    ListagemDocumentosUnidade,
    MenuDocumentos,
    UnidadePadrao,
    TituloPagina,
    Aviso,
  },

  computed: {
    ...mapState({
      unidade: (state) => state.unidades.unidade,
    }),
    ...mapGetters({
      verificarPermissao: "verificarPermissao",
      acessoSindico: "getAcessoSindico",
    }),
  },

  created() {
    this.$mixpanel.track(
      Vue.prototype.$eventoMixPanel,
      objetoTraking(
        "Meus Documentos",
        "Meus Documentos",
        "Listagem",
        true,
        false
      )
    );
  },

  watch: {
    unidade(newValue) {
      if (!this.acessoSindico) {
        if (
          this.verificarPermissao(newValue.perfil.sigla, "exibir_documentos")
        ) {
          this.idEmpreendimento = newValue.empreendimento.idempreendimento;
          this.idUnidade = newValue.idunidade;
        } else {
          this.idEmpreendimento = 0;
          this.idUnidade = 0;
        }
        return newValue;
      }
    }
  },

  data() {
    return {
      idEmpreendimento: null,
      idUnidade: null,
      menuAtivo: "docEmpreendimento",
      caminho: [
        {
          texto: "Meus Documentos",
          ativo: true,
        },
      ],
      aviso: {
        exibir: false,
        tipo: null,
        mensagem: null,
      },
    };
  },

  beforeMount() {
    if (!this.acessoSindico) {
      let idUnidade = this.$route.params.idUnidade;
      if (idUnidade !== undefined) {
        this.novaUnidadePadrao(idUnidade);
        setUnidadePadrao({ idunidade: idUnidade });
      }
      if (
        this.verificarPermissao(this.unidade.perfil.sigla, "exibir_documentos")
      ) {
        this.idEmpreendimento = this.unidade.empreendimento.idempreendimento;
        this.idUnidade = this.unidade.idunidade;
      } else {
        this.idEmpreendimento = 0;
        this.idUnidade = 0;
      }
    }
  },

  methods: {
    ...mapActions(["novaUnidadePadrao"]),
    tratarErro(erro) {
      let mensagem = "Ocorreu um erro inesperado, por favor tente mais tarde.";
      if (erro.code != 422) {
        mensagem = erro.error;
      }

      this.aviso = {
        exibir: true,
        tipo: "erro",
        mensagem: mensagem,
      };
    },
  },
};
</script>
