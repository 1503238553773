<template>
    <div>
        <div class="" v-if="verificacaoFinalizada">
            <TituloLogin/>
            <div class="pc-btns-Login">
                <router-link :class="'active'" :to="{ name: 'login' }">
                    Acesse sua conta
                </router-link>
                <router-link
                    v-if="configuracoes.especifico_cv.cadastro_painel_cliente"
                    :to="{ name: 'cadastre-se' }"
                >
                    Cadastre-se
                </router-link>
                <router-link 
                    :to="{ 
                        name: 'loginSindico', 
                        params: {slug: this.$store.state.configuracoes.slug} 
                    }" 
                    v-if="configuracoes.tela_login.exibir_acesso_sindico"
                >
                    Acesse como síndico
                </router-link>
            </div>
            <SelectDocumentoInternacional />
            <form @submit.prevent="realizarLogin()">
                <div v-if="sessaoExpiradaLocal" class="pc-alert pc-error">
                    <p>Sessão Expirada</p>
                </div>
                <div class="form-group">
                    <input
                        type="text"
                        inputmode="numeric"
                        v-mask="documentoInternacional ? null : mask"
                        @keyup="() => formatarMascaraCPFCNPJ(!documentoInternacional)"
                        name="usuario"
                        v-model="formData.usuario"
                        :class="[
                            'form-control',
                            { 'is-invalid': errors.usuario != '' },
                        ]"
                        value=""
                        :placeholder="documentoInternacional ? 'Identificador do documento' : 'CPF ou CNPJ'"
                        required
                    />
                </div>
                <div class="form-group pc-login-password">
                    <input
                        :type="exibirSenha ? 'text' : 'password'"
                        name=""
                        v-model="formData.senha"
                        :class="[
                            'form-control',
                            { 'is-invalid': errors.senha != '' },
                        ]"
                        value=""
                        placeholder="Senha"
                        required
                    />
                    <a
                        href="#"
                        @click.prevent="showPassword()"
                        class="pc-login-showPassword"
                    >
                        <div v-if="exibirSenha">
                            <IconEye></IconEye>
                        </div>
                        <div v-else>
                            <IconEyeSlash></IconEyeSlash>
                        </div>
                    </a>
                </div>
                <div class="d-flex justify-content-between">
                    <router-link
                        class="pc-login-btnEsqueciSenha"
                        :to="{ name: configuracoes.tela_login.padrao_reset_senha === 'sms' ? 'esqueceu-senha-sms' : 'esqueceu-senha-email' }"
                    >
                        Primeiro acesso!

                    </router-link>
                    <router-link
                        class="pc-login-btnEsqueciSenha"
                        :to="{
                            name: configuracoes.tela_login.padrao_reset_senha === 'sms' ? 'esqueceu-senha-sms' : 'esqueceu-senha-email',
                            params: {slug: this.$store.state.configuracoes.slug}
                        }"
                    >
                        Esqueceu a senha?
                    </router-link>
                </div>
                <br/>
                <button
                    type="submit"
                    name="button"
                    class="pc-btnBox pc-btnBox--fullW"
                    :disabled="loading"
                >
                    <span v-if="loading">Autenticando...</span>
                    <span v-else>Acessar Portal</span>
                </button>
            </form>
        </div>

        <LoadingComponent :isLoading="!this.verificacaoFinalizada" :isFullPage="true"/>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import TituloLogin from "./components/TituloLogin";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SelectInput from "@/components/SelectInput.vue";
import SelectDocumentoInternacional from "./components/SelectDocumentoInternacional/SelectDocumentoInternacional";
import {validateSlug} from "@/plugins/Slugs";

export default {
    name: "Login",

    components: {SelectInput, LoadingComponent, TituloLogin, SelectDocumentoInternacional},

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
            documentoInternacional: "getDocumentoInternacional",
        }),
        ...mapState({
            sessaoExpirada: (state) => state.sessao.sessaoExpirada,
        }),
    },

    data() {
        return {
            mask: '###.###.###-##',
            exibirSenha: false,
            loading: false,
            sessaoExpiradaLocal: false,
            formData: {
                usuario: "",
                senha: "",
                slug: "",
                documento_internacional: false,
            },
            errors: {
                usuario: "",
                senha: "",
            },
            slug: this.$store.state.configuracoes.slug,
            verificacaoFinalizada: false,
        };
    },

    beforeMount() {
        this.sessaoExpiradaLocal = this.sessaoExpirada;
        this.resetarSessao();
        this.resetarRedirecionar();
        if (this.$route.params.error) {
            this.$vToastify.error(this.$route.params.error, "Opps");
        }
    },

    mounted() {
        validateSlug(this, this.slug).finally(() => {
            this.verificacaoFinalizada = true;
        });
    },

    methods: {
        ...mapActions(["login", "resetarRedirecionar", "resetarSessao", "getConfiguracao"]),

        showPassword() {
            this.exibirSenha = !this.exibirSenha;
        },

        realizarLogin() {
            this.reset();
            this.loading = true;
            this.formData.slug = this.$store.state.configuracoes.slug;
            this.formData.documento_internacional = this.documentoInternacional;
            this.login(this.formData)
                .then((response) => {
                    this.$router.push({
                        name: "redirecionando",
                    });
                })
                .catch((error) => {
                    this.$vToastify.error(error.response.data.message, "Opps");
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        reset() {
            this.errors = {
                usuario: "",
                senha: "",
            };
        },

        formatarMascaraCPFCNPJ(formatar) {
          if (formatar) {
            const numericValue = this.formData.usuario.replace(/\D/g, '');
            let newMask = '';

            if (numericValue.length <= 11) {
                newMask = '###.###.###-##?#';
            } else {
                newMask = '##.###.###/####-##';
            }

            this.mask = newMask;
          }
        },
    },
};
</script>
