<template>
    <div id="divAviso" v-if="exibir">
        <div :class="`pc-alert pc-warning closeButton`" v-if="tipo == 'aviso'">
            <IconAlert />
            <p>{{ mensagem }}</p>
        </div>
        <div
            :class="`pc-alert pc-error closeButton`"
            v-else-if="tipo == 'erro'"
        >
            <p>{{ mensagem }}</p>
        </div>
        <div
            :class="`pc-alert pc-confirmed closeButton`"
            v-else-if="tipo == 'sucesso'"
        >
            <IconSucesso />
            <p>{{ mensagem }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["exibir", "mensagem", "tipo"],

    watch: {
        mensagem(newValue) {
            if (this.exibir) {
                window.scrollTo(0, 0);
            }
            return newValue;
        },
    },
};
</script>
