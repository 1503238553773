var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-tabela--row"},[_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Protocolo"}},[_vm._v(_vm._s(_vm.getSafe(() => _vm.atendimento.protocolo)))])]),_c('div',{staticClass:"pc-tabela--td pc-f1"},[_c('p',{attrs:{"data-title":"Data"}},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(_vm.atendimento.cadastro,"YYYY-MM-DD"),"DD/MM/YYYY")))])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Assunto"}},[_vm._v(_vm._s(_vm.getSafe(() => _vm.atendimento.assunto.nome)))])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Subassunto"}},[_vm._v(_vm._s(_vm.getSafe(() => _vm.atendimento.assunto.subassunto)))])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Empreendimento"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => _vm.atendimento.unidade.empreendimento.nome))+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{attrs:{"data-title":"Bloco"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => _vm.atendimento.unidade.bloco.nome))+" ")])]),(_vm.acessoSindico)?_c('div',{staticClass:"pc-tabela--td pc-f3"},[_c('p',{attrs:{"data-title":"Área Comum"}},[_vm._v(_vm._s(_vm.getSafe(() => _vm.atendimento.unidade.empreendimento.area.nome)))])]):_c('div',{staticClass:"pc-tabela--td pc-f2"},[(_vm.atendimento.unidade.nome)?_c('p',{attrs:{"data-title":"Unidade(s)"}},[_vm._v(" "+_vm._s(_vm.getSafe(() => _vm.atendimento.unidade.nome))+" ")]):_c('p',[_vm._v("--")])]),_c('div',{staticClass:"pc-tabela--td pc-f2"},[_c('p',{staticClass:"pc-labelStatus",style:({
            color: _vm.atendimento.situacao.situacao_cor_nome ?? '#fff',
            background: _vm.atendimento.situacao.situacao_cor_bg ?? '#0533ff'
        }),attrs:{"data-title":"Situação atual"}},[_vm._v(" "+_vm._s(_vm.atendimento.situacao.nome)+" ")])]),_c('div',{staticClass:"pc-tabela--td pc-tabela--td-icons pc-f1 justify-content-center"},[_c('router-link',{staticClass:"pc-btnGhost pc-btnGhost-small pc-btnGhost-secondary",attrs:{"to":{
                name: 'atendimento',
                params: { idAtendimento: _vm.atendimento.idatendimento}
            }}},[_vm._v("Visualizar ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }