<template>
  <div class="pc-bloco">

    <div class="pc-modalPopostaAtiva" v-if="mostrarModalEspecial">
        <a class="close" data-dismiss="modal" @click="fecharModalEspecial()">
          <span><IconClose /></span>
        </a>
        <div class="pc-modalPopostaAtiva--title">
          <h4>Para abrir seu PDF{{ mensagemEspecial }}.</h4>
          <hr>
          <div style="display: flex; justify-content: space-evenly;">
            <a
              :href="linkBoleto"
              toggle="tooltip"
              placement="bottom"
              title="Baixar Boleto"
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
            >
              Baixar Boleto
            </a>
          <a
              @click="
                copiarLink()
              "
              toggle="tooltip"
              placement="bottom"
              title="Copiar Código"
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
            >
              <IconCopy /> Copiar Código
            </a>
          </div>
          
        </div>
    </div>

    <div class="pc-titleBloco">
      <h3>Boletos</h3>
      <div class="pull-right pc-title-legenda" v-if="listagemBoletos">
        Informações encontradas: {{ listagemBoletos.total }}
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-tabela-overflow">
    <div class="pc-tabela" v-if="listagemBoletos">
      <div class="pc-tabela--row pc-tabela--head">
        <div class="pc-tabela--td pc-f1">
          <p>Nº parcela</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Vencimento</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Nº Título</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Tipo</p>
        </div>
        <div
          class="pc-tabela--td pc-f1"
          v-if="!configuracoes.especifico_cv.retirar_coluna_valor_boleto_sienge"
        >
          <p>Valor</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Situação</p>
        </div>
        <div class="pc-tabela--td pc-f2"></div>
      </div>
      <div
        v-for="(boleto, index) in listagemBoletos.boletos"
        v-bind:key="index"
        class="pc-tabela--row"
      >
          <div class="pc-tabela--td pc-f1">
              <p data-title="Número da parcela">
                  {{
                      boleto.numero_parcela_sienge
                  }}
              </p>
          </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Vencimento">
            {{
              boleto.data_vencimento
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YY")
            }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Número do Título">
            {{
              boleto.codigo_titulo_sienge
            }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Tipo">
            {{ boleto.tipo_condicao_parcela }}
          </p>
        </div>
        <div
          class="pc-tabela--td pc-f1"
          v-if="!configuracoes.especifico_cv.retirar_coluna_valor_boleto_sienge"
        >
          <p data-title="Valor">
            {{ boleto.valor_corrigido }}
          </p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p data-title="Situação">
            {{ boleto.situacao.nome }}
          </p>
        </div>
        <div
          class="pc-tabela--td pc-tabela--td-icons pc-f2 justify-content-center"
        >
          <div v-if="boleto.situacao.sigla != 'P'">
            <a
              class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
              @click="
                baixarBoleto(
                  boleto.codigo_titulo_sienge,
                  boleto.codigo_parcela_sienge
                )
              "
              >Efetuar Pagamento</a
            >
          </div>
        </div>
      </div>
      <PaginateInput
        :total="listagemBoletos.total"
        :limit="paginacao.limit"
        :offset="paginacao.offset"
        @paginacao="montarListagem"
      />
    </div>
    <div v-else>Nenhuma informação encontrada.</div>
    </div>
  </div>
</template>

<style>
@media (max-width: 768px) {
  .pc-tabela-overflow .pc-tabela {
    min-width: 100% !important;
  }
}
</style>

<script>
import {
  getBoletosSegundaVia,
  gerarBoleto,
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";
import { mapGetters } from "vuex";
import store from "../../../store";

export default {
  props: {
    idUnidade: { type: Number },
  },

  emits: ["error"],

  components: {
    LoadingComponent,
    PaginateInput,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
    }),
  },

  data() {
    return {
      listagemBoletos: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: false,
      linkBoleto: '',
      codigoBarras: '',
      mostrarModalEspecial: false,
      mensagemEspecial: "",
      codigoTitulo: '',
      codigoParcela: '',
    };
  },

  beforeMount() {
    if (this.idUnidade) {
      this.montarListagem(1);
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      getBoletosSegundaVia(
        (pagina - 1) * this.paginacao.limit,
        this.paginacao.limit,
        this.idUnidade
      )
        .then((response) => {
          this.listagemBoletos = response.data;
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.$emit("error", error.response.data);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    baixarBoleto(codigoTituloSienge, codigoParcelaSienge) {
      this.isLoading = true;
      this.trakingMixpanel(
        "Segunda via financeira",
        "Baixar boleto",
        true,
        true
      );
      this.codigoTitulo = codigoTituloSienge;
      this.codigoParcela = codigoParcelaSienge;

      gerarBoleto(this.codigoTitulo, this.codigoParcela)
        .then((response) => {
          this.linkBoleto = response.data.link;
          this.codigoBarras = response.data.codigo_barras;
          switch (response.data.tipo_senha) {
            case 'S':
              this.mensagemEspecial = ', utilize seu documento para visualizá-lo';
              break;
            case 'P':
              this.mensagemEspecial = ', utilize os 5 primeiros números do seu documento para visualizá-lo';
              break;
            default:
              console.log(JSON.stringify({'data': response.data.link, 'origem': 'download_boleto'}))
              if (store.state.login.login_origem !== 'app') {
                  window.open(response.data.link, "_blank");
              }
              break;
          }
          this.mostrarModalEspecial = true;
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
          this.mostrarModalEspecial = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fecharModalEspecial() {
      this.mostrarModalEspecial = false;
    },

    copiarLink() {
      this.trakingMixpanel("Segunda via financeira", "Copiar link", true, true);
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = this.codigoBarras;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$vToastify.success("", "Copiado!");
    },
    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
