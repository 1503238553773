<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <div>
        <h3>Informações</h3>
      </div>
    </div>
    <div class="pc-unidadePerfil borderless">
      <div class="pc-avaliacao">
        <div class="pc-titleBloco">
          <div>
            <h3>Avaliação do Atendimento</h3>
          </div>
        </div>
        <div class="estrelas">
          <div class="rating">
            <input
              value="1"
              type="radio"
              name="rating-star"
              class="rating__control screen-reader"
              id="rc1"
              :checked="avaliacao == 1"
            />
            <input
              value="2"
              type="radio"
              name="rating-star"
              class="rating__control screen-reader"
              id="rc2"
              :checked="avaliacao == 2"
            />
            <input
              value="3"
              type="radio"
              name="rating-star"
              class="rating__control screen-reader"
              id="rc3"
              :checked="avaliacao == 3"
            />
            <input
              value="4"
              type="radio"
              name="rating-star"
              class="rating__control screen-reader"
              id="rc4"
              :checked="avaliacao == 4"
            />
            <input
              value="5"
              type="radio"
              name="rating-star"
              class="rating__control screen-reader"
              id="rc5"
              :checked="avaliacao == 5"
            />
            <label for="rc1" class="rating__item">
              <svg class="rating__star">
                <use xlink:href="#star"></use>
              </svg>
              <span class="screen-reader">1</span>
            </label>
            <label for="rc2" class="rating__item">
              <svg class="rating__star">
                <use xlink:href="#star"></use>
              </svg>
              <span class="screen-reader">2</span>
            </label>
            <label for="rc3" class="rating__item">
              <svg class="rating__star">
                <use xlink:href="#star"></use>
              </svg>
              <span class="screen-reader">3</span>
            </label>
            <label for="rc4" class="rating__item">
              <svg class="rating__star">
                <use xlink:href="#star"></use>
              </svg>
              <span class="screen-reader">4</span>
            </label>
            <label for="rc5" class="rating__item">
              <svg class="rating__star">
                <use xlink:href="#star"></use>
              </svg>
              <span class="screen-reader">5</span>
            </label>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
            <symbol id="star" viewBox="0 0 26 28">
              <path
                d="M26 10.109c0 .281-.203.547-.406.75l-5.672 5.531 1.344 7.812c.016.109.016.203.016.313 0 .406-.187.781-.641.781a1.27 1.27 0 0 1-.625-.187L13 21.422l-7.016 3.687c-.203.109-.406.187-.625.187-.453 0-.656-.375-.656-.781 0-.109.016-.203.031-.313l1.344-7.812L.39 10.859c-.187-.203-.391-.469-.391-.75 0-.469.484-.656.875-.719l7.844-1.141 3.516-7.109c.141-.297.406-.641.766-.641s.625.344.766.641l3.516 7.109 7.844 1.141c.375.063.875.25.875.719z"
              />
            </symbol>
          </svg>
        </div>
      </div>
      <div v-if="!avaliacao" class="pc-comentario" id="pc-comentario">
        <div class="form-group">
          <label for="exampleFormControlTextarea1"
            >Descreva o motivo da sua avaliação</label
          >
          <textarea
            v-model="avalicao_comentario"
            type="text"
            class="form-control"
            id="pc-com"
          ></textarea>
        </div>
        <button
          v-on:click="avaliar(avalicao_comentario)"
          type="submit"
          class="btn-form pc-btnBox pc-btnBox-secondary"
        >
          Enviar avaliação
        </button>
      </div>
      <div class="pc-infoAtendimento " v-if="avaliacao">
        <ul class="pc-list">
          <li class="w-100">
            <p>
              Comentário da avaliação:<span>{{
                getSafe(() => comentario_avaliacao)
              }}</span>
            </p>
          </li>
        </ul>
      </div>
      <hr />
      <div class="pc-infoAtendimento">
        <ul class="pc-list">
          <li>
            <p>
              CPF<span>{{ getSafe(() => atendimento.pessoa.documento) }}</span>
            </p>
          </li>
          <li>
            <p>
              Telefone<span>{{
                getSafe(() => atendimento.pessoa.telefone)
              }}</span>
            </p>
          </li>
          <li>
            <p>
              E-mail<span class="pc-elipsis">{{
                getSafe(() => atendimento.pessoa.email)
              }}</span>
            </p>
          </li>
          <li>
            <p>
              Data de abertura
              <span>{{
                atendimento.cadastrado
                  | dateParse("YYYY-MM-DD HH:mm:ss")
                  | dateFormat("DD/MM/YYYY HH:mm:ss")
              }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { avaliar } from "../../../../services/AtendimentosService";
import getSafe from "@/plugins/OptionalChain";
import { value } from "nanomatch/lib/utils";

export default {
  props: ["atendimento"],
  emits: ["error"],

  data() {
    return {
      avaliacao: 0,
      comentario_avaliacao: null
    };
  },

  beforeMount() {
    this.avaliacao = this.atendimento.avaliacao;
    this.comentario_avaliacao = this.atendimento.comentario_avaliacao;
  },

  methods: {
    getSafe,
    avaliar(comentario) {
      let nota;
      let inputsStar = document.getElementsByName("rating-star");
      inputsStar.forEach((element) => {
        if (element.checked == true) {
          nota = element.value;
        }
      });

      if (comentario && nota) {
        this.$parent.isLoading = true;
        avaliar(this.$route.params.idAtendimento, nota, comentario)
        .then((response) => {
          this.avaliacao = nota;
          this.comentario_avaliacao = comentario;
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
        })
        .finally(() => {
          this.$parent.isLoading = false;
        });
      } else {
        this.$emit("error", {error : 'Nota e comentário são obrigatórios.'});
      }
    }
  },
};
</script>
