<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<div>
				<h3>Extrato do Cliente</h3>
			</div>
		</div>
		<div class="pull-right mb-20 pc-title-legenda" v-if="listagemExtrato">
			Informações encontradas: {{ listagemExtrato.total }}
		</div>
		<LoadingComponent :isLoading="isLoading" />
			<div class="pc-tabela" v-if="listagemExtrato">
				<div class="pc-tabela--row pc-tabela--head">
					<div class="pc-tabela--td pc-f2">
						<p>Nome do arquivo</p>
					</div>
					<div class="pc-tabela--td">
						<p></p>
					</div>
				</div>
				<div
					v-for="(extrato, numP) in listagemExtrato.extratos"
					:key="numP"
				>
					<div class="pc-tabela--row">
						<div class="pc-tabela--td pc-f2">
							<p data-title="Número Parcela">
								{{ extrato.descricao }}
							</p>
						</div>
						<div class="pc-tabela--td">
							<p data-title="Download">
								<a
										class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
										:href="extrato.url_extrato"
										target="_blank"
								>Baixar Extrato</a>
							</p>
						</div>
					</div>
				</div>
				<PaginateInput
					:total="listagemExtrato.total"
					:limit="paginacao.limit"
					:offset="paginacao.offset"
					@paginacao="montarListagem"
				/>
			</div>
			<div v-else>Nenhuma informação encontrada.</div>
		</div>
</template>

<script>
import {
	baixarExtrato,
	getExtratoCV
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import SelectInput from "@/components/SelectInput";
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	props: {
		idUnidade: { type: Number },
	},

	emits: ["error", "exibirAviso"],

	components: {
		LoadingComponent,
		PaginateInput,
		SelectInput,
	},

	data() {
		return {
			listagemExtrato: null,
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
			temExtrato: false,
		};
	},

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
			configuracoes: "getConfiguracoes",
		}),
		console: () => console,
	},

	beforeMount() {
		this.montarListagem(1);
	},

	methods: {
		montarListagem(pagina) {
			this.isLoading = true;
			getExtratoCV(
				(pagina - 1) * this.paginacao.limit,
					this.paginacao.limit,
					this.idUnidade
			)
				.then((response) => {
					this.temExtrato = true;
					this.listagemExtrato = response.data;
				})
				.catch((error) => {
					this.temExtrato = false;
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
			);
		},
	},
};
</script>
<style scoped>
.pull-right {
	text-align: right;
}
</style>
