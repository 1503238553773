import MeusDocumentos from '@/pages/documentos/MeusDocumentos';
import NovoDocumento from '@/pages/documentos/NovoDocumento'
import store from '@/store';

const sindico = store.state.login.acesso_sindico;
let rotas = [];

if (store.getters.mostrarMenu("exibir_documentos") && !sindico) {
    rotas.push(
        {
            path: '/cliente/documentos/:idEmpreendimento?',
            component: MeusDocumentos,
            name: 'documentos',
            props: true,
            meta: {
                title: 'Portal do Cliente - Meus Documentos'
            }
        },
    );
}

if (sindico) {
    rotas.push(
        {
            path: '/cliente/documentos',
            component: MeusDocumentos,
            name: 'documentos',
            props: true,
            meta: {
                title: 'Portal do Cliente - Documentos'
            }
        },
    );

    rotas.push(
        {
            path: '/cliente/documentos/cadastrar',
            component: NovoDocumento,
            name: 'novoDocumento',
            meta: {
                title: 'Portal do Cliente - Documentos Síndico',
                slugDropZone: 'documento'
            }
        }
    );
}

export default {
    rotas
}
