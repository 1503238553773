import axios from "axios";
import store from '../store';

export function retornarPermissoes() {
    return axios.get("/permissoes-perfis-cliente", {
      headers: {
        Authorization: `Bearer ${store.state.login.access_token}`,
      }
    });
}

export function retornarPermissoesSindico() {
    return axios.get("/permissoes-sindico", {
      headers: {
        Authorization: `Bearer ${store.state.login.access_token}`,
      }
    });
}