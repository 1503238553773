<template>
  <div>
    <div
      class="modal fade"
      id="comunicadoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="comunicadoModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5>Comunicado</h5>
            <div v-html="modalTexto"></div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="pc-bloco">
      <div class="pc-titleBloco">
        <h3>Comunicados</h3>
        <div
          v-if="filtroEmpreendimentos.length"
          class="dropdown pc-dropdownArrow"
        >
          <a
            href="javascript:void(0)"
            class="pc-btnText"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Filtrar pelo empreendimento:
            <IconSetaDown />
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <a
              class="dropdown-item"
              v-on:click.stop="
                filtrarEmpreendimento(empreendimento.idempreendimento)
              "
              v-for="empreendimento in filtroEmpreendimentos"
              >{{ empreendimento.nome }}</a
            >
          </div>
        </div>
      </div>
      <div class="noStatusChart" v-if="exibirNoContentComunicados">
        <LoadingComponent :isLoading="isLoading" />
        <img src="@/assets/img/preview-comunicados.png" alt="" />
        <span class="posi"
          ><font size="3"><b>Nenhum Resultado Encontrado!</b></font></span
        >
      </div>
      <div class="pc-tabela" v-else>
        <div class="pc-tabela--row pc-tabela--head">
          <div class="pc-tabela--td pc-f1">
            <p>Data</p>
          </div>
          <div class="pc-tabela--td pc-f2">
            <p>Dados</p>
          </div>
          <div class="pc-tabela--td pc-f1">
            <p>Ações</p>
          </div>
        </div>

        <div
          class="pc-tabela--row"
          v-for="comunicado in comunicados"
          :key="comunicado.idmural"
        >
          <div class="pc-tabela--td pc-f1">
            <p data-title="Data">
              {{
                comunicado.data_cadastro
                  | dateParse("YYYY-MM-DD")
                  | dateFormat("DD/MM/YYYY")
              }}
            </p>
          </div>

          <div class="pc-tabela--td pc-f3">
            <p class="pc-multipleLines">
              <span data-title="Assunto">{{ comunicado.titulo }}</span
              ><br />
              <span class="pc-fontLow" data-title="Categoria">{{
                comunicado.categoria
              }}</span
              ><br />
              <span class="pc-fontLower" data-title="Empreendimento"
                >Empreendimento(s): {{ comunicado.empreendimento }}</span
              >
            </p>
          </div>
          <div class="pc-tabela--td pc-f1 justify-content-center">
            <a
              href=""
              @click.prevent="openModal(comunicado.descricao)"
              data-toggle="modal"
              data-target="#comunicadoModal"
              class="pc-btnGhost pc-btnGhost-small"
              >Visualizar Comunicado</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getComunicados } from "./../../../services/ComunicadoService";
import LoadingComponent from "@/components/LoadingComponent";

export default {
  data() {
    return {
      exibirNoContentComunicados: true,
      isLoading: true,
      comunicados: null,
      comunicadosData: null,
      modalTexto: null,
      filtroEmpreendimentos: [],
    }
  },
  components: {
    LoadingComponent,
  },
  beforeMount() {
    getComunicados()
      .then((response) => {
        this.comunicados = response.data;
        this.comunicadosData = response.data;
        this.criarFiltroEmpreendimentos(response.data);
        this.exibirNoContentComunicados = false;
      })
      .catch(() => {
        this.exibirNoContentComunicados = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    openModal(conteudo) {
      this.modalTexto = conteudo;
    },

    criarFiltroEmpreendimentos(comunicados) {
      let empreendimentos = [];
      comunicados.map(function(comunicado) {
        empreendimentos.push({
          idempreendimento: comunicado.idempreendimento,
          nome: comunicado.empreendimento,
        });
      });
      this.filtroEmpreendimentos = [
        ...new Map(
          empreendimentos.map((item) => [item["idempreendimento"], item])
        ).values(),
      ];
    },

    filtrarEmpreendimento(idempreendimento) {
      this.comunicados = [];
      let comunicados = [];
      this.comunicadosData.map(function(comunicado) {
        if (idempreendimento === comunicado.idempreendimento) {
          comunicados.push(comunicado);
        }
      });

      this.comunicados = comunicados;

      this.criarFiltroEmpreendimentos(this.comunicadosData);
      let empreendimentoSelecionado = [];
      this.filtroEmpreendimentos.forEach(function(empreendimentosFiltro) {
        if (idempreendimento !== empreendimentosFiltro.idempreendimento) {
          empreendimentoSelecionado.push(empreendimentosFiltro);
        }
      });

      this.filtroEmpreendimentos = empreendimentoSelecionado;
    },
  },
};
</script>
<style>
.posi {
  right: 0%;
  bottom: 50%;
  left: 35%;
  position: absolute;
}
</style>
