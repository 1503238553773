<template>
  <div v-if="showForm" class="tc-wrap -atualizacao">
    <div class="pc-bloco pc-form-atualizacao">
      <div class="pc-icon-atualizacao">
          <svg width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.0833 0.9375H2.91667C1.30642 0.9375 0 2.23926 0 3.84375V25.1562C0 26.7607 1.30642 28.0625 2.91667 28.0625H32.0833C33.6936 28.0625 35 26.7607 35 25.1562V3.84375C35 2.23926 33.6936 0.9375 32.0833 0.9375ZM32.0833 25.1562H18.4236C18.4783 24.8838 18.4722 25.3742 18.4722 23.8C18.4722 21.8746 16.6432 20.3125 14.3889 20.3125C13.7326 20.3125 13.2526 20.7969 11.6667 20.7969C10.0321 20.7969 9.63715 20.3125 8.94444 20.3125C6.6901 20.3125 4.86111 21.8746 4.86111 23.8C4.86111 25.3742 4.84896 24.8838 4.90972 25.1562H2.91667V7.71875H32.0833V25.1562ZM21.875 20.3125H28.6806C28.9479 20.3125 29.1667 20.0945 29.1667 19.8281V18.8594C29.1667 18.593 28.9479 18.375 28.6806 18.375H21.875C21.6076 18.375 21.3889 18.593 21.3889 18.8594V19.8281C21.3889 20.0945 21.6076 20.3125 21.875 20.3125ZM21.875 16.4375H28.6806C28.9479 16.4375 29.1667 16.2195 29.1667 15.9531V14.9844C29.1667 14.718 28.9479 14.5 28.6806 14.5H21.875C21.6076 14.5 21.3889 14.718 21.3889 14.9844V15.9531C21.3889 16.2195 21.6076 16.4375 21.875 16.4375ZM21.875 12.5625H28.6806C28.9479 12.5625 29.1667 12.3445 29.1667 12.0781V11.1094C29.1667 10.843 28.9479 10.625 28.6806 10.625H21.875C21.6076 10.625 21.3889 10.843 21.3889 11.1094V12.0781C21.3889 12.3445 21.6076 12.5625 21.875 12.5625ZM11.6667 18.375C13.8116 18.375 15.5556 16.6373 15.5556 14.5C15.5556 12.3627 13.8116 10.625 11.6667 10.625C9.5217 10.625 7.77778 12.3627 7.77778 14.5C7.77778 16.6373 9.5217 18.375 11.6667 18.375Z" fill="white"/>
          </svg>
      </div>
      <div class="pc-titleBloco">
        <div class="pc-atualizacao-text">
          <h3>Atualização Cadastral</h3>
          <p>Atualize seus dados cadastrais para manter seu perfil sempre atualizado e garantir o melhor atendimento!</p>
        </div>
      </div>
      <LoadingComponent :isLoading="isLoading" />
      <div class="pc-atualizacao-form">
        <div class="row mt-10" style="text-align: start">
          <div class="col-lg-6 col-12" v-if="this.campos.includes('nome')">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input
                v-model="formCliente.nome"
                type="text"
                class="form-control"
                placeholder="Nome"
                id="nome"
                name="nome"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('rg')">
            <div class="form-group">
              <label for="rg">RG</label>
              <input
                v-model="formCliente.rg"
                v-mask="'##.###.###-#'"
                type="text"
                class="form-control"
                placeholder="RG"
                id="rg"
                name="rg"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('telefone')">
            <div class="form-group">
              <label for="telefone">Telefone</label>
              <vue-tel-input
                v-model="formCliente.telefone"
                v-bind="bindProps"
                id="telefone"
                name="telefone"
              >
              </vue-tel-input>
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('celular')">
            <div class="form-group">
              <label for="celular">Celular</label>
              <vue-tel-input
                v-model="formCliente.celular"
                v-bind="bindProps"
                id="celular"
                name="celular"
              >
              </vue-tel-input>
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('email')">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                v-model="formCliente.email"
                type="text"
                class="form-control"
                placeholder="Email"
                id="email"
                name="email"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('renda_familiar')">
            <div class="form-group">
              <label for="renda_familiar">Renda Familiar</label>
              <input
                v-model="formCliente.renda_familiar"
                @keypress="onlyNumber"
                maxlength="10"
                type="text"
                class="form-control"
                placeholder="Renda Familiar"
                id="renda_familiar"
                name="renda_familiar"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('cep')">
            <div class="form-group">
              <label>CEP</label>
              <input
                type="text"
                class="form-control input-tel"
                placeholder="CEP"
                v-model="formEndereco.cep"
                v-mask="'#####-###'"
                @keyup="enderecoPorCep"
                id="cep"
                name="cep"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('logradouro')">
            <div class="form-group">
              <SelectInput
                titulo="Logradouro"
                label="nome"
                :clearable="false"
                :options="logradouros"
                :reduce="(option) => option.idlogradouro"
                :valor.sync="formEndereco.logradouro.idlogradouro"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('endereco')">
            <div class="form-group">
              <label>Endereço</label>
              <input
                type="text"
                class="form-control input-alpha"
                maxlength="100"
                v-model="formEndereco.nome"
                placeholder="Endereço"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('bairro')">
            <div class="form-group">
              <label>Bairro</label>
              <input
                type="text"
                class="form-control input-alpha"
                maxlength="100"
                v-model="formEndereco.bairro"
                placeholder="Bairro"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('numero')">
            <div class="form-group">
              <label>Número</label>
              <input
                type="text"
                class="form-control input-alpha"
                maxlength="10"
                v-model="formEndereco.numero"
                placeholder="Número"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('complemento')">
            <div class="form-group">
              <label>Complemento</label>
              <input
                type="text"
                class="form-control input-alpha"
                maxlength="100"
                v-model="formEndereco.complemento"
                placeholder="Complemento"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('estado')">
            <div class="form-group">
              <SelectInput
                titulo="Estado"
                label="nome"
                regras="required"
                :clearable="false"
                :options="estados"
                :reduce="(option) => option.idestado"
                :valor.sync="formEndereco.estado.idestado"
                @update:valor="atualizarCidades(null)"
              />
            </div>
          </div>
  
          <div class="col-lg-6 col-12" v-if="this.campos.includes('cidade')">
            <div class="form-group">
              <SelectInput
                titulo="Cidade"
                label="nome"
                regras="required"
                :clearable="false"
                :options="cidades"
                :reduce="(option) => option.idcidade"
                :valor.sync="formEndereco.cidade.idcidade"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pc-atualizacao-btns-form">
        <button
          name="btSend"
          type="button"
          value="Alterar"
          class="btn-form pc-btnBox pc-btnBox-primary"
          @click="enviarFormulario()"
        >
        Salvar modificações
        </button>
        <button
          name="btSend"
          type="button"
          value="Alterar"
          class="btn-form pc-btnBox pc-btnBox-secondary"
          @click="enviarEstouAtualizado()"
        >
          Estou atualizado
        </button>
      </div>
    </div>
  </div>
  <div v-else class="tc-wrap -atualizacao -small">
    <div class="pc-bloco pc-form-atualizacao ">
      <div class="pc-icon-atualizacao">
          <svg width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.0833 0.9375H2.91667C1.30642 0.9375 0 2.23926 0 3.84375V25.1562C0 26.7607 1.30642 28.0625 2.91667 28.0625H32.0833C33.6936 28.0625 35 26.7607 35 25.1562V3.84375C35 2.23926 33.6936 0.9375 32.0833 0.9375ZM32.0833 25.1562H18.4236C18.4783 24.8838 18.4722 25.3742 18.4722 23.8C18.4722 21.8746 16.6432 20.3125 14.3889 20.3125C13.7326 20.3125 13.2526 20.7969 11.6667 20.7969C10.0321 20.7969 9.63715 20.3125 8.94444 20.3125C6.6901 20.3125 4.86111 21.8746 4.86111 23.8C4.86111 25.3742 4.84896 24.8838 4.90972 25.1562H2.91667V7.71875H32.0833V25.1562ZM21.875 20.3125H28.6806C28.9479 20.3125 29.1667 20.0945 29.1667 19.8281V18.8594C29.1667 18.593 28.9479 18.375 28.6806 18.375H21.875C21.6076 18.375 21.3889 18.593 21.3889 18.8594V19.8281C21.3889 20.0945 21.6076 20.3125 21.875 20.3125ZM21.875 16.4375H28.6806C28.9479 16.4375 29.1667 16.2195 29.1667 15.9531V14.9844C29.1667 14.718 28.9479 14.5 28.6806 14.5H21.875C21.6076 14.5 21.3889 14.718 21.3889 14.9844V15.9531C21.3889 16.2195 21.6076 16.4375 21.875 16.4375ZM21.875 12.5625H28.6806C28.9479 12.5625 29.1667 12.3445 29.1667 12.0781V11.1094C29.1667 10.843 28.9479 10.625 28.6806 10.625H21.875C21.6076 10.625 21.3889 10.843 21.3889 11.1094V12.0781C21.3889 12.3445 21.6076 12.5625 21.875 12.5625ZM11.6667 18.375C13.8116 18.375 15.5556 16.6373 15.5556 14.5C15.5556 12.3627 13.8116 10.625 11.6667 10.625C9.5217 10.625 7.77778 12.3627 7.77778 14.5C7.77778 16.6373 9.5217 18.375 11.6667 18.375Z" fill="white"/>
          </svg>
      </div>
      <div class="pc-titleBloco">
        <div class="pc-atualizacao-text">
          <h3>Atualização Cadastral</h3>
          <p>Atualize seus dados cadastrais para manter seu perfil sempre atualizado e garantir o melhor atendimento!</p>
        </div>
      </div>
      <div class="pc-atualizacao-btn-preview">
        <button
          name="btSend"
          type="button"
          value="Alterar"
          class="btn-form pc-btnBox pc-btnBox-primary pc-btnBox--fullW pc-btnBox-small"
          @click="showFormAtualizacao()"
        >
          Atualizar
        </button>
        <a
          name="btSend"
          type="button"
          value="Alterar"
          class="btn-form pc-btnText"
          @click="enviarEstouAtualizado()"
        >
          Estou atualizado
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SelectInput from "@/components/SelectInput.vue";
import {URL_API} from "@/bootstrap";
import {objetoTraking, removerMascaraString, removerMascaraTelefone} from "@/plugins/Utils";
import Vue from "vue";
import {VueTelInput} from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {getCidades, getEnderecoPorCep, getEstados, getLogradouros} from "@/services/EnderecoService";
import cloneDeep from "clone-deep";
import {postAtualizacaoCadastral, postEstouAtualizado} from "@/services/AtualizacaoCadastralService";

Vue.use(VueTelInput);

export default {
  name: "AtualizacaoCadastral",

  components: {
    SelectInput,
    LoadingComponent,
    VueTelInput,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
      campos: "retornarCamposAtualizacaoCadastral",
      cliente: "getCliente",
    }),
  },

  data() {
    return {
      isLoading: false,
      baseUrl: URL_API,
      senha_regex: null,
      formCliente: null,
      formEndereco: null,

      logradouros: [],
      estados: [],
      cidades: [],

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },

      showForm: false
    };
  },

  beforeMount() {
    this.formCliente = cloneDeep(this.cliente);
    this.formEndereco = cloneDeep(this.formCliente.endereco);
    delete this.formCliente.endereco;

    getLogradouros()
      .then((response) => {
        this.logradouros = response.data;
      })
      .catch((error) => {
      });

    getEstados()
      .then((response) => {
        this.estados = response.data;
      })
      .catch((error) => {
      });

    if (this.formEndereco.estado) {
      this.atualizarCidades(this.formEndereco.cidade.idcidade);
    }
  },

  methods: {
    ...mapActions(["getMeOnlyMe", "setPendencia"]),

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    enderecoPorCep() {
      if (this.formEndereco.cep.length === 9) {
        this.isLoading = true;
        getEnderecoPorCep(removerMascaraString(this.formCliente.cep))
          .then((response) => {
            this.formEndereco.logradouro.idLogradouro = Number(
              response.data.idlogradouro
            );
            this.formEndereco.nome = response.data.endereco;
            this.formEndereco.bairro = response.data.bairro;
            this.formEndereco.estado.idEstado = Number(
              response.data.idestado
            );
            this.formEndereco.numero = "";
            this.formEndereco.complemento = "";
            this.atualizarCidades(Number(response.data.idcidade));
          })
          .catch((error) => {
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    atualizarCidades(idCidade) {
      getCidades(this.formEndereco.estado.idestado)
        .then((response) => {
          this.cidades = response.data;
          this.formEndereco.cidade.idcidade = idCidade;
        })
        .catch((error) => {
        });
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Atualização Cadastral", acao, subacao, intencao, ateracao)
      );
    },

    enderecoToForm() {
      if (this.formEndereco.cep) {
        this.formCliente.cep = this.formEndereco.cep;
      }
      if (this.formEndereco.logradouro) {
        this.formCliente.idlogradouro = this.formEndereco.logradouro.idlogradouro;
      }
      if (this.formEndereco.bairro) {
        this.formCliente.bairro = this.formEndereco.bairro;
      }
      if (this.formEndereco.numero) {
        this.formCliente.numero = this.formEndereco.numero;
      }
      if (this.formEndereco.complemento) {
        this.formCliente.complemento = this.formEndereco.complemento;
      }
      if (this.formEndereco.estado) {
        this.formCliente.idestado = this.formEndereco.estado.idestado;
      }
      if (this.formEndereco.cidade) {
        this.formCliente.idcidade = this.formEndereco.cidade.idcidade;
      }
      if (this.formEndereco.nome) {
        this.formCliente.endereco = this.formEndereco.nome;
      }
    },

    enviarFormulario() {
      this.isLoading = true;
      if (this.formCliente.rg) {
        this.formCliente.rg = removerMascaraString(this.formCliente.rg);
      }
      if (this.formCliente.telefone) {
        this.formCliente.telefone = removerMascaraTelefone(
          this.formCliente.telefone
        );
      }
      if (this.formCliente.celular) {
        this.formCliente.celular = removerMascaraTelefone(
          this.formCliente.celular
        );
      }
      if (this.formEndereco.cep) {
        this.formEndereco.cep = removerMascaraString(
          this.formEndereco.cep
        );
      }
      this.enderecoToForm();

      postAtualizacaoCadastral(this.formCliente)
        .then((response) => {
          this.getMeOnlyMe();
          this.trakingMixpanel(
            "Atualização cadastral - Alterar dados do cliente",
            "Alterar",
            true,
            true
          );
          this.setPendencia(false);
          this.$router.push({name: "home"});
        })
        .catch((error) => {
          this.$router.push({name: "home"});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showFormAtualizacao() {
      this.showForm = true;
    },

    enviarEstouAtualizado() {
      this.isLoading = true;

      postEstouAtualizado()
        .then(() => {
          this.trakingMixpanel(
            "Atualização cadastral - Manter dados do cliente",
            "Manter",
            true,
            false
          );
          this.setPendencia(false);
          this.$router.push({name: "home"});
        })
        .catch((error) => {
          this.$router.push({name: "home"});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.pc-form-atualizacao{
  position: relative;
  margin-bottom: 0;
}
.pc-icon-atualizacao{
  width: 85px;
  height: 85px;
  background: var(--cor-secundaria);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: -42px;
  transform: translateX(-50%);
  border: 5px solid white;
}
.pc-atualizacao-text{
  text-align: center;
  max-width: 60%;
  margin: 10px auto;
  margin-top: 50px;

  @media (max-width: 767.98px) {
      max-width: 100%;
  }
}
.tc-wrap {
  &.-atualizacao{
    text-align: center;
    width: 900px;
    max-width: calc(100% - 30px);
    margin: 0 auto;
    padding: 70px 30px 40px 30px;
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
			padding: 70px 0px 40px 0px;
		}

    &.-small{
      width: 400px;

      .pc-atualizacao-text{
        max-width: 100%;
      }
    }
  }
}
.pc-atualizacao-form{
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  padding: 0px 15px 10px 15px;
  margin-bottom: 20px;
}
.pc-atualizacao-btns-form{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.form-group {
    margin-bottom: 10px;
}
.pc-atualizacao-btn-preview{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
</style>
