<template>
  <div v-if="banners && banners.length > 0">
    <VueSlickCarousel v-bind="settingsSlickLateral" class="pc-slide --vertical">
      <a
        v-for="(banner, index) in banners"
        :key="index"
        :href="banner.link ? banner.link.url : null"
        class="pc-banner-sidebar item"
        :target="
          banner.link && banner.link.abertura == 'mesma_pagina'
            ? '_self'
            : '_blank'
        "
      >
        <img
          v-if="banner.imagem"
          :src="
            banner.imagem.url
              .replace('[[LARGURA]]', 'x')
              .replace('[[ALTURA]]', 'x')
          "
          alt=""
        />
        <p
          v-else
          :style="`background-color: ${banner.texto.cor.fundo};color: ${banner.texto.cor.texto};`"
        >
          {{ banner.texto.texto }}
        </p>
      </a>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { getBanner } from "@/services/BannerService";

export default {
  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      settingsSlickLateral: {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 8000,
        cssEase: "linear",
      },
      banners: null,
    };
  },

  beforeMount() {
    getBanner("home_sidebar")
      .then((response) => {
        let dispositivoMobile = window.screen.width < 768;
        let banners = [];
        for (let elemento of response.data.data) {
          if (
            (dispositivoMobile &&
              elemento.imagem.dispositivo_exibicao == "desktop") ||
            (!dispositivoMobile &&
              elemento.imagem.dispositivo_exibicao == "mobile")
          ) {
            continue;
          }
          banners.push(elemento);
        }
        this.banners = banners;
      })
      .catch();
  },
};
</script>