<template>
    <div class="boxAtendimento">
        <ul class="pc-list -fourColumns">
            <li>
                <p>Empreendimento<span>{{ getSafe(() => agendamento.unidade.empreendimento.nome ) }}</span></p>
            </li>
            <li>
                <p>Unidade<span>{{ getSafe(() => agendamento.unidade.nome ) }}</span></p>
            </li>
            <li>
                <p>Agendamento<span>{{ getSafe(() => agendamento.tipo ) }}</span></p>
            </li>
            <li>
                <p>Motivo<span>{{ getSafe(() => agendamento.motivo) }}</span></p>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import getSafe from '@/plugins/OptionalChain';

export default {
    name: "InfoAgendamento",
    props: ["agendamento"],
    computed: {
        ...mapGetters({
            cliente: 'getCliente'
        }),
    },
    methods: {getSafe},
};
</script>
