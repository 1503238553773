import axios from "axios";
import store from '../store';

export function getPesquisas(offset, limit) {
    return axios.get('/pesquisas', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
        }
    });
}