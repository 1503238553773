<template>
  <div
    class="modal fade"
    id="indicaramigo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="indicaramigoTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <LoadingComponent :isLoading="isLoading" />
        <div class="modal-body">
          <h5>Indique um Amigo</h5>
          <p>
            Indique um amigo preenchendo o formulário abaixo, deixe os contatos dele que
            entratemos em contato
          </p>
          <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
          />
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(enviar)">
              <div class="form-group">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <label for="nome"><strong>*Nome</strong></label>
                  <input
                    type="text"
                    name="nome"
                    placeholder="Nome do seu amigo"
                    class="form-control input-alpha"
                    v-model="formulario.nome"
                  />
                  <label class="error"
                    ><span>{{ errors[0] }}</span></label
                  >
                </ValidationProvider>
              </div>
              <div class="form-group">
                <SelectInput
                  label="nome"
                  titulo="Empreendimento"
                  :reduce="(option) => option.idempreendimento"
                  :options="empreendimentos"
                  :valor.sync="formulario.idempreendimento"
                  :placeholder="empreendimentos.length > 0 ? 'Selecione um Empreendimento' : 'Nenhum Empreendimento Ativo no Sistema'"
                />

              </div>
              <div class="form-group">
                <label for="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  placeholder="E-mail do seu amigo"
                  class="form-control input-email"
                  v-model="formulario.email"
                />
              </div>
              <div class="form-group">
                <label for="telefone">Telefone</label>
                <vue-tel-input
                  v-model="formulario.telefone"
                  v-bind="bindProps"
                  id="telefone"
                  name="telefone"
                >
                </vue-tel-input>
              </div>
              <div class="form-group">
                <label for="observacoes">Observações</label>
                <textarea
                  class="form-control"
                  rows="3"
                  placeholder="Observações"
                  name="observacoes"
                  v-model="formulario.observacoes"
                ></textarea>
              </div>
              <button
                :disabled="acessoComo"
                class="pc-btnBox pc-btnBox-secundary"
              >
                Indicar um Amigo
              </button>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aviso from "@/components/Aviso";
import LoadingComponent from "@/components/LoadingComponent";
import { indicarAmigo } from "@/services/LeadService";
import { retornarPessoaToken } from "@/services/PessoasService";
import SelectInput from "@/components/SelectInput";
import { mapState, mapGetters } from "vuex";
import { removerMascaraTelefone } from "../../../plugins/Utils";
import {
  retornarEmpreendimentos,
} from "@/services/EmpreendimentoService";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

export default {
  components: {
    LoadingComponent,
    Aviso,
    VueTelInput,
    SelectInput,
  },

  computed: {
    ...mapState({
      cliente: (state) => state.login.cliente,
    }),
    ...mapGetters({
      acessoComo: "getAcessoComo",
    }),
  },

  data() {
    return {
      formulario: {
        acao: "salvar",
        nome: null,
        email: null,
        idempreendimento: null,
        telefone: "",
        origem: "CL",
        modulo: "cliente",
        observacoes: null,
        midia: "Indique um amigo",
        conversao: "Indique um amigo",
        interacoes: null,
      },
      aviso: {
        exibir: false,
        tipo: null,
        mensagem: null,
      },
      isLoading: false,

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },

      empreendimentos: [],
    };
  },

  methods: {
    enviar() {
      if (this.acessoComo) {
        return;
      }

      if (!this.formulario.email && !this.formulario.telefone) {
        this.aviso = {
          exibir: true,
          tipo: "erro",
          mensagem: "É necessario informar um e-mail ou telefone.",
        };
        return;
      }

      this.isLoading = true;
      retornarPessoaToken()
        .then((response) => {
          this.formulario.interacoes = [
            {
              tipo: "A",
              descricao:
                `Lead indicado pro “${this.cliente.nome}”, ` +
                `originado do portal do cliente “Indique um amigo”.` +
                `\n${this.formulario.observacoes}`,
            },
          ];
          this.enviarIndiqueAmigo(response.data.token);
        })
        .catch((error) => {
          this.aviso = {
            exibir: true,
            tipo: "erro",
            mensagem: "Ocorreu um erro inesperado, por favor tente mais tarde.",
          };
          this.isLoading = false;
        });
    },

    enviarIndiqueAmigo(token) {
      this.formulario.telefone = removerMascaraTelefone(
        this.formulario.telefone
      );
      indicarAmigo(this.formulario, this.cliente.email, token)
        .then((response) => {
          this.aviso = {
            exibir: true,
            tipo: "sucesso",
            mensagem: "Amigo indicado com sucesso.",
          };
        })
        .catch((error) => {
          let mensagem =
            "Ocorreu um erro inesperado, por favor tente mais tarde.";
          if (error.response.data.mensagem == "erro_ao_alterar_lead") {
            mensagem = "E-mail ou telefone informado(s) já cadastrados.";
          }
          this.aviso = {
            exibir: true,
            tipo: "erro",
            mensagem: mensagem,
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  beforeMount() {
    retornarEmpreendimentos()
      .then((response) => {
        this.empreendimentos = response.data;
      })
      .catch((error) => { });
  }
};
</script>
