import { login, loginSindico, loginExterno, registrarCliente, acessarComo } from '@/services/AutenticacaoService'
import { retornarDadosPessoa } from '@/services/PessoasService'
import { retornarDadosSindico } from '@/services/SindicosService'
import { mixPanelLogin } from "../../../plugins/Utils";
import decode from "jwt-decode";

export default {
    limparSessao({ commit, dispatch }) {
        dispatch('resetState');
    },

    register({ commit }, params) {
        return registrarCliente(params);
    },

    login({ commit, dispatch }, params) {
        return login(params)
            .then(response => {
                commit('SET_LOGIN', response.data.access_token);
                dispatch('getMe');
            });
    },

    loginSindico({ commit, dispatch }, params) {
        return loginSindico(params)
            .then(response => {
                commit('SET_LOGIN', response.data.access_token);
                dispatch('getMeSindico');
            });
    },


    loginExterno({ commit, dispatch }, { origem, dados }) {
        return loginExterno(origem, dados)
            .then(response => {
                commit('SET_LOGIN', response.data.access_token);
                dispatch('getMe');
                return response;
            });
    },

    autenticarAcessarComo({ commit, dispatch }, dados) {
        return acessarComo(dados)
            .then(response => {
                commit('SET_LOGIN_COMO', response.data.access_token);
                dispatch('getMe');
                return response;
            });
    },

    async getMe({ commit, state, dispatch }) {
        if (!state.access_token) return;

        const { origin: origem } = decode(state.access_token);
        commit('SET_LOGIN_ORIGEM', origem ? origem : 'web');
        return retornarDadosPessoa()
            .then(response => {
                commit('SET_ME', response.data);
                commit('CONCLUIDO_PESSOA');
                dispatch('getTermos', state.acesso_como);
                dispatch('unidadesCliente', response.data);
                mixPanelLogin(response.data.idpessoa, 'cliente', response);
            }).catch(error => {
                commit('LOGOUT');
            });
    },

    getMeOnlyMe({ commit, state, dispatch }) {
        return retornarDadosPessoa()
            .then(response => {
                commit('SET_ME', response.data);
            });
    },

    async getMeSindico({ commit, state, dispatch }) {
        if (!state.access_token) return;

        return retornarDadosSindico()
            .then(response => {
                commit('SET_SINDICO', response.data);
                commit('SET_LOGIN_SINDICO');
                commit('CONCLUIDO_PESSOA');
                dispatch('getTermos', state.acesso_como);
                dispatch('unidadesCliente', response.data);
                dispatch('retornarPermissoesSindico', response.data);
                mixPanelLogin(response.data.idsindico, 'sindico', response);
            }).catch(error => {
                commit('LOGOUT');
            });
        
    },
    logout({ commit, dispatch }) {
        commit('CLEAN_CONFIG');
        commit('LOGOUT');
    },

    toggleDocumentoInternacional({ commit }) {
        commit('TOGGLE_DOCUMENTO_INTERNACIONAL');
    },
}
