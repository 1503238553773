import axios from "axios";
import store from '../store';

export function getAgendamento(idAgendamento, idUnidade) {
    return axios.get('/agendamentos/' + idAgendamento + '/unidade/' + idUnidade, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getAgendamentos(
    offset, 
    limit, 
    idUnidade = null,
    {
        dataagendamento = null,
        idempreendimento = null,
        idbloco = null,
        idunidade = null,
        tipo = null,
        situacao = null,
    }) {
    return axios.get('/agendamentos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
            idUnidade: idUnidade || idunidade,
            data_agendamento: dataagendamento,
            idempreendimento: idempreendimento,
            idbloco: idbloco,
            tipo: tipo,
            situacao: situacao,
        }
    });
}

export function cancelarAgendamentos(tipo, id) {
    return axios.put('/agendamentos/' + tipo + '/cancelar/' + id, null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function getUnidadesDisponiveis(offset, limit) {
    return axios.get('/agendamentos/unidades-disponiveis', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });
}

export function getHorariosDisponiveis(idAgendamento, idUnidade, data) {
    return axios.get(`/agendamentos/${idAgendamento}/unidade/${idUnidade}/horarios`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            data: data
        }
    });
}

export function cadastrarAgendamento(dados) {
    return axios.post('/agendamentos', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function retornarSituacoes() {
    return axios.get(`/agendamentos/situacoes`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}