<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Painel Financeiro"
            subtitulo="Painel com informações de seu financeiro"
        />

        <div :class="`pc-alert pc-warning closeButton`" v-if="configuracoes.mensagem_informacoes_financeiras != null">
            <p>{{ configuracoes.mensagem_informacoes_financeiras }}</p>
        </div>

        <div class="row">
            <div class="col-12">
                <UnidadePadrao />
            </div>
        </div>

        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />
        <div class="row">
            <div class="col-12 col-lg-3 col-md-4">
                <MenuFinanceiro :ativo.sync="menuAtivo" />
            </div>
            <div v-if="menuAtivo == 'segundaVia' && configuracoes.exibir_financeiro.boletos" class="col-12 col-lg-9 col-md-8">
                <ListagemBoletosSegundaVia
                    :key="idUnidade"
                    :idUnidade="idUnidade"
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                />
            </div>
            <div
                v-else-if="menuAtivo == 'antecipacao' && configuracoes.exibir_financeiro.boletos_antecipacao"
                class="col-12 col-lg-9 col-md-8"
            >
              <ListagemAntecipacaoParcelas
                  :idUnidade="idUnidade"
                  @exibirAviso="exibirAviso"
                  @error="tratarErro"
              />
            </div>
            <div
                v-else-if="menuAtivo == 'reparcelamento' && configuracoes.exibir_financeiro.boletos_reparcelamento"
                class="col-12 col-lg-9 col-md-8"
            >
              <ListagemReparcelamento
                  :idUnidade="idUnidade"
                  @exibirAviso="exibirAviso"
                  @error="tratarErro"
              />
            </div>
            <div
                v-else-if="menuAtivo == 'extratos'"
                class="col-12 col-lg-9 col-md-8"
            >
                <ListagemExtrato
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                />
            </div>
            <div
                v-else-if="menuAtivo == 'demostrativoIr'"
                class="col-12 col-lg-9 col-md-8"
            >
                <ListagemDemonstrativoIr
                    :idUnidade="idUnidade"
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemBoletosSegundaVia from "./components/ListagemBoletosSegundaVia";
import ListagemDemonstrativoIr from "./components/ListagemDemonstrativoIr";
import ListagemExtrato from "./components/ListagemExtrato";
import MenuFinanceiro from "./components/MenuFinanceiro";
import UnidadePadrao from "@/components/UnidadePadrao";
import Aviso from "@/components/Aviso";
import TituloPagina from "@/components/TituloPagina";
import { setUnidadePadrao } from "@/services/PessoasService";
import { mapState, mapGetters, mapActions } from "vuex";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";
import ListagemAntecipacaoParcelas from "./components/ListagemAntecipacaoParcelas.vue";
import ListagemReparcelamento from "./components/ListagemReparcelamento.vue";

export default {
    props: {
        ativo: { type: String, default: "segundaVia" },
    },
    components: {
      ListagemReparcelamento,
        BreadCrumbs,
        ListagemBoletosSegundaVia,
        UnidadePadrao,
        MenuFinanceiro,
        ListagemDemonstrativoIr,
        Aviso,
        TituloPagina,
        ListagemExtrato,
        ListagemAntecipacaoParcelas
    },

    computed: {
        ...mapState({
            unidadeGlobal: (state) => state.unidades.unidade,
            ...mapGetters({
                verificarPermissao: "verificarPermissao",
                configuracoes: "getConfiguracoes"
            }),
        }),
         ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes
        }),
    },
    beforeMount() {
        let idUnidade = this.$route.params.idUnidade;
        if(idUnidade !== undefined){
            this.novaUnidadePadrao(idUnidade);
           setUnidadePadrao({ idunidade: idUnidade }, false);
        }
    },
    mounted() {
        var arrayConfiguracoes = [
            { permissao: 'boletos', ativo: 'segundaVia'},
            { permissao: 'extrato_financeiro', ativo: 'extratos' },
            { permissao: 'imposto_de_renda', ativo: 'demostrativoIr' }
        ];

        for (let index of arrayConfiguracoes) {
            if (this.configuracoes.exibir_financeiro[index.permissao]) {
                this.menuAtivo = index.ativo;
                return;
            }
        }
    },
    data() {
        return {
            menuAtivo: this.ativo,
            idUnidade: null,
            caminho: [
                {
                    texto: "Financeiro",
                    ativo: true,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
        };
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Financeiro', 'Financeiro', 'Listagem', true, false)
        );
        if (
            this.verificarPermissao(
                this.unidadeGlobal.perfil.sigla,
                "exibir_financeiro"
            )
        ) {
            this.idUnidade = this.unidadeGlobal.idunidade;
        } else {
            this.idUnidade = 0;
        }
    },

    watch: {
        unidadeGlobal(newValue) {
            if (
                this.verificarPermissao(
                    newValue.perfil.sigla,
                    "exibir_financeiro"
                )
            ) {
                this.idUnidade = newValue.idunidade;
                this.aviso.exibir = false;
            } else {
                this.idUnidade = 0;
            }
            return newValue;
        },

        menuAtivo(newValue) {
            this.aviso.exibir = false;
        },
    },

    methods: {
        ...mapActions(["novaUnidadePadrao"]),
        exibirAviso(aviso) {
            this.aviso = aviso;
        },

        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code !== 422) {
                mensagem = erro.error ?? erro.message;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
