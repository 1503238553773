<template>
    <div class="sidebar-filters-overlay" :class="show" @click.self="hideSidebarFilters">
        <div class="sidebar-filters" :class="show">
            <div class="sidebar-filters-header">
                <h3>Filtros</h3>
                <button class="sidebar-button-close" @click="hideSidebarFilters">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                        <path fill="#000000" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                    </svg>
                </button>
            </div>
            <div class="sidebar-filters-body">
                <form @submit.prevent="search">
                    <div class="form-group">
                        <label for="protocolo">Protocolo:</label>
                        <input
                            id="protocolo"
                            class="form-control"
                            placeholder="Protocolo"
                            v-model="searchParams.protocolo"
                            @input="tratarProtocoloInput"
                        />
                    </div>
                    <div class="form-group">
                        <label for="cadastro">Data de cadastro:</label>
                        <input
                            id="cadastro"
                            type="date"
                            class="form-control"
                            placeholder="Data de cadastro"
                            v-model="searchParams.cadastro"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Empreendimento:"
                            placeholder="Todos"
                            :options="empreendimentosCliente"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idempreendimento"
                            @update:valor="atualizaEmpreendimento(searchParams.idempreendimento)"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Bloco:"
                            placeholder="Todos"
                            :options="blocosCliente"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idbloco"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Unidade:"
                            placeholder="Todos"
                            :options="unidadesCliente"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idunidade"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Assunto:"
                            placeholder="Todos"
                            :options="assuntos"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idassunto"
                            @update:valor="getSubassuntos(searchParams.idassunto)"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Subassunto:"
                            placeholder="Todos"
                            :options="subassuntos"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idsubassunto"
                        />
                    </div>
                    <div class="form-group">
                        <SelectInput
                            titulo="Situação atual:"
                            placeholder="Todos"
                            :options="situacoes"
                            :reduce="(option) => option.id"
                            :valor.sync="searchParams.idsituacao"
                        />
                    </div>
                </form>
            </div>
            <div class="sidebar-filters-footer">
                <button @click="search" class="pc-btnBox pc-btnBox-primary">Aplicar Filtros</button>
                <button @click="clearForm" class="pc-btnBox pc-btnBox-branco">Limpar Filtros</button>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectInput from '@/components/SelectInput.vue';
    import { getSituacoes } from "@/services/AtendimentosService";
    import { getAssuntos, getSubassuntos } from "@/services/AssuntoSubassuntoService";

    export default {
        components: {
            SelectInput
        },
        props: {
            searchParams: {
                protocolo: null,
                cadastro: null,
                idempreendimento: null,
                idbloco: null,
                idunidade: null,
                idassunto: null,
                idsubassunto: null,
                idsituacao: null,
            }
        },
        data() {
            return {
                show: "",
                assuntos: [],
                subassuntos: [],
                situacoes: [],
            };
        },
        computed: {
            empreendimentosCliente() {
                if (this.$store.getters.getAcessoSindico) {
                    return this.$store.getters.getSindico.empreendimentos_associados.map((e) => ({
                        id: e.idempreendimento,
                        label: e.nome
                    }));
                }
                return this.$store.getters.getEmpreendimentos.map((e) => ({ 
                    id: e.idempreendimento, 
                    label: e.nome,
                }));
            },
            blocosCliente() {
                if (!this.searchParams.idempreendimento) {
                    return [];
                }
                if (this.$store.getters.getAcessoSindico) {
                    const blocos = this.$store.getters.getBlocos.filter((b) => b.idempreendimento === this.searchParams.idempreendimento);
                    return blocos.map((b) => ({ id: b.idbloco, label: b.nome }));
                }
                const blocos = this.$store.getters.getBlocos.filter((b) => b.idempreendimento === this.searchParams.idempreendimento);
                return blocos.map((b) => ({ id: b.idbloco, label: b.nome }));
            },
            unidadesCliente() {
                if (!this.searchParams.idbloco) {
                    return [];
                }
                if (this.$store.getters.getAcessoSindico) {
                    const unidades = this.$store.getters.getUnidades.filter((u) => u.bloco.idbloco === this.searchParams.idbloco);
                    return unidades.map((u) => ({ id: u.idunidade, label: u.nome }))
                }
                const unidades = this.$store.getters.getUnidades.filter((u) => u.bloco.idbloco === this.searchParams.idbloco);
                return unidades.map((u) => ({ id: u.idunidade, label: u.nome }))
            }
        },
        methods: {
            showSidebarFilters() {
                this.show = "show";
            },
            hideSidebarFilters() {
                this.show = "";
            },
            clearForm() {
                this.$emit("clear-form", {
                    protocolo: null,
                    cadastro: null,
                    idempreendimento: null,
                    idbloco: null,
                    idunidade: null,
                    idassunto: null,
                    idsubassunto: null,
                    idsituacao: null,
                });
            },
            search() {
                this.hideSidebarFilters();
                this.$emit("search", this.searchParams);
            },
            atualizaEmpreendimento(idEmpreendimento) {
                this.getAssuntosEmpreendimento(idEmpreendimento);
            },
            getAssuntosEmpreendimento(idEmpreendimento) {
                getAssuntos(idEmpreendimento)
                    .then((response) => {
                        this.assuntos = response.data.map((a) => ({ id: a.idassunto, label: a.nome }));
                    })
                    .catch((error) => { });
            },
            getSubassuntos(idAssunto) {
                getSubassuntos(idAssunto)
                    .then((response) => {
                        this.subassuntos = response.data.map((s) => ({ id: s.idsubassunto, label: s.nome }));
                    })
                    .catch((error) => { });
            },
            getSituacoes() {
                getSituacoes()
                    .then((response) => {
                        this.situacoes = response.data.map((a) => ({ id: a.idsituacao, label: a.nome }));
                    })
                    .catch((error) => { });
            },
            tratarProtocoloInput() {
                this.searchParams.protocolo = this.searchParams.protocolo.replace(/\D/g, '');
            }
        },
        mounted() {
            document.addEventListener('keydown', (e) => {
                if (this.show && e.key === 'Escape') {
                    this.hideSidebarFilters();
                }
            });
            this.getSituacoes()
        }
    };
</script>

<style scoped>
    .sidebar-filters-overlay {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2223;
        visibility: hidden;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-filters-overlay.show {
        visibility: visible;
    }

    .sidebar-filters {
        position: fixed;
        top: 0;
        right: -300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 300px;
        height: 100%;
        background: #ffffff;
        border-radius: 4px 0 0 0;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-filters.show {
        right: 0;
    }

    .sidebar-filters-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    .sidebar-filters-header h3 {
        font-size: 16px;
        font-weight: 700;
        color: #2f2f2f;
        margin-bottom: 0;
    }

    .sidebar-filters-body {
        flex: 1;
        padding: 20px;
        border-top: 2px solid #d9d9d9;
        background-color: #f6f7f8;
        overflow: auto;
    }

    .sidebar-filters-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    }

    .sidebar-button-close {
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
    }
</style>
