<template>
    <div>
        <LoadingComponent :isLoading="isLoading" />
        <div class="--content">
            <div class="pc-titleBloco">
                <h3>Endereço</h3>
            </div>
            <p>CEP: {{ endereco.cep }}</p>
            <p>Endereço: {{ endereco.logradouro.nome }} {{ endereco.nome }}, {{ endereco.numero }}</p>
            <p>Bairro: {{ endereco.bairro }}</p>
            <p v-if="endereco.complemento">Complemento: {{ endereco.complemento }}</p>
            <p>{{ endereco.cidade.nome }} - {{ endereco.estado.nome }}</p>
        </div>
    </div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import TextInput from "@/components/TextInput";
import {
    getLogradouros,
    getEstados,
    getCidades,
    getEnderecoPorCep,
} from "@/services/EnderecoService";
import { removerMascaraString } from "@/plugins/Utils";
import LoadingComponent from "@/components/LoadingComponent";
import { mapGetters, mapActions } from "vuex";
import { putPessoaEndereco } from "@/services/PessoasService";
const cloneDeep = require("clone-deep");

export default {
    components: {
        SelectInput,
        LoadingComponent,
        TextInput,
    },

    data() {
        return {
            logradouros: [],
            estados: [],
            cidades: [],
            endereco: null,
            isLoading: false,
        };
    },

    computed: {
        ...mapGetters({
            cliente: "getCliente",
        }),

        ...mapActions(["getMe"]),
    },

    beforeMount() {
        this.endereco = cloneDeep(this.cliente.endereco);

        getLogradouros()
            .then((response) => {
                this.logradouros = response.data;
            })
            .catch((error) => {});

        getEstados()
            .then((response) => {
                this.estados = response.data;
            })
            .catch((error) => {});

        if (this.endereco.estado) {
            this.atualizarCidades(this.endereco.cidade.idcidade);
        }
    },

    methods: {
        enderecoPorCep() {
            if (this.endereco.cep.length == 9) {
                this.isLoading = true;
                getEnderecoPorCep(removerMascaraString(this.endereco.cep))
                    .then((response) => {
                        this.endereco.logradouro.idLogradouro = Number(
                            response.data.idlogradouro
                        );
                        this.endereco.nome = response.data.endereco;
                        this.endereco.bairro = response.data.bairro;
                        this.endereco.estado.idEstado = Number(
                            response.data.idestado
                        );
                        this.endereco.numero = "";
                        this.endereco.complemento = "";
                        this.atualizarCidades(Number(response.data.idcidade));
                    })
                    .catch((error) => {})
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },

        atualizarCidades(idCidade) {
            getCidades(this.endereco.estado.idestado)
                .then((response) => {
                    this.cidades = response.data;
                    this.endereco.cidade.idcidade = idCidade;
                })
                .catch((error) => {});
        },

        retornarDadosFormulario() {
            return {
                cep: removerMascaraString(this.endereco.cep),
                endereco: this.endereco.nome,
                bairro: this.endereco.bairro,
                numero: this.endereco.numero.toString(),
                complemento: this.endereco.complemento,
                idlogradouro: this.endereco.logradouro.idlogradouro,
                idestado: this.endereco.estado.idestado,
                idcidade: this.endereco.cidade.idcidade,
            };
        },

        verificarForm() {
            if (
                this.cliente.endereco.cep !=
                    removerMascaraString(this.endereco.cep) ||
                this.cliente.endereco.logradouro.idlogradouro !=
                    this.endereco.logradouro.idlogradouro ||
                this.cliente.endereco.nome != this.endereco.nome ||
                this.cliente.endereco.bairro != this.endereco.bairro ||
                this.cliente.endereco.numero != this.endereco.numero ||
                this.cliente.endereco.complemento !=
                    this.endereco.complemento ||
                this.cliente.endereco.estado.idestado !=
                    this.endereco.estado.idestado ||
                this.cliente.endereco.cidade.idcidade !=
                    this.endereco.cidade.idcidade
            ) {
                return this.retornarDadosFormulario();
            } else {
                return false;
            }
        },

        atualizarDados() {
            this.isLoading = true;
            putPessoaEndereco(this.retornarDadosFormulario())
                .then((response) => {
                    this.$emit("sucesso", true);
                    this.getMe();
                })
                .catch((error) => {
                    this.$emit("error", error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
