<template>
    <div v-if="icone === 'fa-alerta'">
        <IconAlert/>
    </div>
    <div v-else-if="icone === 'fa-antecipacao'">
        <IconAntecipacao/>
    </div>
    <div v-else-if="icone === 'fa-assistencia'">
        <IconAssistencia/>
    </div>
    <div v-else-if="icone === 'fa-atendimento'">
        <IconAtendimento/>
    </div>
    <div v-else-if="icone === 'fa-calendario'">
        <IconCalendar/>
    </div>
    <div v-else-if="icone === 'fa-chart'">
        <IconChart/>
    </div>
    <div v-else-if="icone === 'fa-close'">
        <IconClose/>
    </div>
    <div v-else-if="icone === 'fa-contratos'">
        <IconContratos/>
    </div>
    <div v-else-if="icone === 'fa-copy'">
        <IconCopy/>
    </div>
    <div v-else-if="icone === 'fa-documentos'">
        <IconDocumentos/>
    </div>
    <div v-else-if="icone === 'fa-eye'">
        <IconEye/>
    </div>
    <div v-else-if="icone === 'fa-eyeSlash'">
        <IconEyeSlash/>
    </div>
    <div v-else-if="icone === 'fa-facebook'">
        <IconFacebook/>
    </div>
    <div v-else-if="icone === 'fa-faq'">
        <IconFaq/>
    </div>
    <div v-else-if="icone === 'fa-ferramenta'">
        <IconFerramenta/>
    </div>
    <div v-else-if="icone === 'fa-file'">
        <IconFile/>
    </div>
    <div v-else-if="icone === 'fa-fileCheckAlt'">
        <IconFileCheckAlt/>
    </div>
    <div v-else-if="icone === 'fa-financeiro'">
        <IconFinanceiro/>
    </div>
    <div v-else-if="icone === 'fa-icon'">
        <IconHouse/>
    </div>
    <div v-else-if="icone === 'fa-locatario'">
        <IconLocatario/>
    </div>
    <div v-else-if="icone === 'fa-menu'">
        <IconMenu/>
    </div>
    <div v-else-if="icone === 'fa-menu2'">
        <IconMenu2/>
    </div>
    <div v-else-if="icone === 'fa-obra'">
        <IconObra/>
    </div>
    <div v-else-if="icone === 'fa-predio'">
        <IconPredio/>
    </div>
    <div v-else-if="icone === 'fa-puzzlePiece'">
        <IconPuzzlePiece/>
    </div>
    <div v-else-if="icone === 'fa-question'">
        <IconQuestion/>
    </div>
    <div v-else-if="icone === 'fa-resumo'">
        <IconResumo/>
    </div>
    <div v-else-if="icone === 'fa-sair'">
        <IconSair/>
    </div>
    <div v-else-if="icone === 'fa-setaDown'">
        <IconSetaDown/>
    </div>
    <div v-else-if="icone === 'fa-setaLeft'">
        <IconSetaLeft/>
    </div>
    <div v-else-if="icone === 'fa-setaDireita'">
        <IconSetaRight/>
    </div>
    <div v-else-if="icone === 'fa-share'">
        <IconShare/>
    </div>
    <div v-else-if="icone === 'fa-siginInGoogle'">
        <IconSignInGoogle/>
    </div>
    <div v-else-if="icone === 'fa-sucesso'">
        <IconSucesso/>
    </div>
    <div v-else-if="icone === 'fa-Upload'">
        <IconUpload/>
    </div>
    <div v-else-if="icone === 'fa-x'">
        <IconX/>
    </div>
    <div v-else-if="icone === 'fa-financeiro'">
        <Icon/>
    </div>
    <div v-else>
        <IconPuzzlePiece/>
    </div>
</template>

<script>
export default {
    name: "IconHandler",
    props: ["icone"],
}
</script>
