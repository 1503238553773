import MeusContratos from "@/pages/contratos/MeusContratos";
import store from "@/store";

const sindico = store.state.login.acesso_sindico;
let rotas = [];

if (store.getters.mostrarMenu("exibir_contratos") && !sindico) {
  rotas.push({
    path: "/cliente/contratos/:idUnidade?",
    component: MeusContratos,
    name: "contratos",
    props: true,
    meta: {
      title: "Portal do Cliente - Meus Contratos",
    },
  });
}
export default {
  rotas,
};
