const getDefaultState = () => {
    return {
        unidades: [
            {
                idunidade: null,
                nome: null,
                perfil: {
                    nome: null,
                    sigla: 'AC'
                },
                previsao_entrega: null,
                grupo_especial: null,
                financeiro: null,
                bloco: {
                    idbloco: null,
                    nome: null
                },
                etapa: {
                    idetapa: null,
                    nome: null
                },
                empreendimento: {
                    idempreendimento: null,
                    nome: null,
                    porcentagem_total_estagio_obra: null,
                    permitir_criar_atendimento: null,
                    permitir_criar_assistencia: null,
                    permitir_criar_agendamento: null,
                    foto: {
                        nome: null,
                        tipo: null,
                        tamanho: null,
                        url: null
                    }
                },
                andar: null,
                coluna: null
            }
        ],
        unidade: {
            idunidade: null,
            nome: null,
            previsao_entrega: null,
            financeiro: null,
            bloco: {
                idbloco: null,
                nome: null
            },
            etapa: {
                idetapa: null,
                nome: null
            },
            empreendimento: {
                idempreendimento: null,
                nome: null,
                porcentagem_total_estagio_obra: null,
                permitir_criar_atendimento: null,
                permitir_criar_assistencia: null,
                permitir_criar_agendamento: null,
                foto: {
                    nome: null,
                    tipo: null,
                    tamanho: null,
                    url: null
                }
            },
            andar: null,
            coluna: null,
            perfil: {
                nome: null,
                sigla: 'AC'
            }
        }
    }
};

const state = getDefaultState();

export {getDefaultState}
export default state;
