require('./bootstrap');
require('./plugins');
import Vue from 'vue'
import BaseTemplate from './layouts/BaseTemplate'
import router from './routes'
import store from './store'
import '@/components/icons'

import VueRecord from '@codekraft-studio/vue-record'
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import JwPagination from 'jw-vue-pagination';
import VuePaginateAl from 'vue-paginate-al'
import Loading from 'vue-loading-overlay';
import VueMask from 'v-mask';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from "vee-validate/dist/rules";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker';
import Lingallery from 'lingallery';
import VueAnalytics from 'vue-analytics';
import AvatarCropper from "vue-avatar-cropper";
import VueSilentbox from "vue-silentbox";
import VueMixpanel from 'vue-mixpanel';
import Hotjar from 'vue-hotjar'

Vue.config.productionTip = false

/**
 * Global Componetns
 */
Vue.prototype.$eventoMixPanel = 'Portal do Cliente';

Vue.component('preloader-component', () => import('./components/Preloader'));
Vue.component('jw-pagination', JwPagination);
Vue.component('vue-paginate-al', VuePaginateAl);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('lingallery', Lingallery);
Vue.component('AvatarCropper', AvatarCropper);

Vue.use(VueRecord)
Vue.use(Datepicker);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(Loading);
Vue.use(VueSilentbox);
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
        'Domingo', 'Segunda', 'Terça-feira', 'Quarta-feira', 'Quita-feira',
        'Sexta-feira', 'Sábado'
    ],
    dayOfWeekNamesShort: [
        'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
    ],
    monthNames: [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ],
    monthNamesShort: [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
    ],
});
Vue.use(VueAnalytics, {
    id: store.state.configuracoes.configuracoes.especifico_cv.analytics,
    router
});
Vue.use(Hotjar, {
    id: '2603553',
    isProduction: true,
})
Vue.use(VueMixpanel, {
    token: '7ab66aed07a40c4db90310e0a74433a8',
    config: {
        debug: false,
        ignore_dnt: true
    }
})

extend('required', {
    ...required,
    message: 'Campo obrigatório'
});

Vue.mixin({
    methods: {
        verificarRotaExiste: function (rota) {
            return router.resolve({ name: rota }).route.matched.length > 0;
        },
    },
})

new Vue({
    render: h => h(BaseTemplate),
    router,
    store,
    async beforeCreate() {
        if (this.$router.currentRoute.name == 'login') {
            let slug = 'default';
            if (this.$router.currentRoute.params.slug) {
                slug = this.$router.currentRoute.params.slug;
            }
            this.$store.commit('SET_SLUG', slug);
        }
        await this.$store.dispatch('getConfiguracao');
    },
}).$mount('#app');
