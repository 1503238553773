<template>
    <div class="titulo-login">
        <h2
            v-html="'Seja bem-vindo!'"
        ></h2>
        <h1
            v-html="'Fique por dentro das principais informações sobre o seu condomínio'"
        ></h1>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "TituloLoginSindico",
    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
    },
};
</script>
