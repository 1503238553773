export default {
    SET_CONFIGURACAO(state, configuracoes) {
        state.configuracoes = configuracoes;
    },
    SET_SLUG(state, slug) {
        state.slug = slug;
    },
    SET_EMPREENDIMENTO(state, idempreendimento) {
        state.empreendimento = idempreendimento;
    },
    CLEAN_CONFIG(state) {
        state.empreendimento = null;
    }
}
