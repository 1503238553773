const getters = {
    retornarPermissoes: (state) => {
        return state.permissoes;
    },

    retornarPermissoesSindico: (state) => {
        return state.permissoesSindico;
    },

    mostrarMenu: (state) => (permissao) => {
        for (let index = 0; index < state.perfis.length; index++) {
            let perfil = state.perfis[index];
            if (
                state.perfisCliente[perfil] &&
                state.permissoes[perfil][permissao]
            ) {
                return true;
            }
        }
        return false;
    },

    verificarPermissao: (state) => (perfil, permissao) => {
        if (state.permissoes[perfil][permissao]) {
            return true;
        }
        return false;
    },

    verificarPermissoes: (state) => (objeto, permissao) => {
        for (const elemento of objeto) {
            if (state.permissoes[elemento.perfil.sigla][permissao]) {
                return true;
            }
        }
        return false;
    },

    verificarPermissaoSindico: (state) => (permissao) => {
        if (state.permissoesSindico[permissao]) {
            return true;
        }
        return false;
    },
};
export default getters
