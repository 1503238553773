import MinhasAssistencias from '@/pages/assistencias/MinhasAssistencias'
import NovaAssistencia from '@/pages/assistencias/NovaAssistencia'
import NovaVisitaTecnica from '@/pages/assistencias/NovaVisitaTecnica'
import Visualizar from "@/pages/assistencias/Visualizar";
import store from '@/store';
import getSafe from '../../plugins/OptionalChain'

const sindico = store.state.login.acesso_sindico;
const modulos = store.state.configuracoes.configuracoes.modulos;
const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
const unidades = store.state.unidades.unidades;
const perfil = getSafe(() => store.state.unidades.unidade.perfil.sigla);
let rotas = [];

if (modulos.assistencia_tecnica && exibir_menu.assistencia_tecnica) {
    if ((!sindico &&
        getSafe(() => store.state.permissao.permissoes[perfil].criar_assistencias) || unidades === null) ||
        (sindico && getSafe(() => store.state.permissao.permissoesSindico.criar_assistencias))) {
        {
            rotas.push(
                {
                    path: '/cliente/assistencias/cadastrar',
                    component: NovaAssistencia,
                    name: 'novaAssistencia',
                    meta: {
                        title: 'Portal do Cliente - Nova Assistência Técnica',
                        slugDropZone: 'assistencias'
                    }
                }
            );
        }
    }
    rotas.push(
        {
            path: '/cliente/assistencias',
            component: MinhasAssistencias,
            props: true,
            name: 'assistencias',
            meta: {
                title: 'Portal do Cliente - Minhas Assistências Técnicas'
            },
        },
        {
            path: '/cliente/assistencia/:idAssistencia',
            component: Visualizar,
            name: 'assistencia',
            meta: {
                title: 'Portal do Cliente - Assistência Técnica',
                slugDropZone: 'assistencia'
            }
        },
        {
            path: '/cliente/assistencia/:idAssistencia/visita/:item/:idAssistenciaItem',
            component: NovaVisitaTecnica,
            name: 'novaVisitaTecnica',
            meta: {
                title: 'Portal do Cliente - Visita técnica'
            }
        }
    );

}

export default {
    rotas
}