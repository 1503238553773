<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Meus Cadastros"
            subtitulo="Painel com suas pessoas associadas"
        />

        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />

        <div class="row">
            <div class="col-12 col-lg-4">
                <MenuCadastro
                    :key="refreshComponent"
                    :idAssociacao.sync="idAssociacao"
                    @update:idAssociacao="aviso.exibir = false"
                />
            </div>

            <div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-12">
                        <EditarCadastros
                            v-if="idAssociacao"
                            :key="idAssociacao"
                            :idAssociacao="idAssociacao"
                            @exibirAviso="exibirAviso"
                            @removeuAssociacao="novaAssociacao(null)"
                        />
                        <NovoCadastros
                            v-else
                            @novaAssociacao="novaAssociacao"
                            @exibirAviso="exibirAviso"
                            @error="tratarErro"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import HomeButton from "@/components/HomeButton";
import TituloPagina from "@/components/TituloPagina";
import MenuCadastro from "./components/MenuCadastro";
import NovoCadastros from "./components/NovoCadastros";
import EditarCadastros from "./components/EditarCadastros";
import Aviso from "@/components/Aviso";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        BreadCrumbs,
        HomeButton,
        TituloPagina,
        MenuCadastro,
        NovoCadastros,
        EditarCadastros,
        Aviso,
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Cadastros', 'Meus Cadastros', 'Formulário', true, false)
        );
    },

    data() {
        return {
            caminho: [
                {
                    texto: "Meus Cadastros",
                    ativo: true,
                    rota: null,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
            refreshComponent: 0,
            idAssociacao: null,
        };
    },

    methods: {
        exibirAviso(aviso) {
            this.aviso = aviso;
        },

        novaAssociacao(idAssociacao) {
            this.idAssociacao = idAssociacao;
            this.refreshComponent += 1;
        },
        
        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.message;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>