<template>
    <div>
        <div class="pc-titleBloco">
            <div>
                <h3>Atendimento</h3>
            </div>
        </div>
        <div class="boxAtendimento">
            <div class="boxAtendimento--header">
                <ul class="pc-list -full">
                    <li>
                        <p>Data de abertura<span>{{atendimento.cadastrado | dateParse("YYYY-MM-DD HH:mm:ss") | dateFormat("DD/MM/YYYY HH:mm:ss")}}</span></p>
                    </li>
                </ul>
                <div class="pc-tagStatus">
                    <p>Situação: <span :style="`background: ${atendimento.situacao.situacao_cor_bg}; color: ${atendimento.situacao.situacao_cor_nome};`">{{atendimento.situacao.nome}}</span></p>
                </div>
            </div>
            <ul class="pc-list -fourColumns">
                <li>
                    <p>Protocolo<span>#{{atendimento.protocolo}}</span></p>
                </li>
                <li>
                    <p>Título:<span>{{atendimento.titulo}}</span></p>
                </li>
                <li>
                    <p>Empreendimento:<span>{{getSafe(() => atendimento.unidade.empreendimento.nome)}}</span></p>
                </li>
                <li v-if="acessoSindico">
                    <p>Área Comum:<span>{{getSafe(() => atendimento.unidade.empreendimento.area.nome)}}</span></p>
                </li>
                <li v-if="!acessoSindico && !configuracoes.especifico_cv.ocultar_etapa_portal_cliente">
                    <p>Etapa:<span>{{getSafe(() => atendimento.unidade.etapa.nome)}}</span></p>
                </li>
                <li v-if="!acessoSindico && !configuracoes.especifico_cv.ocultar_bloco_portal_cliente">
                    <p>Bloco:<span>{{getSafe(() => atendimento.unidade.bloco.nome)}}</span></p>
                </li>
                <li v-if="!acessoSindico">
                    <p>Unidade(s):<span>{{getSafe(() => atendimento.unidade.nome)}}</span></p>
                </li>
                <li>
                    <p>Assunto:<span>{{getSafe(() => atendimento.assunto.nome)}}</span></p>
                </li>
                <li>
                    <p>Subassunto<span>{{getSafe(() => atendimento.assunto.subassunto)}}</span></p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import getSafe from '@/plugins/OptionalChain';  
import { mapGetters } from "vuex";
export default {
    props: ["atendimento"],

    computed: {
    ...mapGetters({
        acessoSindico: "getAcessoSindico",
        configuracoes: "getConfiguracoes"
    }),
    },
    methods: {getSafe},
};
    
</script>
