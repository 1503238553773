<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<h3>Unidades disponíveis</h3>
			<div
				class="pull-right pc-title-legenda"
				v-if="unidadesDisponiveis.total"
			>
				Informações encontradas: {{ unidadesDisponiveis.total }}
			</div>
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-tabela">
			<div v-if="unidadesDisponiveis.total">
				<div class="pc-tabela--row pc-tabela--head">
					<div class="pc-tabela--td pc-f3">
						<p>Empreendimento</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Etapa</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p>Bloco</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Unidade</p>
					</div>
					<div class="pc-tabela--td pc-f3">
						<p>Motivo</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p>Tipo</p>
					</div>
					<div class="pc-tabela--td pc-f1"></div>
				</div>
				<div
					class="pc-tabela--row"
					v-for="unidade in unidadesDisponiveis.unidades"
					v-bind:key="
						unidade.tipo +
							'-' +
							unidade.idagendamento +
							'-' +
							unidade.unidade.idunidade
					"
				>
					<div class="pc-tabela--td pc-f3">
						<p data-title="Empreendimento">
							{{
								getSafe(
									() => unidade.unidade.empreendimento.nome
								)
							}}
						</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p data-title="Etapa">
							{{ getSafe(() => unidade.unidade.etapa.nome) }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f2">
						<p data-title="Bloco">
							{{ getSafe(() => unidade.unidade.bloco.nome) }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<p data-title="Unidade">
							{{ getSafe(() => unidade.unidade.nome) }}
						</p>
					</div>
					<div class="pc-tabela--td pc-f3">
						<p v-if="unidade.motivo" data-title="Motivo">
							{{ unidade.motivo }}
						</p>
						<p v-else data-title="Motivo">--</p>
					</div>
					<div class="pc-tabela--td pc-f1">
						<div v-if="unidade.tipo == 'vistoria'">
							<p
								class="pc-labelStatus"
								style="background: #dc910c; color: white"
							>
								Vistoria
							</p>
						</div>
						<div v-else>
							<p
								class="pc-labelStatus"
								style="background: #1e90ff; color: white"
							>
								Visita
							</p>
						</div>
					</div>
					<div
						class="pc-tabela--td pc-tabela--td-icons pc-f1 v justify-content-center"
					>
						<router-link v-if="unidade.vistoriadores > 0 && unidade.unidade.empreendimento.permitir_criar_agendamento"
							:to="{
								name: 'novo-agendamento',
								params: {
									idAgendamento: unidade.idagendamento,
									idUnidade: unidade.unidade.idunidade,
								},
							}"
							class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
						>
							Agendar
						</router-link>
					</div>
				</div>
				<PaginateInput
					:total="unidadesDisponiveis.total"
					:limit="paginacao.limit"
					:offset="paginacao.offset"
					@paginacao="montarListagem"
				/>
			</div>
			<div v-else>
				Nenhuma unidade disponível encontrada para realizar agendamento.
			</div>
		</div>
	</div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { getUnidadesDisponiveis } from "@/services/AgendamentosService";
import getSafe from "@/plugins/OptionalChain";

export default {
	name: "ListagemUnidadesDisponiveis",

	components: {
		LoadingComponent,
		PaginateInput,
	},
	data() {
		return {
			paginacao: {
				offset: 0,
				limit: 30,
			},
			unidadesDisponiveis: [],
			isLoading: false,
		};
	},
	beforeMount() {
		this.montarListagem(1);
	},
	methods: {
		montarListagem(pagina) {
			this.isLoading = true;
			getUnidadesDisponiveis(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit
			)
				.then((response) => {
					this.unidadesDisponiveis = response.data;
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		getSafe,
	},
};
</script>
