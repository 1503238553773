<template>
	<div class="pc-content">
		<BreadCrumbs :caminho="caminho" />
		<TituloPagina
			:titulo="'Portal de Benefícios'"
			:subtitulo="'Cupons e Desconto em lojas parceiras'"
		/>
		<div class="row">
			<p v-if="marketplaces.length==0">Nenhum cupom encontrado</p>
			<div v-else v-for="(marketplace, key, index) in marketplaces" :key="`${ key }-${ index }`" class="col-xl-2 col-lg-4 col-md-6">
				<a 
					@click="modalInput(key)"
					href="javascript:void(0)"
                    :ref="'openModal' + marketplace.idmarketplace"
					class="card-marketplace hvr-buzz-out pc-sameHeight"
					data-toggle="modal"
					data-target="#exampleModal" 
				>
					<div v-if="marketplace.dias_restantes>0" class="--label">Faltam {{getSafe(() => marketplace.dias_restantes)}} dias</div>
					<div v-else-if="marketplace.dias_restantes==0" class="--label">Válido até hoje</div>
					<div v-else-if="marketplace.dias_restantes<0" class="--label">Cupom vencido</div>
					<div class="logo">
						<img
							:src="marketplace.url"
						/>
					</div>
					<div class="--content">
						<h3>{{getSafe(() => marketplace.valor_desconto)}}% de Desconto</h3>
						<p>
							{{getSafe(() => marketplace.descricao_card)}}
						</p>
					</div>
				</a>
			</div>	
		</div>

    <div
      class="modal fade marketplace-modal"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <header class="--header">
            <div class="--logo">
              <img v-if="marketplace" :src="marketplace.url" />
            </div>
            <div class="--content">
              <h2>{{ getSafe(() => marketplace.nome_loja) }}</h2>

              <p>
                <b
                  >{{ getSafe(() => marketplace.valor_desconto) }}% de
                  Desconto</b
                >
                | De {{ getSafe(() => marketplace.data_cad) | formatDate }} -
                Até
                {{ getSafe(() => marketplace.data_validade) | formatDate }}
              </p>
              <p>
                {{ getSafe(() => marketplace.descricao_loja) }}
              </p>
            </div>
          </header>
          <div class="--passo-passo" style="display: block">
            <div class="--content">
              <h5>Veja como é fácil participar:</h5>

              {{ getSafe(() => marketplace.regras_desconto) }}
            </div>
            <br />
            <div class="--cupom" style="text-align: center">
              <input
                v-if="marketplace"
                href="javascript:void(0)"
                @click="copyCupom()"
                :value="marketplace.cupom_desconto"
                type="text"
                class="copy-cupom"
                readonly
              />
              <span class="legenda-copy">Clique para Copiar</span>
            </div>
          </div>
          <div v-if="marketplace && marketplace.logradouro_loja" class="--mapa">
            <AddGoogleMap :address="marketplace.logradouro_loja" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 50px;
}
</style>
<script>
import getSafe from "@/plugins/OptionalChain";
import AddGoogleMap from "./components/AddGoogleMap";
import BreadCrumbs from "@/components/BreadCrumbs";
import TituloPagina from "../../components/TituloPagina";
import { mapState } from "vuex";
import { getPortalBeneficios } from "@/services/PortalBeneficios";
import { objetoTraking } from "../../plugins/Utils";
import moment from "moment";
import Vue from "vue";

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  created() {
    this.$mixpanel.track(
      Vue.prototype.$eventoMixPanel,
      objetoTraking(
        "Minhas Unidades",
        "Minhas Unidades",
        "Listagem",
        true,
        false
      )
    );
  },

  components: {
    TituloPagina,
    BreadCrumbs,
    AddGoogleMap,
  },

  beforeMount() {
    this.setarPagina(1);
  },

  watch: {
    marketplaceData(idmarketplace) {
      this.modalInput(idmarketplace);
    },
  },

	methods: {
		getSafe,
		modalInput(idmarketplace){
			this.marketplace = this.marketplaces[idmarketplace]
		},
		setarPagina(pagina) {
			this.isLoading = true;
			getPortalBeneficios(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit,
			)
			.then((response) => {
				this.marketplaces = response.data.marketplaces;
				this.paginacao.total = response.data.total;
                this.checkRouteParams();
			})
			.catch((error) => {
				if (error.response.status != 404) {
					this.tratarErro(error.response.data);
				}
			})
			.finally(() => {
				this.isLoading = false;
			});
		},
		copyCupom() {
			let testingCodeToCopy = document.querySelector(".copy-cupom");
			let legenda = document.querySelector(".legenda-copy");
			testingCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        document.getSelection().removeAllRanges();
        testingCodeToCopy.classList.add("active");
        legenda.innerHTML = "Copiado";
        const myTimeout = setTimeout(function () {
          testingCodeToCopy.classList.remove("active");
          legenda.innerHTML = "Clique para Copiar";
        }, 1000);
      } catch (err) {}
    },
        checkRouteParams() {
            if (this.$route.params.idmarketplace) {
                let marketplace = this.marketplaces.find(
                    ({ idmarketplace }) => idmarketplace === parseInt(this.$route.params.idmarketplace)
                );
                if (marketplace) {
                    this.marketplace = marketplace;
                }
            }
        },
        autoShowModal() {
            if (this.$route.params.idmarketplace) {
                setTimeout(() => {
                    this.$refs["openModal" + this.$route.params.idmarketplace][0].click();
                }, 500);
            }
        },
  },
  mounted() {
    //equalHeight
    let windowWidth = window.innerWidth;
    let pcSameHeight = document.querySelectorAll(".pc-sameHeight");

    if (windowWidth > 992) {
      sameHeight();
    }
    window.onresize = sameHeight;

    function sameHeight() {
      let maxHeight = 0;

      if (pcSameHeight !== null) {
        pcSameHeight.forEach(function (elPcSameHeight) {
          if (elPcSameHeight.offsetHeight > maxHeight) {
            maxHeight = elPcSameHeight.offsetHeight;
          }
        });
        pcSameHeight.forEach(function (elPcSameHeight) {
          elPcSameHeight.style.height = maxHeight + "px";
        });
      }
    }
    this.autoShowModal();
  },

	data() {
		return {
			caminho: [
				{
					texto: "Portal de Benefícios",
					ativo: true,
					rota: null,
				},
			],
			marketplaces: [],
			marketplace: null,
			paginacao: {
				total: 0,
				offset: 0,
				limit: 30,
			},
		};
	},
};
</script>