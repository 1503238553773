<template>
  <div class="pc-bloco">
    <Aviso
      :exibir="alerta.exibir"
      :mensagem="alerta.mensagem"
      :tipo="alerta.tipo"
    />
    <div class="pc-titleBloco">
      <h3>Formulário de cadastro de documentos do empreendimento</h3>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(enviarArquivos)"
        class="fileuploader center-align center-block"
      >
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="titulo"><strong>*Título</strong></label>
                <input
                  type="text"
                  class="form-control input-alpha"
                  placeholder="Título"
                  name="titulo"
                  v-model="formulario.titulo"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <SelectInput
                label="nome"
                titulo="Empreendimento"
                :reduce="(option) => option.idempreendimento"
                :options="empreendimentos"
                :valor.sync="formulario.idempreendimento"
                :placeholder="
                  empreendimentos.length > 0
                    ? 'Selecione um Empreendimento'
                    : 'Buscando empreendimentos, por favor aguarde...'
                "
                @update:valor="escolheuEmpreendimento"
                :regras="'required'"
              />
            </div>
          </div>

          <div class="col-lg-6 col-12">
            <div class="form-group">
              <SelectInput
                titulo="Unidade(s)"
                :options="unidades"
                :reduce="(option) => option.idunidade"
                :valor.sync="formulario.unidades"
                :placeholder="placeHolderUnidade"
                multiple
              />
            </div>
          </div>
        </div>

        <div class="row mt-20">
          <div class="col-12">
            <div class="control-group">
              <ValidationProvider v-slot="{ errors }">
                <label class="control-label" for="descricao">Descrição</label>
                <VueEditor
                  v-model="formulario.descricao"
                  :editorToolbar="customToolbar"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="control-group with-top-padding">
              <label class="control-label"><strong>*Arquivo(s):</strong></label>
              <label class="error" v-if="erroSemArquivo"
                ><span>Campo obrigatório</span></label
              >
              <Dropzone
                ref="dropzone"
                @sucesso="cadastrarDocumentoSindico"
                @erroArquivos="erroEnviarArquivos"
                :uniqid="formulario.uniqid"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-12 btnEnviar">
            <button
              :disabled="acessoComo"
              name="btSend"
              type="submit"
              value="Enviar"
              class="btn-form pc-btnBox pc-btnBox-secondary"
            >
              Cadastrar documento</button
            >&nbsp;
            <router-link
              :to="{ name: 'novoDocumento' }"
              class="btn-form pc-btnGhost"
            >
              <span>Cancelar</span>
            </router-link>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { retornarUnidadesDocumentoSindico } from "@/services/EmpreendimentoService";
import { gerarUniqId } from "@/plugins/Utils";
import { cadastrarDocumento } from "@/services/DocumentosService";
import Dropzone from "@/components/Dropzone";
import SelectInput from "@/components/SelectInput";
import Aviso from "@/components/Aviso";
import LoadingComponent from "@/components/LoadingComponent";
import { VueEditor } from "vue2-editor";
import { retornarDadosSindico } from "@/services/SindicosService";
import { mapState, mapGetters } from "vuex";
import { objetoTraking } from "../../../../plugins/Utils";
import Vue from "vue";

export default {
  computed: {
    ...mapState({
      unidadesCliente: (state) => state.unidades.unidades,
      configuracoes: (state) => state.configuracoes.configuracoes,
    }),
    ...mapGetters({
      verificarPermissao: "verificarPermissao",
      acessoComo: "getAcessoComo",
      acessoSindico: "getAcessoSindico",
    }),
  },

  created() {
    this.$mixpanel.track(
      Vue.prototype.$eventoMixPanel,
      objetoTraking(
        "Documentos - Síndico",
        "Novo Documento",
        "Formulário",
        true,
        false
      )
    );
  },

  components: {
    Dropzone,
    SelectInput,
    LoadingComponent,
    VueEditor,
    Aviso,
  },

  data() {
    return {
      erroSemArquivo: false,
      empreendimentos: [],
      unidades: [],
      isLoading: false,
      placeHolderUnidade:
        "Selecione o 'Empreendimento' antes de escolher uma Unidade",
      alerta: {
        exibir: false,
        mensagem: null,
        tipo: null,
      },
      formulario: {
        titulo: null,
        uniqid: null,
        descricao: null,
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["clean"], // remove formatting button
      ],
    };
  },

  beforeMount() {
    this.formulario.uniqid = gerarUniqId();

    retornarDadosSindico()
      .then((result) => {
        this.empreendimentos = result.data.empreendimentos_associados;
        if (this.empreendimentos.length == 1) {
          this.formulario.idempreendimento = this.empreendimentos[0].idempreendimento;
          this.escolheuEmpreendimento(this.formulario.idempreendimento);
        } else {
          this.formulario.idempreendimento = null;
        }
      })
      .catch((error) => {});
  },

  methods: {
    escolheuEmpreendimento(idEmpreendimento) {
      this.formulario.unidades = null;
      retornarUnidadesDocumentoSindico(idEmpreendimento)
        .then((response) => {
          let unidades = [];
          for (let elemento of response.data) {
            let unidade = {
              label: `${elemento.bloco.nome} - ${elemento.nome}`,
              idunidade: elemento.idunidade,
            };
            unidades.push(unidade);
          }
          this.unidades = unidades;
          if (this.unidades.length == 1) {
            this.formulario.unidades = [this.unidades[0].idunidade];
          }
          this.placeHolderUnidade = "Selecione a(s) Unidade(s)";
        })
        .catch((error) => {});
    },

    enviarArquivos() {
      if (this.acessoComo) {
        return;
      }

      this.isLoading = true;
      this.erroSemArquivo = false;
      if (this.$refs.dropzone.quantidadeArquivos() === 0) {
        this.erroSemArquivo = true;
        this.isLoading = false;
        return;
      }

      this.$refs.dropzone.enviarArquivos();
    },

    async cadastrarDocumentoSindico() {
      this.trakingMixpanel(
        "Novo documento síndico",
        "Criar documento síndico",
        true,
        true
      );

      cadastrarDocumento(this.formulario)
        .then((response) => {
          this.$router.push({
            name: "documentos",
          });
        })
        .catch((error) => {
          this.$refs.dropzone.removerArquivos();
          this.alerta = {
            exibir: true,
            mensagem: Object.values(error.response.data)[0],
            tipo: "erro",
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    empreendimentosAtivosAtendimentos(empreendimentos) {
      let empreendimentoAtendimento = [];
      for (let empreendimento of empreendimentos) {
        if (empreendimento.permitir_criar_atendimento) {
          empreendimentoAtendimento.push(empreendimento);
        }
      }
      return empreendimentoAtendimento;
    },

    erroEnviarArquivos(error) {
      this.alerta = {
        exibir: true,
        mensagem: "Erro interno ao tentar enviar o(s) anexo(s) do atendimento.",
        tipo: "erro",
      };
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Atendimentos", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>

<style lang="css">
.endereco {
  padding-top: 10px;
  border-top: 1px dashed #d8d8d8;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}

.with-top-padding {
  padding-top: 10px;
}
</style>
