import { getConfiguracoes } from '@/services/ConfiguracoesService'
import router from "../../../routes";

export default {
    getConfiguracao({commit}) {
        getConfiguracoes()
            .then(response => {
                commit('SET_CONFIGURACAO', response.data)
            })
            .catch(error => {
                if (
                  error.response.status == 404 &&
                  router.currentRoute.path == "/cliente"
                ) {
                  router.push({ name: "not-found" });
                }
                 console.error(error)
            });
    },
}
