var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titulo-login"},[_c('h2',{domProps:{"innerHTML":_vm._s(
            _vm.configuracoes.tela_login && _vm.configuracoes.tela_login.titulo
                ? _vm.configuracoes.tela_login.titulo
                : 'Seja Bem-vindo'
        )}}),_c('h1',{domProps:{"innerHTML":_vm._s(
            _vm.configuracoes.tela_login && _vm.configuracoes.tela_login.subtitulo
                ? _vm.configuracoes.tela_login.subtitulo
                : 'Fique por dentro de cada detalhe do seu imóvel'
        )}})])
}
var staticRenderFns = []

export { render, staticRenderFns }