<template>
    <div class="pc-bloco closeButton" v-if="avisoGeral">
        <a class="btnFecharBloco" href="javascript:void(0)" @click.prevent="fecharAviso()"><span>x</span></a>
        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />
        <ul class="pc-list -fourColumns">
            <li>
                <p>Cliente<span>{{ cliente.nome }}</span></p>
            </li>
            <li>
                <p>Empreendimento<span>{{ empreendimento }}</span></p>
            </li>
            <li>
                <p>Unidade<span>{{ unidade }}</span></p>
            </li>
            <li>
                <p>Tipo de Agendamento<span>{{ tipo }}</span></p>
            </li>
            <li>
                <p>Motivo<span>{{ motivo }}</span></p>
            </li>
            <li>
                <p>Data<span>{{ data }}</span></p>
            </li>
            <li>
                <p>Horário<span>{{ horario }}</span></p>
            </li>
            <li>
                <p>Vistoriador<span>{{ vistoriador }}</span></p>
            </li>
        </ul>
    </div>
</template>

<script>
import Aviso from "@/components/Aviso";
import {mapGetters} from "vuex";

export default {
    name: "AvisoAgendamentoConcluido",
    computed: {
        ...mapGetters({
            cliente: "getCliente",
        }),
    },
    props: {
        empreendimento: { type: String, required: true },
        unidade: { type: String, required: true },
        tipo: { type: String, required: true },
        motivo: { type: String, default: "-" },
        data: { type: String, required: true },
        horario: { type: String, required: true },
        vistoriador: { type: String, required: true }
    },
    components: {
        Aviso,
    },
    data() {
        return {
            avisoGeral: true,
            aviso: {
                exibir: true,
                tipo: 'sucesso',
                mensagem: 'Seu agendamento foi cadastrado com sucesso!',
            },
        };
    },
    methods: {
        fecharAviso() {
            this.avisoGeral = false;
        }
    }
}
</script>
