<template>
    <div class="pc-bloco mb-20" v-if="unidade">
        <div class="row">
            <div class="pc-titleBloco col-12">
                <h3>Status da Obra</h3>
            </div>
            <LoadingComponent :isLoading="isLoading"/>
            <div class="pc-grafico-status-obra-noStatusChart col-12" v-if="noStatusChart">
                <span class="pc-grafico-status-obra-posicao"><font size="3"><b>Nenhum Resultado Encontrado!</b></font></span>
            </div>
            <div
                class="pc-grafico-status-obra col-12"
                v-bind:style="{filter: 'blur(' + (noStatusChart ? '3' : '0') + 'px)'}"
            >
                <div class="row">
                    <div class="col-12 col-md-10">
                        <div class="pc-grafico-status-obra--svgs">
                            <svg preserveAspectRatio="none" class="pc-grafico-status-obraPorcentagem" width="360"
                                 height="360" xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    class="pc-grafico-status-obraPorcentagem--bar"
                                    width="0"
                                    height="38"
                                    x="0"
                                    y="0"
                                    fill="none"
                                    v-bind:style="{width: (noStatusChart ? '80' : andamentoObra) + '%'}"
                                />
                            </svg>
                            <svg preserveAspectRatio="none" class="pc-grafico-status-obraMask" width="360" height="360"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    class="pc-grafico-status-obraMask--bar"
                                    width="0"
                                    height="38"
                                    x="-25"
                                    y="20"
                                    fill="none"
                                    stroke="white"
                                />
                            </svg>
                            <svg preserveAspectRatio="none" class="pc-grafico-status-obraBg" width="360" height="360"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect
                                    class="pc-grafico-status-obraBg--bar"
                                    width="0"
                                    height="38"
                                    x="-25"
                                    y="0"
                                    fill="none"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mt-3 mt-md-0">
                        <div class="pc-grafico-status-obra--info">
                            <p>{{ noStatusChart ? '80' : andamentoObra }}% <span>Status Geral</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <router-link :to="{ name: 'andamento-obra' }" class="pc-btnSelect">
                    <span>Ver mais Detalhes da obra</span>
                    <IconSetaRight/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {retornarAndamentoObra} from "@/services/EmpreendimentoService";
import LoadingComponent from "@/components/LoadingComponent";

export default {
    components: {
        LoadingComponent,
    },

    computed: {
        ...mapState({
            unidade: state => state.unidades.unidade,
        }),
        noStatusChart() {
            return this.unidade.empreendimento.porcentagem_total_estagio_obra === null
        }
    },

    data() {
        return {
            isLoading: false,
            andamentoObra: null,
        };
    },

    watch: {
        unidade(newValue) {
            return newValue;
        },
        andamentoObra(newValue) {
            return newValue;
        },
    },

    beforeMount() {
        this.isLoading = true;
        this.retornarDadosAndamento();
    },

    methods: {
        retornarDadosAndamento() {
            retornarAndamentoObra(this.unidade.empreendimento.idempreendimento, this.unidade.idunidade)
                .then((response) => {
                    this.andamentoObra = response.data["porcentagem_total"];
                })
                .catch((error) => {
                    if (error.response.status != 404) {
                        this.$emit("error", error.response.data);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
</script>
