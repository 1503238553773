<template>
    <div
      class="pesquisa-bar"
    >
        <div class="--text">
            <h3>Título da pesquisa</h3>
            <p>Texto introdutório, explicativo da pesquisa.</p>
        </div>
        <div class="--btns">
            <a href="#" target="_blank" class="pc-btnBox pc-btnBox-primary">Link Pesquisa</a>
            <a href="javascript:void(0)" class="--btn-close" @click="handlePesquisaBar">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="times"><path fill="#0092E4" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg>
            </a>
        </div>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  
  
  export default {
    components: {
      
    },
  
    computed: {
      
    },
  
    data() {
      return {
        showBar: true,
      };
    },
  
    methods: {
        handlePesquisaBar() {
           this.$root.$emit('pesquisaStatus')
        }
    },
  };
  </script>
  