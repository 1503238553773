<template>
  <div v-if="unidades && unidades.length > 1">
    <div class="dropdown pc-dropdownArrow">
      <a
        href="#"
        class="pc-btnBox pc-btnBox-icon-right pc-btnBox-primary"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span>Trocar de Unidade</span>
        <IconSetaDown />
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <a
          v-for="unidade in unidades"
          v-bind:key="unidade.idunidade"
          class="dropdown-item"
          href="#"
          @click.prevent="setarUnidade(unidade.idunidade, unidade.empreendimento.idempreendimento)"
        >
          <span v-if="unidade.idunidade != unidadeGlobal" class="ml-0">
            {{ unidade.empreendimento.nome }}
          </span>
          <span v-if="unidade.idunidade != unidadeGlobal">
            {{ unidade.bloco.nome }} - {{ unidade.nome }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { setUnidadePadrao } from "@/services/PessoasService";

export default {
  computed: {
    ...mapState({
      unidades: (state) => state.unidades.unidades,
      unidadeGlobal: (state) => state.unidades.unidade.idunidade,
    }),
  },

  methods: {
    ...mapActions(["novaUnidadePadrao"]),

    setarUnidade(idUnidade, idempreendimento) {
      this.novaUnidadePadrao(idUnidade);
      this.$store.commit('SET_EMPREENDIMENTO', idempreendimento);
      setUnidadePadrao({ idunidade: idUnidade });
    }
  },
};
</script>
