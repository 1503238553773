<template>
    <vue-recaptcha
        ref="recaptcha"
        @verify="onVerifyCaptcha"
        @expired="onCaptchaExpired"
        :sitekey="recaptcha"
        :loadRecaptchaScript="true"
        size="invisible"
    />
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
const { recaptcha_site_key } = require("@/configs/recaptcha");
export default {
    components: {
        VueRecaptcha,
    },

    data() {
        return {
            recaptcha: recaptcha_site_key,
        };
    },

    methods: {
        execute() {
            this.$refs.recaptcha.execute();
        },

        onVerifyCaptcha(response) {
            this.$emit("verified", response);
            this.$refs.recaptcha.reset();
        },

        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        },
    },
};
</script>
<style>
.grecaptcha-badge {
    visibility: hidden;
}
</style>
