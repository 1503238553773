<style>
.pc-tabela.pc-tabela-cor .pc-tabela--row:before {
    z-index: initial;
}
.pc-tabela.pc-tabela-cor .pc-tabela--row .pc-tabela--td {
    z-index: initial;
}
</style>
<template>
	<div class="pc-bloco">
		<div class="pc-titleBloco">
			<div>
				<h3>Demonstrativo de IR</h3>
				<p>
					As informações a serem apresentadas abaixo são
					empreendimentos adquiridos pelo cliente e servem para
					obter-se uma filtragem nos dados que serão exibidos no
					relatório de demonstrativo de valores pagos.
				</p>
			</div>
		</div>
		<div class="pc-selectAno col-lg-4 col-12">
			<SelectInput
				titulo="Ano Base"
				:options="anosImposto"
				:clearable="false"
				:valor.sync="anoEscolhido"
			/>
		</div>
		<div class="pull-right pc-title-legenda" v-if="listagemImpostoRenda">
			Informações encontradas: {{ listagemImpostoRenda.total }}
		</div>
		<LoadingComponent :isLoading="isLoading" />
		<div class="pc-tabela" v-if="listagemImpostoRenda">
			<div class="pc-tabela--row pc-tabela--head">
				<div class="pc-tabela--td pc-f3">
					<p>Empreendimento</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Nº do Contrato</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Bloco</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Unidade</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p>Ano Base</p>
				</div>
				<div class="pc-tabela--td pc-f1 pc-column-option"></div>
			</div>
			<div
				v-for="impostoRenda in listagemImpostoRenda.unidades"
				v-bind:key="impostoRenda.numero_contrato"
				class="pc-tabela--row"
			>
				<div class="pc-tabela--td pc-f3">
					<p data-title="Empreendimento">
						{{ impostoRenda.unidade.empreendimento.nome }}
					</p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Nº do Contrato">
            {{ impostoRenda.numero_contrato }}
          </p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Bloco">
            {{ impostoRenda.unidade.bloco.nome }}
          </p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Unidade">
            {{ impostoRenda.unidade.nome }}
          </p>
				</div>
				<div class="pc-tabela--td pc-f1">
					<p data-title="Ano Base">
            {{ anoEscolhido }}
          </p>
				</div>
                <div class="pc-tabela--td pc-f1 pc-column-option">
                    <div class="dropdown">
                        <a
                            href="javascript:void(0)"
                            class="pc-btnBox pc-btnBox-small pc-btnBox-primary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Opções
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <a class="dropdown-item"
                            :class="acessoComo ? 'disabled' : null"
                            @click="
                                enviarDeclaracao(
                                    impostoRenda.unidade.empreendimento
                                        .idempreendimento,
                                    impostoRenda.unidade.idunidade
                                )
                            "
                            >Enviar para o e-mail</a>
                            <a class="dropdown-item"
                               :class="acessoComo ? 'disabled' : null"
                               @click="
                                baixarDeclaracao(
                                    impostoRenda.unidade.empreendimento
                                        .idempreendimento
                                )
                            "
                            >Fazer o download</a>
                        </div>
                    </div>
                </div>
			</div>
			<PaginateInput
				:total="listagemImpostoRenda.total"
				:limit="paginacao.limit"
				:offset="paginacao.offset"
				@paginacao="montarListagem"
			/>
		</div>
		<div v-else>Nenhuma informação encontrada.</div>
	</div>
</template>

<script>
import {
    getEmpreendimentosIr,
    gerarRelatorioImpostoDeRenda, retornarImpostoDeRendaLink,
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import SelectInput from "@/components/SelectInput";
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";
export default {
	props: {
		idUnidade: { type: Number },
	},
	emits: ["error", "exibirAviso"],

	components: {
		LoadingComponent,
		PaginateInput,
		SelectInput,
	},

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
		}),
	},

	data() {
		return {
			listagemImpostoRenda: null,
			anoEscolhido: null,
			anosImposto: null,
			paginacao: {
				offset: 0,
				limit: 30,
			},
			isLoading: false,
		};
	},

	beforeMount() {
		this.montarListagem(1);

		const anoAtual = new Date().getFullYear();
		this.anosImposto = [anoAtual - 1, anoAtual - 2, anoAtual - 3];
		this.anoEscolhido = this.anosImposto[0];
	},

	methods: {
		montarListagem(pagina) {
			this.isLoading = true;
			getEmpreendimentosIr(
				(pagina - 1) * this.paginacao.limit,
				this.paginacao.limit
			)
				.then((response) => {
					let unidades  = [];
					response.data.unidades.forEach(element => {
						if (element.unidade.idunidade == this.idUnidade) {
							unidades.push(element);
						}
					});
					response.data.unidades = unidades;
					this.listagemImpostoRenda = response.data;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.$emit("error", error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

        baixarDeclaracao(idEmpreendimento) {
            if (this.acessoComo) {
                return;
            }
            this.isLoading = true;
            this.trakingMixpanel(
                "Demonstrativo de IR",
                "Baixar demonstrativo",
                true,
                true
            );
            retornarImpostoDeRendaLink(idEmpreendimento, this.anoEscolhido)
                .then((response) => {
                    window.open(response.data.link, "_blank");
                    console.log(JSON.stringify({'data': response.data.link, 'origem': 'imposto_de_renda'}));
                })
                .catch((error) => {
					this.$emit(
							"exibirAviso", 
							{
								exibir: true,
								tipo: "erro",
								mensagem: error.response.data.message,
							}
						);
						
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

		enviarDeclaracao(idEmpreendimento, idUnidade) {
			if (this.acessoComo) {
				return;
			}

			this.isLoading = true;
			this.trakingMixpanel(
				"Demonstrativo de IR",
				"Enviar demonstrativo",
				true,
				true
			);
			gerarRelatorioImpostoDeRenda(idEmpreendimento, this.anoEscolhido, idUnidade)
				.then((response) => {
					this.$emit("exibirAviso", {
						exibir: true,
						tipo: "sucesso",
						mensagem:
							"Relatório de imposto de renda enviado com sucesso.",
					});
				})
				.catch((error) => {
					this.$emit("error", error.response.data);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking("Financeiro", acao, subacao, intencao, ateracao)
			);
		},
	},
};
</script>

<style scoped>
.pull-right {
	text-align: right;
}
</style>
