<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Minhas Pesquisas"
            subtitulo="Painel com informações de suas pesquisas"
        />

        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />

        <div class="row">
            <div class="col-12">
                <ListagemPesquisas @error="tratarErro" />
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemPesquisas from "./components/ListagemPesquisas";
import TituloPagina from "@/components/TituloPagina";
import Aviso from "@/components/Aviso";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        BreadCrumbs,
        ListagemPesquisas,
        TituloPagina,
        Aviso,
    },
    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Minhas Pesquisas', 'Minhas Pesquisas', 'Listagem', true, false)
        );
    },
    data() {
        return {
            caminho: [
                {
                    texto: "Minhas Pesquisas",
                    ativo: true,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
        };
    },

    methods: {
        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>