<template>
  <div class="pc-login-form--wrap">
    <div class="titulo-login">
      <h2>Seja Bem-vindo</h2>
      <h1>Utilize os campos abaixo para criar sua nova senha:</h1>
    </div>
    <Aviso
        id="aviso"
        :exibir="alerta.exibir"
        :mensagem="alerta.mensagem"
        :tipo="alerta.tipo"
    />
    <router-link
        v-if="alerta.exibir && alerta.tipo === 'erro'"
        class="pc-btnBox pc-btnBox--fullW"
        :to="{
           name: configuracoes.tela_login.padrao_reset_senha === 'sms' ?
           ($route.params.modulo === 'sindico' ? 'esqueceu-senha-sms-sindico' : 'esqueceu-senha-sms') :
           ($route.params.modulo === 'sindico' ? 'esqueceu-senha-email-sindico' : 'esqueceu-senha-email'),
           params: {slug: this.$store.state.configuracoes.slug}
        }"
    >
      Gerar uma nova solicitação
    </router-link>
    <router-link
        v-if="alerta.exibir && alerta.tipo === 'sucesso'"
        class="pc-btnBox pc-btnBox--fullW"
        :to="{
           name: 'login',
           params: {slug: this.$store.state.configuracoes.slug}
        }"
    >
      Voltar para realizar login
    </router-link>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form v-if="exibirForm" @submit.prevent="handleSubmit(alterarSenha)">
        <div id="cadastre-se" class="form-group">
          <LoadingComponent :isLoading="isLoading"/>

          <div class="form-group pc-login-password">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                  :type="exibirSenha ? 'text' : 'password'"
                  name="senha"
                  v-model="formulario.senha"
                  placeholder="Insira sua senha"
                  id="senha"
                  :class="[
                  'form-control',
                  senha_regex && formulario.senha
                    ? {
                        'is-invalid': !senha_regex.test(formulario.senha),
                      }
                    : '',
                ]"
                  required="required"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
              <a
                  href="#"
                  @click.prevent="showPassword()"
                  class="pc-login-showPassword"
              >
                <div v-if="exibirSenha">
                  <IconEye></IconEye>
                </div>
                <div v-else>
                  <IconEyeSlash></IconEyeSlash>
                </div>
              </a>
            </ValidationProvider>
            <label
                v-if="
                configuracoes.especifico_cv.senha_portal_cliente &&
                configuracoes.especifico_cv.senha_portal_cliente.mensagem
              "
                class="form-group--label"
            >
              {{ configuracoes.especifico_cv.senha_portal_cliente.mensagem }}
            </label>
          </div>
          <div class="form-group pc-login-password">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <input
                  :type="exibirSenhaConfirma ? 'text' : 'password'"
                  name="confirma"
                  placeholder="Confirme sua senha"
                  id="confirma"
                  v-model="formulario.confirma"
                  :class="[
                  'form-control',
                  formulario.confirma
                    ? {
                        'is-invalid': formulario.senha !== formulario.confirma,
                      }
                    : '',
                ]"
                  required="required"
              />
              <label class="error">
                <span>{{ errors[0] }}</span>
              </label>
              <a
                  href="#"
                  @click.prevent="showPasswordConfirma()"
                  class="pc-login-showPassword"
              >
                <div v-if="exibirSenhaConfirma">
                  <IconEye></IconEye>
                </div>
                <div v-else>
                  <IconEyeSlash></IconEyeSlash>
                </div>
              </a>
            </ValidationProvider>
          </div>
          <ReCaptcha ref="recaptcha" @verified="enviarFormulario"/>
          <button
              type="submit"
              name="button"
              class="pc-btnBox pc-btnBox--fullW"
              v-bind:disabled="botaoDisable"
          >
            <span>{{ tituloBotao }}</span>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import Aviso from "@/components/Aviso";
import ReCaptcha from "@/components/ReCaptcha";
import {novaSenha, validarSolicitacaoNovaSenha} from "@/services/AutenticacaoService";
import LoadingComponent from "@/components/LoadingComponent";
import IconEye from "@/components/icons/IconEye";
import IconEyeSlash from "@/components/icons/IconEyeSlash";
import {mapGetters} from "vuex";

export default {
  name: "NovaSenha",

  components: {
    IconEyeSlash,
    IconEye,
    Aviso,
    ReCaptcha,
    LoadingComponent,
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
    }),
  },

  data() {
    return {
      isLoading: false,
      exibirSenha: false,
      exibirSenhaConfirma: false,
      exibirForm: true,
      botaoDisable: false,
      senha_regex: null,
      idSolicitacao: null,
      alerta: {
        exibir: false,
        mensagem: null,
        tipo: null,
      },
      formulario: {
        senha: null,
        confirma: null,
        recaptcha_token: null,
      },
      tituloBotao: "Alterar Senha",
    };
  },

  beforeMount() {
    if (
        this.configuracoes.especifico_cv.senha_portal_cliente &&
        this.configuracoes.especifico_cv.senha_portal_cliente.regex
    ) {
      this.senha_regex = new RegExp(
          this.configuracoes.especifico_cv.senha_portal_cliente.regex
      );
    }

    if (!this.$route.params.id || !this.$route.params.hash) {
      this.alerta.exibir = true;
      this.alerta.mensagem = "Link inválido!";
      this.alerta.tipo = "erro";
      this.exibirForm = false;
    } else if (this.$route.params.error) {
      this.alerta.exibir = true;
      this.alerta.mensagem = this.$route.params.error;
      this.alerta.tipo = "erro";
      this.exibirForm = false;
    } else {
      this.isLoading = true;
      validarSolicitacaoNovaSenha({
        idpessoa: this.$route.params.id,
        hash: this.$route.params.hash,
        modulo: this.$route.params.modulo,
        slug: this.$route.params.slug,
      })
          .then((response) => {
            this.idSolicitacao = response.data.idsolicitacao_senha;
            this.isLoading = false;
          })
          .catch((error) => {
            this.alerta.exibir = true;
            this.alerta.mensagem = error.response.data.error;
            this.alerta.tipo = "erro";
            this.exibirForm = false;
          });
    }
  },

  methods: {
    showPassword() {
      this.exibirSenha = !this.exibirSenha;
    },

    showPasswordConfirma() {
      this.exibirSenhaConfirma = !this.exibirSenhaConfirma;
    },

    alterarSenha() {
      if (this.checarFormulario()) {
        this.botaoDisable = true;
        this.tituloBotao = "Alterando...";
        this.isLoading = true;
        this.$refs.recaptcha.execute();
      }
    },

    enviarFormulario(recaptcha) {
      this.formulario.recaptcha_token = recaptcha;

      novaSenha({
        idsolicitacao: this.idSolicitacao,
        idpessoa: this.$route.params.id,
        hash: this.$route.params.hash,
        ...this.formulario
      })
          .then((response) => {
            this.alerta.exibir = true;
            this.alerta.mensagem = "Senha alterada com sucesso!";
            this.alerta.tipo = "sucesso";
            this.exibirForm = false;
          })
          .catch((error) => {
            this.alerta.exibir = true;
            this.alerta.mensagem = error.response.data.error;
            this.alerta.tipo = "erro";
            this.exibirForm = true;
          })
          .finally(() => {
            this.isLoading = false;
            this.botaoDisable = false;
            this.tituloBotao = "Alterar Senha";
          });
    },

    checarFormulario() {
      if (this.senha_regex && !this.senha_regex.test(this.formulario.senha)) {
        alert(this.configuracoes.especifico_cv.senha_portal_cliente.mensagem);
        return false;
      }

      if (this.formulario.senha !== this.formulario.confirma) {
        alert("Senhas não conferem!");
        return false;
      }

      return true;
    },
  },
};
</script>
<style>
.input-tel {
  display: flex !important;
}

.vti__selection span {
  width: 30px;
}
</style>