<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
                titulo="Painel Financeiro"
                subtitulo="Painel com informações de seu financeiro"
        />

        <div class="row">
            <div class="col-12">
                <UnidadePadrao />
            </div>
        </div>
        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />
        <div class="row">
            <div class="col-12 col-lg-3 col-md-4">
                <MenuFinanceiro :ativo.sync="menuAtivo" />
            </div>
            <div v-if="menuAtivo == 'segundaVia' && configuracoes.exibir_financeiro.boletos_cv" class="col-12 col-lg-9 col-md-8">
                <ListagemBoletosSegundaViaCV
                        :key="idUnidade"
                        :idUnidade="idUnidade"
                        @exibirAviso="exibirAviso"
                        @error="tratarErro"
                />
            </div>
            <div
                v-else-if="menuAtivo == 'extratos'"
                class="col-12 col-lg-9 col-md-8"
            >
                <ListagemExtratoCV
                    :idUnidade="idUnidade"
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                />
            </div>
            <div
                v-else-if="menuAtivo == 'demostrativoIr'"
                class="col-12 col-lg-9 col-md-8"
            >
                <ListagemDemonstrativoIrCV
                    @exibirAviso="exibirAviso"
                    @error="tratarErro"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ListagemBoletosSegundaViaCV from "./components/ListagemBoletosSegundaViaCV";
import MenuFinanceiro from "./components/MenuFinanceiro";
import UnidadePadrao from "@/components/UnidadePadrao";
import Aviso from "@/components/Aviso";
import TituloPagina from "@/components/TituloPagina";
import { setUnidadePadrao } from "@/services/PessoasService";
import { mapState, mapGetters, mapActions } from "vuex";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";
import ListagemDemonstrativoIrCV from "@/pages/financeiro/components/ListagemDemonstrativoIrCV.vue";
import ListagemExtratoCV from "@/pages/financeiro/components/ListagemExtratoCV.vue";

export default {
    props: {
        ativo: { type: String, default: "segundaVia" },
    },
    components: {
        ListagemExtratoCV, ListagemDemonstrativoIrCV,
        BreadCrumbs,
        ListagemBoletosSegundaViaCV,
        UnidadePadrao,
        MenuFinanceiro,
        Aviso,
        TituloPagina,
    },

    computed: {
        ...mapState({
            unidadeGlobal: (state) => state.unidades.unidade,
            ...mapGetters({
                verificarPermissao: "verificarPermissao",
                configuracoes: "getConfiguracoes"
            }),
        }),
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes
        }),
    },
    beforeMount() {
        let idUnidade = this.$route.params.idUnidade;
        if(idUnidade !== undefined){
            this.novaUnidadePadrao(idUnidade);
            setUnidadePadrao({ idunidade: idUnidade });
        }
    },
    mounted() {
        var arrayConfiguracoes = [
            { permissao: 'boletos_cv', ativo: 'segundaVia'},
        ];

        for (let index of arrayConfiguracoes) {
            if (this.configuracoes.exibir_financeiro[index.permissao]) {
                this.menuAtivo = index.ativo;
                return;
            }
        }
    },
    data() {
        return {
            menuAtivo: this.ativo,
            idUnidade: null,
            caminho: [
                {
                    texto: "Financeiro",
                    ativo: true,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
        };
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Financeiro', 'Financeiro', 'Listagem', true, false)
        );
        if (
            this.verificarPermissao(
                this.unidadeGlobal.perfil.sigla,
                "exibir_financeiro_cv"
            )
        ) {
            this.idUnidade = this.unidadeGlobal.idunidade;
        } else {
            this.idUnidade = 0;
        }
    },

    watch: {
        unidadeGlobal(newValue) {
            if (
                this.verificarPermissao(
                    newValue.perfil.sigla,
                    "exibir_financeiro_cv"
                )
            ) {
                this.idUnidade = newValue.idunidade;
                this.aviso.exibir = false;
            } else {
                this.idUnidade = 0;
            }
            return newValue;
        },

        menuAtivo(newValue) {
            this.aviso.exibir = false;
        },
    },

    methods: {
        ...mapActions(["novaUnidadePadrao"]),
        exibirAviso(aviso) {
            this.aviso = aviso;
        },

        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
