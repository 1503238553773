<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            :titulo="'Novo Agendamento'"
            :subtitulo="'Unidades disponíveis para agendamento'"
        />
        
        <div class="row">
            <div class="col-12">
                <ListagemUnidadesDisponiveis/>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import BreadCrumbs from "@/components/BreadCrumbs";
import TituloPagina from "../../components/TituloPagina";
import ListagemUnidadesDisponiveis from "@/pages/agendamentos/components/ListagemUnidadesDisponiveis";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    name: "UnidadesDisponiveis",
    components: {
        ListagemUnidadesDisponiveis,
        Loading,
        TituloPagina,
        BreadCrumbs
    },
    created () {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Agendamentos', 'Unidades Disponíveis', 'Listagem', true, false)
        );
    },
    data() {
        return {
            caminho: [
                {
                   texto: "Meus Agendamentos",
                   ativo: false,
                   rota: "agendamentos",
                },
                {
                    texto: "Unidades Disponíveis",
                    ativo: true,
                    rota: null,
                }
            ]
        }
    }
}
</script>
