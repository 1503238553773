const state = {
    cliente: {
        idpessoa: '',
        nome: '',
        telefone: '',
        celular: '',
        email: '',
        idunidade_padrao: null,
        endereco: {
            nome: '',
            numero: '',
            bairro: '',
            cep: '',
            complemento: '',
            estado: {
                idestado: '',
                nome: '',
            },
            cidade: {
                idcidade: '',
                nome: '',
            },
            logradouro: {
                idlogradouro: '',
                nome: '',
            }
        },
        avatar: null,
    },
    sindico : {
        idsindico: null,
        nome: '',
        email: '',
        celular: '',
        avatar: null,
    },
    access_token: null,
    acesso_como: false,
    acesso_sindico: false,
    documento_internacional: false
};

export default state;
