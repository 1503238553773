<template>
  <div>
    <button
      class="pc-btnBox pc-btnBox-branco pc-btnBox--fullW pc-btnBox-icon-fullW-right mb-20 d-flex d-lg-none"
      type="button"
      data-toggle="collapse"
      data-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Menu Documentos
      <IconSetaDown />
    </button>
    <div class="collapse dont-collapse-sm" id="collapseExample">
      <a
        class="pc-bloco pc-btnAtalho"
        :class="{ active: ativoMenu == 'docEmpreendimento' }"
        @click="mudarAtivo('docEmpreendimento')"
      >
        <div class="pc-infoIcon pc-iconInfo-big">
          <div class="pc-infoIcon--icon">
            <IconFerramenta />
          </div>
          <p>
            Doc. do Empreendimento<span
              >Consulte os documentos do empreendimento</span
            >
          </p>
        </div>
        <div class="pc-btnAtalho--seta">
          <IconSetaRight />
        </div>
      </a>
      <a
        class="pc-bloco pc-btnAtalho"
        :class="{ active: ativoMenu == 'docUnidade' }"
        @click="mudarAtivo('docUnidade')"
        v-if="!acessoSindico"
      >
        <div class="pc-infoIcon pc-iconInfo-big">
          <div class="pc-infoIcon--icon">
            <IconCapacete />
          </div>
          <p>Doc. da Unidade<span>Consulte os documentos da unidade</span></p>
        </div>
        <div class="pc-btnAtalho--seta">
          <IconSetaRight />
        </div>
      </a>
      <a
        class="pc-bloco pc-btnAtalho"
        :class="{ active: ativoMenu == 'docSindico' }"
        @click="mudarAtivo('docSindico')"
        v-if="acessoSindico"
      >
        <div class="pc-infoIcon pc-iconInfo-big">
          <div class="pc-infoIcon--icon">
            <IconDocumentos />
          </div>
          <p>Meus documentos<span>Consulte seus documentos</span></p>
        </div>
        <div class="pc-btnAtalho--seta">
          <IconSetaRight />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
  computed: {
    ...mapGetters({
      acessoSindico: "getAcessoSindico",
    }),
  },
  props: {
    ativo: { type: String, default: "docEmpreendimento" },
  },

  data() {
    return {
      ativoMenu: this.ativo,
    };
  },

  methods: {
    mudarAtivo(novoAtivo) {
      if (novoAtivo === "docEmpreendimento") {
        this.trakingMixpanel(
          "Doc. do Empreendimento",
          "Visualizar",
          true,
          false
        );
      } else if (novoAtivo === "docSindico") {
        this.trakingMixpanel("Doc. do síndico", "Visualizar", true, false);
      } else {
        this.trakingMixpanel("Doc. da Unidade", "Visualizar", true, false);
      }

      if (this.ativoMenu != novoAtivo) {
        this.ativoMenu = novoAtivo;
        this.$emit("update:ativo", this.ativoMenu);
      }
    },
    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Documentos", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>