
import axios from "axios";

export function login(dados) {
    return axios.post('/autenticacao', dados);
}

export function loginSindico(dados) {
    return axios.post(`/autenticacao/sindico`, dados);
}
export function registrarCliente(dados) {
    return axios.post('auth/register', dados);
}

export function loginExterno(origem, dados) {
    return axios.post(`/autenticacao-externa/${origem}`, dados);
}

export function acessarComo(dados) {
    return axios.post(`/autenticacao-acessar-como`, dados);
}

export function validarSolicitacaoNovaSenha(dados) {
    return axios.post(`/validar-solicitacao-nova-senha`, dados);
}

export function novaSenha(dados) {
    return axios.post(`/nova-senha`, dados);
}