import MinhasPesquisas from '@/pages/pesquisas/MinhasPesquisas';
import store from '@/store';

const modulos = store.state.configuracoes.configuracoes.modulos;
const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
const sindico = store.state.login.acesso_sindico;

let rotas = [];

if (
     modulos.pesquisas
     && exibir_menu.pesquisas 
     && store.state.unidades.unidades 
     && store.state.unidades.unidades.length > 0 
     && !sindico
    ) {
    rotas.push(
        {
            path: '/cliente/minhas-pesquisas',
            component: MinhasPesquisas,
            name: 'pesquisas',
            meta: {
                title: 'Portal do Cliente - Minhas Pesquisas'
            }
        }
    );
}

export default {
    rotas
}
