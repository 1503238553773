import PerguntasFrequentes from "@/pages/perguntasFrequentes/PerguntasFrequentes";
import store from "@/store";

const exibir_menu = store.state.configuracoes.configuracoes.exibir_menu;
let rotas = [];

if (exibir_menu.perguntas_frequentes) {
	rotas.push({
		path: "/cliente/perguntas-frequentes",
		component: PerguntasFrequentes,
		name: "perguntas-frequentes",
		meta: {
			title: "Portal do Cliente - Perguntas Frequentes",
		},
	});
}

export default {
	rotas,
};
