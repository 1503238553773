import {getConfiguracoes, getMultiplosPortais, validarSlug} from "@/services/ConfiguracoesService";

export const validateSlug = (app, slug) => {
    return new Promise((resolve) => {
        resolve(
            validarSlug(slug)
            .then((response) => {
                if (!response.data) {
                    direcionarSlug(app);
                }
            })
            .catch(() => {
                    direcionarSlug(app);
                }
            ));
    });
}

export const direcionarSlug = async (app) => {
    getConfiguracoes()
        .then((response) => {
            app.$store.commit("SET_CONFIGURACAO", response.data);
            if (response.data.especifico_cv.exibir_multiplos_portais) {
                getMultiplosPortais()
                    .then((response) => {
                        if (response.data.length > 1) {
                            irParaMultiplosPortais(app);
                        } else if (response.data.length === 1) {
                            selectSlug(app, response.data[0].slug);
                        } else {
                            selectSlug(app,'default');
                        }
                    })
                    .catch((error) => {
                        app.$router.replace({
                            name: 'not-found'
                        });
                    });
            } else {
                app.$router.replace({
                    name: 'not-found',
                    params: {erroPortais: true},
                });
            }
        });
}

export const selectSlug = (app, slug) => {
    app.$router.replace({
        name: 'login',
        params: {slug: slug},
    });
    location.reload();
}

const irParaMultiplosPortais = (app) => {
    app.$router.replace({
        name: "multiplos-portais",
    });
}