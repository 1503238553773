<template>
  <div class="pc-bloco">
    <LoadingComponent :isLoading="isLoading" />
    <div class="pc-titleBloco">
      <h3>Antecipação de Parcelas</h3>
      <div class="pull-right pc-title-legenda" v-if="parcelasAntecipacao">
        Informações encontradas: {{ parcelasAntecipacao.total }}
      </div>
    </div>
    <div class="pc-bloco">
      <div v-if="parcelasConfirmadas.length > 0" class="pc-titleBloco">
        <h3>Boletos antecipados disponíveis</h3>
      </div>
      <div class="pc-tabela pc-table-check">
        <div
            v-for="parcela in parcelasConfirmadas"
            v-bind:key="parcela.id_parcela_sienge"
            class="pc-tabela--row pc-tabela--head">
          <div class="pc-tabela--td ">
            <p data-title="Tipo">{{ parcela.id_parcela_sienge }}</p>
          </div>
          <div class="pc-tabela--td ">
            <p data-title="Tipo">{{ parcela.nome + '-' + parcela.empreendimento }}</p>
          </div>
          <div
              class="pc-tabela--td pc-tabela--td-icons pc-f2 justify-content-center"
          >
            <div>
              <button
                  class="pc-btnGhost pc-btnGhost-small pc-btnGhost-primary"
                  @click="
                baixarBoleto(
                  parcela.titulo,
                  parcela.id_parcela_sienge
                )
              "
              >Baixar Boleto</button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="parcelasAntecipacao.parcelas">
      <div v-if="simulacaoAntecipacao.parcelas" id="collapseResumo">
        <div class="d-flex ">
            <div class="form-group">
              <label>Definir data de pagamento</label>
              <datepicker
                  input-class="form-control"
                  name="data-pagamento"
                  id="data-pagamento"
                  placeholder="- Selecione uma data - "
                  :disabled-dates="disabledFn"
                  v-model="datavencimento"
                  format="dd/MM/yyyy"
              ></datepicker>
            </div>
          <div class="pc-titleBloco align-items-center m-l-10">
            <button @click="simularAntecipacao" class="m-t-20 pc-btnBox pc-btnBox-small pc-btnBox-primary ">Recalcular Antecipação</button>
          </div>
        </div>
        <div class="pc-bloco box-resumo-antecipacao">
          <div class="pc-titleBloco">
            <h3>Resumo de pagamento</h3>
          </div>
          <div v-if="parcelasAntecipacao.possui_parcelas_pendentes" id="alertaParcela" class="cv-notificacao --erro m-b-15">
            <p class="alert alert-danger">Você possui parcelas em atraso, é necessário quitar essas parcelas para efetivar a antecipação.</p>
          </div>
          <div class="pc-tabela pc-table-check">

            <div class="pc-tabela--row pc-tabela--head">
              <div class="pc-tabela--td pc-f2">
                <p>Vencimento</p>
              </div>
              <div class="pc-tabela--td pc-f2">
                <p>Número da parcela</p>
              </div>
              <div class="pc-tabela--td pc-f2">
                <p>Valor a pagar</p>
              </div>
            </div>
            <div
                v-for="parcela in simulacaoAntecipacao.parcelas"
                v-bind:key="parcela.idparcelasienge"
                class="pc-tabela--row">
              <div class="pc-tabela--td pc-f2npm r">
                <p data-title="Vencimento">
                  {{
                    parcela.datavencimento
                  }}
                </p>
              </div>
              <div class="pc-tabela--td ">
                <p data-title="Tipo">{{ parcela.idparcelasienge }}</p>
              </div>
              <div class="pc-tabela--td ">
                <p data-title="Valor da parc.">{{ parcela.valorparcela}}</p>
              </div>
            </div>
          </div>
          <div class="justify-content-between d-flex align-items-center m-t-10">
            <b>Total à pagar: {{simulacaoAntecipacao.valorantecipacao}}</b>
          </div>
          <button v-if="!parcelasAntecipacao.possui_parcelas_pendentes" @click="efetuarAntecipacao" class="m-t-10 pc-btnBox pc-btnBox-small pc-btnBox-primary">Efetuar Antecipação</button>
        </div>
      </div>
      <div class="pc-bloco">
        <div id="alertaParcela" class="cv-notificacao --alerta m-b-15">
          <p>Boletos em atraso não são considerados na antecipação.</p>
        </div>
        <div class="pc-titleBloco">
          <h3>Minhas parcelas</h3>
          <button v-if="parcelasSelecionadas.length > 0" @click="simularAntecipacao" class="pc-btnBox pc-btnBox-small pc-btnBox-primary">Calcular Antecipação</button>
        </div>
        <div class="pc-tabela pc-table-check">

          <div class="pc-tabela--row pc-tabela--head">
            <div class="pc-tabela--td pc-f1">
              <p class="-checkbox">
                <input type="checkbox" name="item" id="checkALl" class="cv-checkAll" @click="selectAll" :checked="todosSelecionados"> Nº
                <span class="text-desktop"></span>
              </p>
            </div>
            <div class="pc-tabela--td ">
              <p>Vencimento</p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p>Tipo</p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p>Valor da parcela</p>
            </div>
          </div>
          <div
              v-for="parcela in parcelasAntecipacao.parcelas"
              v-bind:key="parcela.codigo_parcela_sienge"
              class="pc-tabela--row">
            <div class="pc-tabela--td">
              <p data-title="Nº" class="-checkbox">
                <input
                    type="checkbox"
                    name="parcela"
                    v-model="parcelasSelecionadas"
                    :value="parcela.codigo_parcela_sienge"
                    @change="selectOne"
                > {{parcela.codigo_parcela_sienge}}
              </p>
            </div>
            <div class="pc-tabela--td ">
              <p data-title="Vencimento">
                {{
                  parcela.data_vencimento
                  | dateParse("YYYY-MM-DD")
                  | dateFormat("DD/MM/YY")
                }}
              </p>
            </div>
            <div class="pc-tabela--td pc-f2">
              <p data-title="Tipo">{{ parcela.tipo_condicao_parcela }}</p>
            </div>
            <div class="pc-tabela--td pc-f2 m-t-0-mobile">
              <p data-title="Valor da parc.">{{ parcela.valor_corrigido}}</p>
            </div>
          </div>
        </div>
      </div>
      <PaginateInput
          :total="parcelasAntecipacao.total"
          :limit="paginacao.limit"
          :offset="paginacao.offset"
          @paginacao="montarListagem"
      />
    </div>
    <div v-else >Nenhuma informação encontrada.</div>
  </div>
</template>

<script>
import {
  postEfetuarAntecipacao,
  getParcelasConfirmadas,
  gerarBoleto,
  getParcelasAntecipacao,
  postCalcularAntecipacao
} from "@/services/FinanceiroService";
import LoadingComponent from "@/components/LoadingComponent";
import PaginateInput from "@/components/PaginateInput";
import { ptBR } from "vuejs-datepicker/dist/locale";
import {feriadosNacionais} from "@/plugins/Utils";
import Datepicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import moment from "moment/moment";
import store from "@/store";

export default {
  props: {
    idUnidade: { type: Number },
  },

  emits: ["error"],

  components: {
    LoadingComponent,
    PaginateInput,
    Datepicker
  },

  computed: {
    ...mapGetters({
      configuracoes: "getConfiguracoes",
    }),
  },

  data() {
    return {
      ptBR: ptBR,
      todosSelecionados: false,
      parcelasConfirmadas: [],
      simulacaoAntecipacao: [],
      parcelasAntecipacao: [],
      parcelasSelecionadas: [],
      datavencimento: null,
      paginacao: {
        offset: 0,
        limit: 30,
      },
      isLoading: true,
      erroParcelasAtraso: false,
      disabledFn: {
        customPredictor: function(date) {
          const hoje = new Date();
          const formattedDate = date.toISOString().split('T')[0];
          const dayOfWeek = date.getDay();
          if (dayOfWeek === 0 || dayOfWeek === 6 || date <= hoje) {
            return true;
          }
          return feriadosNacionais.includes(formattedDate);
        },
      },
    };
  },

  beforeMount() {
    if (this.idUnidade) {
      this.montarListagem(1);
      this.getParcelasConfirmadasAntecipacao();
    }
  },

  methods: {
    montarListagem(pagina) {
      this.isLoading = true;
      getParcelasAntecipacao(
          (pagina - 1) * this.paginacao.limit,
          this.paginacao.limit,
          this.idUnidade
      )
          .then((response) => {
            this.parcelasAntecipacao = response.data;
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.$emit("error", error.response.data);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    retornaCheckboxes() {
      return document.querySelectorAll("input[type='checkbox'][name='parcela']");
    },
    selectOne() {
      const checkboxes = this.retornaCheckboxes();
      this.todosSelecionados = this.parcelasSelecionadas.length === checkboxes.length;
    },
    selectAll() {
      this.selectOne();
      const checkboxes = this.retornaCheckboxes();
      this.parcelasSelecionadas = [];
      if (!this.todosSelecionados) {
        checkboxes.forEach(checkbox => {
          this.parcelasSelecionadas.push(checkbox.value);
        });
      }
      this.selectOne();
    },
    simularAntecipacao() {
      this.isLoading = true
      if (!this.datavencimento) {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        while (
            date.getDay() === 0
            || date.getDay() === 6
            || feriadosNacionais.includes(date.toISOString().split('T')[0])
            ) {
          date.setDate(date.getDate() + 1);
        }
        this.datavencimento = date;
      }
      postCalcularAntecipacao({
        idunidade: this.idUnidade,
        idsparcelas : this.parcelasSelecionadas,
        datavencimento :  moment(this.datavencimento).format("YYYY-MM-DD")
      })
        .then((response) => {
          this.simulacaoAntecipacao = response.data;
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
          this.simulacaoAntecipacao = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    efetuarAntecipacao() {
      this.isLoading = true;
      postEfetuarAntecipacao({
        idunidade: this.idUnidade,
        idsparcelas : this.parcelasSelecionadas,
        datavencimento :  moment(this.datavencimento).format("YYYY-MM-DD")
      })
          .then((response) => {
            this.$emit("exibirAviso", {
              exibir: true,
              tipo: "sucesso",
              mensagem: "Solicitação efetuada com sucesso",
            });
          })
          .catch((error) => {
            this.$emit("error", error.response.data);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getParcelasConfirmadasAntecipacao() {
      getParcelasConfirmadas(this.idUnidade)
        .then((response) => {
          this.parcelasConfirmadas = response.data;
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
        });
    },
    baixarBoleto(codigoTituloSienge, codigoParcelaSienge) {
      this.isLoading = true;
      gerarBoleto(codigoTituloSienge, codigoParcelaSienge)
        .then((response) => {
          console.log(JSON.stringify({'data': response.data.link, 'origem': 'download_boleto'}))
          if (store.state.login.login_origem !== 'app') {
            window.open(response.data.link, "_blank");
          }
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
