<template>
    <LoadingComponent :isLoading="true" />
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import { mapActions } from "vuex";
import decode from 'jwt-decode';

export default {
    props: {
        origem: { type: String, required: true },
    },

    components: {
        LoadingComponent,
    },

    beforeMount() {
        this.resetarSessao();
        this.resetarRedirecionar();
    },

    mounted() {
        this.loginExterno({ origem: this.origem, dados: this.$route.query })
            .then((response) => {
                this.$router.push({ name: "redirecionando" });
                if (response.data.slug) {
                    this.$store.commit('SET_SLUG', decode(response.data.slug));
                } else if (response.data.access_token) {
                    let decoded = decode(response.data.access_token);
                    this.$store.commit('SET_SLUG', decoded.slug);
                }
            })
            .catch((error) => {
                this.$router.push({
                    name: "login",
                    params: {
                        error: error.response.data.error,
                    },
                });
            });
    },

    methods: {
        ...mapActions(["resetarSessao", "resetarRedirecionar", "loginExterno"]),
    },
};
</script>
