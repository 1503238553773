<template>
	<div class="pc-content">
		<BreadCrumbs :caminho="caminho" />
		<TituloPagina
			:titulo="'Perguntas Frequentes'"
			:subtitulo="'Painel com informações sobre o Portal'"
		/>
		<LoadingComponent :isLoading="isLoading" />
		<div class="row">
			<div class="col-12">
				<div class="pc-bloco -full">
					<div class="pc-titleBloco buscaFaq -left m-b-30">
						<p class="m-t-0">Buscar:</p>
						<div class="form-group m-b-0">
							<input
								id="buscaFaq"
								@input="filterFaq()"
								style="min-width: 300px;"
								type="text"
								placeholder="Digite uma palavra chave"
								name="nome"
								class="form-control input-tel"
							/>
						</div>
					</div>
					<div class="accordion" id="accordionExample">
						<div class="cardFaq" v-for="(pergunta, indice) in this.perguntas" :key="indice">
							<div class="-header" :id="'heading' + indice">
								<button
									class="pergunta collapsed"
									type="button"
									data-toggle="collapse"
									:data-target="'#collapse-' + indice"
									aria-expanded="true"
									:aria-controls="'collapse-' + indice">
									{{ pergunta.pergunta }}
									<IconSetaDown />
								</button>
							</div>

							<div :id="'collapse-' + indice" class="collapse" :aria-labelledby="'heading-' + indice"
																																					data-parent="#accordionExample">
								<div class="-content">
									{{ pergunta.resposta }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import TituloPagina from "../../components/TituloPagina";
import LoadingComponent from "@/components/LoadingComponent";
import { getPerguntasFrequentes } from "@/services/PerguntasFrequentesService";
import { objetoTraking } from "../../plugins/Utils";
import Vue from "vue";

export default {
	created() {
		this.$mixpanel.track(
			Vue.prototype.$eventoMixPanel,
			objetoTraking(
				"Minhas Unidades",
				"Minhas Unidades",
				"Listagem",
				true,
				false
			)
		);
	},

	components: {
		TituloPagina,
		BreadCrumbs,
		LoadingComponent,
	},
	beforeMount() {
		this.exibirPerguntas();
	},
	methods: {
		filterFaq() {
			var input, filter, resposta, pergunta, i, txtPergunta, txtResposta;
			input = document.getElementById("buscaFaq");
			filter = input.value.toUpperCase();
			var itensFaq = document.querySelectorAll(".cardFaq");

			for (i = 0; i < itensFaq.length; i++) {
				pergunta = itensFaq[i].querySelector(".pergunta");
				resposta = itensFaq[i].querySelector(".-content");

				txtPergunta = pergunta.textContent || pergunta.innerText;
				txtResposta = resposta.textContent || resposta.innerText;
				if (txtPergunta.toUpperCase().indexOf(filter) > -1 || txtResposta.toUpperCase().indexOf(filter) > -1) {
					itensFaq[i].style.display = "";
				} else {
					itensFaq[i].style.display = "none";
				}
			}
		},
		exibirPerguntas() {
			this.isLoading = true;
			getPerguntasFrequentes()
				.then((response) => {
					this.perguntas = response.data;
				})
				.catch((error) => {
					if (error.response.status != 404) {
						this.tratarErro(error.response.data);
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		tratarErro(erro) {
			let mensagem =
				"Ocorreu um erro inesperado, por favor tente mais tarde.";
			if (erro.code != 422) {
				mensagem = erro.error;
			}

			this.aviso = {
				exibir: true,
				tipo: "erro",
				mensagem: mensagem,
			};
		}
	},

	data() {
		return {
			caminho: [
				{
					texto: "Perguntas Frequentes",
					ativo: true,
					rota: null,
				},
			],
			perguntas: [],
			isLoading: false,
		};
	},
};
</script>
