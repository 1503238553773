<template>
    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="isFullPage"
        :color="configuracoes.cor.primaria"
    >
    </loading>
</template>

<script>
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "LoadingComponent",

    components: {
        Loading,
    },

    computed: {
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes,
        }),
    },

    props: {
        isLoading: { type: Boolean, default: false },
        isFullPage: { type: Boolean, default: false },
    },
};
</script>
