<template>
    <div>
        <button
            class="pc-btnBox pc-btnBox-branco pc-btnBox--fullW pc-btnBox-icon-fullW-right mb-20 d-flex d-lg-none"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
        >
            Menu Financeiro
            <IconSetaDown />
        </button>
        <div class="collapse dont-collapse-sm" id="collapseExample">
            <a
                v-if="configuracoes.exibir_financeiro.boletos || configuracoes.exibir_financeiro.boletos_cv"
                v-on:click="trakingMixpanel('Boletos', 'Visualizar', true, false)"
                class="pc-bloco pc-btnAtalho"
                :class="{ active: ativoMenu == 'segundaVia' }"
                @click="mudarAtivo('segundaVia')"
            >
                <div class="pc-infoIcon pc-iconInfo-big">
                    <div class="pc-infoIcon--icon">
                        <IconFinanceiro />
                    </div>
                    <p>
                        Boletos
                        <span>Consulte os boletos registrados</span>
                    </p>
                </div>
                <div class="pc-btnAtalho--seta">
                    <IconSetaRight />
                </div>
            </a>
            <a
                v-if="configuracoes.exibir_financeiro.boletos_antecipacao"
                v-on:click="trakingMixpanel('Antecipação', 'Visualizar', true, false)"
                class="pc-bloco pc-btnAtalho"
                :class="{ active: ativoMenu == 'antecipacao' }"
                @click="mudarAtivo('antecipacao')"
            >
              <div class="pc-infoIcon pc-iconInfo-big">
                <div class="pc-infoIcon--icon">
                  <IconFinanceiro />
                </div>
                <p>
                  Antecipação de Parcelas
                  <span>Calcule a quitação antecipada da unidade</span>
                </p>
              </div>
              <div class="pc-btnAtalho--seta">
                <IconSetaRight />
              </div>
            </a>
            <a
                v-if="configuracoes.exibir_financeiro.boletos_reparcelamento"
                v-on:click="trakingMixpanel('Reparcelamento', 'Visualizar', true, false)"
                class="pc-bloco pc-btnAtalho"
                :class="{ active: ativoMenu === 'reparcelamento' }"
                @click="mudarAtivo('reparcelamento')"
            >
              <div class="pc-infoIcon pc-iconInfo-big">
                <div class="pc-infoIcon--icon">
                  <IconFinanceiro />
                </div>
                <p>
                  Reparcelamento
                  <span>Realize a renegociação do fluxo financeiro</span>
                </p>
              </div>
              <div class="pc-btnAtalho--seta">
                <IconSetaRight />
              </div>
            </a>
            <a
                v-on:click="trakingMixpanel('Extratos', 'Visualizar', true, false)"
                v-if="configuracoes.especifico_cv.exibir_extrato_portal_cliente
                && (
                    configuracoes.exibir_financeiro.extrato_financeiro
                    || !configuracoes.modulos.sienge && configuracoes.exibir_financeiro.extrato_financeiro_cv
                )"
                class="pc-bloco pc-btnAtalho"
                :class="{ active: ativoMenu == 'extratos' }"
                @click="mudarAtivo('extratos')"
            >
                <div class="pc-infoIcon pc-iconInfo-big">
                    <div class="pc-infoIcon--icon">
                        <IconResumo />
                    </div>
                    <p>
                        Extratos
                        <span>Consulte os extratos registrados</span>
                    </p>
                </div>
                <div class="pc-btnAtalho--seta">
                    <IconSetaRight />
                </div>
            </a>
            <a
                v-if="(
                    configuracoes.exibir_financeiro.imposto_de_renda
                    || !configuracoes.modulos.sienge && configuracoes.exibir_financeiro.informe_rendimentos_cv
                )"
                v-on:click="trakingMixpanel('Demonstrativo de IR', 'Visualizar', true, false)"
                class="pc-bloco pc-btnAtalho"
                :class="{ active: ativoMenu == 'demostrativoIr' }"
                @click="mudarAtivo('demostrativoIr')"
            >
                <div class="pc-infoIcon pc-iconInfo-big">
                    <div class="pc-infoIcon--icon">
                        <IconDocumentos />
                    </div>
                    <p>
                        Demonstrativo de IR
                        <span>Consulte seu imposto de renda</span>
                    </p>
                </div>
                <div class="pc-btnAtalho--seta">
                    <IconSetaRight />
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
    props: {
        ativo: { type: String, default: "segundaVia" },
    },

    computed: {
        ...mapGetters({
            configuracoes: "getConfiguracoes",
        }),
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes
        }),
    },
    beforeMounted() {
        var arrayConfiguracoes = [
            { permissao: 'boletos', ativo: 'segundaVia' },
            { permissao: 'extrato_financeiro', ativo: 'extratos' },
            { permissao: 'imposto_de_renda', ativo: 'demostrativoIr' },
            { permissao: 'boletos_cv', ativo: 'segundaVia' },
        ];

        for (let index of arrayConfiguracoes) {
            if (this.configuracoes.exibir_financeiro[index.permissao]) {
                this.ativoMenu = index.ativo;
                return;
            }
        }
    },
    data() {
        return {
            ativoMenu: this.ativo,
        };
    },

    methods: {
        mudarAtivo(novoAtivo) {
            if (this.ativoMenu != novoAtivo) {
                this.ativoMenu = novoAtivo;
                this.$emit("update:ativo", this.ativoMenu);
            }
        },

        trakingMixpanel(acao, subacao, intencao, ateracao) {
            this.$mixpanel.track(
                Vue.prototype.$eventoMixPanel,
                objetoTraking('Financeiro', acao, subacao, intencao, ateracao)
            );
        },
    },
};
</script>