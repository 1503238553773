<template>
    <div class="search-container">
        <IconSearch :color="iconColor" />
        <div class="divider"></div>
        <input
            type="text"
            class="search-input"
            placeholder="Pesquise por empreendimento, bloco, tipo..."
            v-model="inputValue"
            ref="searchInput"
            @focus="isFocused = true"
            @blur="isFocused = false"
        />
        <IconSearchReset
            v-if="inputValue"
            @click="clearInput"
        />
    </div>
</template>

<script>
    import IconSearch from '../icons/IconSearch.vue';
    import IconSearchReset from '../icons/IconSearchReset.vue';

    export default {
        name: 'InputSearch',
        components: {
            IconSearch,
            IconSearchReset,
        },
        data() {
            return {
                inputValue: '',
                isFocused: false,
            };
        },
        computed: {
            iconColor() {
                return this.inputValue || this.isFocused ? 'var(--cor-primaria)' : '#7f7f7f';
            }
        },
        methods: {
            clearInput() {
                this.inputValue = '';
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            },
        },
    };
</script>

<style scoped>
    .search-container {
        display: flex;
        align-items: center;
        border: 1px solid #c6c6c6;
        border-radius: 4px;
        padding: 10px;
        min-width: 400px;
        width: auto;
        gap: 10px;
    }

    .search-container:focus-within {
        border-color: var(--cor-primaria);
    }

    .search-input {
        flex: 1;
        border: none;
        height: 20px;
        outline: none;
        background: none;
    }

    .divider {
        border: 1px solid #c6c6c6;
        height: 20px;
    }

    @media (max-width: 768px) {
        .search-container {
            min-width: auto;
            width: 100%;
        }
    }
</style>
