<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            titulo="Novo Agendamento"
            subtitulo="Informe a data e horário para concluir o agendamento"
        />
        
        <LoadingComponent :isLoading="isLoading" />
        <div class="row">
            <div class="col-12">
                <div v-if="agendamento">
                    <div class="pc-bloco">
                        <InfoAgendamento :agendamento="agendamento"/>
                        <FormularioNovoAgendamento :agendamento="agendamento"/>
                    </div>
                </div>
                <div v-else>Nenhuma informação encontrada.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import BreadCrumbs from "@/components/BreadCrumbs";
import InfoAgendamento from "@/pages/agendamentos/components/InfoAgendamento";
import FormularioNovoAgendamento from "@/pages/agendamentos/components/FormularioNovoAgendamento";
import { getAgendamento } from "@/services/AgendamentosService";
import TituloPagina from "@/components/TituloPagina";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    name: "NovoAgendamento",
    components: {
        LoadingComponent,
        BreadCrumbs,
        InfoAgendamento,
        FormularioNovoAgendamento,
        TituloPagina,
    },
    computed: {
        ...mapGetters({
            configuracoes: 'getConfiguracoes',
        }),
    },
    created () {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Meus Agendamentos', 'Novo Agendamento', 'Formulário', true, false)
        );
    },
    data() {
        return {
            isLoading: false,
            agendamento: null,
            caminho: [
                {
                    texto: "Meus Agendamentos",
                    rota: "agendamentos",
                    ativo: false,
                },
                {
                    texto: "Novo Agendamento",
                    ativo: true
                },
            ]
        }
    },
    beforeMount() {
        this.isLoading = true;
        getAgendamento(
            this.$route.params.idAgendamento,
            this.$route.params.idUnidade
        ).then((response) => {
            this.agendamento = response.data;
            if (this.$route.params.idVistoria) {
                this.agendamento.idvistoria = this.$route.params.idVistoria;
            }
            this.isLoading = false;
        }).catch(() => {})
        .finally(() => {
            this.isLoading = false;
        });
    },
}
</script>
