<template>
  <div class="pc-bloco">
    <div class="pc-titleBloco">
      <div>
        <h3>Cadastrar Associados</h3>
        <p v-if="configuracoes.exibir_menu.atendimentos">
          Cadastre seus associados para que eles possam Cadastrar/Solicitar
          atendimentos para o imóvel junto a construtora.
        </p>
      </div>
    </div>
    <LoadingComponent :isLoading="isLoading" />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(enviarFormulario)"
        class="center-block"
      >
        <div class="row">
          <div class="col-lg-6 col-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <label><strong>*Documento do Associado</strong></label>
              <input
                type="text"
                class="form-control input-cpf"
                v-model="documento"
                v-mask="
                  documento.length > 14
                    ? '##.###.###/####-##'
                    : '###.###.###-##'
                "
                @blur="verificarDocumento"
              />
              <label class="error"
                ><span>{{ errors[0] }}</span></label
              >
            </ValidationProvider>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <SelectInput
                titulo="Tipo de Associação"
                label="nome"
                :reduce="(option) => option.idtipo"
                :options="tiposAssociacoes"
                :clearable="false"
                :valor.sync="formulario.idtipo"
                regras="required"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label><strong>*Nome do Associado</strong></label>
                <input
                  type="text"
                  class="form-control input-cpf"
                  v-model="formulario.nome"
                  :readonly="formulario.idpessoa"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label><strong>*E-mail do Associado</strong></label>
                <input
                  type="email"
                  class="form-control input-cpf"
                  v-model="formulario.email"
                  :readonly="formulario.idpessoa"
                />
                <label class="error"
                  ><span>{{ errors[0] }}</span></label
                >
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Telefone do Associado</label>
              <vue-tel-input
                v-model="formulario.telefone"
                :readonly="formulario.idpessoa"
                v-bind="bindProps"
              >
              </vue-tel-input>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Celular do Associado</label>
              <vue-tel-input
                v-model="formulario.celular"
                :readonly="formulario.idpessoa"
                v-bind="bindProps"
              >
              </vue-tel-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <SelectInput
                titulo="Unidade de acesso do Associado"
                :reduce="(option) => option.idunidade"
                :options="unidadesEscolher"
                :clearable="true"
                :valor.sync="unidades"
                :multiple="true"
                regras="required"
              />
            </div>
          </div>
        </div>

        <div class="row mt-10">
          <div class="col">
            <div class="btnEnviar">
              <button
                :disabled="acessoComo"
                name="btSend"
                type="submit"
                value="Enviar"
                class="btn-form pc-btnBox pc-btnBox-secondary"
              >
                Cadastrar Associação
              </button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import LoadingComponent from "@/components/LoadingComponent";
import { verificarPessoaDocumento } from "@/services/PessoasService";
import {
  retornarTiposAssociacoes,
  cadastrarAssociacao,
  cadastrarAssociacaoComPessoa,
} from "@/services/CadastrosService";
import { removerMascaraString } from "@/plugins/Utils";
import { mapState, mapGetters } from "vuex";
import { objetoTraking } from "../../../plugins/Utils";
import { removerMascaraTelefone } from "../../../plugins/Utils";
import Vue from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

export default {
  components: {
    SelectInput,
    LoadingComponent,
    VueTelInput,
  },

  computed: {
    ...mapGetters({
      verificarPermissao: "verificarPermissao",
      acessoComo: "getAcessoComo",
    }),
    ...mapState({
      unidadesCliente: (state) => state.unidades.unidades,
      configuracoes: (state) => state.configuracoes.configuracoes,
    }),
  },

  data() {
    return {
      formulario: {
        idpessoa: null,
        documento: null,
        documento_tipo: null,
        nome: null,
        email: null,
        telefone: null,
        celular: null,
        idtipo: null,
        unidades: [],
      },
      documento: "",
      unidades: [],
      unidadesEscolher: [],
      tiposAssociacoes: [],
      isLoading: false,

      bindProps: {
        nationalMode: false,
        separateDialCode: true,
        formatOnDisplay: true,
        initialCountry: "BR",
        preferredCountries: ["BR"],
        autoHideDialCode: true,
        enabledCountryCode: true,
        mode: 'international',
        validCharactersOnly: true,
        inputOptions: {
          showDialCode: false
        },
      },
    };
  },

  beforeMount() {
    for (let index = 0; index < this.unidadesCliente.length; index++) {
      if (
        this.verificarPermissao(
          this.unidadesCliente[index].perfil.sigla,
          "exibir_cadastros"
        )
      ) {
        this.unidadesEscolher.push({
          idunidade: this.unidadesCliente[index].idunidade,
          label: [
            this.unidadesCliente[index].empreendimento.nome,
            this.unidadesCliente[index].bloco.nome,
            this.unidadesCliente[index].nome,
          ].join(" - "),
        });
      }
    }

    retornarTiposAssociacoes()
      .then((response) => {
        this.tiposAssociacoes = response.data;
      })
      .catch((error) => {});
  },

  methods: {
    enviarFormulario() {
      if (this.acessoComo) {
        return;
      }

      this.formulario.documento = removerMascaraString(this.documento);
      this.formulario.telfone = removerMascaraTelefone(this.formulario.telfone);
      this.formulario.celular = removerMascaraTelefone(this.formulario.celular);

      this.formulario.documento_tipo =
        this.formulario.documento.length == 11 ? "cpf" : "cnpj";

      this.formulario.unidades = this.unidades.map(function (unidade) {
        return { idunidade: unidade };
      });

      this.isLoading = true;
      this.trakingMixpanel("Cadastrar Associado", "Cadastrar", true, true);
      if (this.formulario.idpessoa) {
        cadastrarAssociacao(this.formulario)
          .then((response) => {
            this.exibirSucesso("Associado cadastrado com sucesso.");
            this.$emit("novaAssociacao", response.data.idassociacao);
          })
          .catch((error) => {
            this.$emit("error", error.response.data);
          })
          .finally(() => {
            this.isLoading = false;
          });
        return;
      }
      cadastrarAssociacaoComPessoa(this.formulario)
        .then((response) => {
          this.exibirSucesso("Associado cadastrado com sucesso.");
          this.$emit("novaAssociacao", response.data.idassociacao);
        })
        .catch((error) => {
          this.$emit("error", error.response.data);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    verificarDocumento() {
      if (this.documento) {
        this.isLoading = true;
        verificarPessoaDocumento(removerMascaraString(this.documento))
          .then((response) => {
            this.setarDadosFormulario(response.data);
          })
          .catch((error) => {
            this.setarDadosFormulario(null);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.setarDadosFormulario(null);
      }
    },

    setarDadosFormulario(dados) {
      this.formulario.idpessoa = dados ? dados.idpessoa : null;
      this.formulario.nome = dados ? dados.nome : null;
      this.formulario.email = dados ? dados.email : null;
      this.formulario.telefone = dados ? dados.telefone : null;
      this.formulario.celular = dados ? dados.celular : null;
    },

    exibirSucesso(mensagem) {
      this.$emit("exibirAviso", {
        exibir: true,
        mensagem: mensagem,
        tipo: "sucesso",
      });
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Meus Cadastros", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
