<template>
    <div class="text-center">
        <div class="pb-0 pt-3">
            <vue-paginate-al
                :totalPage="Math.ceil(total / limit)"
                :current-page="Math.round(offset / limit) + 1"
                :withNextPrev="false"
                :customActiveBGColor="configuracoes.cor.primaria"
                @btnClick="paginacao"
            >
            </vue-paginate-al>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        total: { type: Number, required: true },
        limit: { type: Number, required: true },
        offset: { type: Number, required: true },
    },

    computed: {
        ...mapState({
            configuracoes: (state) => state.configuracoes.configuracoes,
        }),
    },

    methods: {
        paginacao(pagina) {
            this.$emit("paginacao", pagina);
        },
    },
};
</script>
