<template>
  <router-link class="pc-bloco pc-btnAtalho pc-sameHeight" :to="rota">
    <div class="pc-infoIcon pc-iconInfo-big">
      <div class="pc-infoIcon--icon">
        <component :is="icone"/>
      </div>
      <p>{{ titulo }}<span>{{ descricao }}</span></p>
    </div>
    <div class="pc-btnAtalho--seta">
      <IconSetaRight/>
    </div>
  </router-link>
</template>

<script>
import IconSetaRight from "@/components/icons/IconSetaRight.vue";

export default {
  name: "Atalho",
  components: {IconSetaRight},
  props: {
    rota: {type: Object, required: true},
    icone: {type: Object, required: true},
    titulo: {type: String, required: true},
    descricao: {type: String, required: true},
  }
}
</script>