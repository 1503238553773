var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pc-login-form--wrap"},[_c('TituloLoginSindico'),_c('div',{staticClass:"pc-btns-Login"},[_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v(" Acesse sua conta ")]),(_vm.configuracoes.especifico_cv.cadastro_painel_cliente)?_c('router-link',{attrs:{"to":{ name: 'cadastre-se' }}},[_vm._v(" Cadastre-se ")]):_vm._e(),(_vm.configuracoes.tela_login.exibir_acesso_sindico)?_c('router-link',{class:'active',attrs:{"to":{ name: 'loginSindico' }}},[_vm._v(" Acesse como síndico ")]):_vm._e()],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.realizarLoginSindico()}}},[(_vm.sessaoExpiradaLocal)?_c('div',{staticClass:"pc-alert pc-error"},[_c('p',[_vm._v("Sessão Expirada")])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"},{name:"model",rawName:"v-model",value:(_vm.formData.usuario),expression:"formData.usuario"}],class:[
                    'form-control',
                    { 'is-invalid': _vm.errors.usuario != '' },
                ],attrs:{"type":"text","inputmode":"numeric","name":"usuario","value":"","placeholder":"CPF ou CNPJ","required":""},domProps:{"value":(_vm.formData.usuario)},on:{"keyup":_vm.formatarMascaraCPFCNPJ,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "usuario", $event.target.value)}}})]),_c('div',{staticClass:"form-group pc-login-password"},[((_vm.exibirSenha ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.senha),expression:"formData.senha"}],class:[
                    'form-control',
                    { 'is-invalid': _vm.errors.senha != '' },
                ],attrs:{"name":"","value":"","placeholder":"Senha","required":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.formData.senha)?_vm._i(_vm.formData.senha,"")>-1:(_vm.formData.senha)},on:{"change":function($event){var $$a=_vm.formData.senha,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formData, "senha", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formData, "senha", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formData, "senha", $$c)}}}}):((_vm.exibirSenha ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.senha),expression:"formData.senha"}],class:[
                    'form-control',
                    { 'is-invalid': _vm.errors.senha != '' },
                ],attrs:{"name":"","value":"","placeholder":"Senha","required":"","type":"radio"},domProps:{"checked":_vm._q(_vm.formData.senha,"")},on:{"change":function($event){return _vm.$set(_vm.formData, "senha", "")}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.senha),expression:"formData.senha"}],class:[
                    'form-control',
                    { 'is-invalid': _vm.errors.senha != '' },
                ],attrs:{"name":"","value":"","placeholder":"Senha","required":"","type":_vm.exibirSenha ? 'text' : 'password'},domProps:{"value":(_vm.formData.senha)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "senha", $event.target.value)}}}),_c('a',{staticClass:"pc-login-showPassword",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showPassword()}}},[(_vm.exibirSenha)?_c('div',[_c('IconEye')],1):_c('div',[_c('IconEyeSlash')],1)])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticClass:"pc-login-btnEsqueciSenha",attrs:{"to":{ 
                    name: _vm.configuracoes.tela_login.padrao_reset_senha === 'sms' ? 'esqueceu-senha-sms-sindico' : 'esqueceu-senha-email-sindico',
                    params: {slug: this.$store.state.configuracoes.slug}
                }}},[_vm._v(" Esqueceu a senha? ")])],1),_c('br'),_c('button',{staticClass:"pc-btnBox pc-btnBox--fullW",attrs:{"type":"submit","name":"button","disabled":_vm.loading}},[(_vm.loading)?_c('span',[_vm._v("Autenticando...")]):_c('span',[_vm._v("Acessar Portal")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }