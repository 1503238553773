<template>
    <div class="pc-bloco">
        <Atendimento :atendimento="atendimento" />
        <Descricao :atendimento="atendimento" />
        <Mensagens
            v-if="atendimento.respostas && atendimento.respostas.length > 0"
            :respostas="atendimento.respostas"
        />
        <Responder v-if="!atendimento.finalizado && atendimento.permitir_responder" :atendimento="atendimento" @error="enviarErro" @refresh="$emit('refresh')" />
    </div>
</template>

<script>
import Mensagens from "./Mensagens";
import Atendimento from "./Atendimento";
import Responder from "./Responder";
import Descricao from "./Descricao";
export default {
    props: ["atendimento"],
    emits: ["error", "refresh"],

    components: {
        Descricao,
        Mensagens,
        Atendimento,
        Responder,
    },

    methods: {
        enviarErro(erro) {
            this.$emit("error", erro);
        },
    },
};
</script>
