<template>
    <div class="row">
        <div class="col-12">
            <div class="pc-breadcramb">
                <ul class="">
                    <li v-if="!acessoSindico">
                        <router-link :to="{ name: 'home' }">
                            Início
                            <IconSetaRight />
                        </router-link>
                    </li>
                    <li v-for="item in caminho">
                        <span v-if="item.ativo">{{ item.texto }}</span>
                        <router-link :to="{ name: item.rota }" v-else>
                            {{ item.texto }}
                            <IconSetaRight />
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
    computed: {
      ...mapGetters({
            acessoSindico: "getAcessoSindico",
        }),
    },
    props: {
        caminho: { type: Array, required: true },
    },
};
</script>