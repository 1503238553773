import axios from "axios";
import store from '../store';

export function getContratos(offset, limit, idUnidade = null) {
    return axios.get('/contratos', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            offset: offset,
            limit: limit,
            idUnidade: idUnidade
        }
    });
}
