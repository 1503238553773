import { render, staticRenderFns } from "./FormularioDocumento.vue?vue&type=template&id=052b0b31"
import script from "./FormularioDocumento.vue?vue&type=script&lang=js"
export * from "./FormularioDocumento.vue?vue&type=script&lang=js"
import style0 from "./FormularioDocumento.vue?vue&type=style&index=0&id=052b0b31&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports