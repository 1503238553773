<template>
  <div>
    <div
      v-if="mostrarModal"
      class="pc-modalPopostaAtiva"
      style="height: 60%; overflow-y: scroll"
    >
      <div v-if="primeiroAcesso">
        <a
          style="margin-left: 98%"
          href="javascript:void(0)"
          @click.prevent="fecharModal()"
        >
          <span><IconClose /></span>
        </a>
      </div>
      <div v-else>
        <a class="close" data-dismiss="modal" href="javascript:void(0)">
          <span><IconClose /></span>
        </a>
      </div>
      <LoadingComponent :isLoading="isLoading" />
      <div class="pc-modalPopostaAtiva--title">
        <p>Olá {{ cliente.nome }},</p>
        <h1
          v-if="
            quantidadeReservasPendentes > 0 &&
            quantidadepreCadastroPendentes > 0
          "
        >
          Você tem {{ quantidadeReservasPendentes }} proposta(s) e
          {{ quantidadepreCadastroPendentes }} pré-cadastro(s) pendente(s) de
          documento
        </h1>
        <h1 v-else-if="quantidadeReservasPendentes > 0">
          Você tem {{ quantidadeReservasPendentes }} proposta(s) pendente(s) de
          documento
        </h1>
        <h1 v-else-if="quantidadepreCadastroPendentes > 0">
          Você tem {{ quantidadepreCadastroPendentes }} pré-cadastro(s)
          pendente(s) de documento
        </h1>
      </div>
      <div class="row" v-if="reservasPendentes && reservasPendentes.length > 0">
        <ModalInfoReservas
          v-for="reserva in reservasPendentes"
          :reserva="reserva"
        />
      </div>
      <div class="row" v-if="preCadastroPendentes && preCadastroPendentes.length > 0">
        <ModalInfoPreCadastros
          v-for="precadastro in preCadastroPendentes"
          :precadastro="precadastro"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getReservasPendentesDocumento } from "@/services/ReservaService";
import { getPreCadastroPendentesDocumento } from "@/services/PreCadastroService";
import IconClose from "@/components/icons/IconClose";
import ModalInfoReservas from "@/pages/home/components/ModalInfoReservas";
import ModalInfoPreCadastros from "@/pages/home/components/ModalInfoPreCadastros";
import LoadingComponent from "@/components/LoadingComponent";

export default {
  name: "ModalReservasPendentes",
  components: {
    IconClose,
    ModalInfoReservas,
    ModalInfoPreCadastros,
    LoadingComponent,
  },
  props: {
    primeiroAcesso: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      cliente: "getCliente",
    }),
    ...mapState({
      configuracoes: (state) => state.configuracoes.configuracoes,
    }),
  },
  data() {
    return {
      reservasPendentes: [],
      preCadastroPendentes: [],
      quantidadeReservasPendentes: 0,
      quantidadepreCadastroPendentes: 0,
      isLoading: true,
      mostrarModal: false,
    };
  },
  beforeMount() {
    getReservasPendentesDocumento()
      .then((response) => {
        if (this.configuracoes.exibir_menu.propostas_pendentes) {
          this.reservasPendentes = response.data;
          this.quantidadeReservasPendentes = Object.keys(
            this.reservasPendentes
          ).length;

          if (this.quantidadeReservasPendentes > 0) {
            this.mostrarModal = true;
          }

          if (this.primeiroAcesso && this.mostrarModal) {
            document.body.classList.add(`noContent`);
          }
        }
      })
      .catch(() => {
        this.mostrarModal = false;
      })
      .finally(() => {
        this.isLoading = false;
      });

    getPreCadastroPendentesDocumento()
      .then((response) => {
        if (this.configuracoes.exibir_menu.precadastros_pendentes) {
          this.preCadastroPendentes = response.data;
          this.quantidadepreCadastroPendentes = Object.keys(
            this.preCadastroPendentes
          ).length;

          if (this.quantidadepreCadastroPendentes > 0) {
            this.mostrarModal = true;
          }

          if (this.primeiroAcesso && this.mostrarModal) {
            document.body.classList.add(`noContent`);
          }
        }
      })
      .catch(() => {
        this.mostrarModal = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    abrirModal() {
      this.mostrarModal = true;
      document.body.classList.add(`noContent`);
    },
    fecharModal() {
      this.mostrarModal = false;
      document.body.classList.remove(`noContent`);
    },
  },
};
</script>