import axios from "axios";
import store from '../store';

export function getTermo() {
    return axios.get('/termo-aceite', {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function aceitarTermo(idTermo) {
    return axios.post(`/termo-aceite/${idTermo}/aceitar`, null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}
