<template>
    <div class="pc-content">
        <BreadCrumbs :caminho="caminho" />
        <TituloPagina
            :titulo="'Assistências Técnicas'"
            :subtitulo="'Relação de assistências'"
        />
        <LoadingComponent :isLoading="isLoading" />
        <Aviso
            :exibir="aviso.exibir"
            :tipo="aviso.tipo"
            :mensagem="aviso.mensagem"
        />

        <div class="row">
            <div class="col-12">
                <Assistencia
                    v-if="assistencia"
                    :key="chaveComponentes"
                    :assistencia="assistencia"
                    @error="tratarErro"
                    @refresh="dadosAssistencia"
                />
            </div>
        </div>
      </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import { getAssistenciaTecnica } from "../../services/AssistenciasService";
import Assistencia from "./components/administrar/Assistencia";
import Aviso from "@/components/Aviso";
import TituloPagina from "../../components/TituloPagina";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    components: {
        TituloPagina,
        BreadCrumbs,
        LoadingComponent,
        Assistencia,
        Aviso,
    },
    data() {
        return {
            isLoading: false,
            assistencia: null,
            caminho: [
                {
                    texto: "Minhas Assistências",
                    ativo: false,
                    rota: "assistencias",
                },
                {
                    texto: "Assistências Técnicas",
                    ativo: true,
                    rota: null,
                },
            ],
            aviso: {
                exibir: false,
                tipo: null,
                mensagem: null,
            },
            chaveComponentes: 0,
        };
    },

    created () {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Minhas Assistências', 'Minhas Assistências', 'Visualizar Assistência', true, false)
        );
    },


    beforeMount() {
        this.dadosAssistencia();
    },

    methods: {
        dadosAssistencia() {
            this.isLoading = true;
            getAssistenciaTecnica(this.$route.params.idAssistencia)
                .then((response) => {
                    this.assistencia = response.data;
                    var itens = [];
                    for (let elemento of response.data.itens) {
                        if (elemento.visita_liberada) {
                            itens.push(elemento);
                        }
                    }
                    this.assistencia.itens = itens;
                })
                .catch((error) => {
                    this.tratarErro(error.response.data);
                })
                .finally(() => {
                    this.chaveComponentes += 1;
                    this.isLoading = false;
                });
        },

        tratarErro(erro) {
            let mensagem =
                "Ocorreu um erro inesperado, por favor tente mais tarde.";
            if (erro.code != 422) {
                mensagem = erro.error;
            }

            this.aviso = {
                exibir: true,
                tipo: "erro",
                mensagem: mensagem,
            };
        },
    },
};
</script>
