export default {
    SET_ME(state, cliente) {
        state.cliente = cliente;
    },

    SET_SINDICO(state, sindico) {
        state.sindico = sindico;
    },
    
    LOGOUT(state) {
        state.cliente = {
            idpessoa: '',
            nome: '',
            telefone: '',
            celular: '',
            email: '',
            idunidade_padrao: null,
            endereco: {
                nome: '',
                numero: '',
                bairro: '',
                cep: '',
                complemento: '',
                estado: {
                    idestado: '',
                    nome: '',
                },
                cidade: {
                    idcidade: '',
                    nome: '',
                },
                logradouro: {
                    idlogradouro: '',
                    nome: '',
                }
            },
            avatar: null,
        };
        state.sindico = {
            idsindico: null,
            nome: '',
            email: '',
            celular: '',
        }
        state.login_origem = null;
        state.access_token = null;
        state.acesso_como = false;
        state.acesso_sindico = false;
        console.log('Logout do cliente efetuado')
    },

    SET_LOGIN(state, token) {
        state.access_token = token;
        state.acesso_como = false;
        state.acesso_sindico = false;
    },

    SET_LOGIN_COMO(state, token) {
        state.access_token = token;
        state.acesso_como = true;
        state.acesso_sindico = false;
    },

    SET_LOGIN_ORIGEM(state, origem) {
        state.login_origem = origem;
    },

    SET_LOGIN_SINDICO(state){
        state.acesso_sindico = true;
    },

    SET_LOGIN_CLIENTE(state){
        state.acesso_sindico = false;
    },

    TOGGLE_DOCUMENTO_INTERNACIONAL(state){
        state.documento_internacional = !state.documento_internacional;
    },
}
