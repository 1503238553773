<template>
    <div>
        <div class="modal fade" style="top: 10%" id="modalPropostas" tabindex="-1" role="dialog" aria-labelledby="comunicadoModalTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog" role="document">
            </div>
            <ModalReservasPendentes/>
        </div>

        <a v-if="mostrarBotao"
           data-toggle="modal"
           data-target="#modalPropostas"
           class="pc-btnBox pc-btnBox-icon-left pc-btnBox-primary">
            <IconAlert />
            <span>Propostas Pendentes</span>
        </a>
    </div>
</template>
<script>
import IconAlert from "@/components/icons/IconAlert";
import ModalReservasPendentes from "@/pages/home/components/ModalReservasPendentes";
import {getReservasPendentesDocumento} from "@/services/ReservaService";

export default {
    name: "PropostasPendentes",
    components: {
        IconAlert,
        ModalReservasPendentes
    },
    data() {
        return {
            quantidadeReservasPendentes: 0,
            mostrarBotao: false
        }
    },
    beforeMount() {
        getReservasPendentesDocumento().then((response) => {
            this.reservasPendentes = response.data;
            this.quantidadeReservasPendentes = Object.keys(this.reservasPendentes).length;
            if (this.quantidadeReservasPendentes > 0) {
                this.mostrarBotao = true;
            }
        }).catch()
        .finally();
    },
}
</script>
