<template>
    <div>
        <div class="pc-titleBloco">
            <div>
                <h3>Descrição da Solicitação</h3>
            </div>
        </div>
        <div class="historico-mensagem">
            <div class="linhadotempo-mensagens">
                <div class="box-mensagem mensagem">
                    <div class="header-mensagem">
                        <div class="perfil">
                            <div class="info-perfil">
                                <div class="linhadotempo-quando">
                                    <small>{{atendimento.cadastrado | dateParse("YYYY-MM-DD HH:mm:ss") | dateFormat("DD/MM/YYYY HH:mm:ss")}}</small>
                                </div>
                                <strong class="linhadotempo-quem">{{atendimento.pessoa.nome}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="info-mensagem">
                        <span class="cv-descricao-mensagem" v-html="quebraDeLinhaParaHtml(atendimento.descricao)"></span>

                        <div class="pc-files" v-if="atendimento.arquivos.length">
                            <span>Arquivo(s):</span>
                            <div class="pictures" v-for="arquivo in atendimento.arquivos">
                                <a :href="arquivo.url" :title="arquivo.nome" target="_blank">
                                    <IconFile />
                                    <span>{{arquivo.nome}} ({{formatBytes(arquivo.tamanho)}})</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {formatBytes, quebraDeLinhaParaHtml} from './../../../../plugins/Utils'
    export default {
        props: ["atendimento"],
        methods: {formatBytes, quebraDeLinhaParaHtml},
    };
</script>
