<template>
	<div class="pc-bloco">
		<Aviso :exibir="alerta.exibir" :mensagem="alerta.mensagem" :tipo="alerta.tipo" />
		<div class="pc-titleBloco" v-if="nomeItem != null">
			<h3>Item: {{ nomeItem }}</h3>

		</div>
		<div v-if="nomeItem != null">
			<LoadingComponent :isLoading="isLoading" />
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(enviarFormulario)" class="center-block">
					<div class="row">
						<div class="col-lg-3 col-4">
							<div class="form-group">
								<ValidationProvider rules="required" v-slot="{ errors }">
									<label for="data"><strong>* Período da Visita</strong></label>
									<datepicker input-class="form-control" name="data_de" id="data_de" :placeholder="placeHolderDataDe"
									:disabledDates="disabledDates"
									@selected="escolheuDataDe"
									format="dd/MM/yyyy"
									:value="dataEscolhidaDe"
									:language="ptBR"
									:dataDisable="dataDisable"
									:disabled="disabledDataDe">
									</datepicker>
									<label class="error"><span>{{ errors[0] }}</span></label>
								</ValidationProvider>
							</div>
						</div>

						<div class="col-lg-3 col-4">
							<div class="form-group">
								<SelectInput label="hora" titulo="Horário de Visita"
								:options="horariosDisponiveisDe"
								:reduce="(option) => option.hora"
								:clearable="false"
								:placeholder="placeHolderHoras"
								:valor.sync="horaEscolhidaDe"
								regras="required"
								@update:valor="escolheuHoraDe" />
							</div>
						</div>

						<div class="col-12" id="div_observacao">
							<div class="control-group">
								<ValidationProvider v-slot="{ errors }">
									<label class="control-label" for="observacao">
										<strong>Observação</strong>
									</label>
									<VueEditor v-model="formulario.observacao" :editorToolbar="customToolbar" />
									<label class="error">
										<span>{{ errors[0] }}</span>
									</label>
								</ValidationProvider>
							</div>
						</div>
						<div class="col-lg-4 col-12 btnEnviar">
							<br /><button :disabled="acessoComo" name="btSend" type="submit" value="Enviar"
																																					class="btn-form pc-btnBox pc-btnBox-secondary pc-btnBox-small m-t-3">
								Concluir Visita
							</button>
						</div>
					</div>
				</form>
			</ValidationObserver>
		</div>
		<div v-else>Por favor aguarde...</div>
	</div>

</template>

<script>
import SelectInput from "@/components/SelectInput";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import {
	retornarDatasDisponiveis,
	retornarHorarioDisponivel,
	retornarItensManutencao,
	cadastrarVisita
} from "@/services/AssistenciasService";
import LoadingComponent from "@/components/LoadingComponent";
import { VueEditor } from "vue2-editor";
import Aviso from "@/components/Aviso";
import { mapGetters } from "vuex";
import { objetoTraking } from "../../../../plugins/Utils";
import VueSessionStorage from "vue-sessionstorage";
import Vue from "vue";
Vue.use(VueSessionStorage);
export default {
	name: "FormularioVisita",
	props: ["assistencia"],
	components: {
		SelectInput,
		Datepicker,
		LoadingComponent,
		Aviso,
		VueEditor,
	},

	data() {
		return {
			nomeItem: null,
			disabledHoraDe: true,
			disabledDataDe: true,
			disabledHoraAte: true,
			disabledDataAte: true,
			isLoading: false,
			datasDisponiveis: [],
			horariosDisponiveisDe: [],
			horariosDisponiveisAte: [],
			dataEscolhidaDe: null,
			horaEscolhidaDe: null,
			dataEscolhidaAte: null,
			horaEscolhidaAte: null,
			minimo_dias_finalizacao: 0,
			ptBR: ptBR,
			dataDisable: true,
			disabledDates: {
				dates: [],
				customPredictor: function (dateCalendario) {
					var hoje = new Date();
					var dataMax = moment(dateCalendario);
					var dataInial = moment(hoje);
					var diferenca = dataMax.diff(dataInial, 'days');
					if (dateCalendario < hoje || diferenca > 90) {
						return true
					}
				}
			},
			disabledDatesAte: {
				dates: [],
				customPredictor: function (dateCalendario) {
					var hoje = new Date();
					var dataMax = moment(dateCalendario);
					var dataInial = moment(hoje);
					var diferenca = dataMax.diff(dataInial, 'days');
					if (dateCalendario < hoje || diferenca > 90) {
						return true
					}
				}
			},
			placeHolderDataDe: "- Buscando datas disponíveis -",
			placeHolderDataAte: "- Buscando datas disponíveis -",
			placeHolderHoras: "- Selecione uma data antes de escolher a hora -",
			alerta: {
				exibir: false,
				mensagem: null,
				tipo: null,
			},
			formulario: {
				idassistencia_item: this.$route.params.item,
				data_de: null,
				data_ate: null,
				hora_de: null,
				hora_ate: null,
				idequipe: null,
				observacao: null
			}, customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				["bold", "italic", "underline", "strike"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" },
				],
				["blockquote", "code-block"],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
				[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				["clean"], // remove formatting button
			],
		};
	},

	computed: {
		...mapGetters({
			acessoComo: "getAcessoComo",
		}),
	},
	mounted() {
		this.$session.set("idItem",this.$route.params.item);
	},
	
	beforeMount() {
		this.isLoading = true;
		this.formulario.idassistencia_item = this.$route.params.idAssistenciaItem;

		retornarItensManutencao(this.$route.params.item)
			.then((response) => {
				this.nomeItem = response.data[0].nome;
			})
			.catch((error) => { });

		let idItem = undefined;
		if (this.$route.params.item !== undefined) {
			idItem = this.$route.params.item;
		} else {
			idItem = this.$session.get("idItem")
		}

		if (idItem !== undefined) {
			retornarDatasDisponiveis(idItem, this.$route.params.idAssistencia).then((response) => {
				var retorno = response.data;
				this.formulario.idequipe = retorno.id_equipe;
				this.minimo_dias_finalizacao = retorno.minimo_dias_finalizacao;
				this.datasDisponiveis = retorno.dias_disponiveis;

				var datasDisponiveis = retorno.dias_disponiveis;
				let datasIndisponiveis = [];

				for (let x = 0; x < 92; x++) {
					var date = new Date();
					date = date.setDate(date.getDate() + x);

					let dataFormatada = moment(date).format("YYYY-MM-DD");
					if (!datasDisponiveis.includes(dataFormatada)) {
						let dataIndisponivel = dataFormatada.split('-');
						datasIndisponiveis.push(new Date(dataIndisponivel[0], dataIndisponivel[1] - 1, dataIndisponivel[2]));
					}
				}
				this.disabledDates.dates = datasIndisponiveis;
				this.disabledDataDe = false;
				this.placeHolderDataDe = "Selecione a data";

			})
				.catch((error) => {
					this.disabledDataDe = true;
					this.placeHolderHoras = "Nenhuma data disponível";
				});
		}
		this.isLoading = false;
	},
	methods: {
		escolheuDataDe(data) {
			this.formulario.data_de = moment(data).format("DD/MM/YYYY");
			this.dataEscolhidaDe = data;
			let dataCalendarioFormatada = moment(data).format("YYYY-MM-DD");

			retornarHorarioDisponivel(this.formulario.idequipe,
				this.$route.params.idAssistencia,
				dataCalendarioFormatada).then((response) => {
					this.horariosDisponiveis = response.data;

					var horarios = [];
					for (let elemento of response.data) {
						let hora = elemento;
						if (hora == 'T') {
							hora = 'Tarde';
						}
						if (hora == 'M') {
							hora = 'Manhã';
						}
						var horario = {
							hora: hora,
						};
						horarios.push(horario);
					}
					this.horariosDisponiveisDe = horarios;
					this.disabledHora = false;
					this.isLoading = false;
					this.placeHolderHoras = "Selecione um horário";
					this.horaEscolhidaDe = null;

				})
				.catch(() => {
					this.disabledHora = true;
					this.placeHolderHoras = "Nenhum horário disponível";
				})
				.finally(() => {
					this.isLoading = false;
				});

			var datasIndisponiveisAte = [...this.disabledDates.dates];
			let ajusteDataMinima = 1;
			if (this.minimo_dias_finalizacao > 0) {
				ajusteDataMinima = 0;
			}
			for (let elemento of this.datasDisponiveis) {
				if (elemento > dataCalendarioFormatada) {
					break;
				}
				let dataIndisponivel = elemento.split('-');
				datasIndisponiveisAte.push(new Date(dataIndisponivel[0], dataIndisponivel[1] - 1, dataIndisponivel[2] - ajusteDataMinima));

			}

			for (let x = 0; x <= this.minimo_dias_finalizacao; x++) {
				var date = new Date(dataCalendarioFormatada);
				date = date.setDate(date.getDate() + x);
				let dataFormatada = moment(date).format("YYYY-MM-DD");
				let dataIndisponivel = dataFormatada.split('-');

				datasIndisponiveisAte.push(new Date(dataIndisponivel[0], dataIndisponivel[1] - 1, dataIndisponivel[2]));

			}

			this.disabledDatesAte.dates = datasIndisponiveisAte;
			this.disabledDataAte = false;
			this.placeHolderDataAte = "Selecione a data";

			this.escolheuDataAte(data)
		},
		escolheuDataAte(data) {
			this.formulario.data_ate = moment(data).format("DD/MM/YYYY");
			this.dataEscolhidaAte = data;
			let dataCalendarioFormatada = moment(data).format("YYYY-MM-DD");

			retornarHorarioDisponivel(this.formulario.idequipe, this.$route.params.idAssistencia, dataCalendarioFormatada).then((response) => {
				this.horariosDisponiveis = response.data;

				var horarios = [];
				for (let elemento of response.data) {
					var horario = {
						hora: elemento,
					};
					horarios.push(horario);
				}
				this.horariosDisponiveisAte = horarios;
				this.disabledHora = false;
				this.isLoading = false;
				this.placeHolderHoras = "Selecione um horário";
				this.horaEscolhidaAte = null;
			})
				.catch(() => {
					this.disabledHora = true;
					this.placeHolderHoras = "Nenhum horário disponível";
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		async enviarFormulario() {
			if (this.acessoComo) {
				return;
			}
			this.isLoading = true;
			let dataDe = '';
			let dataAte = '';
			if (this.formulario.hora_de == 'Manhã') {
				dataDe = this.formulario.data_de + ' ' + 'M';
				dataAte = this.formulario.data_ate + ' ' + 'M';
			}
			else if (this.formulario.hora_de == 'Tarde') {
				dataDe = this.formulario.data_de + ' ' + 'T';
				dataAte = this.formulario.data_ate + ' ' + 'T';
			}
			 else {
				dataDe = this.formulario.data_de + ' ' + this.formulario.hora_de + ':00';
				dataAte = this.formulario.data_ate + ' ' + this.formulario.hora_de + ':00';
			}
			var arrayForm = {
				idassistencia_item: this.formulario.idassistencia_item,
				periodo_data_de: dataDe,
				periodo_data_ate: dataAte,
				idequipe: this.formulario.idequipe,
				observacao: this.formulario.observacao
			};
			this.trakingMixpanel("Nova Visita Técnica", "Criar Visita Técnica", true, true);
			await cadastrarVisita(this.$route.params.idAssistencia, arrayForm)
				.then((response) => {
					this.$router.push({
						name: "assistencia",
						params: { idAssistencia: this.$route.params.idAssistencia },
					});
				})
				.catch((error) => {
					this.alerta = {
						exibir: true,
						mensagem: error.response.data.message,
						tipo: "erro",
					};
				});
			this.isLoading = false;
		},
		escolheuHoraDe(hora) {
			this.formulario.hora_de = hora;
		},
		escolheuHoraAte(hora) {
			this.formulario.hora_ate = hora;
		},
		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Visita técnica",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);

		}
	}
};
</script>
